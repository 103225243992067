/* eslint-disable redux-saga/yield-effects */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import _ from 'lodash';
import {
  CREATE_CUSTOMER,
  DELETE_DISCOUNT_ITEM,
  INIT_CUSTOMER,
  UPDATE_CUSTOMER,
} from './constants';
import { getSequenceNumber } from '../../graphql/queries';
import {
  createCustomerError,
  createCustomerSuccess,
  deleteDiscountItemError,
  deleteDiscountItemSuccess,
  initCustomerError,
  initCustomerSuccess,
  initEmptyCustomerSuccess,
  updateCustomerError,
  updateCustomerSuccess,
} from './actions';
import {
  makeSelectCustomer,
  makeSelectDiscountItems,
  makeSelectSavedDiscountItems,
} from './selectors';
import { CUSTOMER_TYPE, SEQUENCE_NUMBER_TYPE } from '../../constants';
import { updateSequenceNumber } from '../../graphql/mutations';
import { getProducts } from '../App/saga';
import { selectProducts } from '../App/selectors';

export const getCustomerGraphql = `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      type
      deliveryRouteId
      _version
      discounts {
        items {
          id
          discount
          productId
          _version
          _deleted
        }
      }
    }
  }
`;

export const createCustomerGraphql = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      deliveryRouteId
      createdAt
      _version
    }
  }
`;

export const updateCustomerGraphql = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      deliveryRouteId
      createdAt
      _version
    }
  }
`;

export const createDiscountGraphql = /* GraphQL */ `
  mutation CreateDiscount(
    $input: CreateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    createDiscount(input: $input, condition: $condition) {
      id
      discount
      productId
      _version
      _deleted
    }
  }
`;

export const updateDiscountGraphql = /* GraphQL */ `
  mutation UpdateDiscount(
    $input: UpdateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    updateDiscount(input: $input, condition: $condition) {
      id
      discount
      productId
      _version
      _deleted
    }
  }
`;

export const deleteDiscountGraphql = /* GraphQL */ `
  mutation DeleteDiscount(
    $input: DeleteDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    deleteDiscount(input: $input, condition: $condition) {
      id
      discount
      productId
      _version
    }
  }
`;

/* eslint-disable func-names */
function* createCustomerSequenceNumber() {
  const result = yield call(
    [API, 'graphql'],
    graphqlOperation(getSequenceNumber, {
      id: SEQUENCE_NUMBER_TYPE.CUSTOMER_SEQUENCE_NUMBER_ID,
    }),
  );

  const { sequenceNumber } = result.data.getSequenceNumber;

  yield call(
    [API, 'graphql'],
    graphqlOperation(updateSequenceNumber, {
      input: {
        id: SEQUENCE_NUMBER_TYPE.CUSTOMER_SEQUENCE_NUMBER_ID,
        sequenceNumber: sequenceNumber + 1,
      },
    }),
  );

  return sequenceNumber;
}

function createDiscountItems(products, customerType, discountItems) {
  return discountItems.map(({ id, productId, discount, _version }) => {
    const { prices } = products[productId];
    const specialPrice = _.find(prices, ['type', 'SPECIAL']);
    const defaultPrice = _.find(prices, ['type', 'DEFAULT']);
    const unitPrice =
      !_.isEmpty(specialPrice) &&
      (customerType === CUSTOMER_TYPE.MEMBER ||
        customerType === CUSTOMER_TYPE.OTHER_COMPANY_CUSTOMER)
        ? specialPrice.price
        : defaultPrice.price;
    return {
      id,
      productId,
      discount,
      unitPrice,
      _version,
    };
  });
}

function createCustomer(key) {
  return function* () {
    try {
      const customer = yield select(makeSelectCustomer(key));
      const discountItems = yield select(makeSelectDiscountItems(key));
      const input = { ...customer };
      input.sequenceNumber = yield createCustomerSequenceNumber();
      if (!input.deliveryRouteId) {
        delete input.deliveryRouteId;
      }
      const result = yield call(
        [API, 'graphql'],
        graphqlOperation(createCustomerGraphql, { input }),
      );
      const createdCustomer = result.data.createCustomer;
      const { id: customerId, type } = createdCustomer;
      const discountCalls = discountItems.map(({ productId, discount }) =>
        call(
          [API, 'graphql'],
          graphqlOperation(createDiscountGraphql, {
            input: {
              customerId,
              productId,
              discount,
            },
          }),
        ),
      );

      const results = yield all(discountCalls);
      const products = yield select(selectProducts);
      const createdDiscountItems = createDiscountItems(
        products,
        type,
        _.map(results, 'data.createDiscount'),
      );
      yield put(
        createCustomerSuccess(key, createdCustomer, createdDiscountItems),
      );
    } catch {
      yield put(createCustomerError(key));
    }
  };
}

function updateDiscountItemIds(discountItems, results) {
  const discountItemsResults = _.flow([
    _.partialRight(
      _.map,
      result => result.data.updateDiscount || result.data.createDiscount,
    ),
    _.partialRight(_.keyBy, 'productId'),
  ])(results);

  return discountItems.map(discountItem => {
    const { productId } = discountItem;
    const discountItemsResult = _.get(discountItemsResults, productId);
    if (discountItemsResult) {
      const { id, _version } = discountItemsResult;
      return {
        ...discountItem,
        id,
        _version,
      };
    }
    return {
      ...discountItem,
    };
  });
}

function updateCustomer(key) {
  return function* () {
    try {
      const customer = yield select(makeSelectCustomer(key));
      const discountItems = yield select(makeSelectDiscountItems(key));
      const savedDiscountItems = yield select(
        makeSelectSavedDiscountItems(key),
      );
      const input = { ...customer };
      if (!input.deliveryRouteId) {
        input.deliveryRouteId = null;
      }
      const result = yield call(
        [API, 'graphql'],
        graphqlOperation(updateCustomerGraphql, { input }),
      );
      const updatedCustomer = result.data.updateCustomer;

      const { id: customerId } = updatedCustomer;
      const updatedDiscountItems = _.differenceBy(
        discountItems,
        savedDiscountItems,
        ['id', 'productId', 'discount'],
      );
      const deletedDiscountItems = _.differenceBy(
        savedDiscountItems,
        discountItems,
        'id',
      );

      const updatedDiscountCalls = updatedDiscountItems.map(
        ({ id, productId, discount, _version }) => {
          if (id) {
            return call(
              [API, 'graphql'],
              graphqlOperation(updateDiscountGraphql, {
                input: {
                  id,
                  productId,
                  discount,
                  _version,
                },
              }),
            );
          }
          return call(
            [API, 'graphql'],
            graphqlOperation(createDiscountGraphql, {
              input: {
                customerId,
                productId,
                discount,
              },
            }),
          );
        },
      );
      const deletedDiscountCalls = deletedDiscountItems.map(({ id }) =>
        call(
          [API, 'graphql'],
          graphqlOperation(deleteDiscountGraphql, {
            input: { id },
          }),
        ),
      );
      yield all(deletedDiscountCalls);

      const results = yield all(updatedDiscountCalls);
      yield put(
        updateCustomerSuccess(
          key,
          updatedCustomer,
          updateDiscountItemIds(discountItems, results),
        ),
      );
    } catch {
      yield put(updateCustomerError(key));
    }
  };
}

/* eslint-disable no-param-reassign */
function initCustomer(key) {
  return function* (action) {
    try {
      yield call(getProducts);
      const products = yield select(selectProducts);
      const { customerId } = action.payload;
      if (customerId) {
        const result = yield call(
          [API, 'graphql'],
          graphqlOperation(getCustomerGraphql, { id: customerId }),
        );
        const {
          id,
          name,
          type,
          sequenceNumber,
          phoneNumber,
          email,
          address,
          subdistrict,
          district,
          province,
          postalCode,
          discounts,
          deliveryRouteId,
          _version,
        } = result.data.getCustomer;
        const discountItemsResult = _.reject(discounts.items, [
          '_deleted',
          true,
        ]);
        const discountItems = createDiscountItems(
          products,
          type,
          discountItemsResult,
        );
        const customer = {
          id,
          name,
          type,
          sequenceNumber,
          phoneNumber,
          email,
          address,
          subdistrict,
          district,
          province,
          postalCode,
          deliveryRouteId,
          _version,
        };
        yield put(initCustomerSuccess(key, customer, discountItems));
      } else {
        yield put(initEmptyCustomerSuccess(key));
      }
    } catch {
      yield put(initCustomerError(key));
    }
  };
}

function deleteDiscountItem(key) {
  return function* (action) {
    try {
      const { idx } = action.payload;
      const discountItems = yield select(makeSelectDiscountItems(key));
      const discountItem = discountItems[idx];
      const { id, _version } = discountItem;
      if (id) {
        const input = { id, _version };
        yield call(
          [API, 'graphql'],
          graphqlOperation(deleteDiscountGraphql, { input }),
        );
      }
      yield put(deleteDiscountItemSuccess(key, idx));
    } catch {
      yield put(deleteDiscountItemError(key));
    }
  };
}

export default function createCustomerSaga(key) {
  return function* () {
    yield takeLatest(`${key}/${INIT_CUSTOMER}`, initCustomer(key));
    yield takeLatest(`${key}/${CREATE_CUSTOMER}`, createCustomer(key));
    yield takeLatest(`${key}/${UPDATE_CUSTOMER}`, updateCustomer(key));
    yield takeLatest(`${key}/${DELETE_DISCOUNT_ITEM}`, deleteDiscountItem(key));
  };
}
