import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { createStructuredSelector } from 'reselect';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import {
  makeSelectError,
  makeSelectLoading,
  makeSelectOverdueTruckAssignments,
  makeSelectShowNext,
  makeSelectShowPrevious,
} from './selectors';
import {
  getNextOverdueTruckAssignments,
  getOverdueTruckAssignments,
  getPreviousOverdueTruckAssignments,
  refreshOverdueTruckAssignments,
} from './actions';
import { SNACKBAR_VARIANT_TYPE } from '../../constants';
import TruckAssignmentSearchResultTable from '../../components/TruckAssignmentSearchResultTable';
import useEffectAfterFirstRender from '../../utils/uesEffectAfterFirstRender';

const stateSelector = createStructuredSelector({
  error: makeSelectError,
  loading: makeSelectLoading,
  showNext: makeSelectShowNext,
  showPrevious: makeSelectShowPrevious,
  overdueTruckAssignments: makeSelectOverdueTruckAssignments,
});

function OverdueTruckAssignmentList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { error, loading, showNext, showPrevious, overdueTruckAssignments } =
    useSelector(stateSelector);

  useEffect(() => {
    dispatch(getOverdueTruckAssignments());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
    }
  }, [enqueueSnackbar, error]);

  useEffectAfterFirstRender(() => {
    if (!overdueTruckAssignments.length && !error) {
      enqueueSnackbar('ไม่พบข้อมูล', {
        variant: SNACKBAR_VARIANT_TYPE.WARNING,
      });
    }
  }, [enqueueSnackbar, overdueTruckAssignments]);

  return (
    <Container>
      <TruckAssignmentSearchResultTable
        loading={loading}
        showNext={showNext}
        showPrevious={showPrevious}
        assignments={overdueTruckAssignments}
        next={() => dispatch(getNextOverdueTruckAssignments())}
        previous={() => dispatch(getPreviousOverdueTruckAssignments())}
        refresh={() => dispatch(refreshOverdueTruckAssignments())}
        component={({ children }) => (
          <Paper>
            <Box p={2}>
              <Typography variant="h6">ชำระเงิน</Typography>
            </Box>
            <Divider />
            {children}
          </Paper>
        )}
      />
    </Container>
  );
}

export default OverdueTruckAssignmentList;
