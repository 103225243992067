import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import brown from '@material-ui/core/colors/brown';
import { CommaSeparatedNumber } from '../../shared';
import { ORDER_TYPE, PRODUCT_TYPE } from '../../constants';

const useStyles = makeStyles({
  lpg: {
    backgroundColor: grey[50],
  },
  nonLpg: {
    backgroundColor: brown[50],
  },
});

function ReportTableExpandedRow({ orderType, orderItem }) {
  const classes = useStyles();
  const { name, type: orderItemType, weight, quantity, subtotal } = orderItem;
  const isSalesOrder = orderType === ORDER_TYPE.SALES_ORDER;
  const isLpgOrLpgContainer =
    orderItemType === PRODUCT_TYPE.LPG ||
    orderItemType === PRODUCT_TYPE.CONTAINER;
  return (
    <TableRow
      className={
        orderItemType !== PRODUCT_TYPE.LPG ? classes.nonLpg : classes.lpg
      }
    >
      <TableCell align="right" colSpan={7}>
        {orderItemType !== PRODUCT_TYPE.LPG ? `${name}*` : name}
      </TableCell>
      <TableCell align="center">
        <CommaSeparatedNumber value={weight === 15 ? quantity : ''} />
      </TableCell>
      <TableCell align="center">
        <CommaSeparatedNumber value={weight === 48 ? quantity : ''} />
      </TableCell>
      <TableCell align="center">
        <CommaSeparatedNumber value={weight === 4 ? quantity : ''} />
      </TableCell>
      <TableCell align="center">
        <CommaSeparatedNumber value={weight === 7 ? quantity : ''} />
      </TableCell>
      <TableCell align="center">
        <CommaSeparatedNumber value={weight === 9 ? quantity : ''} />
      </TableCell>
      <TableCell align="center">
        <CommaSeparatedNumber value={weight === 11.5 ? quantity : ''} />
      </TableCell>
      <TableCell align="center">
        <CommaSeparatedNumber value={weight === 13.5 ? quantity : ''} />
      </TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber
          value={isLpgOrLpgContainer && isSalesOrder ? weight * quantity : ''}
          fixedDecimalScale
        />
      </TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber
          value={isLpgOrLpgContainer && !isSalesOrder ? weight * quantity : ''}
          fixedDecimalScale
        />
      </TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber value={subtotal} fixedDecimalScale />
      </TableCell>
      <TableCell colSpan={3} />
    </TableRow>
  );
}

export default ReportTableExpandedRow;
