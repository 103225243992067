import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import MuiImageList from '@material-ui/core/ImageList';
import MuiImageListItem from '@material-ui/core/ImageListItem';
import MuiImageListItemBar from '@material-ui/core/ImageListItemBar';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const ImageListItem = withStyles({
  root: {
    textAlign: 'center',
  },
})(MuiImageListItem);

const ImageListItemBar = withStyles({
  root: {
    background: 'transparent',
  },
})(MuiImageListItemBar);

/* eslint-disable react/no-array-index-key */
function ImageList({ images, disabled, onRemove }) {
  const theme = useTheme();
  const isLargerThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const cols = useMemo(() => {
    if (isLargerThanLg) {
      return 4;
    }
    if (isLargerThanMd) {
      return 3;
    }
    if (isLargerThanSm) {
      return 2;
    }
    return 1;
  }, [isLargerThanLg, isLargerThanMd, isLargerThanSm]);
  return (
    <MuiImageList rowHeight={185} cols={cols}>
      {images.map((image, idx) => (
        <ImageListItem key={idx}>
          <Image src={URL.createObjectURL(image)} />
          <ImageListItemBar
            position="top"
            actionIcon={
              <IconButton disabled={disabled} onClick={() => onRemove(idx)}>
                <CancelIcon color={disabled ? 'disabled' : 'secondary'} />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </MuiImageList>
  );
}

export default ImageList;
