import lightBlue from '@material-ui/core/colors/lightBlue';
import green from '@material-ui/core/colors/green';
import { styled } from '@material-ui/core/styles';
import MaterialTableCell from '@material-ui/core/TableCell';

function getTableCellColor(weight) {
  switch (weight) {
    case 15.0:
      return lightBlue[50];
    case 48.0:
      return green[50];
    default:
      return 'white';
  }
}

const TableCell = styled(MaterialTableCell)(
  ({ theme, weight, bt = 0, br = 1, bb = 1, bl = 0 }) => ({
    whiteSpace: 'nowrap',
    borderStyle: 'solid',
    borderTopWidth: bt,
    borderLeftWidth: bl,
    borderBottomWidth: bb,
    borderRightWidth: br,
    borderColor: theme.palette.divider,
    backgroundColor: getTableCellColor(weight),
  }),
);

export default TableCell;
