import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import Container from '@material-ui/core/Container';
import Paper from '../../components/Paper';
import DebtorSearchForm from './DebtorSearchForm';
import {
  clearDebtor,
  refreshDebtors,
  searchDebtors,
  searchNextDebtors,
  searchPreviousDebtors,
  setDebtor,
  setName,
  setSequenceNumber,
  setSortField,
} from './actions';
import {
  makeSelectDebtor,
  makeSelectDebtors,
  makeSelectError,
  makeSelectLoading,
  makeSelectShowNext,
  makeSelectShowPrevious,
  makeSelectSort,
} from './selectors';
import DebtorTable from './DebtorTable';

const stateSelector = createStructuredSelector({
  debtor: makeSelectDebtor,
  sort: makeSelectSort,
  debtors: makeSelectDebtors,
  showNext: makeSelectShowNext,
  showPrevious: makeSelectShowPrevious,
  error: makeSelectError,
  loading: makeSelectLoading,
});

function DebtorList() {
  const dispatch = useDispatch();
  const {
    debtor: { name, sequenceNumber },
    sort: { field, asc },
    debtors,
    loading,
    showNext,
    showPrevious,
  } = useSelector(stateSelector);

  useEffect(() => {
    dispatch(searchDebtors());
  }, []);

  return (
    <Container>
      <Paper elevation={0}>
        <Typography variant="h6">ค้นหาลูกหนี้</Typography>
        <Box mt={2}>
          <DebtorSearchForm
            name={name}
            sequenceNumber={sequenceNumber}
            loading={loading}
            onSubmit={() => dispatch(searchDebtors())}
            onReset={() => dispatch(clearDebtor())}
            onInputChange={name => dispatch(setName(name))}
            onChange={(event, customer) =>
              dispatch(setDebtor(!isEmpty(customer) ? customer : null))
            }
            onSequenceNumberChange={e =>
              dispatch(setSequenceNumber(e.target.value))
            }
          />
        </Box>
      </Paper>
      <Box mt={3}>
        <DebtorTable
          debtors={debtors}
          showNext={showNext}
          showPrevious={showPrevious}
          sortField={field}
          loading={loading}
          sortDirection={asc ? 'asc' : 'desc'}
          next={() => dispatch(searchNextDebtors())}
          previous={() => dispatch(searchPreviousDebtors())}
          refresh={() => dispatch(refreshDebtors())}
          onSort={field => dispatch(setSortField(field))}
        />
      </Box>
    </Container>
  );
}

export default DebtorList;
