import { action } from 'typesafe-actions';
import {
  CLEAR_SEARCH_PARAMS,
  EXPORT_TRUCK_ASSIGNMENTS,
  EXPORT_TRUCK_ASSIGNMENTS_ERROR,
  EXPORT_TRUCK_ASSIGNMENTS_SUCCESS,
  REFRESH_TRUCK_ASSIGNMENTS,
  SEARCH_NEXT_TRUCK_ASSIGNMENTS,
  SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS,
  SEARCH_TRUCK_ASSIGNMENTS,
  SEARCH_TRUCK_ASSIGNMENTS_ERROR,
  SEARCH_TRUCK_ASSIGNMENTS_SUCCESS,
  SET_SEARCH_PARAM,
} from './constants';

export const setSearchParam = (param, value) =>
  action(SET_SEARCH_PARAM, {
    param,
    value,
  });

export const clearSearchParams = () => action(CLEAR_SEARCH_PARAMS);

export const searchTruckAssignments = () => action(SEARCH_TRUCK_ASSIGNMENTS);

export const searchTruckAssignmentsSuccess = (truckAssignments, nextToken) =>
  action(SEARCH_TRUCK_ASSIGNMENTS_SUCCESS, {
    assignments: truckAssignments,
    nextToken,
  });

export const searchTruckAssignmentsError = () =>
  action(SEARCH_TRUCK_ASSIGNMENTS_ERROR);

export const searchNextTruckAssignments = () =>
  action(SEARCH_NEXT_TRUCK_ASSIGNMENTS);

export const searchPreviousTruckAssignments = () =>
  action(SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS);

export const refreshTruckAssignments = () => action(REFRESH_TRUCK_ASSIGNMENTS);

export const exportTruckAssignments = (nextToken?: string) =>
  action(EXPORT_TRUCK_ASSIGNMENTS, { nextToken });

export const exportTruckAssignmentsSuccess = (nextToken: string) =>
  action(EXPORT_TRUCK_ASSIGNMENTS_SUCCESS, { nextToken });

export const exportTruckAssignmentsError = () =>
  action(EXPORT_TRUCK_ASSIGNMENTS_ERROR);
