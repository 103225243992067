export const getTruckAssignment = /* GraphQL */ `
  query GetTruckAssignment($id: ID!) {
    getTruckAssignment(id: $id) {
      id
      driver
      companion
      departure
      station
      licensePlate
      deliveryRouteId
      status
      sequenceNumber
      comment
      deliveryRoute {
        id
        name
        licensePlate
        customers {
          nextToken
        }
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          customer {
            id
            name
            phoneNumber
          }
          orderItems {
            items {
              productId
              quantity
              unitPrice
              adjustment
            }
          }
        }
        nextToken
      }
      payments {
        items {
          id
          amount
          type
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createTruckAssignmentPayment = /* GraphQL */ `
  mutation CreateTruckAssignmentPayment(
    $input: CreateTruckAssignmentPaymentInput
  ) {
    createTruckAssignmentPayment(input: $input) {
      id
      driver
      companion
      station
      deliveryRouteId
      sequenceNumber
      departure
      status
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
