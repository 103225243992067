import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import DrawerLink from './DrawerLink';
import useHasPermission from '../../utils/useHasPermission';

export default function DrawerCustomerLinks() {
  const [openCollapse, setOpenCollapse] = useState(false);
  const hasCustomerPermission = useHasPermission(['Admin', 'Accounting']);
  const hasCreateCustomerPermission = useHasPermission(['Admin', 'Order']);
  return (
    <>
      <ListItem button onClick={() => setOpenCollapse(!openCollapse)}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="ลูกค้า" />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasCustomerPermission && (
            <DrawerLink
              paddingLeft
              to="/customer"
              title="ค้นหาลูกค้า"
              icon={<SearchIcon />}
            />
          )}
          {hasCreateCustomerPermission && (
            <DrawerLink
              paddingLeft
              to="/createCustomer"
              title="เพิ่มลูกค้า"
              icon={<PersonAddIcon />}
            />
          )}
        </List>
      </Collapse>
    </>
  );
}
