import React from 'react';
import moment from 'moment-timezone';
import TableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import { CommaSeparatedNumber } from '../../shared';
import TableCell from '../../components/TableCell';

function AdjustmentHistoryTableRow({ idx, adjustment }) {
  const {
    id,
    amount,
    comment,
    createdBy: { firstName, lastName },
    createdAt,
  } = adjustment;
  return (
    <TableRow key={id}>
      <TableCell align="center" padding="none">
        {idx + 1}
      </TableCell>
      <TableCell align="left">{`${firstName} ${lastName}`}</TableCell>
      <TableCell align="left">
        {moment(createdAt).format('DD/MM/YYYY HH:mm')}
      </TableCell>
      <MuiTableCell align="left">{comment}</MuiTableCell>
      <TableCell align="left">
        {amount > 0 ? 'ค่าใช้เพิ่มเติม' : 'ส่วนลด'}
      </TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber value={amount} fixedDecimalScale />
      </TableCell>
    </TableRow>
  );
}

export default AdjustmentHistoryTableRow;
