import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DrawerLink from './DrawerLink';

function DrawerReportLinks() {
  const [openCollapse, setOpenCollapse] = useState(false);
  return (
    <>
      <ListItem button onClick={() => setOpenCollapse(!openCollapse)}>
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText primary="รายการ" />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <DrawerLink
            paddingLeft
            to="/report"
            title="รายงานขาย"
            icon={<AssessmentIcon />}
          />
          <DrawerLink
            paddingLeft
            to="/fulfillmentReport"
            title="รายงานบรรจุแก๊ส"
            icon={<LocalGasStationIcon />}
          />
        </List>
      </Collapse>
    </>
  );
}

export default DrawerReportLinks;
