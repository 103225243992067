import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectValidator } from 'react-material-ui-form-validator';
import { STATION, STATION_DESC } from '../../constants';

function ReportStationSelectValidator({ station, onChange }) {
  return (
    <SelectValidator
      fullWidth
      margin="none"
      label="โรงอัดแก๊ส"
      variant="outlined"
      value={station}
      onChange={onChange}
    >
      <MenuItem value="ALL">ทั้งหมด</MenuItem>
      <MenuItem value={STATION.UNIQUE_GAS_STATION}>
        {STATION_DESC.UNIQUE_GAS_STATION}
      </MenuItem>
      <MenuItem value={STATION.WORLD_GAS_STATION}>
        {STATION_DESC.WORLD_GAS_STATION}
      </MenuItem>
      <MenuItem value={STATION.PTT_STATION}>
        {STATION_DESC.PTT_STATION}
      </MenuItem>
    </SelectValidator>
  );
}

export default ReportStationSelectValidator;
