import { ReactNode } from 'react';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setHideDrawer } from '../App/actions';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

interface DrawerLinkProps {
  to: string;
  title: string;
  icon: ReactNode;
  paddingLeft?: boolean;
}

function DrawerLink({ to, title, icon, paddingLeft = false }: DrawerLinkProps) {
  const dispatch = useDispatch();
  return (
    <MuiLink
      to={to}
      component={Link}
      underline="none"
      color="textPrimary"
      onClick={() => dispatch(setHideDrawer())}
    >
      <ListItem button>
        <ListItemIcon sx={{ pl: paddingLeft ? 2 : 0 }}>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    </MuiLink>
  );
}

export default DrawerLink;
