import React, { useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface DatePickerButtonProps {
  value: any;
  onChange: (value: any) => void;
}

function DatePickerButton({ value, onChange }: DatePickerButtonProps) {
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        open={open}
        disableFuture
        value={value}
        onChange={onChange}
        onClose={() => setOpen(false)}
        renderInput={params => (
          <Box>
            <Button color="primary" onClick={() => setOpen(!open)}>
              เปลี่ยนวันที่
            </Button>
            <TextField
              {...params}
              sx={{ height: 0, width: 0, visibility: 'hidden' }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
}

export default DatePickerButton;
