import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { API, graphqlOperation } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import {
  getNextOverdueOrders,
  getOverdueOrders,
  getPreviousOverdueOrders,
  refreshOverdueOrders,
} from './actions';
import {
  makeSelectError,
  makeSelectLoading,
  makeSelectOverdueOrders,
  makeSelectShowNext,
  makeSelectShowPrevious,
} from './selectors';
import OrderTable, { ORDER_TABLE_COLUMNS } from '../../components/OrderTable';
import { onBatchUpdateOrders } from '../../graphql/subscriptions';

const stateSelector = createStructuredSelector({
  overdueOrders: makeSelectOverdueOrders,
  showNext: makeSelectShowNext,
  showPrevious: makeSelectShowPrevious,
  error: makeSelectError,
  loading: makeSelectLoading,
});

function Cashier() {
  const dispatch = useDispatch();
  const { overdueOrders, loading, showNext, showPrevious } =
    useSelector(stateSelector);

  useEffect(() => {
    dispatch(getOverdueOrders());
    const subscription = API.graphql(
      graphqlOperation(onBatchUpdateOrders),
    ).subscribe({
      next: () => dispatch(getOverdueOrders()),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <Container>
      <OrderTable
        orders={overdueOrders}
        loading={loading}
        showNext={showNext}
        showPrevious={showPrevious}
        next={() => dispatch(getNextOverdueOrders())}
        previous={() => dispatch(getPreviousOverdueOrders())}
        refresh={() => dispatch(refreshOverdueOrders())}
        columns={{
          [ORDER_TABLE_COLUMNS.SEQUENCE_NUMBER]: true,
          [ORDER_TABLE_COLUMNS.NAME]: true,
          [ORDER_TABLE_COLUMNS.TYPE]: true,
          [ORDER_TABLE_COLUMNS.STATUS]: true,
          [ORDER_TABLE_COLUMNS.STATION]: true,
          [ORDER_TABLE_COLUMNS.SUBTOTAL]: true,
          [ORDER_TABLE_COLUMNS.CREATED_AT]: true,
        }}
        component={({ children }) => (
          <Paper variant="outlined">
            <Box p={2}>
              <Typography variant="h6">ชำระเงิน</Typography>
            </Box>
            <Divider />
            {children}
          </Paper>
        )}
      />
    </Container>
  );
}

export default Cashier;
