import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TableRow from '@material-ui/core/TableRow';
import isFunction from 'lodash/isFunction';

function AddAdjustmentRow({ disabled, onClick }) {
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={onClick}
          startIcon={<AttachMoneyIcon />}
          disabled={disabled || !isFunction(onClick)}
        >
          เพิ่มส่วนลด / ค่าใช้จ่าย
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default AddAdjustmentRow;
