import React, { forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import CreateOrderQueueCardHeader from './CreateOrderQueueCardHeader';
import CreateOrderQueueCardTable from '../../components/CreateOrderQueueCardTable';

function CreateOrderQueueCardContent({ order, orderItems }, ref) {
  return (
    <Box display="flex" flexDirection="column" ref={ref}>
      <CreateOrderQueueCardHeader order={order} />
      <Box mt={1}>
        <CreateOrderQueueCardTable orderItems={orderItems} />
      </Box>
    </Box>
  );
}

export default forwardRef(CreateOrderQueueCardContent);
