import React, { forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TruckAssignmentQueueCardHeader from './TruckAssignmentQueueCardHeader';
import CreateOrderQueueCardTable from '../CreateOrderQueueCardTable';

function TruckAssignmentQueueCard({ truckAssignment, orderItems }, ref) {
  return (
    <Box ref={ref} display="none" displayPrint="flex" flexDirection="column">
      <Typography variant="h6">
        <b>{`ใบสั่งซื้อรถเร่เลขที่ ${truckAssignment.sequenceNumber}`}</b>
      </Typography>
      <Box mt={1}>
        <TruckAssignmentQueueCardHeader truckAssignment={truckAssignment} />
      </Box>
      <Box mt={1}>
        <CreateOrderQueueCardTable orderItems={orderItems} />
      </Box>
    </Box>
  );
}

export default forwardRef(TruckAssignmentQueueCard);
