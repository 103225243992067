export const GET_DELIVERY_ROUTE =
  'CreateOrUpdateTruckAssignment/GET_DELIVERY_ROUTE';
export const GET_DELIVERY_ROUTE_SUCCESS =
  'CreateOrUpdateTruckAssignment/GET_DELIVERY_ROUTE_SUCCESS';
export const GET_DELIVERY_ROUTE_ERROR =
  'CreateOrUpdateTruckAssignment/GET_DELIVERY_ROUTE_ERROR';
export const SUBMIT_TRUCK_ASSIGNMENT =
  'CreateOrUpdateTruckAssignment/SUBMIT_TRUCK_ASSIGNMENT';
export const SUBMIT_TRUCK_ASSIGNMENT_SUCCESS =
  'CreateOrUpdateTruckAssignment/SUBMIT_TRUCK_ASSIGNMENT_SUCCESS';
export const SUBMIT_TRUCK_ASSIGNMENT_ERROR =
  'CreateOrUpdateTruckAssignment/SUBMIT_TRUCK_ASSIGNMENT_ERROR';
export const SET_ORDER = 'CreateOrUpdateTruckAssignment/SET_ORDER';
export const SET_TRUCK_ASSIGNMENT =
  'CreateOrUpdateTruckAssignment/SET_TRUCK_ASSIGNMENT';
export const RESET_TRUCK_ASSIGNMENT =
  'CreateOrUpdateTruckAssignment/RESET_TRUCK_ASSIGNMENT';
export const UPDATE_TRUCK_ASSIGNMENT =
  'CreateOrUpdateTruckAssignment/UPDATE_TRUCK_ASSIGNMENT';
export const GET_TRUCK_ASSIGNMENT =
  'CreateOrUpdateTruckAssignment/GET_TRUCK_ASSIGNMENT';
export const GET_TRUCK_ASSIGNMENT_SUCCESS =
  'CreateOrUpdateTruckAssignment/GET_TRUCK_ASSIGNMENT_SUCCESS';
export const GET_TRUCK_ASSIGNMENT_ERROR =
  'CreateOrUpdateTruckAssignment/GET_TRUCK_ASSIGNMENT_ERROR';
