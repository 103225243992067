import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TitleProps {
  title: string;
  chip?: string;
  right?: ReactNode;
  marginBottom?: boolean;
  marginRight?: boolean;
}

function Title({
  title,
  chip,
  right,
  marginRight = false,
  marginBottom = false,
}: TitleProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mr: marginRight ? 1 : 0,
        mb: marginBottom ? { xs: 1, md: 2 } : 0,
      }}
    >
      <Box>
        <Typography variant="h6" display="inline">
          {title}
        </Typography>
        {chip && (
          <Box display="inline-block" ml={1}>
            {chip}
          </Box>
        )}
      </Box>
      {right}
    </Box>
  );
}

export default Title;
