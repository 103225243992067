import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import QRCode from 'qrcode.react';
import Avatar from '@material-ui/core/Avatar';
import SignInMfaCodeForm from './SignInMfaCodeForm';
import Paper from '../../components/Paper';

function SignInMfaSetupForm({ totpCode, loading, onSubmit }) {
  return (
    <Container component="main">
      <Box
        m="auto"
        width="100%"
        maxWidth={500}
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        justifyContent="center"
      >
        <Paper>
          <Typography variant="h6">
            <b>บันทึกเครื่องที่ใช้งานเข้ากับชื่อบัญชีของคุณ</b>
          </Typography>
          <Box mt={2} display="flex" alignItems="center">
            <Box mr={2}>
              <Avatar>1</Avatar>
            </Box>
            <Typography variant="subtitle1">
              สแกนโค้ดเพื่อบันทึกเครื่องที่ใช้งานเข้ากับชื่อบัญชีของคุณ
            </Typography>
          </Box>
          <Box mt={2} textAlign="center">
            <QRCode value={totpCode} />
          </Box>
          <Box mt={2} display="flex" alignItems="center">
            <Box mr={2}>
              <Avatar>2</Avatar>
            </Box>
            <Typography variant="subtitle1">
              กรุณาใส่รหัสผ่านจากเครื่องที่ใช้งาน
            </Typography>
          </Box>
          <Box mt={2}>
            <SignInMfaCodeForm loading={loading} onSubmit={onSubmit} />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default SignInMfaSetupForm;
