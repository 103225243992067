// eslint-disable-next-line import/prefer-default-export
export const ordersByCreatedDateGraphql = /* GraphQL */ `
  query OrdersByCreatedDate(
    $createdDate: AWSDate
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCreatedDate(
      createdDate: $createdDate
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        type
        station
        truckAssignmentId
        createdDate
        createdAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          debt
        }
        orderItems {
          items {
            id
            productId
            quantity
            unitPrice
            adjustment
          }
        }
        adjustments {
          items {
            amount
          }
        }
        payments {
          items {
            id
            amount
            createdAt
          }
        }
      }
      nextToken
    }
  }
`;
