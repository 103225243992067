import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import {
  CLEAR_DEBTOR,
  REFRESH_DEBTORS,
  SEARCH_DEBTORS,
  SEARCH_DEBTORS_ERROR,
  SEARCH_DEBTORS_SUCCESS,
  SEARCH_NEXT_DEBTORS,
  SEARCH_PREVIOUS_DEBTORS,
  SET_DEBTOR,
  SET_NAME,
  SET_SEQUENCE_NUMBER,
  SET_SORT_FIELD,
} from './constants';

export const initialState = {
  debtor: {
    name: null,
    customerId: null,
    sequenceNumber: null,
  },
  sort: {
    field: 'sequenceNumber',
    asc: true,
  },
  tokens: [],
  debtors: [],
  error: false,
  loading: false,
  showNext: false,
  showPrevious: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_DEBTOR: {
        draft.debtor.customerId = action.payload.debtor.customerId;
        draft.debtor.name = action.payload.debtor.name;
        break;
      }
      case SET_NAME: {
        draft.debtor.name = action.payload.name;
        draft.debtor.customerId = null;
        break;
      }
      case SET_SEQUENCE_NUMBER: {
        draft.debtor.sequenceNumber = action.payload.sequenceNumber;
        break;
      }
      case CLEAR_DEBTOR: {
        draft.debtor.name = null;
        draft.debtor.customerId = null;
        draft.debtor.sequenceNumber = null;
        break;
      }
      case SET_SORT_FIELD: {
        draft.sort.asc =
          draft.sort.field === action.payload.field ? !draft.sort.asc : true;
        draft.sort.field = action.payload.field;
        draft.tokens = [];
        draft.error = false;
        draft.loading = true;
        break;
      }
      case SEARCH_DEBTORS: {
        draft.tokens = [];
        draft.sort.field = 'sequenceNumber';
        draft.sort.asc = true;
        draft.error = false;
        draft.loading = true;
        break;
      }
      case SEARCH_NEXT_DEBTORS: {
        draft.error = false;
        draft.loading = true;
        break;
      }
      case REFRESH_DEBTORS: {
        if (!isEmpty(draft.tokens)) {
          draft.tokens = draft.tokens.slice(0, draft.tokens.length - 1);
        }
        draft.error = false;
        draft.loading = true;
        break;
      }
      case SEARCH_PREVIOUS_DEBTORS: {
        draft.tokens = draft.tokens.slice(0, draft.tokens.length - 2);
        draft.error = false;
        draft.loading = true;
        break;
      }
      case SEARCH_DEBTORS_SUCCESS: {
        draft.debtors = action.payload.debtors;
        draft.tokens.push(action.payload.nextToken);
        draft.showNext =
          !isEmpty(draft.tokens) &&
          !isEmpty(draft.tokens[draft.tokens.length - 1]);
        draft.showPrevious =
          draft.tokens.length > 1 && !isEmpty(draft.tokens[0]);
        draft.error = false;
        draft.loading = false;
        break;
      }
      case SEARCH_DEBTORS_ERROR: {
        draft.error = true;
        draft.loading = false;
        break;
      }
    }
  });

export default reducer;
