import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import _ from 'lodash';
import {
  GET_CURRENT_AUTHENTICATED_USER,
  GET_PRODUCTS,
  GET_WAREHOUSES,
  SET_UP_MFA,
  SIGN_IN,
  SIGN_OUT,
  VERIFY_MFA_CODE,
} from './constants';
import {
  getCurrentAuthenticatedUserError,
  getCurrentAuthenticatedUserSuccess,
  getProductsError,
  getProductsSuccess,
  getWarehousesError,
  getWarehousesSuccess,
  showErrorMessage,
  signInSuccess,
  signOutError,
  signOutSuccess,
} from './actions';
import {
  selectProducts,
  selectSignInResult,
  selectUser,
  selectWarehouses,
} from './selectors';
import { AUTH_CHALLENGE_NAME } from '../../constants';
import { listWarehouses } from '../../graphql/queries';

export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        brand {
          id
          name
          abbr
          logo {
            key
          }
        }
        prices {
          items {
            id
            type
            price
            productId
            conditionDesc
            conditionValue
          }
        }
      }
      nextToken
    }
  }
`;

function getErrorMessage(errorCode) {
  switch (errorCode) {
    case 'PasswordResetRequiredException':
      return 'กรุณารีเซ็ตรหัสผ่าน';
    case 'CodeMismatchException':
    case 'EnableSoftwareTokenMFAException':
      return 'รหัสผ่านไม่ถูกต้อง';
    case 'UserNotFoundException':
    case 'NotAuthorizedException':
      return 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง';
    default:
      return 'ระบบขัดข้องกรุณาลองใหม่ภายหลัง';
  }
}

function createUser(result) {
  const { challengeName, username, userDataKey, storage, signInUserSession } =
    result;
  const { UserAttributes } = JSON.parse(storage[userDataKey]);
  const attributes = _.flow(
    _.partialRight(_.keyBy, 'Name'),
    _.partialRight(_.mapValues, 'Value'),
  )(UserAttributes);
  const {
    // eslint-disable-next-line camelcase
    name,
    family_name,
    email,
    sub,
  } = attributes;
  return {
    id: sub,
    name,
    lastName: family_name,
    email,
    username,
    challengeName,
    groups: signInUserSession.accessToken.payload['cognito:groups'],
  };
}

function* signIn(action) {
  try {
    const result = yield call(
      [Auth, 'signIn'],
      action.payload.username,
      action.payload.password,
    );
    yield put(signInSuccess(result));
  } catch (e) {
    yield put(showErrorMessage(getErrorMessage(e.code)));
  }
}

function* signOut() {
  try {
    yield call([Auth, 'signOut'], { global: true });
    yield put(signOutSuccess());
  } catch {
    yield put(signOutError());
  }
}

function* getCurrentAuthenticatedUser() {
  try {
    const result = yield call([Auth, 'currentAuthenticatedUser']);
    const user = createUser(result);
    // TODO: Try to not set signInResult
    yield put(getCurrentAuthenticatedUserSuccess(result, user));
  } catch {
    yield put(getCurrentAuthenticatedUserError());
  }
}

function* verifyMfaCode(action) {
  try {
    const signInResult = yield select(selectSignInResult);
    yield call(
      [Auth, 'confirmSignIn'],
      signInResult,
      action.payload.mfaCode,
      AUTH_CHALLENGE_NAME.SOFTWARE_TOKEN_MFA,
    );
    yield call(getCurrentAuthenticatedUser);
  } catch (e) {
    yield put(showErrorMessage(getErrorMessage(e.code)));
  }
}

function* setUpMfa(action) {
  try {
    const user = yield select(selectUser);
    yield call([Auth, 'verifyTotpToken'], user, action.payload.mfaCode);
    yield call([Auth, 'setPreferredMFA'], user, 'TOTP');
    yield call(getCurrentAuthenticatedUser);
  } catch (e) {
    yield put(showErrorMessage(getErrorMessage(e.code)));
  }
}

export function* getProducts() {
  try {
    const products = yield select(selectProducts);
    if (_.isEmpty(products)) {
      const result = yield call(
        [API, 'graphql'],
        graphqlOperation(listProducts),
      );
      const products = _.map(result.data.listProducts.items, product => ({
        ...product,
        prices: product.prices.items,
      }));
      yield put(getProductsSuccess(products));
    }
  } catch {
    yield put(getProductsError());
  }
}

export function* getWarehouses() {
  try {
    const warehouses = yield select(selectWarehouses);
    if (_.isEmpty(warehouses)) {
      const result = yield call(
        [API, 'graphql'],
        graphqlOperation(listWarehouses),
      );
      yield put(getWarehousesSuccess(result.data.listWarehouses.items));
    }
  } catch {
    yield put(getWarehousesError());
  }
}

export default function* appSaga() {
  yield takeLatest(SIGN_IN, signIn);
  yield takeLatest(SIGN_OUT, signOut);
  yield takeLatest(SET_UP_MFA, setUpMfa);
  yield takeLatest(VERIFY_MFA_CODE, verifyMfaCode);
  yield takeLatest(GET_CURRENT_AUTHENTICATED_USER, getCurrentAuthenticatedUser);
  yield takeLatest(GET_PRODUCTS, getProducts);
  yield takeLatest(GET_WAREHOUSES, getWarehouses);
}
