import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '../../components/TableCell';

function ReportTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>#</TableCell>
        <TableCell>เลขที่ใบสั่งซื้อ</TableCell>
        <TableCell>ชื่อลูกค้า / สายรถเร่</TableCell>
        <TableCell>ประเภท</TableCell>
        <TableCell>สถานะ</TableCell>
        <TableCell>โรงบรรจุแก๊ส</TableCell>
        <TableCell backgroundColor="green" align="center">
          15
        </TableCell>
        <TableCell backgroundColor="lightBlue" align="center">
          48
        </TableCell>
        <TableCell align="center">4</TableCell>
        <TableCell align="center">7</TableCell>
        <TableCell align="center">9</TableCell>
        <TableCell align="center">11.5</TableCell>
        <TableCell align="center">13.5</TableCell>
        <TableCell align="right">ลูกค้าหน้าร้าน (นน.)</TableCell>
        <TableCell align="right">
          รถเร่ / ออเดอร์ / ลูกค้าบริษัทอื่น (นน.)
        </TableCell>
        <TableCell align="right">ยอดขาย (บาท)</TableCell>
        <TableCell align="right">ส่วนลด / ค่าใช้จ่าย (บาท)</TableCell>
        <TableCell align="right">เงินสด (ลูกค้าหน้าร้าน)</TableCell>
        <TableCell align="right">
          เงินสด (รถเร่ / ออเดอร์ / ลูกค้าบริษัทอื่น)
        </TableCell>
        <TableCell align="right">ค้างชำระ (บาท)</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ReportTableHeader;
