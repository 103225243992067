// eslint-disable-next-line import/prefer-default-export
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        type
        station
        createdAt
        customer {
          name
        }
        orderItems {
          items {
            quantity
            unitPrice
            adjustment
            _deleted
          }
        }
        payments {
          items {
            amount
            _deleted
          }
        }
        adjustments {
          items {
            amount
            _deleted
          }
        }
      }
      nextToken
      total
    }
  }
`;
