import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import DevicesIcon from '@material-ui/icons/Devices';
import SignInMfaCodeForm from './SignInMfaCodeForm';
import Paper from '../../components/Paper';

function SignInMfaVerificationForm({ loading, onSubmit }) {
  return (
    <Container component="main">
      <Box
        m="auto"
        width="100%"
        maxWidth={425}
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        justifyContent="center"
      >
        <Paper>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box mr={2}>
              <Avatar>
                <DevicesIcon />
              </Avatar>
            </Box>
            <Typography variant="h6">
              กรุณาใส่รหัสผ่านจากเครื่องที่ใช้งาน
            </Typography>
          </Box>
          <Box mt={2}>
            <SignInMfaCodeForm loading={loading} onSubmit={onSubmit} />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default SignInMfaVerificationForm;
