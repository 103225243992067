import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

function CustomerLink({ customerId }) {
  const history = useHistory();
  return (
    <Button
      color="primary"
      onClick={() => history.push(`/customer/${customerId}`)}
    >
      ดูข้อมูลลูกค้า
    </Button>
  );
}

export default CustomerLink;
