import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectValidator } from 'react-material-ui-form-validator';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import { ORDER_STATUS, ORDER_STATUS_DESC } from '../../constants';

const useStyles = makeStyles({
  select: {
    padding: '16px 14px',
  },
});

function StatusSelectValidator({ statuses, onChange, disabled }) {
  const classes = useStyles();
  return (
    <SelectValidator
      fullWidth
      margin="none"
      label="สถานะ"
      variant="outlined"
      disabled={disabled}
      SelectProps={{
        multiple: true,
        value: statuses,
        classes: {
          select: clsx(!_.isEmpty(statuses) && classes.select),
        },
        onChange,
        renderValue: statuses =>
          statuses.map(status => (
            <Box key={status} display="inline" mr={1}>
              <Chip
                size="small"
                color="primary"
                label={ORDER_STATUS_DESC[status]}
              />
            </Box>
          )),
      }}
    >
      <MenuItem value="" disabled>
        กรุณาเลือก
      </MenuItem>
      <MenuItem key={ORDER_STATUS.CREATED} value={ORDER_STATUS.CREATED}>
        <Checkbox
          color="primary"
          checked={statuses.includes(ORDER_STATUS.CREATED)}
        />
        <ListItemText primary={ORDER_STATUS_DESC.CREATED} />
      </MenuItem>
      <MenuItem key={ORDER_STATUS.OVERDUE} value={ORDER_STATUS.OVERDUE}>
        <Checkbox
          color="primary"
          checked={statuses.includes(ORDER_STATUS.OVERDUE)}
        />
        <ListItemText primary={ORDER_STATUS_DESC.OVERDUE} />
      </MenuItem>
      <MenuItem key={ORDER_STATUS.COMPLETED} value={ORDER_STATUS.COMPLETED}>
        <Checkbox
          color="primary"
          checked={statuses.includes(ORDER_STATUS.COMPLETED)}
        />
        <ListItemText primary={ORDER_STATUS_DESC.COMPLETED} />
      </MenuItem>
      <MenuItem key={ORDER_STATUS.CANCELED} value={ORDER_STATUS.CANCELED}>
        <Checkbox
          color="primary"
          checked={statuses.includes(ORDER_STATUS.CANCELED)}
        />
        <ListItemText primary={ORDER_STATUS_DESC.CANCELED} />
      </MenuItem>
    </SelectValidator>
  );
}

export default StatusSelectValidator;
