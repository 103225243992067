import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import {
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import {
  COMPANY_BRAND_ID,
  PRICE_TYPE,
  PRODUCT_TYPE,
  PRODUCT_TYPE_DESC,
  VALIDATOR,
} from '../../../constants';
import ProductContext from '../context';
import { setPrices, setProduct } from '../actions';
import ProductWholeSalePrice from './ProductWholeSalePrice';

/* eslint-disable no-shadow */
export default function ProductForm() {
  const { state, dispatch } = useContext(ProductContext);
  const [defaultPrice, setDefaultPrice] = useState('');
  const [specialPrice, setSpecialPrice] = useState('');
  const [defaultPriceIndex, setDefaultPriceIndex] = useState(-1);
  const [specialPriceIndex, setSpecialPriceIndex] = useState(-1);
  const { brands, product, prices, disabled } = state;
  const { id, name, type, weight, brandId, isTruckAssignmentProduct } = product;

  useEffect(() => {
    // Set Default Price
    const defaultIndex = prices.findIndex(
      price => price.type === PRICE_TYPE.DEFAULT,
    );

    if (defaultIndex !== -1) {
      setDefaultPrice(prices[defaultIndex].price);
      setDefaultPriceIndex(defaultIndex);
    } else {
      setDefaultPrice('');
      setDefaultPriceIndex(-1);
    }

    // Set Special Price
    const specialIndex = prices.findIndex(
      price => price.type === PRICE_TYPE.SPECIAL,
    );

    if (specialIndex !== -1) {
      setSpecialPrice(prices[specialIndex].price);
      setSpecialPriceIndex(specialIndex);
    } else {
      setSpecialPrice('');
      setSpecialPriceIndex(-1);
    }
  }, [prices]);

  const handleProductFormChange = field => e => {
    dispatch(
      setProduct({
        ...product,
        [field]: e.target.value,
      }),
    );
  };

  const handleIsTruckAssignmentProductChange = e => {
    dispatch(
      setProduct({
        ...product,
        isTruckAssignmentProduct: e.target.checked,
      }),
    );
  };

  const handlePriceChange = type => e => {
    const index =
      type === PRICE_TYPE.SPECIAL ? specialPriceIndex : defaultPriceIndex;

    const updatedPrices = [...prices];

    if (index > -1) {
      updatedPrices.splice(index, 1, {
        ...prices[index],
        price: e.target.value,
      });
    } else {
      updatedPrices.push({
        price: e.target.value,
        productId: id,
        type,
      });

      if (type === PRICE_TYPE.SPECIAL) {
        setSpecialPriceIndex(updatedPrices.length);
      } else {
        setDefaultPriceIndex(updatedPrices.length);
      }
    }

    dispatch(setPrices(updatedPrices));
  };

  const renderBrandMenuItems = () => {
    if (brands.length) {
      if (type === PRODUCT_TYPE.SERVICE) {
        return <MenuItem value={COMPANY_BRAND_ID}>แม่จันแก๊ส</MenuItem>;
      }
      return brands.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ));
    }
    return <MenuItem value={brandId}>{product.brand.name}</MenuItem>;
  };

  const renderBrand = () => {
    switch (type) {
      case PRODUCT_TYPE.SERVICE:
        return (
          <SelectValidator
            label="บริการโดย"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            value={brandId}
            onChange={handleProductFormChange('brandId')}
          >
            {renderBrandMenuItems()}
          </SelectValidator>
        );
      case PRODUCT_TYPE.LPG:
      case PRODUCT_TYPE.CONTAINER:
      default:
        return (
          <SelectValidator
            label="ยี่ห้อ"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={!!id || disabled}
            value={brandId}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            onChange={handleProductFormChange('brandId')}
          >
            <MenuItem value="" disabled>
              กรุณาเลือกยี่ห้อ
            </MenuItem>
            {renderBrandMenuItems()}
          </SelectValidator>
        );
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mt={3}>
        <SelectValidator
          label="ประเภท"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={!!id || disabled}
          value={type}
          validators={['required']}
          errorMessages={[VALIDATOR.REQUIRED]}
          onChange={handleProductFormChange('type')}
        >
          <MenuItem value={PRODUCT_TYPE.LPG}>{PRODUCT_TYPE_DESC.LPG}</MenuItem>
          <MenuItem value={PRODUCT_TYPE.CONTAINER}>
            {PRODUCT_TYPE_DESC.CONTAINER}
          </MenuItem>
          <MenuItem value={PRODUCT_TYPE.SERVICE}>
            {PRODUCT_TYPE_DESC.SERVICE}
          </MenuItem>
        </SelectValidator>
      </Box>

      <Box mt={3}>
        <TextValidator
          label={type === PRODUCT_TYPE.SERVICE ? 'ชื่อบริการ' : 'ชื่อสินค้า'}
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={name}
          validators={['required']}
          errorMessages={[VALIDATOR.REQUIRED]}
          onChange={handleProductFormChange('name')}
        />
      </Box>

      <Box mt={3}>{renderBrand()}</Box>

      {type === PRODUCT_TYPE.LPG && (
        <Box mt={3}>
          <TextValidator
            label="น้ำหนัก (กก.)"
            variant="outlined"
            margin="none"
            type="number"
            fullWidth
            disabled={disabled}
            value={weight}
            validators={['required', 'isFloat']}
            errorMessages={[VALIDATOR.REQUIRED, VALIDATOR.IS_FLOAT]}
            onChange={handleProductFormChange('weight')}
          />
        </Box>
      )}

      <Box mt={3}>
        <TextValidator
          label="ราคาขายปลีก (บาท)"
          variant="outlined"
          margin="none"
          type="number"
          fullWidth
          disabled={disabled}
          value={defaultPrice}
          validators={['required', 'isFloat']}
          errorMessages={[VALIDATOR.REQUIRED, VALIDATOR.IS_FLOAT]}
          onChange={handlePriceChange(PRICE_TYPE.DEFAULT)}
        />
      </Box>

      <Box mt={3}>
        <TextValidator
          label="ราคาพิเศษ (บาท)"
          variant="outlined"
          margin="none"
          type="number"
          fullWidth
          disabled={disabled}
          value={specialPrice}
          validators={['required', 'isFloat']}
          errorMessages={[VALIDATOR.REQUIRED, VALIDATOR.IS_FLOAT]}
          onChange={handlePriceChange(PRICE_TYPE.SPECIAL)}
        />
      </Box>

      <Box mt={2}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={isTruckAssignmentProduct}
              onChange={handleIsTruckAssignmentProductChange}
              color="primary"
            />
          }
          label="แสดงสินค้านี้ในหน้ารถเร่"
        />
      </Box>

      {/* Product Whole Price */}
      {type !== PRODUCT_TYPE.SERVICE && <ProductWholeSalePrice />}
    </Box>
  );
}
