import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '../../components/Paper';

import { getDebtor, refreshDebtor } from './actions';
import {
  makeSelectDebtor,
  makeSelectLoading,
  makeSelectOverdueOrders,
} from './selectors';
import CustomerForm from '../../components/CustomerForm';
import OrderTable, { ORDER_TABLE_COLUMNS } from '../../components/OrderTable';
import Spinner from '../../components/Spinner';
import DebtorOrderTableHeader from './DebtorOrderTableHeader';
import DebtorChip from './DebtorChip';

const stateSelector = createStructuredSelector({
  debtor: makeSelectDebtor,
  overdueOrders: makeSelectOverdueOrders,
  loading: makeSelectLoading,
});

function Debtor() {
  const history = useHistory();
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { debtor, overdueOrders, loading } = useSelector(stateSelector);

  useEffect(() => {
    dispatch(getDebtor(customerId));
  }, [customerId, dispatch]);

  return (
    <Container>
      <Spinner loading={loading}>
        <Paper>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box mr={1}>
              <Typography variant="h6">ลูกหนี้</Typography>
            </Box>
            <Box flexGrow={1}>
              <DebtorChip debt={debtor.debt || 0} />
            </Box>
            <Button
              color="primary"
              onClick={() => history.push(`/createDebt/${debtor.id}`)}
            >
              เพิ่มใบสั่งซื้อค้างชำระ
            </Button>
          </Box>
          <Box mt={2}>
            <ValidatorForm onSubmit={() => {}}>
              <CustomerForm
                disabled
                customer={debtor}
                checkDuplicateName={false}
              />
            </ValidatorForm>
          </Box>
        </Paper>
      </Spinner>
      <Box mt={3}>
        <OrderTable
          orders={overdueOrders}
          loading={loading}
          showNext={false}
          showPrevious={false}
          refresh={() => dispatch(refreshDebtor())}
          columns={{
            [ORDER_TABLE_COLUMNS.SEQUENCE_NUMBER]: true,
            [ORDER_TABLE_COLUMNS.STATUS]: true,
            [ORDER_TABLE_COLUMNS.STATION]: true,
            [ORDER_TABLE_COLUMNS.SUBTOTAL]: true,
            [ORDER_TABLE_COLUMNS.OVERDUE_AMOUNT]: true,
            [ORDER_TABLE_COLUMNS.CREATED_AT]: true,
          }}
          component={({ children }) => (
            <DebtorOrderTableHeader
              customerId={debtor.id}
              debt={debtor.debt || 0}
            >
              {children}
            </DebtorOrderTableHeader>
          )}
        />
      </Box>
    </Container>
  );
}

export default Debtor;
