import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const selectOrderList = state => state.orderList;

export const makeSelectParams = () =>
  createSelector(selectOrderList, orderList => orderList.params);

export const makeSelectOrders = () =>
  createSelector(selectOrderList, orderList => orderList.orders);

export const makeSelectNextToken = () =>
  createSelector(selectOrderList, ({ tokens }) =>
    !isEmpty(tokens) ? tokens[tokens.length - 1] : null,
  );

export const makeSelectShowNext = () =>
  createSelector(selectOrderList, ({ showNext }) => showNext);

export const makeSelectShowPrevious = () =>
  createSelector(selectOrderList, ({ showPrevious }) => showPrevious);

export const makeSelectExportNextToken = () =>
  createSelector(selectOrderList, ({ exportNextToken }) => exportNextToken);

export const makeSelectError = () =>
  createSelector(selectOrderList, orderList => orderList.error);

export const makeSelectLoading = () =>
  createSelector(selectOrderList, orderList => orderList.loading);
