import TableCell from '../../components/TableCell';
import React from 'react';
import TableRow from '@mui/material/TableRow';
import { FulfillmentReportRow } from './types';
import TableBody from '@mui/material/TableBody';
import { isEmpty } from '../../utils/validators';
import FulfillmentReportTableRow from './FulfillmentReportTableRow';
import Typography from '@mui/material/Typography';

interface FulfillmentReportTableBodyProps {
  rows: FulfillmentReportRow[];
}

function FulfillmentReportTableBody({ rows }: FulfillmentReportTableBodyProps) {
  return (
    <TableBody>
      {isEmpty(rows) ? (
        <TableRow>
          <TableCell colSpan={23}>
            <Typography variant="subtitle2" sx={{ m: 'auto' }}>
              ไม่มีข้อมูล
            </Typography>
          </TableCell>
        </TableRow>
      ) : (
        rows.map((row, idx) => (
          <FulfillmentReportTableRow key={row.id} idx={idx} row={row} />
        ))
      )}
    </TableBody>
  );
}

export default FulfillmentReportTableBody;
