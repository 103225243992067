import React from 'react';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment-timezone';
import { DELIVERY_ROUTE_DESC, STATION_DESC } from '../../constants';

const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      fontSize: '1rem',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'black',
    },
  },
})(TextField);

function TruckAssignmentQueueCardHeader({ truckAssignment }) {
  const {
    station,
    deliveryRouteId,
    driver,
    companion,
    departure,
    licensePlate,
  } = truckAssignment;
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Grid item xs={12}>
        <StyledTextField
          label="สายรถเร่"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={DELIVERY_ROUTE_DESC[deliveryRouteId] || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="โรงอัดแก๊ส"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={STATION_DESC[station] || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="พนักงานขับรถ"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={driver}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="พนักงานนั่งข้าง"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={companion}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="ทะเบียนรถยนต์"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={licensePlate}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="วันที่ขาย"
          margin="none"
          size="small"
          disabled
          fullWidth
          value={moment(departure)
            .tz('Asia/Bangkok')
            .format('DD/MM/YYYY HH:mm')}
        />
      </Grid>
    </Grid>
  );
}

export default TruckAssignmentQueueCardHeader;
