/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      debt
      beginningBalance
      deliveryRouteId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      discounts {
        items {
          id
          discount
          productId
          customerId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const customersByDeliveryRoute = /* GraphQL */ `
  query CustomersByDeliveryRoute(
    $deliveryRouteId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByDeliveryRoute(
      deliveryRouteId: $deliveryRouteId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: SearchableCustomerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      name
      abbr
      logo {
        bucket
        region
        level
        key
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      products {
        items {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        abbr
        logo {
          bucket
          region
          level
          key
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBrands = /* GraphQL */ `
  query SyncBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBrands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        abbr
        logo {
          bucket
          region
          level
          key
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      brandId
      weight
      type
      isTruckAssignmentProduct
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        name
        abbr
        logo {
          bucket
          region
          level
          key
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      prices {
        items {
          id
          type
          price
          productId
          conditionDesc
          conditionValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const productsByProductType = /* GraphQL */ `
  query ProductsByProductType(
    $type: ProductType
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByProductType(
      type: $type
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      type
      price
      productId
      conditionDesc
      conditionValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        price
        productId
        conditionDesc
        conditionValue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrices = /* GraphQL */ `
  query SyncPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        price
        productId
        conditionDesc
        conditionValue
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      id
      discount
      productId
      customerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        discount
        productId
        customerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDiscounts = /* GraphQL */ `
  query SyncDiscounts(
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDiscounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        discount
        productId
        customerId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByStatus = /* GraphQL */ `
  query OrdersByStatus(
    $status: OrderStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByStatus(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByFulfillmentStatus = /* GraphQL */ `
  query OrdersByFulfillmentStatus(
    $fulfillmentStatus: FulfillmentStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByFulfillmentStatus(
      fulfillmentStatus: $fulfillmentStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByCustomer = /* GraphQL */ `
  query OrdersByCustomer(
    $customerId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCustomer(
      customerId: $customerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByCreatedDate = /* GraphQL */ `
  query OrdersByCreatedDate(
    $createdDate: AWSDate
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCreatedDate(
      createdDate: $createdDate
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ordersByTruckAssignment = /* GraphQL */ `
  query OrdersByTruckAssignment(
    $truckAssignmentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByTruckAssignment(
      truckAssignmentId: $truckAssignmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderItem = /* GraphQL */ `
  query GetOrderItem($id: ID!) {
    getOrderItem(id: $id) {
      id
      orderId
      truckAssignmentId
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listOrderItems = /* GraphQL */ `
  query ListOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        truckAssignmentId
        productId
        quantity
        unitPrice
        adjustment
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderItems = /* GraphQL */ `
  query SyncOrderItems(
    $filter: ModelOrderItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderId
        truckAssignmentId
        productId
        quantity
        unitPrice
        adjustment
        comment
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrderAdjustment = /* GraphQL */ `
  query GetOrderAdjustment($id: ID!) {
    getOrderAdjustment(id: $id) {
      id
      orderId
      amount
      comment
      createdByUserId
      createdAt
      _version
      _deleted
      _lastChangedAt
      createdBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listOrderAdjustments = /* GraphQL */ `
  query ListOrderAdjustments(
    $filter: ModelOrderAdjustmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderAdjustments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        amount
        comment
        createdByUserId
        createdAt
        _version
        _deleted
        _lastChangedAt
        createdBy {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrderAdjustments = /* GraphQL */ `
  query SyncOrderAdjustments(
    $filter: ModelOrderAdjustmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderAdjustments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderId
        amount
        comment
        createdByUserId
        createdAt
        _version
        _deleted
        _lastChangedAt
        createdBy {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      orderId
      truckAssignmentId
      amount
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        truckAssignmentId
        amount
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderId
        truckAssignmentId
        amount
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWorkflow = /* GraphQL */ `
  query GetWorkflow($id: ID!) {
    getWorkflow(id: $id) {
      id
      orderId
      truckAssignmentId
      station
      comment
      userId
      createdDate
      createdAt
      _version
      _deleted
      _lastChangedAt
      order {
        id
        customerId
        sequenceNumber
        status
        paymentStatus
        fulfillmentStatus
        type
        station
        comment
        createdBy
        licensePlateNumber
        truckAssignmentId
        images {
          bucket
          region
          level
          key
          __typename
        }
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        adjustments {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        workflows {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      workflowItems {
        items {
          id
          workflowId
          orderId
          truckAssignmentId
          productId
          quantity
          type
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      truckAssignment {
        id
        driver
        companion
        station
        deliveryRouteId
        licensePlate
        sequenceNumber
        comment
        departure
        status
        paymentStatus
        fulfillmentStatus
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orders {
          nextToken
          startedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      user {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listWorkflows = /* GraphQL */ `
  query ListWorkflows(
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkflows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderId
        truckAssignmentId
        station
        comment
        userId
        createdDate
        createdAt
        _version
        _deleted
        _lastChangedAt
        order {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        workflowItems {
          nextToken
          startedAt
          __typename
        }
        truckAssignment {
          id
          driver
          companion
          station
          deliveryRouteId
          licensePlate
          sequenceNumber
          comment
          departure
          status
          paymentStatus
          fulfillmentStatus
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const workflowsByOrder = /* GraphQL */ `
  query WorkflowsByOrder(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workflowsByOrder(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        truckAssignmentId
        station
        comment
        userId
        createdDate
        createdAt
        _version
        _deleted
        _lastChangedAt
        order {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        workflowItems {
          nextToken
          startedAt
          __typename
        }
        truckAssignment {
          id
          driver
          companion
          station
          deliveryRouteId
          licensePlate
          sequenceNumber
          comment
          departure
          status
          paymentStatus
          fulfillmentStatus
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const workflowsByCreatedDate = /* GraphQL */ `
  query WorkflowsByCreatedDate(
    $createdDate: AWSDate
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workflowsByCreatedDate(
      createdDate: $createdDate
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        truckAssignmentId
        station
        comment
        userId
        createdDate
        createdAt
        _version
        _deleted
        _lastChangedAt
        order {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        workflowItems {
          nextToken
          startedAt
          __typename
        }
        truckAssignment {
          id
          driver
          companion
          station
          deliveryRouteId
          licensePlate
          sequenceNumber
          comment
          departure
          status
          paymentStatus
          fulfillmentStatus
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const workflowsByTruckAssignment = /* GraphQL */ `
  query WorkflowsByTruckAssignment(
    $truckAssignmentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workflowsByTruckAssignment(
      truckAssignmentId: $truckAssignmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        truckAssignmentId
        station
        comment
        userId
        createdDate
        createdAt
        _version
        _deleted
        _lastChangedAt
        order {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        workflowItems {
          nextToken
          startedAt
          __typename
        }
        truckAssignment {
          id
          driver
          companion
          station
          deliveryRouteId
          licensePlate
          sequenceNumber
          comment
          departure
          status
          paymentStatus
          fulfillmentStatus
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWorkflows = /* GraphQL */ `
  query SyncWorkflows(
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkflows(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderId
        truckAssignmentId
        station
        comment
        userId
        createdDate
        createdAt
        _version
        _deleted
        _lastChangedAt
        order {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        workflowItems {
          nextToken
          startedAt
          __typename
        }
        truckAssignment {
          id
          driver
          companion
          station
          deliveryRouteId
          licensePlate
          sequenceNumber
          comment
          departure
          status
          paymentStatus
          fulfillmentStatus
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        user {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWorkflowItem = /* GraphQL */ `
  query GetWorkflowItem($id: ID!) {
    getWorkflowItem(id: $id) {
      id
      workflowId
      orderId
      truckAssignmentId
      productId
      quantity
      type
      createdAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listWorkflowItems = /* GraphQL */ `
  query ListWorkflowItems(
    $filter: ModelWorkflowItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkflowItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workflowId
        orderId
        truckAssignmentId
        productId
        quantity
        type
        createdAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const workflowItemsByWorkflow = /* GraphQL */ `
  query WorkflowItemsByWorkflow(
    $workflowId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkflowItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workflowItemsByWorkflow(
      workflowId: $workflowId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workflowId
        orderId
        truckAssignmentId
        productId
        quantity
        type
        createdAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const workflowItemsByOrder = /* GraphQL */ `
  query WorkflowItemsByOrder(
    $orderId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkflowItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workflowItemsByOrder(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workflowId
        orderId
        truckAssignmentId
        productId
        quantity
        type
        createdAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const workflowItemsByTruckAssignment = /* GraphQL */ `
  query WorkflowItemsByTruckAssignment(
    $truckAssignmentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkflowItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workflowItemsByTruckAssignment(
      truckAssignmentId: $truckAssignmentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workflowId
        orderId
        truckAssignmentId
        productId
        quantity
        type
        createdAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWorkflowItems = /* GraphQL */ `
  query SyncWorkflowItems(
    $filter: ModelWorkflowItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkflowItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        workflowId
        orderId
        truckAssignmentId
        productId
        quantity
        type
        createdAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeliveryRoute = /* GraphQL */ `
  query GetDeliveryRoute($id: ID!) {
    getDeliveryRoute(id: $id) {
      id
      name
      licensePlate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customers {
        items {
          id
          type
          sequenceNumber
          name
          phoneNumber
          email
          address
          subdistrict
          district
          province
          postalCode
          debt
          beginningBalance
          deliveryRouteId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const listDeliveryRoutes = /* GraphQL */ `
  query ListDeliveryRoutes(
    $filter: ModelDeliveryRouteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveryRoutes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        licensePlate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customers {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeliveryRoutes = /* GraphQL */ `
  query SyncDeliveryRoutes(
    $filter: ModelDeliveryRouteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliveryRoutes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        licensePlate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customers {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTruckAssignment = /* GraphQL */ `
  query GetTruckAssignment($id: ID!) {
    getTruckAssignment(id: $id) {
      id
      driver
      companion
      station
      deliveryRouteId
      licensePlate
      sequenceNumber
      comment
      departure
      status
      paymentStatus
      fulfillmentStatus
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryRoute {
        id
        name
        licensePlate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customers {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listTruckAssignments = /* GraphQL */ `
  query ListTruckAssignments(
    $filter: ModelTruckAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTruckAssignments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        driver
        companion
        station
        deliveryRouteId
        licensePlate
        sequenceNumber
        comment
        departure
        status
        paymentStatus
        fulfillmentStatus
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orders {
          nextToken
          startedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const truckAssignmentsByCreatedDate = /* GraphQL */ `
  query TruckAssignmentsByCreatedDate(
    $createdDate: AWSDate
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTruckAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    truckAssignmentsByCreatedDate(
      createdDate: $createdDate
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        driver
        companion
        station
        deliveryRouteId
        licensePlate
        sequenceNumber
        comment
        departure
        status
        paymentStatus
        fulfillmentStatus
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orders {
          nextToken
          startedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const truckAssignmentByFulfillmentStatus = /* GraphQL */ `
  query TruckAssignmentByFulfillmentStatus(
    $fulfillmentStatus: FulfillmentStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTruckAssignmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    truckAssignmentByFulfillmentStatus(
      fulfillmentStatus: $fulfillmentStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        driver
        companion
        station
        deliveryRouteId
        licensePlate
        sequenceNumber
        comment
        departure
        status
        paymentStatus
        fulfillmentStatus
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orders {
          nextToken
          startedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchTruckAssignments = /* GraphQL */ `
  query SearchTruckAssignments(
    $filter: SearchableTruckAssignmentFilterInput
    $sort: SearchableTruckAssignmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTruckAssignments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        driver
        companion
        station
        deliveryRouteId
        licensePlate
        sequenceNumber
        comment
        departure
        status
        paymentStatus
        fulfillmentStatus
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orders {
          nextToken
          startedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const syncTruckAssignments = /* GraphQL */ `
  query SyncTruckAssignments(
    $filter: ModelTruckAssignmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTruckAssignments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        driver
        companion
        station
        deliveryRouteId
        licensePlate
        sequenceNumber
        comment
        departure
        status
        paymentStatus
        fulfillmentStatus
        createdDate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        orders {
          nextToken
          startedAt
          __typename
        }
        orderItems {
          nextToken
          startedAt
          __typename
        }
        payments {
          nextToken
          startedAt
          __typename
        }
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSequenceNumber = /* GraphQL */ `
  query GetSequenceNumber($id: ID!) {
    getSequenceNumber(id: $id) {
      id
      prefix
      sequenceNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSequenceNumbers = /* GraphQL */ `
  query ListSequenceNumbers(
    $id: ID
    $filter: ModelSequenceNumberFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSequenceNumbers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        prefix
        sequenceNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSequenceNumbers = /* GraphQL */ `
  query SyncSequenceNumbers(
    $filter: ModelSequenceNumberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSequenceNumbers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        prefix
        sequenceNumber
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($date: AWSDate!, $type: OrderType!, $station: Station!) {
    getReport(date: $date, type: $type, station: $station) {
      date
      type
      station
      w40Count
      w70Count
      w90Count
      w115Count
      w135Count
      w150Count
      w480Count
      cash
      sales
      weight
      orderCount
      adjustment
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $date: AWSDate
    $typeStation: ModelReportPrimaryCompositeKeyConditionInput
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReports(
      date: $date
      typeStation: $typeStation
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        type
        station
        w40Count
        w70Count
        w90Count
        w115Count
        w135Count
        w150Count
        w480Count
        cash
        sales
        weight
        orderCount
        adjustment
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByType = /* GraphQL */ `
  query ReportsByType(
    $type: OrderType
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByType(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        type
        station
        w40Count
        w70Count
        w90Count
        w115Count
        w135Count
        w150Count
        w480Count
        cash
        sales
        weight
        orderCount
        adjustment
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportsByStation = /* GraphQL */ `
  query ReportsByStation(
    $station: Station
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportsByStation(
      station: $station
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        type
        station
        w40Count
        w70Count
        w90Count
        w115Count
        w135Count
        w150Count
        w480Count
        cash
        sales
        weight
        orderCount
        adjustment
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReports = /* GraphQL */ `
  query SyncReports(
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        date
        type
        station
        w40Count
        w70Count
        w90Count
        w115Count
        w135Count
        w150Count
        w480Count
        cash
        sales
        weight
        orderCount
        adjustment
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      userGroup
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInbound = /* GraphQL */ `
  query GetInbound($id: ID!) {
    getInbound(id: $id) {
      id
      warehouseId
      createdByUserId
      updatedByUserId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      inventoryItems {
        items {
          id
          inboundId
          productId
          quantity
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      warehouse {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const listInbounds = /* GraphQL */ `
  query ListInbounds(
    $filter: ModelInboundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInbounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        warehouseId
        createdByUserId
        updatedByUserId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedBy {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        inventoryItems {
          nextToken
          startedAt
          __typename
        }
        warehouse {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInbounds = /* GraphQL */ `
  query SyncInbounds(
    $filter: ModelInboundFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInbounds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        warehouseId
        createdByUserId
        updatedByUserId
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        createdBy {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        updatedBy {
          id
          firstName
          lastName
          userGroup
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        inventoryItems {
          nextToken
          startedAt
          __typename
        }
        warehouse {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInventoryItem = /* GraphQL */ `
  query GetInventoryItem($id: ID!) {
    getInventoryItem(id: $id) {
      id
      inboundId
      productId
      quantity
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listInventoryItems = /* GraphQL */ `
  query ListInventoryItems(
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventoryItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        inboundId
        productId
        quantity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInventoryItems = /* GraphQL */ `
  query SyncInventoryItems(
    $filter: ModelInventoryItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInventoryItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        inboundId
        productId
        quantity
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        product {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWarehouse = /* GraphQL */ `
  query GetWarehouse($id: ID!) {
    getWarehouse(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWarehouses = /* GraphQL */ `
  query ListWarehouses(
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarehouses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWarehouses = /* GraphQL */ `
  query SyncWarehouses(
    $filter: ModelWarehouseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWarehouses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
