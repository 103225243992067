import React from 'react';
import Chip from '@material-ui/core/Chip';
import { useTheme } from '@material-ui/core';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';

function DebtorChip({ debt }) {
  const theme = useTheme();
  return (
    <CommaSeparatedNumber
      value={debt}
      fixedDecimalScale
      renderText={formattedDebt => (
        <Chip
          label={`ยอดหนี้ค้างชำระ: ${formattedDebt} บาท`}
          style={{
            backgroundColor:
              debt > 0
                ? theme.palette.secondary.main
                : theme.palette.success.main,
            color: 'white',
          }}
        />
      )}
    />
  );
}

export default DebtorChip;
