import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function CommaSeparatedNumber({
  value,
  renderText,
  fixedDecimalScale = false,
}) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale={fixedDecimalScale}
      renderText={renderText}
    />
  );
}

/* eslint-disable react/require-default-props, react/forbid-prop-types */
CommaSeparatedNumber.propTypes = {
  value: PropTypes.any.isRequired,
  renderText: PropTypes.func,
};

export default CommaSeparatedNumber;
