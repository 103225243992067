import {
  GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS,
  GET_OVERDUE_TRUCK_ASSIGNMENTS,
  GET_OVERDUE_TRUCK_ASSIGNMENTS_ERROR,
  GET_OVERDUE_TRUCK_ASSIGNMENTS_SUCCESS,
  GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS,
  REFRESH_OVERDUE_TRUCK_ASSIGNMENTS,
} from './constants';

export const getOverdueTruckAssignments = () => ({
  type: GET_OVERDUE_TRUCK_ASSIGNMENTS,
});

export const getNextOverdueTruckAssignments = () => ({
  type: GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS,
});

export const getPreviousOverdueTruckAssignments = () => ({
  type: GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS,
});

export const getOverdueTruckAssignmentsSuccess = (
  overdueTruckAssignments,
  nextToken,
) => ({
  type: GET_OVERDUE_TRUCK_ASSIGNMENTS_SUCCESS,
  payload: {
    overdueTruckAssignments,
    nextToken,
  },
});

export const getOverdueTruckAssignmentsError = () => ({
  type: GET_OVERDUE_TRUCK_ASSIGNMENTS_ERROR,
});

export const refreshOverdueTruckAssignments = () => ({
  type: REFRESH_OVERDUE_TRUCK_ASSIGNMENTS,
});
