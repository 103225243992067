import { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PaymentIcon from '@mui/icons-material/Payment';
import DrawerLink from './DrawerLink';
import useHasPermission from '../../utils/useHasPermission';
import { USER_GROUP } from '../../constants';

export default function DrawerTruckAssignmentLinks() {
  const [openCollapse, setOpenCollapse] = useState(false);
  const hasCreateTruckAssignmentPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.CASHIER,
  ]);
  const hasOverdueTruckAssignmentPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.CASHIER,
  ]);
  const hasTruckAssignmentPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.CASHIER,
    USER_GROUP.ACCOUNTING,
  ]);
  return (
    <>
      <ListItem button onClick={() => setOpenCollapse(!openCollapse)}>
        <ListItemIcon>
          <LocalShippingIcon />
        </ListItemIcon>
        <ListItemText primary="รถเร่" />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasCreateTruckAssignmentPermission && (
            <DrawerLink
              paddingLeft
              to="/createOrUpdateTruckAssignment"
              title="สั่งซื้อ"
              icon={<AddShoppingCartIcon />}
            />
          )}

          {hasOverdueTruckAssignmentPermission && (
            <DrawerLink
              paddingLeft
              to="/overdueTruckAssignments"
              title="ชำระเงิน"
              icon={<PaymentIcon />}
            />
          )}
          {hasTruckAssignmentPermission && (
            <DrawerLink
              paddingLeft
              to="/truckAssignments"
              title="ค้นหารถเร่"
              icon={<ListAltIcon />}
            />
          )}
        </List>
      </Collapse>
    </>
  );
}
