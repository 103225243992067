import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import isEmpty from 'lodash/isEmpty';
import CreateOrderTableRow from './CreateOrderTableRow';
import CreateOrderTableHeader from './CreateOrderTableHeader';
import withSpinner from '../WithSpinner';
import TableContainer from '../Container';
import CreateOrderConfirmButtons from '../../containers/CreateOrder/CreateOrderConfirmButtons';
import { isNotEmpty } from '../../utils/validators';
import CreateOrderTableAddOrderItemsRow from './CreateOrderTableAddOrderItemsRow';
import CreateOrderOptions from '../../containers/CreateOrder/CreateOrderOptions';

function CreateOrderTable({
  disabled,
  products,
  order,
  orderItems,
  onReset,
  onChange,
  onDelete,
  onPrintClick,
  onChangeQuantity,
  onAddOrderItemsClick,
  showOrderLink = false,
  showPrintOption = false,
}) {
  return (
    <TableContainer
      title="รายการสินค้า"
      rightTopMenu={
        <CreateOrderOptions
          disabled={disabled}
          orderId={order.id}
          showOrderLink={showOrderLink}
          showPrintOption={showPrintOption}
          onPrintClick={onPrintClick}
          onAddOrderItemsClick={onAddOrderItemsClick}
        />
      }
      footer={
        <CreateOrderConfirmButtons
          order={order}
          disabled={disabled}
          orderItems={orderItems}
          orderCreated={isNotEmpty(order, 'id')}
          disabledSubmit={disabled || isEmpty(orderItems)}
          onReset={onReset}
        />
      }
    >
      <Table>
        <CreateOrderTableHeader />
        <TableBody>
          {isEmpty(orderItems) ? (
            <CreateOrderTableAddOrderItemsRow
              disabled={disabled}
              onClick={onAddOrderItemsClick}
            />
          ) : (
            orderItems.map((orderItem, idx) => (
              <CreateOrderTableRow
                key={orderItem.productId}
                idx={idx}
                disabled={disabled}
                orderItem={orderItem}
                orderItems={orderItems}
                products={products}
                onChange={onChange}
                onDelete={onDelete}
                onQuantityChange={onChangeQuantity}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withSpinner(CreateOrderTable);
