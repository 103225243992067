import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonLoader from '../../../components/ButtonLoader';
import ProductContext from '../context';
import { setDisabled, setPrices, setProduct } from '../actions';

export default function ProductConfirmButtons() {
  const { state, dispatch } = useContext(ProductContext);
  const { product, savedProduct, savedPrices, loading, disabled } = state;

  const handleEditProduct = () => {
    dispatch(setDisabled(false));
  };

  const handleCancel = () => {
    if (product.id) {
      dispatch(setDisabled(true));
    }

    dispatch(setProduct({ ...savedProduct }));
    dispatch(setPrices([...savedPrices]));
  };

  return (
    <Box mt={3}>
      {disabled && !loading ? (
        <Button variant="contained" color="primary" onClick={handleEditProduct}>
          แก้ไข
        </Button>
      ) : (
        <Box display="flex" flexWrap="wrap">
          <Box mr={1}>
            <ButtonLoader type="submit" loading={loading}>
              บันทึก
            </ButtonLoader>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            disabled={disabled}
            onClick={handleCancel}
          >
            ยกเลิก
          </Button>
        </Box>
      )}
    </Box>
  );
}
