import React from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RefreshIcon from '@material-ui/icons/Refresh';

function SearchResultPaginator({
  showNext = false,
  showPrevious = false,
  next = () => {},
  previous = () => {},
  refresh = () => {},
}) {
  return (
    <Box display="flex" p={1}>
      {showPrevious && (
        <Box mr={1}>
          <IconButton size="small" onClick={previous}>
            <NavigateBeforeIcon />
          </IconButton>
        </Box>
      )}
      {showNext && (
        <IconButton size="small" onClick={next}>
          <NavigateNextIcon />
        </IconButton>
      )}
      <IconButton size="small" onClick={refresh}>
        <RefreshIcon />
      </IconButton>
    </Box>
  );
}

export default SearchResultPaginator;
