import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectCreateOrUpdateTruckAssignment = state =>
  state.createOrUpdateTruckAssignment;

export const selectLoading = createSelector(
  selectCreateOrUpdateTruckAssignment,
  ({ loading }) => loading,
);

export const selectDisabled = createSelector(
  selectCreateOrUpdateTruckAssignment,
  ({ disabled }) => disabled,
);

export const selectSaved = createSelector(
  selectCreateOrUpdateTruckAssignment,
  ({ saved }) => saved,
);

export const selectTruckAssignment = createSelector(
  selectCreateOrUpdateTruckAssignment,
  ({ truckAssignment }) => truckAssignment,
);

export const selectTruckAssignmentId = createSelector(
  selectTruckAssignment,
  truckAssignment => truckAssignment.truckAssignmentId,
);

export const selectDeliveryRouteId = createSelector(
  selectTruckAssignment,
  truckAssignment => truckAssignment.deliveryRouteId,
);

export const selectProducts = createSelector(
  selectCreateOrUpdateTruckAssignment,
  ({ products }) => products,
);

export const selectRows = createSelector(
  selectCreateOrUpdateTruckAssignment,
  ({ rows }) => rows,
);

export const selectOrderItems = createSelector(selectRows, rows =>
  _.flatMap(rows, row => _.values(row.orderItems)),
);

export const makeSelectTotals = selectRows =>
  createSelector(selectRows, rows => {
    const formattedRows = _.map(rows, row =>
      _.mapValues(row.orderItems, 'quantity'),
    );
    return _.reduce(
      formattedRows,
      (totals, orderItems) =>
        _.mergeWith(
          {},
          totals,
          orderItems,
          (total, quantity) => (total || 0) + quantity,
        ),
      {},
    );
  });

export const makeSelectDisabledConfirmButton = createSelector(
  [selectDisabled, selectDeliveryRouteId, selectRows],
  (disabled, deliveryRouteId, rows) => {
    let empty = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const row of rows) {
      if (!_.isEmpty(row.orderItems)) {
        empty = false;
        break;
      }
    }
    return disabled || empty || !deliveryRouteId;
  },
);

export const makeSelectDisabledCancelButton = createSelector(
  [selectDisabled, selectDeliveryRouteId],
  (disabled, deliveryRouteId) => disabled || !deliveryRouteId,
);
