import produce from 'immer';
import moment from 'moment';
import {
  GET_REPORTS,
  GET_REPORTS_ERROR,
  GET_REPORTS_SUCCESS,
  SET_DATE,
  SET_ORDER_TYPES,
  SET_STATION,
  TOGGLE_SHOW_TRUCK_ASSIGNMENT,
} from './constants';
import { ORDER_TYPE } from '../../constants';

export const initialState = {
  date: moment().startOf('day'),
  rows: [],
  reports: [],
  truckAssignments: [],
  orderTypes: [
    ORDER_TYPE.SALES_ORDER,
    ORDER_TYPE.DELIVERY_ORDER,
    ORDER_TYPE.TRUCK_SALES_ORDER,
    ORDER_TYPE.OTHER_COMPANY_CUSTOMER_ORDER,
  ],
  station: 'ALL',
  loading: false,
  error: false,
  showTruckAssignment: true,
};

/* eslint-disable default-case, no-param-reassign */
export default function reportReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case GET_REPORTS:
        draft.loading = true;
        break;
      case GET_REPORTS_SUCCESS:
        draft.reports = action.payload.reports;
        draft.rows = action.payload.rows;
        draft.truckAssignments = action.payload.truckAssignments;
        draft.loading = false;
        draft.error = false;
        break;
      case GET_REPORTS_ERROR:
        draft.loading = false;
        draft.error = true;
        break;
      case SET_DATE:
        draft.date = action.payload.date;
        draft.loading = true;
        break;
      case SET_STATION:
        draft.station = action.payload.station;
        break;
      case TOGGLE_SHOW_TRUCK_ASSIGNMENT:
        draft.showTruckAssignment = !draft.showTruckAssignment;
        break;
      case SET_ORDER_TYPES:
        draft.orderTypes = action.payload.orderTypes;
    }
  });
}
