import React from 'react';
import MaterialTableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';
import { CommaSeparatedNumber } from '../../shared';
import { DELIVERY_ROUTE_DESC } from '../../constants';
import TruckAssignmentStatusChip from '../TruckAssignmentStatusChip';

const TableCell = styled(MaterialTableCell)({
  whiteSpace: 'nowrap',
});

function TruckAssignmentSearchResultTableRow({ assignment }) {
  const {
    id,
    sequenceNumber,
    deliveryRouteId,
    status,
    subtotal,
    driver,
    companion,
    createdAt,
    comment,
  } = assignment;
  return (
    <TableRow>
      <TableCell>
        <Link to={`/truckAssignments/${id}`}>{sequenceNumber}</Link>
      </TableCell>
      <TableCell>{DELIVERY_ROUTE_DESC[deliveryRouteId]}</TableCell>
      <TableCell>
        <TruckAssignmentStatusChip status={status} />
      </TableCell>
      <TableCell>
        <CommaSeparatedNumber value={subtotal} fixedDecimalScale />
      </TableCell>
      <TableCell>{driver}</TableCell>
      <TableCell>{companion}</TableCell>
      <TableCell>
        {moment(createdAt).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')}
      </TableCell>
      <TableCell>{comment || '-'}</TableCell>
    </TableRow>
  );
}

export default TruckAssignmentSearchResultTableRow;
