import produce from 'immer';
import _ from 'lodash';
import {
  ADD_INVENTORY_ITEM,
  CANCEL_INBOUND,
  DELETE_INVENTORY_ITEM,
  ENABLE_INBOUND,
  RESET_INBOUND,
  SUBMIT_INBOUND,
  SUBMIT_INBOUND_ERROR,
  SUBMIT_INBOUND_SUCCESS,
  UPDATE_INVENTORY_ITEM_PRODUCT_ID,
  UPDATE_INVENTORY_ITEM_QUANTITY,
  UPDATE_WAREHOUSE_ID,
} from './constants';

const initialState = {
  inbound: {
    id: '',
    warehouseId: '',
    updatedAt: '',
    updatedBy: {},
  },
  savedInbound: {
    id: '',
    warehouseId: '',
    updatedAt: '',
    updatedBy: {},
  },
  inventoryItems: [],
  savedInventoryItems: [],
  loading: false,
  disabled: false,
};

/* eslint-disable default-case, no-param-reassign */
function createInventoryItemsReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ADD_INVENTORY_ITEM:
        draft.inventoryItems.push({
          productId: '',
          quantity: 0,
        });
        break;
      case SUBMIT_INBOUND:
        draft.loading = true;
        break;
      case SUBMIT_INBOUND_SUCCESS:
        draft.inbound = action.payload.inbound;
        draft.loading = false;
        draft.disabled = true;
        break;
      case SUBMIT_INBOUND_ERROR:
        draft.loading = false;
        draft.disabled = false;
        break;
      case DELETE_INVENTORY_ITEM:
        _.pullAt(draft.inventoryItems, [action.payload.idx]);
        break;
      case UPDATE_INVENTORY_ITEM_PRODUCT_ID:
        draft.inventoryItems[action.payload.idx].productId =
          action.payload.productId;
        break;
      case UPDATE_INVENTORY_ITEM_QUANTITY:
        draft.inventoryItems[action.payload.idx].quantity =
          action.payload.quantity;
        break;
      case UPDATE_WAREHOUSE_ID:
        draft.inbound.warehouseId = action.payload.warehouseId;
        break;
      case ENABLE_INBOUND:
        draft.disabled = false;
        draft.savedInbound = { ...draft.inbound };
        draft.savedInventoryItems = [...draft.inventoryItems];
        break;
      case CANCEL_INBOUND:
        draft.disabled = !!draft.savedInbound.id;
        draft.inbound = { ...draft.savedInbound };
        draft.inventoryItems = [...draft.savedInventoryItems];
        break;
      case RESET_INBOUND:
        draft.inbound.id = '';
        draft.inbound.warehouseId = '';
        draft.inbound.createdBy = {};
        draft.inventoryItems = [];
        draft.loading = false;
        draft.disabled = false;
    }
  });
}

export default createInventoryItemsReducer;
