import { createSelector } from 'reselect';

export const selectDebtor = state => state.debtor;

export const makeSelectCustomerId = createSelector(
  selectDebtor,
  ({ debtor }) => debtor.id,
);

export const makeSelectDebtor = createSelector(
  selectDebtor,
  ({ debtor }) => debtor,
);

export const makeSelectOverdueOrders = createSelector(
  selectDebtor,
  ({ overdueOrders }) => overdueOrders,
);

export const makeSelectLoading = createSelector(
  selectDebtor,
  ({ loading }) => loading,
);
