import React from 'react';
import Spinner from '../Spinner';

/* eslint-disable react/jsx-props-no-spreading */
const withSpinner =
  WrappedComponent =>
  ({ loading = false, ...otherProps }) =>
    (
      <Spinner loading={loading}>
        <WrappedComponent {...otherProps} />
      </Spinner>
    );

export default withSpinner;
