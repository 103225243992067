export const SET_SHOW_DRAWER = 'App/SET_SHOW_DRAWER';
export const SET_HIDE_DRAWER = 'App/SET_HIDE_DRAWER';
export const SIGN_IN = 'App/SIGN_IN';
export const SIGN_IN_SUCCESS = 'App/SIGN_IN_SUCCESS';
export const SIGN_OUT = 'App/SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'App/SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'App/SIGN_OUT_ERROR';
export const GET_CURRENT_AUTHENTICATED_USER =
  'App/GET_CURRENT_AUTHENTICATED_USER';
export const GET_CURRENT_AUTHENTICATED_USER_SUCCESS =
  'App/GET_CURRENT_AUTHENTICATED_USER_SUCCESS';
export const GET_CURRENT_AUTHENTICATED_USER_ERROR =
  'App/GET_CURRENT_AUTHENTICATED_USER_ERROR';
export const VERIFY_MFA_CODE = 'App/VERIFY_MFA_CODE';
export const SET_UP_MFA = 'App/SET_UP_MFA';
export const GET_PRODUCTS = 'App/GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'App/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'App/GET_PRODUCTS_ERROR';
export const GET_WAREHOUSES = 'App/GET_WAREHOUSES';
export const GET_WAREHOUSES_SUCCESS = 'App/GET_WAREHOUSES_SUCCESS';
export const GET_WAREHOUSES_ERROR = 'App/GET_WAREHOUSES_ERROR';
export const SHOW_SUCCESS_MESSAGE = 'App/SHOW_SUCCESS_MESSAGE';
export const HIDE_SUCCESS_MESSAGE = 'App/HIDE_SUCCESS_MESSAGE';
export const SHOW_ERROR_MESSAGE = 'App/SHOW_ERROR_MESSAGE';
export const HIDE_ERROR_MESSAGE = 'App/HIDE_ERROR_MESSAGE';
export const SHOW_WARNING_MESSAGE = 'App/SHOW_WARNING_MESSAGE';
export const HIDE_WARNING_MESSAGE = 'App/HIDE_WARNING_MESSAGE';
