import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TruckAssignmentSearchResultTableHeader from './TruckAssignmentSearchResultTableHeader';
import TruckAssignmentSearchResultTableBody from './TruckAssignmentSearchResultTableBody';
import withSpinner from '../WithSpinner';
import SearchResultPaginator from '../SearchResultPaginator';
import Paper from '../Paper';

function TruckAssignmentSearchResultTable({
  assignments,
  next,
  previous,
  showNext,
  showPrevious,
  refresh,
  component,
}) {
  return (
    <TableContainer component={component || Paper} padding={false}>
      <SearchResultPaginator
        next={next}
        previous={previous}
        refresh={refresh}
        showNext={showNext}
        showPrevious={showPrevious}
      />
      <Box overflow="auto">
        <Table
          sx={{
            borderTopWidth: 1,
            borderColor: 'divider',
            borderTopStyle: 'solid',
          }}
        >
          <TruckAssignmentSearchResultTableHeader />
          <TruckAssignmentSearchResultTableBody assignments={assignments} />
        </Table>
      </Box>
      <SearchResultPaginator
        next={next}
        previous={previous}
        refresh={refresh}
        showNext={showNext}
        showPrevious={showPrevious}
      />
    </TableContainer>
  );
}

export default withSpinner(TruckAssignmentSearchResultTable);
