import _ from 'lodash';

export function getSubtotal(orderItems): number {
  return _.flow([
    _.partialRight(_.reject, ['_deleted', true]),
    _.partialRight(
      _.reduce,
      (subtotal, { unitPrice, adjustment, quantity }) => {
        const price = unitPrice + (adjustment || 0);
        return subtotal + price * quantity;
      },
      0,
    ),
  ])(orderItems);
}
