import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { AsyncAutoComplete } from '../../../shared';
import useDebounce from '../../../utils/useDebounce';

export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: SearchableCustomerSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        orders {
          nextToken
        }
        discounts {
          items {
            id
            discount
            productId
          }
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

/* eslint-disable no-shadow, no-restricted-syntax, react/require-default-props */
function CustomerSearch(props) {
  const {
    inputValue,
    label,
    placeholder,
    onSearch,
    onChange,
    onInputChange,
    freeSolo = false,
    disabled = false,
    required = false,
  } = props;

  const [key, setKey] = useState(uuid());
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typedTerm, setTypedTerm] = useState('');
  const debouncedTerm = useDebounce(typedTerm, 500);

  useEffect(() => {
    // Force re-rendering if inputValue is empty
    if (!inputValue) {
      setKey(uuid());
    }
  }, [inputValue]);

  useEffect(() => {
    // If debouncedSearchTerm is empty or null, close the suggestion.
    if (!debouncedTerm || debouncedTerm === '') {
      setOpen(false);
      return;
    }

    setOpen(true);
    setLoading(true);

    (async () => {
      const searchTerm = debouncedTerm.toLowerCase();
      const result = await (onSearch
        ? onSearch(searchTerm)
        : API.graphql(
            graphqlOperation(searchCustomers, {
              filter: {
                or: [
                  { name: { wildcard: `*${searchTerm}*` } },
                  { name: { matchPhrasePrefix: searchTerm } },
                  { phoneNumber: { matchPhrasePrefix: searchTerm } },
                ],
              },
              limit: 30,
            }),
          ));
      const options = result.data.searchCustomers.items;
      setOptions(options);
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, [debouncedTerm]);

  const onSelectOption = useCallback(
    (e, val) => {
      // To prevent useDebounce from running
      setTypedTerm('');
      onChange(e, val);
    },
    [onChange],
  );

  const onTermChange = useCallback(
    (e, val) => {
      setTypedTerm(val);
      if (onInputChange) {
        onInputChange(val);
      }
    },
    [onInputChange],
  );

  const onClose = useCallback(() => {
    // To prevent useDebounce from running
    setTypedTerm('');
    setOpen(false);
  }, [options, typedTerm, onChange]);

  const renderOption = useCallback(
    option => (
      <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        <Box display="inline-block" mr={1}>
          <Typography variant="body1" component="span">
            {option.name}
          </Typography>
        </Box>
        {option.phoneNumber && (
          <Typography variant="caption" component="span" color="textSecondary">
            {`เบอร์โทร ${
              option.phoneNumber &&
              option.phoneNumber.replace(
                /^(\d{3})(\d{3})(\d{4}).*/,
                '($1) $2-$3',
              )
            }`}
          </Typography>
        )}
      </Box>
    ),
    [],
  );

  const getOptionLabel = option => {
    const { name, phoneNumber } = option;
    if (
      phoneNumber &&
      typedTerm &&
      phoneNumber.includes(typedTerm.toLowerCase())
    ) {
      return phoneNumber;
    }
    return name;
  };

  return (
    <AsyncAutoComplete
      key={key}
      open={open}
      label={label}
      options={options}
      loading={loading}
      inputValue={inputValue}
      freeSolo={freeSolo}
      disabled={disabled}
      required={required}
      forcePopupIcon={false}
      placeholder={placeholder}
      onClose={onClose}
      onChange={onSelectOption}
      onInputChange={onTermChange}
      renderOption={option => renderOption(option)}
      getOptionLabel={option => getOptionLabel(option)}
      getOptionSelected={(option, value) => option.id === value.id}
    />
  );
}

CustomerSearch.propTypes = {
  inputValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  freeSolo: PropTypes.bool,
};

export default CustomerSearch;
