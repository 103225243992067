export const SET_DEBTOR = 'DebtorList/SET_DEBTOR';
export const SET_NAME = 'DebtorList/SET_NAME';
export const SET_SEQUENCE_NUMBER = 'DebtorList/SET_SEQUENCE_NUMBER';
export const CLEAR_DEBTOR = 'DebtorList/CLEAR_DEBTOR';
export const SET_SORT_FIELD = 'DebtorList/SET_SORT_FIELD';
export const SEARCH_DEBTORS = 'DebtorList/SEARCH_DEBTORS';
export const SEARCH_DEBTORS_SUCCESS = 'DebtorList/SEARCH_DEBTORS_SUCCESS';
export const SEARCH_DEBTORS_ERROR = 'DebtorList/SEARCH_DEBTORS_ERROR';
export const SEARCH_NEXT_DEBTORS = 'DebtorList/SEARCH_NEXT_DEBTORS';
export const SEARCH_PREVIOUS_DEBTORS = 'DebtorList/SEARCH_PREVIOUS_DEBTORS';
export const REFRESH_DEBTORS = 'DebtorList/REFRESH_DEBTORS';
