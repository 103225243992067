/* eslint-disable no-shadow */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { searchDebtorsError, searchDebtorsSuccess } from './actions';
import { searchCustomers } from '../../graphql/queries';
import {
  REFRESH_DEBTORS,
  SEARCH_DEBTORS,
  SEARCH_NEXT_DEBTORS,
  SEARCH_PREVIOUS_DEBTORS,
  SET_SORT_FIELD,
} from './constants';
import {
  makeSelectDebtor,
  makeSelectNextToken,
  makeSelectSort,
} from './selectors';

function createFilters(debtor) {
  const { customerId, name, sequenceNumber } = debtor;
  const filters = [
    {
      or: [{ debt: { gt: 0 } }, { beginningBalance: { gt: 0 } }],
    },
  ];
  if (customerId) {
    filters.push({
      id: { eq: customerId },
    });
  }

  if (name) {
    filters.push({
      or: [
        { name: { wildcard: `*${name}*` } },
        { name: { matchPhrasePrefix: name } },
      ],
    });
  }

  if (sequenceNumber) {
    filters.push({
      sequenceNumber: { eq: sequenceNumber },
    });
  }
  return { and: filters };
}

function createSort(sort) {
  const { field, asc } = sort;
  return {
    field,
    direction: asc ? 'asc' : 'desc',
  };
}

function* searchDebtors() {
  try {
    const debtor = yield select(makeSelectDebtor);
    const sort = yield select(makeSelectSort);
    const nextToken = yield select(makeSelectNextToken);
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(searchCustomers, {
        filter: createFilters(debtor),
        sort: createSort(sort),
        nextToken,
      }),
    );
    yield put(
      searchDebtorsSuccess(
        result.data.searchCustomers.items,
        result.data.searchCustomers.nextToken,
      ),
    );
  } catch {
    yield put(searchDebtorsError());
  }
}

export default function* saga() {
  yield takeLatest(
    [
      SEARCH_DEBTORS,
      REFRESH_DEBTORS,
      SEARCH_NEXT_DEBTORS,
      SEARCH_PREVIOUS_DEBTORS,
      SET_SORT_FIELD,
    ],
    searchDebtors,
  );
}
