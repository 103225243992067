import produce from 'immer';
import _ from 'lodash';
import {
  ADD_DISCOUNT_ITEM,
  CANCEL_UPDATE,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_SUCCESS,
  DELETE_DISCOUNT_ITEM,
  DELETE_DISCOUNT_ITEM_ERROR,
  DELETE_DISCOUNT_ITEM_SUCCESS,
  INIT_CUSTOMER,
  INIT_CUSTOMER_ERROR,
  INIT_CUSTOMER_SUCCESS,
  INIT_EMPTY_CUSTOMER_SUCCESS,
  RESET_CUSTOMER,
  SET_CUSTOMER,
  SET_CUSTOMER_NAME,
  TOGGLE_DISCOUNT_ITEM,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_ENABLED,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_DISCOUNT_ITEM_DISCOUNT,
  UPDATE_DISCOUNT_ITEM_PRODUCT,
} from './constants';
import { CUSTOMER_TYPE, PRICE_TYPE } from '../../constants';

const initialState = {
  customer: {
    id: null,
    name: null,
    type: null,
    sequenceNumber: null,
    phoneNumber: null,
    email: null,
    address: null,
    subdistrict: null,
    district: null,
    province: null,
    postalCode: null,
    deliveryRouteId: null,
  },
  savedCustomer: {
    id: null,
    name: null,
    type: null,
    sequenceNumber: null,
    phoneNumber: null,
    email: null,
    address: null,
    subdistrict: null,
    district: null,
    province: null,
    postalCode: null,
    deliveryRouteId: null,
  },
  discountItems: [],
  savedDiscountItems: [],
  error: false,
  loading: false,
  disabled: false,
  showSuccessMessage: false,
};

function getUnitPrice(customerType, prices) {
  const price =
    customerType === CUSTOMER_TYPE.MEMBER ||
    customerType === CUSTOMER_TYPE.OTHER_COMPANY_CUSTOMER
      ? _.find(prices, ['type', PRICE_TYPE.SPECIAL])
      : _.find(prices, ['type', PRICE_TYPE.DEFAULT]);
  return _.get(price, 'price', 0);
}

/* eslint-disable default-case, no-param-reassign, consistent-return */
const createCustomerReducer =
  key =>
  (state = initialState, action) =>
    produce(state, draft => {
      switch (action.type) {
        case `${key}/${SET_CUSTOMER}`: {
          draft.customer[action.payload.field] = action.payload.value;
          break;
        }
        case `${key}/${SET_CUSTOMER_NAME}`: {
          draft.customer.name = action.payload.name;
          draft.isNameLoading = true;
          break;
        }
        case `${key}/${TOGGLE_DISCOUNT_ITEM}`: {
          const { id, prices } = action.payload.product;
          const idx = _.findIndex(draft.discountItems, ['productId', id]);
          if (idx > -1) {
            _.pullAt(draft.discountItems, [idx]);
          } else {
            draft.discountItems.push({
              productId: id,
              discount: null,
              unitPrice: getUnitPrice(draft.customer.type, prices),
            });
          }
          break;
        }
        case `${key}/${UPDATE_DISCOUNT_ITEM_DISCOUNT}`:
          draft.discountItems[action.payload.idx].discount =
            action.payload.discount;
          break;
        case `${key}/${UPDATE_DISCOUNT_ITEM_PRODUCT}`: {
          const { id, prices } = action.payload.product;
          draft.discountItems[action.payload.idx].productId = id;
          // eslint-disable-next-line max-len
          draft.discountItems[action.payload.idx].unitPrice = getUnitPrice(
            draft.customer.type,
            prices,
          );
          break;
        }
        case `${key}/${INIT_CUSTOMER}`:
        case `${key}/${CREATE_CUSTOMER}`:
        case `${key}/${UPDATE_CUSTOMER}`:
        case `${key}/${DELETE_DISCOUNT_ITEM}`:
          draft.loading = true;
          break;
        case `${key}/${CREATE_CUSTOMER_SUCCESS}`:
          draft.customer = action.payload.customer;
          draft.savedCustomer = action.payload.customer;
          draft.discountItems = action.payload.discountItems;
          draft.error = false;
          draft.loading = false;
          draft.disabled = true;
          draft.showSuccessMessage = true;
          break;
        case `${key}/${INIT_CUSTOMER_ERROR}`:
        case `${key}/${UPDATE_CUSTOMER_ERROR}`:
        case `${key}/${CREATE_CUSTOMER_ERROR}`:
        case `${key}/${DELETE_DISCOUNT_ITEM_ERROR}`:
          draft.loading = false;
          draft.error = true;
          break;
        case `${key}/${UPDATE_CUSTOMER_ENABLED}`:
          draft.savedCustomer = { ...draft.customer };
          draft.savedDiscountItems = [...draft.discountItems];
          draft.disabled = false;
          break;
        case `${key}/${INIT_CUSTOMER_SUCCESS}`:
          draft.customer = action.payload.customer;
          draft.savedCustomer = action.payload.customer;
          draft.discountItems = action.payload.discountItems;
          draft.error = false;
          draft.loading = false;
          draft.disabled = true;
          break;
        case `${key}/${INIT_EMPTY_CUSTOMER_SUCCESS}`:
          draft.error = false;
          draft.loading = false;
          draft.disabled = false;
          break;
        case `${key}/${DELETE_DISCOUNT_ITEM_SUCCESS}`:
          _.pullAt(draft.discountItems, action.payload.idx);
          draft.savedDiscountItems = [...draft.discountItems];
          draft.error = false;
          draft.loading = false;
          break;
        case `${key}/${UPDATE_CUSTOMER_SUCCESS}`:
          draft.customer = action.payload.customer;
          draft.discountItems = action.payload.discountItems;
          draft.error = false;
          draft.loading = false;
          draft.disabled = true;
          draft.showSuccessMessage = true;
          break;
        case `${key}/${CANCEL_UPDATE}`:
          if (draft.customer.id) {
            draft.customer = draft.savedCustomer;
            draft.discountItems = draft.savedDiscountItems;
            draft.disabled = true;
            break;
          } else {
            return initialState;
          }
        case `${key}/${RESET_CUSTOMER}`:
          draft.customer = initialState.customer;
          draft.savedCustomer = initialState.savedCustomer;
          draft.discountItems = initialState.discountItems;
          draft.savedDiscountItems = initialState.savedDiscountItems;
          draft.disabled = false;
          break;
        case `${key}/${ADD_DISCOUNT_ITEM}`:
          draft.discountItems.push({
            productId: null,
            discount: null,
            unitPrice: null,
          });
          break;
      }
    });

export default createCustomerReducer;
