export const SET_SEARCH_PARAM = 'OrderList/SET_SEARCH_PARAM';
export const CLEAR_SEARCH_PARAMS = 'OrderList/CLEAR_SEARCH_PARAMS';
export const SEARCH_ORDERS = 'OrderList/SEARCH_ORDERS';
export const SEARCH_ORDERS_SUCCESS = 'OrderList/SEARCH_ORDERS_SUCCESS';
export const SEARCH_ORDERS_ERROR = 'OrderList/SEARCH_ORDERS_ERROR';
export const SEARCH_NEXT_ORDERS = 'OrderList/SEARCH_NEXT_ORDERS';
export const SEARCH_PREVIOUS_ORDERS = 'OrderList/SEARCH_PREVIOUS_ORDERS';
export const REFRESH_SEARCH_ORDERS = 'OrderList/REFRESH_SEARCH_ORDERS';
export const EXPORT_ORDERS = 'OrderList/EXPORT_ORDERS';
export const EXPORT_ORDERS_SUCCESS = 'OrderList/EXPORT_ORDERS_SUCCESS';
export const EXPORT_ORDERS_ERROR = 'OrderList/EXPORT_ORDERS_ERROR';
