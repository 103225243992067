import React from 'react';
import _ from 'lodash';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import CustomerPriceTableRow from './CustomerPriceTableRow';
import CustomerPriceTableHeader from './CustomerPriceTableHeader';
import CustomerPriceTableContainer from './CustomerPriceTableContainer';
import CustomerPriceTableEmptyRow from './CustomerPriceTableEmptyRow';
import withSpinner from '../../components/WithSpinner';

/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable react/no-array-index-key */
function CustomerPriceTable({
  disabled,
  products,
  discountItems,
  onDiscountItemChange,
  onDiscountChange,
  onAddDiscountItem,
  onDelete,
  ...otherProps
}) {
  return (
    <TableContainer
      component={CustomerPriceTableContainer}
      disabled={disabled}
      onAddDiscountItem={onAddDiscountItem}
      {...otherProps}
    >
      <Box overflow="auto">
        <Table>
          <CustomerPriceTableHeader />
          <TableBody>
            {_.isEmpty(discountItems) ? (
              <CustomerPriceTableEmptyRow
                disabled={disabled}
                onAddDiscountItem={onAddDiscountItem}
              />
            ) : (
              discountItems.map((discountItem, idx) => (
                <CustomerPriceTableRow
                  key={idx}
                  idx={idx}
                  disabled={disabled}
                  products={products}
                  discountItem={discountItem}
                  discountItems={discountItems}
                  onDiscountItemChange={onDiscountItemChange}
                  onDiscountChange={onDiscountChange}
                  onDelete={onDelete}
                />
              ))
            )}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
}

export default withSpinner(CustomerPriceTable);
