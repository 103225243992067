import _ from 'lodash';
import React, { useCallback, useEffect, useReducer } from 'react';
import Typography from '@material-ui/core/Typography';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import Container from '@material-ui/core/Container';
import { Paper, Spinner } from '../../../shared';
import ProductForm from '../components/ProductForm';
import * as ProductReducer from '../reducer';
import ProductContext from '../context';
import { getProduct, listBrands } from '../graphql/queries';
import {
  setBrandName,
  setBrands,
  setDisabled,
  setLoading,
  setPrices,
  setProduct,
  setSavedPrices,
} from '../actions';
import ProductConfirmButtons from '../components/ProductConfirmButtons';
import { PRICE_TYPE, SNACKBAR_VARIANT_TYPE } from '../../../constants';
import {
  createPrice,
  createProduct,
  deletePrice,
  updatePrice,
  updateProduct,
} from '../graphql/mutations';
import { SET_SAVED_PRODUCT } from '../actionTypes';

/* eslint-disable no-shadow, no-plusplus, no-continue, no-await-in-loop */
export default function Product() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(
    ProductReducer.reducer,
    ProductReducer.initialState,
  );
  const { productId } = useParams();
  const { product, savedProduct, prices, savedPrices } = state;

  const renderProduct = useCallback(async () => {
    try {
      if (productId) {
        dispatch(setDisabled(true));

        const result = await API.graphql(
          graphqlOperation(getProduct, {
            id: productId,
          }),
        );

        const product = result.data.getProduct;

        // Set Product
        dispatch(setProduct({ ...product }));
        dispatch(setProduct({ ...product }, SET_SAVED_PRODUCT));

        // Set Brand Name
        dispatch(setBrandName(product.brand.name));

        // Set Prices
        const prices = product.prices.items;
        const sortedPrices = _.flow([
          _.partialRight(_.reject, ['_deleted', true]),
          _.partialRight(_.orderBy, ['conditionValue']),
        ])(prices);
        dispatch(setPrices(sortedPrices));
        dispatch(setSavedPrices(sortedPrices));
      } else {
        // Get all brands
        const result = await API.graphql(graphqlOperation(listBrands));
        dispatch(setBrands(result.data.listBrands.items));
      }
    } catch {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
    }
  }, [productId, enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      await renderProduct();
    })();
  }, [renderProduct]);

  const handleUpdatePriceId = (index, id, _version) => {
    const updatedPrices = [...prices];

    updatedPrices.splice(index, 1, {
      ...prices[index],
      id,
      _version,
    });

    dispatch(setPrices(updatedPrices));
  };

  const handleDisableProductForm = () => {
    // Display loading animation and disable the form
    dispatch(setDisabled(true));
    dispatch(setLoading(true));
    enqueueSnackbar('กำลังดำเนินการ...', {
      variant: SNACKBAR_VARIANT_TYPE.INFO,
    });
  };

  const handleShowSuccessMessage = () => {
    // Hide loading animation
    dispatch(setLoading(false));

    enqueueSnackbar('บันทึกข้อมูลสินค้าสำเร็จ', {
      variant: SNACKBAR_VARIANT_TYPE.SUCCESS,
    });
  };

  const handleCreateProduct = async () => {
    const { name, type, weight, brandId, isTruckAssignmentProduct } = product;

    const input = {
      name,
      type,
      brandId,
      isTruckAssignmentProduct,
    };

    if (weight) {
      input.weight = weight;
    }

    const result = await API.graphql(
      graphqlOperation(createProduct, { input }),
    );

    const { id, _version } = result.data.createProduct;

    // Update Product
    dispatch(setProduct({ ...product, id, _version }));
    dispatch(setProduct({ ...product, id, _version }, SET_SAVED_PRODUCT));

    return id;
  };

  const handleUpdateProduct = async () => {
    const { id, name, weight, isTruckAssignmentProduct, _version } = product;
    if (
      name !== savedProduct.name ||
      weight !== savedProduct.weight ||
      isTruckAssignmentProduct !== savedProduct.isTruckAssignmentProduct
    ) {
      const input = {
        id,
        name,
        isTruckAssignmentProduct,
        _version,
      };
      if (weight) {
        input.weight = weight;
      }
      await API.graphql(graphqlOperation(updateProduct, { input }));
    }

    // Update Saved Product
    dispatch(setProduct({ ...product }, SET_SAVED_PRODUCT));
  };

  const handleCreatePrice = async (index, productId) => {
    const { type, price, conditionValue } = prices[index];

    const result = await API.graphql(
      graphqlOperation(createPrice, {
        input: {
          type,
          price,
          productId,
          conditionValue,
        },
      }),
    );

    const { id: priceId, _version } = result.data.createPrice.id;
    handleUpdatePriceId(index, priceId, _version);
  };

  const handleDeletePrice = async (id, version) => {
    const input = {
      id,
      _version: version,
    };
    await API.graphql(graphqlOperation(deletePrice, { input }));
  };

  const handleUpdatePrice = async priceObj => {
    const { id, type, price, productId, conditionValue, _version } = priceObj;
    const input = {
      id,
      type,
      price,
      productId,
      conditionValue,
      _version,
    };
    await API.graphql(graphqlOperation(updatePrice, { input }));
  };

  const handleSubmit = async () => {
    try {
      let productId = product.id;
      handleDisableProductForm();

      if (productId) {
        // Update Product
        await handleUpdateProduct();
      } else {
        // Create Product
        productId = await handleCreateProduct();
      }

      // Set Prices
      for (let i = 0; i < prices.length; i++) {
        const { id, type, price, deleted, conditionValue, _version } =
          prices[i];

        if (savedPrices.length > 0 && i < savedPrices.length) {
          const savedPrice = savedPrices[i];
          if (id === savedPrice.id) {
            // Update Prices
            if (
              price !== savedPrice.price ||
              conditionValue !== savedPrice.conditionValue
            ) {
              // Disabled the old price
              await handleUpdatePrice(prices[i]);
            } else if (deleted) {
              // await handleDisablePrice(id);
              await handleDeletePrice(id, _version);
            }
          }

          continue;
        }

        // Create Prices
        if (
          (type === PRICE_TYPE.DEFAULT && price) ||
          (type === PRICE_TYPE.SPECIAL && price) ||
          (type === PRICE_TYPE.WHOLESALE && price && conditionValue)
        ) {
          await handleCreatePrice(i, productId);
        }
      }

      dispatch(setSavedPrices([...prices]));

      handleShowSuccessMessage();
    } catch {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });

      // Hide loading animation
      dispatch(setLoading(false));
    }
  };

  return (
    <Container>
      <ProductContext.Provider value={{ state, dispatch }}>
        <Paper elevation={0}>
          {/* Title */}
          <Typography variant="h6">ข้อมูลสินค้า / บริการ</Typography>

          {(productId && product.id) || !productId ? (
            <ValidatorForm onSubmit={handleSubmit}>
              {/* Product Form */}
              <ProductForm />

              {/* Product Confirm Button */}
              <ProductConfirmButtons />
            </ValidatorForm>
          ) : (
            <Spinner />
          )}
        </Paper>
      </ProductContext.Provider>
    </Container>
  );
}
