import React from 'react';
import { styled } from '@material-ui/core/styles';
import MaterialTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import withSpinner from '../WithSpinner';
// eslint-disable-next-line import/no-cycle
import OrderTableHeader from './OrderTableHeader';
// eslint-disable-next-line import/no-cycle
import OrderTableBody from './OrderTableBody';
import SearchResultPaginator from '../SearchResultPaginator';

const Table = styled(MaterialTable)(({ theme }) => ({
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderColor: theme.palette.divider,
}));

export const ORDER_TABLE_COLUMNS = {
  SEQUENCE_NUMBER: 'SEQUENCE_NUMBER',
  NAME: 'NAME',
  TYPE: 'TYPE',
  STATUS: 'STATUS',
  STATION: 'STATION',
  SUBTOTAL: 'SUBTOTAL',
  OVERDUE_AMOUNT: 'OVERDUE_AMOUNT',
  CREATED_AT: 'CREATED_AT',
};

function OrderTable({
  orders,
  showNext,
  showPrevious,
  next = () => {},
  previous = () => {},
  refresh = () => {},
  component,
  columns = {
    [ORDER_TABLE_COLUMNS.SEQUENCE_NUMBER]: true,
    [ORDER_TABLE_COLUMNS.NAME]: true,
    [ORDER_TABLE_COLUMNS.TYPE]: true,
    [ORDER_TABLE_COLUMNS.STATUS]: true,
    [ORDER_TABLE_COLUMNS.STATION]: true,
    [ORDER_TABLE_COLUMNS.SUBTOTAL]: true,
    [ORDER_TABLE_COLUMNS.OVERDUE_AMOUNT]: true,
    [ORDER_TABLE_COLUMNS.CREATED_AT]: true,
  },
}) {
  return (
    <TableContainer component={component || Paper} variant="outlined">
      <SearchResultPaginator
        showNext={showNext}
        showPrevious={showPrevious}
        next={next}
        previous={previous}
        refresh={refresh}
      />
      <Box overflow="auto">
        <Table>
          <OrderTableHeader columns={columns} />
          <OrderTableBody columns={columns} orders={orders} />
        </Table>
      </Box>
      <SearchResultPaginator
        showNext={showNext}
        showPrevious={showPrevious}
        next={next}
        previous={previous}
        refresh={refresh}
      />
    </TableContainer>
  );
}

export default withSpinner(OrderTable);
