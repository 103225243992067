import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import {
  CLEAR_SEARCH_PARAMS,
  EXPORT_TRUCK_ASSIGNMENTS,
  EXPORT_TRUCK_ASSIGNMENTS_ERROR,
  EXPORT_TRUCK_ASSIGNMENTS_SUCCESS,
  REFRESH_TRUCK_ASSIGNMENTS,
  SEARCH_NEXT_TRUCK_ASSIGNMENTS,
  SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS,
  SEARCH_TRUCK_ASSIGNMENTS,
  SEARCH_TRUCK_ASSIGNMENTS_ERROR,
  SEARCH_TRUCK_ASSIGNMENTS_SUCCESS,
  SET_SEARCH_PARAM,
} from './constants';
import { TruckAssignmentListState } from './types';

export const initialState: TruckAssignmentListState = {
  assignments: null,
  params: {
    startDate: null,
    endDate: null,
    statuses: [],
    deliveryRoutes: [],
  },
  tokens: [],
  loading: false,
  exportNextToken: '',
  showNext: false,
  showPrevious: false,
};

/* eslint-disable default-case, no-param-reassign */
function truckAssignmentListReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_SEARCH_PARAM:
        draft.params[action.payload.param] = action.payload.value;
        break;
      case CLEAR_SEARCH_PARAMS:
        draft.params = initialState.params;
        break;
      case SEARCH_TRUCK_ASSIGNMENTS:
        draft.tokens = [];
        draft.loading = true;
        draft.exportNextToken = '';
        break;
      case SEARCH_NEXT_TRUCK_ASSIGNMENTS:
        draft.loading = true;
        break;
      case SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS:
        draft.tokens = draft.tokens.slice(0, draft.tokens.length - 2);
        draft.loading = true;
        break;
      case REFRESH_TRUCK_ASSIGNMENTS:
        if (!isEmpty(draft.tokens)) {
          draft.tokens = draft.tokens.slice(0, draft.tokens.length - 1);
        }
        draft.loading = true;
        break;
      case SEARCH_TRUCK_ASSIGNMENTS_SUCCESS: {
        draft.assignments = action.payload.assignments;
        draft.tokens.push(action.payload.nextToken);
        draft.showNext =
          !isEmpty(draft.tokens) &&
          !isEmpty(draft.tokens[draft.tokens.length - 1]);
        draft.showPrevious =
          draft.tokens.length > 1 && !isEmpty(draft.tokens[0]);
        draft.loading = false;
        break;
      }
      case EXPORT_TRUCK_ASSIGNMENTS:
        draft.exportNextToken = '';
        draft.loading = true;
        break;
      case EXPORT_TRUCK_ASSIGNMENTS_SUCCESS:
        draft.exportNextToken = action.payload.nextToken;
        draft.loading = false;
        break;
      case SEARCH_TRUCK_ASSIGNMENTS_ERROR:
      case EXPORT_TRUCK_ASSIGNMENTS_ERROR:
        draft.loading = false;
        break;
    }
  });
}

export default truckAssignmentListReducer;
