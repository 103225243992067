import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import isEmpty from 'lodash/isEmpty';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import ReportTableRow from './ReportTableRow';

function ReportTableBody({ rows }) {
  return (
    <TableBody>
      {isEmpty(rows) ? (
        <TableRow>
          <TableCell colSpan={21}>ไม่มีข้อมูล</TableCell>
        </TableRow>
      ) : (
        rows.map((row, idx) => (
          <ReportTableRow key={row.id} idx={idx} row={row} />
        ))
      )}
    </TableBody>
  );
}

export default ReportTableBody;
