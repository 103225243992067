import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import { SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import { PAYMENT_TYPE, PAYMENT_TYPE_DESC, VALIDATOR } from '../../constants';

function PaymentTypeRow({ paymentType, disabled, onChange }) {
  return (
    <TableRow>
      <TableCell colSpan={2} align="right">
        <Box displayPrint="none">
          <SelectValidator
            fullWidth
            margin="none"
            variant="outlined"
            label="ช่องทางชำระเงิน"
            value={paymentType || ''}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            onChange={onChange}
            disabled={disabled}
          >
            <MenuItem value="" disabled>
              กรุณาเลือก
            </MenuItem>
            <MenuItem value={PAYMENT_TYPE.CASH}>
              {PAYMENT_TYPE_DESC.CASH}
            </MenuItem>
            <MenuItem value={PAYMENT_TYPE.CHEQUE}>
              {PAYMENT_TYPE_DESC.CHEQUE}
            </MenuItem>
            <MenuItem value={PAYMENT_TYPE.TRANSFER}>
              {PAYMENT_TYPE_DESC.TRANSFER}
            </MenuItem>
          </SelectValidator>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default PaymentTypeRow;
