import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';

function OrderReceiptTableRow({ idx, orderItem }) {
  const { name, quantity, adjustedPrice, total } = orderItem;
  return (
    <TableRow>
      <TableCell align="center" padding="none">
        {idx + 1}
      </TableCell>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="right">{quantity}</TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber value={adjustedPrice} fixedDecimalScale />
      </TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber value={total} fixedDecimalScale />
      </TableCell>
    </TableRow>
  );
}

export default OrderReceiptTableRow;
