import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';
import MaterialTableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  ORDER_STATUS_DESC,
  ORDER_TYPE_DESC,
  STATION_DESC,
} from '../../constants';
import { CommaSeparatedNumber } from '../../shared';
import StatusChip from '../StatusChip';
// eslint-disable-next-line import/no-cycle
import { ORDER_TABLE_COLUMNS } from './index';

const TableCell = styled(MaterialTableCell)({
  whiteSpace: 'nowrap',
});

function OrderTableRow({ columns, order }) {
  const {
    id,
    type,
    status,
    station,
    createdAt,
    sequenceNumber,
    subtotal,
    paidAmount,
    totalAdjustment,
    customerName,
  } = order;
  return (
    <TableRow>
      {columns[ORDER_TABLE_COLUMNS.SEQUENCE_NUMBER] && (
        <TableCell>
          {sequenceNumber ? (
            <Link to={`/order/${id}`}>{sequenceNumber}</Link>
          ) : (
            '-'
          )}
        </TableCell>
      )}
      {columns[ORDER_TABLE_COLUMNS.NAME] && (
        <TableCell>{customerName}</TableCell>
      )}
      {columns[ORDER_TABLE_COLUMNS.TYPE] && (
        <TableCell>{ORDER_TYPE_DESC[type]}</TableCell>
      )}
      {columns[ORDER_TABLE_COLUMNS.STATUS] && (
        <TableCell>
          <StatusChip
            status={status}
            description={ORDER_STATUS_DESC[status]}
            showPrefix={false}
          />
        </TableCell>
      )}
      {columns[ORDER_TABLE_COLUMNS.STATION] && (
        <TableCell>{STATION_DESC[station]}</TableCell>
      )}
      {columns[ORDER_TABLE_COLUMNS.SUBTOTAL] && (
        <TableCell>
          <CommaSeparatedNumber value={subtotal} fixedDecimalScale />
        </TableCell>
      )}
      {columns[ORDER_TABLE_COLUMNS.OVERDUE_AMOUNT] && (
        <TableCell>
          <CommaSeparatedNumber
            value={subtotal + totalAdjustment - paidAmount}
            fixedDecimalScale
          />
        </TableCell>
      )}
      {columns[ORDER_TABLE_COLUMNS.CREATED_AT] && (
        <TableCell>
          {moment(createdAt).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm')}
        </TableCell>
      )}
    </TableRow>
  );
}

export default OrderTableRow;
