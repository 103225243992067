import {
  GET_DEBTOR,
  GET_DEBTOR_ERROR,
  GET_DEBTOR_SUCCESS,
  REFRESH_DEBTOR,
} from './constants';

export function getDebtor(customerId) {
  return {
    type: GET_DEBTOR,
    payload: { customerId },
  };
}

export function refreshDebtor() {
  return {
    type: REFRESH_DEBTOR,
  };
}

export function getDebtorSuccess(debtor, overdueOrders) {
  return {
    type: GET_DEBTOR_SUCCESS,
    payload: { debtor, overdueOrders },
  };
}

export function getDebtorError() {
  return {
    type: GET_DEBTOR_ERROR,
  };
}
