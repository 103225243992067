import React from 'react';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';

/* eslint-disable react/jsx-props-no-spreading */
function Uploader({ disabled, onAdd }) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: onAdd,
  });
  return (
    <Box
      p={3}
      width="100%"
      display="flex"
      bgcolor="grey.100"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      sx={{
        borderWidth: 0.75,
        borderColor: 'grey',
        borderStyle: 'dashed',
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} disabled={disabled} />
      <AddAPhotoIcon color="disabled" fontSize="large" />
      <Box mt={1}>
        <Typography variant="body1" color="textSecondary">
          เพิ่มรูปภาพ
        </Typography>
      </Box>
    </Box>
  );
}

export default Uploader;
