import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '../../components/TableRow';
import TableCell from '../../components/TableCell';

function CreateInventoryItemTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" width="5%">
          #
        </TableCell>
        <TableCell align="left" width="42.5%" padding="none">
          สินค้า
        </TableCell>
        <TableCell align="left" width="42.5%">
          จำนวน
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default CreateInventoryItemTableHeader;
