import { createSelector } from 'reselect';
import { CREATE_DEBT_STORE_KEY } from './constants';

export const selectCreateOrder = state => state.createOrder;

export const selectCreateDebt = state => state.createDebt;

export const createStateSelector = key =>
  key === CREATE_DEBT_STORE_KEY ? selectCreateDebt : selectCreateOrder;

export const makeSelectOrder = key =>
  createSelector(createStateSelector(key), ({ order }) => order);

export const makeSelectOrderItems = key =>
  createSelector(createStateSelector(key), ({ orderItems }) => orderItems);

export const makeSelectProducts = key =>
  createSelector(createStateSelector(key), ({ products }) => products);

export const makeSelectShowOrderItemDialog = key =>
  createSelector(
    createStateSelector(key),
    ({ showOrderItemDialog }) => showOrderItemDialog,
  );

export const makeSelectLoading = key =>
  createSelector(createStateSelector(key), ({ loading }) => loading);

export const makeSelectDisabled = key =>
  createSelector(createStateSelector(key), ({ disabled }) => disabled);

export const makeSelectShowStatus = key =>
  createSelector(createStateSelector(key), ({ showStatus }) => showStatus);

export const makeSelectShowQueueCard = key =>
  createSelector(
    createStateSelector(key),
    ({ showQueueCard }) => showQueueCard,
  );

export const makeSelectShowSequenceNumber = key =>
  createSelector(
    createStateSelector(key),
    ({ showSequenceNumber }) => showSequenceNumber,
  );

export const makeSelectDisabledSelectCustomer = key =>
  createSelector(
    createStateSelector(key),
    ({ disabledSelectCustomer }) => disabledSelectCustomer,
  );

export const makeSelectImages = key =>
  createSelector(createStateSelector(key), ({ images }) => images);
