export const SET_SEARCH_PARAM = 'TruckAssignmentList/SET_SEARCH_PARAM';
export const CLEAR_SEARCH_PARAMS = 'TruckAssignmentList/CLEAR_SEARCH_PARAMS';
export const SEARCH_TRUCK_ASSIGNMENTS =
  'TruckAssignmentList/SEARCH_TRUCK_ASSIGNMENTS';
export const SEARCH_TRUCK_ASSIGNMENTS_SUCCESS =
  'TruckAssignmentList/SEARCH_TRUCK_ASSIGNMENTS_SUCCESS';
export const SEARCH_TRUCK_ASSIGNMENTS_ERROR =
  'TruckAssignmentList/SEARCH_TRUCK_ASSIGNMENTS_ERROR';
export const SEARCH_NEXT_TRUCK_ASSIGNMENTS =
  'TruckAssignmentList/SEARCH_NEXT_TRUCK_ASSIGNMENTS';
export const SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS =
  'TruckAssignmentList/SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS';
export const REFRESH_TRUCK_ASSIGNMENTS =
  'TruckAssignmentList/REFRESH_TRUCK_ASSIGNMENTS';
export const EXPORT_TRUCK_ASSIGNMENTS =
  'TruckAssignmentList/EXPORT_TRUCK_ASSIGNMENTS';
export const EXPORT_TRUCK_ASSIGNMENTS_SUCCESS =
  'TruckAssignmentList/EXPORT_TRUCK_ASSIGNMENTS_SUCCESS';
export const EXPORT_TRUCK_ASSIGNMENTS_ERROR =
  'TruckAssignmentList/EXPORT_TRUCK_ASSIGNMENTS_ERROR';

export const EXPORT_TRUCK_ASSIGNMENTS_HEADER = [
  'เลขที่ใบสั่งซื้อรถเร่',
  'สายรถเร่',
  'พนักงานขับรถ',
  'พนักงานนั่งข้าง',
  'ทะเบียนรถยนต์',
  'สถานะ',
  'เลขที่ใบสั่งซื้อ',
  'ชื่อลูกค้า / รถเร่',
  'ประเภท',
  'สถานะ',
  'โรงอัดแก๊ส',
  '15',
  '48',
  '4',
  '7',
  '9',
  '11.5',
  '13.5',
  'ลูกค้าหน้าร้าน (นน.)',
  'รถเร่ / ออเดอร์ (นน.)',
  'ยอดขาย (บาท)',
  'ส่วนลด / ค่าใช้จ่าย',
  'เงินสด (ลูกค้าหน้าร้าน)',
  'เงินสด (รถเร่ / ออเดอร์)',
  'ค้างชำระ (บาท)',
  'วันที่ขาย',
];
