import _ from 'lodash';

export function isEmpty(object: any, path?: string) {
  const value = path ? _.get(object, path) : object;
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export function isNotEmpty(object: any, path?: string) {
  return !isEmpty(object, path);
}
