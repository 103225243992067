import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MenuItem from '@material-ui/core/MenuItem';
import keys from 'lodash/keys';
import { STATION, STATION_DESC, VALIDATOR } from '../../constants';
import TruckAssignmentSelector from '../../containers/CreateOrUpdateTruckAssignment/TruckAssignmentSelector';
import Container from '../Container';

/* eslint-disable react/jsx-props-no-spreading */
export default function TruckAssignmentForm({
  disabled,
  truckAssignment,
  onChange = () => {},
  onDateTimeChange = () => {},
  ...otherProps
}) {
  const {
    truckAssignmentId,
    deliveryRouteId,
    driver,
    companion,
    departure,
    licensePlate,
    station,
    sequenceNumber,
    comment,
  } = truckAssignment;

  return (
    <Container
      title="รถเร่"
      bodyPadding
      dividers={false}
      marginTop={false}
      scrollableBody={false}
      {...otherProps}
    >
      <Grid container spacing={2}>
        {sequenceNumber && (
          <Grid item xs={12} sm={6} md={4}>
            <TextValidator
              label="เลขที่ใบสั่งซื้อ"
              variant="outlined"
              margin="none"
              fullWidth
              disabled
              value={sequenceNumber}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <TruckAssignmentSelector
            deliveryRouteId={deliveryRouteId}
            disabled={disabled || !!truckAssignmentId}
            onChange={onChange('deliveryRouteId')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectValidator
            label="โรงอัดแก๊ส"
            variant="outlined"
            margin="none"
            fullWidth
            value={station || ''}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            onChange={onChange('station')}
            disabled={disabled}
          >
            <MenuItem value="" disabled>
              กรุณาเลือก
            </MenuItem>
            {keys(STATION).map(station => (
              <MenuItem key={station} value={station}>
                {STATION_DESC[station]}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextValidator
            label="พนักงานขับรถ"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            value={driver || ''}
            onChange={onChange('driver')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextValidator
            label="พนักงานนั่งข้าง"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            value={companion || ''}
            onChange={onChange('companion')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextValidator
            label="ทะเบียนรถยนต์"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            value={licensePlate || ''}
            onChange={onChange('licensePlate')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              fullWidth
              ampm={false}
              showTodayButton
              minutesStep={5}
              disabled={disabled}
              inputVariant="outlined"
              label="วันที่ / เวลาที่รถออก"
              todayLabel="เวลาตอนนี้"
              cancelLabel="ยกเลิก"
              okLabel="เลือกเวลา"
              format="DD/MM/YYYY HH:mm"
              value={departure}
              validators={['required']}
              errorMessages={[VALIDATOR.REQUIRED]}
              onChange={onDateTimeChange}
              TextFieldComponent={TextValidator}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextValidator
            label="หมายเหตุ"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            value={comment || ''}
            onChange={onChange('comment')}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
