import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const selectDebtorList = state => state.debtorList;

export const makeSelectSort = createSelector(
  selectDebtorList,
  ({ sort }) => sort,
);

export const makeSelectDebtor = createSelector(
  selectDebtorList,
  ({ debtor }) => debtor,
);

export const makeSelectDebtors = createSelector(
  selectDebtorList,
  ({ debtors }) => debtors,
);

export const makeSelectNextToken = createSelector(
  selectDebtorList,
  ({ tokens }) => (!isEmpty(tokens) ? tokens[tokens.length - 1] : null),
);

export const makeSelectShowNext = createSelector(
  selectDebtorList,
  ({ showNext }) => showNext,
);

export const makeSelectShowPrevious = createSelector(
  selectDebtorList,
  ({ showPrevious }) => showPrevious,
);

export const makeSelectError = createSelector(
  selectDebtorList,
  ({ error }) => error,
);

export const makeSelectLoading = createSelector(
  selectDebtorList,
  ({ loading }) => loading,
);
