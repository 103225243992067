import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import CreateInventoryItemTableButtons from './CreateInventoryItemTableButtons';
import CreateInboundForm from './CreateInboundForm';
import PaddingBox from '../../components/PaddingBox';

function CreateInventoryItemTableContainer({
  name,
  lastName,
  createdAt,
  warehouses,
  warehouseId,
  disabled,
  disabledSubmit,
  onAdd,
  onReset,
  onEnable,
  onCancel,
  onWarehouseChange,
  children,
}) {
  return (
    <Paper variant="outlined">
      <PaddingBox>
        <Typography variant="h6">รับสินค้าเข้า</Typography>
        <Box mt={2}>
          <CreateInboundForm
            name={name}
            disabled={disabled}
            lastName={lastName}
            createdAt={createdAt}
            warehouses={warehouses}
            warehouseId={warehouseId}
            onWarehouseChange={onWarehouseChange}
          />
        </Box>
      </PaddingBox>
      <Divider />
      <Box overflow="auto">{children}</Box>
      <Box p={{ xs: 2, md: 3 }}>
        <CreateInventoryItemTableButtons
          disabled={disabled}
          disabledSubmit={disabledSubmit}
          onAdd={onAdd}
          onReset={onReset}
          onEnable={onEnable}
          onCancel={onCancel}
        />
      </Box>
    </Paper>
  );
}

export default CreateInventoryItemTableContainer;
