import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ButtonLoader from '../../components/ButtonLoader';
import CustomerSearch from '../../modules/customer/components/CustomerSearch';

function DebtorSearchForm({
  name,
  sequenceNumber,
  loading,
  onReset,
  onChange,
  onSubmit,
  onInputChange,
  onSequenceNumberChange,
}) {
  return (
    <ValidatorForm onSubmit={onSubmit} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <CustomerSearch
            freeSolo
            inputValue={name || ''}
            disabled={loading}
            onChange={onChange}
            onInputChange={onInputChange}
            placeholder="ค้นหาลูกหนี้ (โดยชื่อหรือเบอร์โทร)"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextValidator
            label="รหัสลูกค้า"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={loading}
            onChange={onSequenceNumberChange}
            value={sequenceNumber || ''}
          />
        </Grid>
      </Grid>
      <Box mt={3} display="flex">
        <Box mr={1}>
          <ButtonLoader
            type="submit"
            color="primary"
            variant="contained"
            loading={loading}
          >
            ค้นหา
          </ButtonLoader>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            type="reset"
            onClick={onReset}
            disabled={loading}
          >
            ยกเลิก
          </Button>
        </Box>
      </Box>
    </ValidatorForm>
  );
}

export default DebtorSearchForm;
