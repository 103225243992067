import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import {
  GET_NEXT_OVERDUE_ORDERS,
  GET_OVERDUE_ORDERS,
  GET_OVERDUE_ORDERS_ERROR,
  GET_OVERDUE_ORDERS_SUCCESS,
  GET_PREVIOUS_OVERDUE_ORDERS,
  REFRESH_OVERDUE_ORDERS,
} from './constants';

export const initialState = {
  tokens: [],
  overdueOrders: [],
  error: false,
  loading: false,
  showNext: false,
  showPrevious: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_OVERDUE_ORDERS: {
        draft.tokens = [];
        draft.error = false;
        draft.loading = true;
        break;
      }
      case GET_NEXT_OVERDUE_ORDERS: {
        draft.error = false;
        draft.loading = true;
        break;
      }
      case REFRESH_OVERDUE_ORDERS: {
        if (!isEmpty(draft.tokens)) {
          draft.tokens = draft.tokens.slice(0, draft.tokens.length - 1);
        }
        draft.error = false;
        draft.loading = true;
        break;
      }
      case GET_PREVIOUS_OVERDUE_ORDERS: {
        draft.tokens = draft.tokens.slice(0, draft.tokens.length - 2);
        draft.error = false;
        draft.loading = true;
        break;
      }
      case GET_OVERDUE_ORDERS_SUCCESS: {
        draft.overdueOrders = action.payload.overdueOrders;
        draft.tokens.push(action.payload.nextToken);
        draft.showNext =
          !isEmpty(draft.tokens) &&
          !isEmpty(draft.tokens[draft.tokens.length - 1]);
        draft.showPrevious =
          draft.tokens.length > 1 && !isEmpty(draft.tokens[0]);
        draft.error = false;
        draft.loading = false;
        break;
      }
      case GET_OVERDUE_ORDERS_ERROR: {
        draft.error = true;
        draft.loading = false;
        break;
      }
    }
  });

export default reducer;
