import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import MaterialTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { styled } from '@material-ui/core/styles';

const TableCell = styled(MaterialTableCell)({
  whiteSpace: 'nowrap',
});

function TruckAssignmentSearchResultTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <b>เลขที่ใบสั่งซื้อรถเร่</b>
        </TableCell>
        <TableCell>
          <b>สายรถเร่</b>
        </TableCell>
        <TableCell>
          <b>สถานะ</b>
        </TableCell>
        <TableCell>
          <b>ยอดขาย</b>
        </TableCell>
        <TableCell>
          <b>พนักงานขับรถ</b>
        </TableCell>
        <TableCell>
          <b>พนักงานนั่งข้าง</b>
        </TableCell>
        <TableCell>
          <b>วันที่ / เวลาที่รถออก</b>
        </TableCell>
        <TableCell>
          <b>หมายเหตุ</b>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TruckAssignmentSearchResultTableHeader;
