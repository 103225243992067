export const GET_REPORTS = 'Report/GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'Report/GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'Report/GET_REPORTS_ERROR';
export const SET_DATE = 'Report/SET_DATE';
export const SET_STATION = 'Report/SET_STATION';
export const SET_ORDER_TYPES = 'Report/SET_ORDER_TYPES';
export const TOGGLE_SHOW_TRUCK_ASSIGNMENT =
  'Report/TOGGLE_SHOW_TRUCK_ASSIGNMENT';
export const REPORT_ALL_ORDERS_CSV_HEADER = [
  'เลขที่ใบสั่งซื้อ',
  'ชื่อลูกค้า / รถเร่',
  'ประเภท',
  'สถานะ',
  'โรงอัดแก๊ส',
  '15',
  '48',
  '4',
  '7',
  '9',
  '11.5',
  '13.5',
  'ลูกค้าหน้าร้าน (นน.)',
  'รถเร่ / ออเดอร์ (นน.)',
  'ยอดขาย (บาท)',
  'ส่วนลด / ค่าใช้จ่าย',
  'เงินสด (ลูกค้าหน้าร้าน)',
  'เงินสด (รถเร่ / ออเดอร์)',
  'ค้างชำระ (บาท)',
  'วันที่ขาย',
];
