import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { select } from '@redux-saga/core/effects';
import _ from 'lodash';
import { Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import Resizer from 'react-image-file-resizer';
import { CREATE_ORDER, GET_CUSTOMER } from './constants';
import {
  createOrderError,
  createOrderSuccess,
  getCustomerError,
  getCustomerSuccess,
} from './actions';
import {
  makeSelectImages,
  makeSelectOrder,
  makeSelectOrderItems,
} from './selectors';
import { batchUpdateOrders } from '../../graphql/mutations';
import { showErrorMessage, showSuccessMessage } from '../App/actions';

export const getCustomerGraphql = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
    }
  }
`;

const resizeImage = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      'JPEG',
      50,
      0,
      uri => {
        resolve(uri);
      },
      'file',
    );
  });

/* eslint-disable no-underscore-dangle */
function* uploadImages(images) {
  const optimizedImages = yield all(
    images.map(image => call(resizeImage, image)),
  );
  const results = yield all(
    optimizedImages.map(image =>
      call(
        [Storage, 'put'],
        `${uuid()}.${image.name.split('.').pop().toLowerCase()}`,
        image,
      ),
    ),
  );
  return results.map(({ key }) => ({
    key,
    level: 'public',
    region: Storage._config.AWSS3.region,
    bucket: Storage._config.AWSS3.bucket,
  }));
}

function* createOrder(action) {
  const { key } = action;
  let order = {};
  try {
    const {
      customerId,
      type,
      station,
      status = null,
      comment = null,
      sequenceNumber = null,
      licensePlateNumber = null,
    } = yield select(makeSelectOrder(key));
    const rawImages = yield select(makeSelectImages(key));
    const images = yield call(uploadImages, rawImages);
    const orderItems = _.map(
      yield select(makeSelectOrderItems(key)),
      orderItem => _.pick(orderItem, ['productId', 'quantity']),
    );
    order = {
      customerId,
      type,
      status,
      station,
      comment,
      sequenceNumber,
      licensePlateNumber,
      orderItems,
      images,
    };
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(batchUpdateOrders, { orders: [order] }),
    );
    yield all([
      put(showSuccessMessage('สั่งซื้อสำเร็จ')),
      put(createOrderSuccess(key, result.data.batchUpdateOrders[0])),
    ]);
  } catch (e) {
    yield all([put(showErrorMessage()), put(createOrderError(key))]);
  }
}

function* getCustomer(action) {
  const { payload, key } = action;
  try {
    const { customerId } = payload;
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(getCustomerGraphql, { id: customerId }),
    );
    const { id, name } = result.data.getCustomer;
    yield put(getCustomerSuccess(key, id, name));
  } catch {
    yield all([put(showErrorMessage()), put(getCustomerError(key))]);
  }
}

function sagaCreator(key, func) {
  return function* saga(action) {
    if (key === action.key) {
      yield call(func, action);
    }
  };
}

export default function createOrderSagaCreator(key) {
  return function* createOrderSaga() {
    yield takeLatest(CREATE_ORDER, sagaCreator(key, createOrder));
    yield takeLatest(GET_CUSTOMER, sagaCreator(key, getCustomer));
  };
}
