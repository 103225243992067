import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import isEmpty from 'lodash/isEmpty';
// eslint-disable-next-line import/no-cycle
import OrderTableRow from './OrderTableRow';

function OrderTableBody({ columns, orders }) {
  return (
    <TableBody>
      {!isEmpty(orders) ? (
        orders.map(order => (
          <OrderTableRow key={order.id} columns={columns} order={order} />
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={8}>ไม่พบข้อมูล</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default OrderTableBody;
