/* eslint-disable no-shadow */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import moment from 'moment';
import Papa from 'papaparse';
import FileDownload from 'js-file-download';
import { makeSelectNextToken, makeSelectParams } from './selectors';
import {
  exportOrdersError,
  exportOrdersSuccess,
  searchOrdersError,
  searchOrdersSuccess,
} from './actions';
import {
  EXPORT_ORDERS,
  REFRESH_SEARCH_ORDERS,
  SEARCH_NEXT_ORDERS,
  SEARCH_ORDERS,
  SEARCH_PREVIOUS_ORDERS,
} from './constants';
import { REPORT_ALL_ORDERS_CSV_HEADER } from '../Report/constants';
import { searchOrders as searchOrdersGraphql } from './graphql';

function createFilters(params) {
  const {
    customerId,
    sequenceNumber,
    types,
    statuses,
    stations,
    startDate,
    endDate,
  } = params;

  const filters = [];
  if (customerId) {
    filters.push({
      customerId: { eq: customerId },
    });
  }

  if (sequenceNumber) {
    filters.push({
      sequenceNumber: { eq: sequenceNumber },
    });
  }

  if (!_.isEmpty(types)) {
    filters.push({
      or: types.map(type => ({ type: { eq: type } })),
    });
  }

  if (!_.isEmpty(statuses)) {
    filters.push({
      or: statuses.map(status => ({ status: { eq: status } })),
    });
  }

  if (!_.isEmpty(stations)) {
    filters.push({
      or: stations.map(station => ({ station: { eq: station } })),
    });
  }

  if (startDate || endDate) {
    filters.push({
      createdAt: {
        range: [
          startDate || moment('19000101'),
          endDate || moment().endOf('day'),
        ],
      },
    });
  }
  return filters;
}

function* searchOrders() {
  try {
    const nextToken = yield select(makeSelectNextToken());
    const params = yield select(makeSelectParams());
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(searchOrdersGraphql, {
        filter: { and: createFilters(params) },
        sort: { direction: 'asc', field: 'createdAt' },
        nextToken,
      }),
    );
    const orders = result.data.searchOrders.items.map(order => {
      const {
        id,
        type,
        status,
        station,
        createdAt,
        customer,
        customerId,
        sequenceNumber,
      } = order;
      const orderItems = _.reject(order.orderItems.items, ['_deleted', true]);
      const payments = _.reject(order.payments.items, ['_deleted', true]);
      const adjustments = _.reject(order.adjustments.items, ['_deleted', true]);
      const subtotal = _.reduce(
        orderItems,
        (subtotal, { quantity, unitPrice, adjustment }) =>
          subtotal + (unitPrice + (adjustment || 0)) * quantity,
        0,
      );
      const paidAmount = _.reduce(
        payments,
        (paidAmount, { amount }) => paidAmount + amount,
        0,
      );
      const totalAdjustment = _.reduce(
        adjustments,
        (total, { amount }) => total + amount,
        0,
      );
      return {
        id,
        type,
        status,
        station,
        createdAt,
        customerId,
        sequenceNumber,
        subtotal,
        paidAmount,
        totalAdjustment,
        customerName: _.get(customer, 'name', ''),
      };
    });

    yield put(searchOrdersSuccess(orders, result.data.searchOrders.nextToken));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield put(searchOrdersError());
  }
}

function* exportOrders(action) {
  try {
    const { nextToken } = action.payload;
    const params = { ...(yield select(makeSelectParams())), nextToken };
    const reqParam = {
      response: true,
      body: _.pickBy(params, val => !_.isEmpty(val)),
    };
    const result = yield call(
      [API, 'post'],
      'export',
      '/export/orders',
      reqParam,
    );
    const csv = Papa.unparse(
      {
        fields: REPORT_ALL_ORDERS_CSV_HEADER,
        data: result.data.data.rows,
      },
      { quotes: true, encoding: 'UTF-8' },
    );
    yield call(
      FileDownload,
      csv,
      'ใบสั่งซื้อ.csv',
      'text/csv;charset=utf-8',
      '\uFEFF',
    );
    yield put(exportOrdersSuccess(result.data.data.nextToken));
  } catch {
    yield put(exportOrdersError());
  }
}

export default function* sage() {
  yield takeLatest(EXPORT_ORDERS, exportOrders);
  yield takeLatest(SEARCH_ORDERS, searchOrders);
  yield takeLatest(REFRESH_SEARCH_ORDERS, searchOrders);
  yield takeLatest(SEARCH_NEXT_ORDERS, searchOrders);
  yield takeLatest(SEARCH_PREVIOUS_ORDERS, searchOrders);
}
