import React, { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import OrderItemDialogProductGrid from './OrderItemDialogProductGrid';
import { PRODUCT_TYPE_DESC } from '../../constants';

function OrderItemDialogTabPanel({
  index,
  tabIndex,
  type,
  groupedByTypeProducts,
  items,
  onClick,
}) {
  const groupedByBrandProducts = useMemo(
    () => _.groupBy(groupedByTypeProducts, 'brandId'),
    [groupedByTypeProducts],
  );
  return (
    <Box hidden={tabIndex !== index}>
      <Typography variant="h6">{PRODUCT_TYPE_DESC[type]}</Typography>
      {_.map(groupedByBrandProducts, (products, brandId) => (
        <OrderItemDialogProductGrid
          key={brandId}
          items={items}
          products={products}
          onClick={onClick}
        />
      ))}
    </Box>
  );
}

export default OrderItemDialogTabPanel;
