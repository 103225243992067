import Typography from '@mui/material/Typography';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';

interface FulfillmentReportTableCellNumberProps {
  value: number;
  bold?: boolean;
  swapped?: boolean;
  fixedDecimalScale?: boolean;
}

function FulfillmentReportTableCellNumber({
  value,
  bold = false,
  swapped = false,
  fixedDecimalScale = false,
}: FulfillmentReportTableCellNumberProps) {
  return (
    <CommaSeparatedNumber
      value={value}
      fixedDecimalScale={fixedDecimalScale}
      renderText={text => (
        <Typography
          variant="body1"
          sx={{
            display: 'inline-block',
            mr: swapped ? 0 : 1,
            color: swapped ? 'warning.main' : 'black',
            fontWeight: bold ? 'bold' : 'normal',
          }}
        >
          {swapped ? `(${text})` : text}
        </Typography>
      )}
    />
  );
}

export default FulfillmentReportTableCellNumber;
