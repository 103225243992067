import React from 'react';
import Box from '@material-ui/core/Box';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';
import { alpha } from '@material-ui/core/styles';

function Spinner({ children, loading = false }) {
  return (
    <Box position="relative">
      {loading && (
        <Box
          width="100%"
          height="100%"
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="appBar"
          style={{ backgroundColor: alpha(grey[200], 0.5) }}
        >
          <CircularProgress color="primary" size={30} />
        </Box>
      )}
      {children}
    </Box>
  );
}

export default Spinner;
