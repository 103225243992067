import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectApp = state => state.app;

export const selectShowDrawer = createSelector(
  selectApp,
  ({ showDrawer }) => showDrawer,
);

export const selectUser = createSelector(selectApp, ({ user }) => user);

export const selectSignInResult = createSelector(
  selectApp,
  ({ signInResult }) => signInResult,
);

export const selectTotpCode = createSelector(
  selectApp,
  ({ totpCode }) => totpCode,
);

export const selectUserGroups = createSelector(
  selectApp,
  ({ user }) => user.groups,
);

export const selectLoading = createSelector(
  selectApp,
  ({ loading }) => loading,
);

export const selectProducts = createSelector(selectApp, ({ products }) =>
  _.flow([
    _.partialRight(
      _.orderBy,
      ['type', 'brandId', 'weight'],
      ['asc', 'asc', 'desc'],
    ),
    _.partialRight(_.keyBy, 'id'),
  ])(products),
);

export const makeSelectOrderItems = selectOrderItems =>
  createSelector([selectOrderItems, selectProducts], (orderItems, products) => {
    if (_.isEmpty(products)) {
      return [];
    }
    return _.map(orderItems, orderItem => {
      const { id, productId, quantity, unitPrice, adjustment, comment } =
        orderItem;
      const { name, weight, type, prices } = products[productId];
      const adjustedPrice = unitPrice + Number(adjustment);
      const total = adjustedPrice * quantity;
      return {
        id,
        productId,
        quantity,
        unitPrice,
        adjustedPrice,
        adjustment,
        comment,
        name,
        weight,
        type,
        prices,
        total,
      };
    });
  });

export const selectGroupedByTypeProducts = createSelector(
  selectApp,
  ({ products }) =>
    _.flow([
      _.partialRight(_.orderBy, ['brandId', 'weight'], ['asc', 'desc']),
      _.partialRight(_.groupBy, 'type'),
    ])(products),
);

export const makeSelectProductsByType = type =>
  createSelector(selectApp, ({ products }) =>
    _.flow([
      _.partialRight(_.filter, ['type', type]),
      _.partialRight(
        _.orderBy,
        ['type', 'brandId', 'weight'],
        ['asc', 'asc', 'desc'],
      ),
      _.partialRight(_.keyBy, 'id'),
    ])(products),
  );

export const selectWarehouses = createSelector(
  selectApp,
  ({ warehouses }) => warehouses,
);

export const selectName = createSelector(selectApp, ({ user }) => user.name);

export const selectLastName = createSelector(
  selectApp,
  ({ user }) => user.lastName,
);

export const selectInitializing = createSelector(
  selectApp,
  ({ initializing }) => initializing,
);

export const selectShowSuccessMessage = createSelector(
  selectApp,
  ({ showSuccessMessage }) => showSuccessMessage,
);

export const selectSuccessMessage = createSelector(
  selectApp,
  ({ successMessage }) => successMessage,
);

export const selectShowErrorMessage = createSelector(
  selectApp,
  ({ showErrorMessage }) => showErrorMessage,
);

export const selectErrorMessage = createSelector(
  selectApp,
  ({ errorMessage }) => errorMessage,
);

export const selectShowWarningMessage = createSelector(
  selectApp,
  ({ showWarningMessage }) => showWarningMessage,
);

export const selectWarningMessage = createSelector(
  selectApp,
  ({ warningMessage }) => warningMessage,
);
