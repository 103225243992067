import MaterialDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DrawerCustomerLinks from './DrawerCustomerLinks';
import DrawerLink from './DrawerLink';
import DrawerOrderLinks from './DrawerOrderLinks';
import DrawerTruckAssignmentLinks from './DrawerTruckAssignmentLinks';
import useHasPermission from '../../utils/useHasPermission';
import { USER_GROUP } from '../../constants';
import DrawerReportLinks from './DrawerReporLinks';

function Drawer({ showDrawer, onClose }) {
  const hasOrderDrawerLinksPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.ORDER,
    USER_GROUP.CASHIER,
    USER_GROUP.ACCOUNTING,
  ]);
  const hasTruckAssignmentDrawerLinksPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.ACCOUNTING,
    USER_GROUP.CASHIER,
  ]);
  const hasCustomerDrawerLinksPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.ACCOUNTING,
    USER_GROUP.ORDER,
  ]);
  const hasDebtorDrawerLinkPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.ACCOUNTING,
    USER_GROUP.CASHIER,
  ]);
  const hasProductDrawerLinkPermission = useHasPermission([USER_GROUP.ADMIN]);
  // const hasInventoryDrawerLinkPermission = useHasPermission([USER_GROUP.ADMIN]);
  const hasReportDrawerLinksPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.ACCOUNTING,
    USER_GROUP.CASHIER,
  ]);

  return (
    <MaterialDrawer open={showDrawer} onClose={onClose}>
      <Box width={250}>
        <Box display="flex" alignItems="center" justifyContent="flex-end" p={1}>
          <IconButton onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />
        <List>
          <DrawerLink to="/" title="แดชบอร์ด" icon={<DashboardIcon />} />
          {hasOrderDrawerLinksPermission && <DrawerOrderLinks />}
          {hasTruckAssignmentDrawerLinksPermission && (
            <DrawerTruckAssignmentLinks />
          )}
          {hasCustomerDrawerLinksPermission && <DrawerCustomerLinks />}
          {hasDebtorDrawerLinkPermission && (
            <DrawerLink
              to="/debtor"
              title="ลูกหนี้"
              icon={<AccountBalanceIcon />}
            />
          )}
          {hasProductDrawerLinkPermission && (
            <DrawerLink
              to="/product"
              title="สินค้าและบริการ"
              icon={<LocalOfferIcon />}
            />
          )}
          {/*{hasInventoryDrawerLinkPermission && <DrawerInventoryLinks />}*/}
          {hasReportDrawerLinksPermission && <DrawerReportLinks />}
        </List>
      </Box>
    </MaterialDrawer>
  );
}

export default Drawer;
