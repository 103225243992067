import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { createStructuredSelector } from 'reselect';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Paper from '../../components/Paper';
import {
  cancelOrder,
  cancelOrderItem,
  createAdjustment,
  createPayment,
  deleteAdjustment,
  disableUpdateOrderHeaderInProgress,
  disableUpdateOrderItemsInProgress,
  enableUpdateOrderHeaderInProgress,
  enableUpdateOrderItemsInProgress,
  getOrder,
  hideAdjustmentDialog,
  hideDeleteAdjustmentDialog,
  hideLightbox,
  resetOrder,
  setNewAmount,
  setPaymentType,
  setUpdatedOrder,
  setUpdatedOrderItem,
  showAdjustmentDialog,
  showDeleteAdjustmentDialog,
  showLightbox,
  updateOrderHeader,
  updateOrderItems,
} from './actions';
import {
  selectAdjustments,
  selectDisabled,
  selectDisabledUpdateOrderItems,
  selectImages,
  selectInitImageIdx,
  selectLoading,
  selectOrderHeader,
  selectOrderItems,
  selectPaidAmount,
  selectPayment,
  selectPayments,
  selectShowAdjustmentDialog,
  selectShowDeleteAdjustmentDialog,
  selectShowLightbox,
  selectSubtotal,
  selectTotal,
  selectUpdatedOrderHeader,
  selectUpdatedOrderItems,
  selectUpdateOrderHeaderInProgress,
  selectUpdateOrderItemsInProgress,
} from './selectors';
import OrderForm from '../../components/OrderForm';
import StatusChip from '../../components/StatusChip';
import Total from '../../components/Total';
import Spinner from '../../components/Spinner';
import PaymentHistoryTable from './PaymentHistoryTable';
import TruckAssignmentOrderAlert from './TruckAssignmentOrderAlert';
import OrderReceipt from './OrderReceipt';
import CustomerLink from './CustomerLink';
import OrderHeaderUpdateButtons from './OrderHeaderUpdateButtons';
import useHasPermission from '../../utils/useHasPermission';
import OrderItemTable from './OrderItemTable';
import OrderItemUpdateButtons from './OrderItemUpdateButtons';
import Album from '../../components/Album';
import OrderLightbox from './OrderLightbox';
import OrderAdjustmentDialog from './OrderAdjustmentDialog';
import AdjustmentHistoryTable from './AdjustmentHistoryTable';
import DeleteAdjustmentDialog from './DeleteAdjustmentDialog';
import Title from '../../components/Title';
import { isNotEmpty } from '../../utils/validators';
import OrderBackButton from './OrderBackButton';
import { makeSelectOrderItems } from '../App/selectors';
import { ORDER_STATUS_DESC } from '../../constants';
import CreateOrderQueueCard from '../CreateOrder/CreateOrderQueueCard';

const stateSelector = createStructuredSelector({
  orderHeader: selectOrderHeader,
  updatedOrderHeader: selectUpdatedOrderHeader,
  orderItems: makeSelectOrderItems(selectOrderItems),
  updatedOrderItems: makeSelectOrderItems(selectUpdatedOrderItems),
  payment: selectPayment,
  subtotal: selectSubtotal,
  paidAmount: selectPaidAmount,
  total: selectTotal,
  payments: selectPayments,
  adjustments: selectAdjustments,
  images: selectImages,
  loading: selectLoading,
  disabled: selectDisabled,
  disabledUpdateOrderItems: selectDisabledUpdateOrderItems,
  updateOrderHeaderInProgress: selectUpdateOrderHeaderInProgress,
  updateOrderItemsInProgress: selectUpdateOrderItemsInProgress,
  showLightbox: selectShowLightbox,
  initImageIdx: selectInitImageIdx,
  showAdjustmentDialog: selectShowAdjustmentDialog,
  showDeleteAdjustmentDialog: selectShowDeleteAdjustmentDialog,
});

function Order() {
  const printRef = useRef();
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const {
    orderHeader,
    updatedOrderHeader,
    orderItems,
    updatedOrderItems,
    payment,
    subtotal,
    paidAmount,
    total,
    payments,
    adjustments,
    images,
    initImageIdx,
    loading,
    disabled,
    disabledUpdateOrderItems,
    updateOrderHeaderInProgress,
    updateOrderItemsInProgress,
    showLightbox: isLightboxShown,
    showAdjustmentDialog: isAdjustmentDialogShow,
    showDeleteAdjustmentDialog: isDeleteAdjustmentDialogShow,
  } = useSelector(stateSelector);
  const { status, customerId, truckAssignmentId } = orderHeader;
  const { newAmount, paymentType } = payment;
  const printable = paidAmount > 0;
  const showUpdateButton = useHasPermission(['Admin']);
  const [showPrintQueueCard, setShowPrintQueueCard] = useState(false);

  useEffect(() => {
    dispatch(getOrder(orderId));
    return () => dispatch(resetOrder());
  }, [orderId, dispatch]);

  return (
    <Container>
      <Spinner loading={loading}>
        <Paper>
          {truckAssignmentId && (
            <TruckAssignmentOrderAlert truckAssignmentId={truckAssignmentId} />
          )}
          <Box
            mb={2}
            gap={1}
            display="flex"
            flexWrap="wrap"
            alignItems="baseline"
          >
            <Title title="รายการสั่งซื้อ" />
            {status && (
              <StatusChip
                status={status}
                description={ORDER_STATUS_DESC[status]}
              />
            )}
            {showUpdateButton && customerId && (
              <CustomerLink customerId={customerId} />
            )}
          </Box>
          <ValidatorForm onSubmit={() => dispatch(updateOrderHeader())}>
            <OrderForm
              disabled={!updateOrderHeaderInProgress}
              order={
                updateOrderHeaderInProgress ? updatedOrderHeader : orderHeader
              }
              onChange={(field, e) =>
                dispatch(setUpdatedOrder(field, e.target.value))
              }
              onCustomerChange={(e, customer) =>
                dispatch(
                  setUpdatedOrder('customerId', customer ? customer.id : null),
                )
              }
              onCustomerNameChange={name =>
                dispatch(setUpdatedOrder('name', name))
              }
            />
            {showUpdateButton && (
              <Box mt={2}>
                <OrderHeaderUpdateButtons
                  disabled={disabled}
                  updatingOrderHeader={updateOrderHeaderInProgress}
                  onCancelOrderClick={() => dispatch(cancelOrder())}
                  onUpdateOrderHeaderClick={() =>
                    dispatch(enableUpdateOrderHeaderInProgress())
                  }
                  onCancelUpdatingOrderHeaderClick={() =>
                    dispatch(disableUpdateOrderHeaderInProgress())
                  }
                />
              </Box>
            )}
          </ValidatorForm>
        </Paper>
      </Spinner>
      {isNotEmpty(images) && (
        <Album
          marginTop
          images={images}
          loading={loading}
          onClick={idx => dispatch(showLightbox(idx))}
        />
      )}
      {isNotEmpty(payments) && (
        <PaymentHistoryTable payments={payments} loading={loading} />
      )}
      {isNotEmpty(adjustments) && (
        <AdjustmentHistoryTable adjustments={adjustments} loading={loading} />
      )}
      <ValidatorForm
        onSubmit={() =>
          updateOrderItemsInProgress
            ? dispatch(updateOrderItems())
            : dispatch(createPayment())
        }
      >
        <OrderItemTable
          ref={printRef}
          loading={loading}
          printable={printable}
          showUpdateButton={showUpdateButton}
          items={updateOrderItemsInProgress ? updatedOrderItems : orderItems}
          updateInProgress={updateOrderItemsInProgress}
          onChange={(idx, param, value) =>
            dispatch(setUpdatedOrderItem(idx, param, value))
          }
          onCancel={idx => dispatch(cancelOrderItem(idx))}
          onPrintQueueCardClick={() => setShowPrintQueueCard(true)}
          onUpdateClick={() => dispatch(enableUpdateOrderItemsInProgress())}
          footer={
            updateOrderItemsInProgress ? (
              <OrderItemUpdateButtons
                disabled={disabledUpdateOrderItems}
                onCancel={() => dispatch(disableUpdateOrderItemsInProgress())}
              />
            ) : (
              <>
                <Box textAlign="right">
                  <Box display="inline-block" width={{ xs: '100%', sm: 350 }}>
                    <Total
                      total={total}
                      disabled={disabled}
                      subtotal={subtotal}
                      paidAmount={paidAmount}
                      newAmount={newAmount}
                      paymentType={paymentType}
                      adjustments={adjustments}
                      onNewAmountChange={newAmount =>
                        dispatch(setNewAmount(newAmount))
                      }
                      onPaymentTypeChange={e =>
                        dispatch(setPaymentType(e.target.value))
                      }
                      onAdjustmentClick={() => dispatch(showAdjustmentDialog())}
                      onAdjustmentRemove={id =>
                        dispatch(showDeleteAdjustmentDialog(id))
                      }
                    />
                  </Box>
                </Box>
                <Divider />
                <OrderBackButton />
              </>
            )
          }
        />
      </ValidatorForm>
      <DeleteAdjustmentDialog
        open={isDeleteAdjustmentDialogShow}
        onConfirm={() => dispatch(deleteAdjustment())}
        onClose={() => dispatch(hideDeleteAdjustmentDialog())}
      />
      <OrderAdjustmentDialog
        total={total}
        disabled={disabled}
        paidAmount={paidAmount}
        open={isAdjustmentDialogShow}
        onClose={() => dispatch(hideAdjustmentDialog())}
        onSubmit={(amount, comment) =>
          dispatch(createAdjustment(amount, comment))
        }
      />
      {isLightboxShown && (
        <OrderLightbox
          images={images}
          initIdx={initImageIdx}
          onClose={() => dispatch(hideLightbox())}
        />
      )}
      <OrderReceipt
        orderHeader={orderHeader}
        orderItems={orderItems}
        total={total}
        subtotal={subtotal}
        paidAmount={paidAmount}
        newAmount={newAmount}
        paymentType={paymentType}
        adjustments={adjustments}
        ref={printRef}
      />
      <CreateOrderQueueCard
        order={orderHeader}
        orderItems={orderItems}
        showQueueCard={showPrintQueueCard}
        onClose={() => setShowPrintQueueCard(false)}
      />
    </Container>
  );
}

export default Order;
