import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { Spinner } from '../index';

function ItemTileList({ items, renderItem, loading }) {
  const renderItems = () => {
    if (!loading && items && items.length === 0) {
      return (
        <Box display="flex" justifyContent="center" mt={3}>
          <Typography variant="body2">ไม่พบรายการ</Typography>
        </Box>
      );
    }

    return items.map(item => renderItem(item));
  };

  return (
    <Box>
      {loading ? (
        <Box mt={3}>
          <Spinner />
        </Box>
      ) : (
        renderItems()
      )}
    </Box>
  );
}

/* eslint-disable react/require-default-props, react/forbid-prop-types */
ItemTileList.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ItemTileList;
