import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import withStyles from '@material-ui/core/styles/withStyles';
import ButtonLoader from '../../components/ButtonLoader';

const CodeValidator = withStyles({
  root: {
    '& .MuiInputBase-input': {
      letterSpacing: '10px',
    },
  },
})(TextValidator);

function SignInMfaCodeForm({ loading, onSubmit }) {
  const [mfaCode, setMfaCode] = useState('');
  return (
    <ValidatorForm onSubmit={() => onSubmit(mfaCode)}>
      <CodeValidator
        fullWidth
        value={mfaCode}
        margin="none"
        variant="outlined"
        disabled={loading}
        validators={['required']}
        inputProps={{ maxLength: 6 }}
        errorMessages=""
        onChange={e => setMfaCode(e.target.value)}
      />
      <Box mt={2}>
        <ButtonLoader
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          loading={loading}
        >
          <Typography variant="subtitle1">ยืนยันรหัสผ่าน</Typography>
        </ButtonLoader>
      </Box>
    </ValidatorForm>
  );
}

export default SignInMfaCodeForm;
