import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CommaSeparatedNumber from '../CommaSeparatedNumber';

function TableNumberRow({ title, value, bold = false }) {
  return (
    <TableRow>
      <TableCell align="left">{bold ? <b>{title}</b> : title}</TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber
          value={value}
          fixedDecimalScale
          renderText={value => <>{bold ? <b>{value}</b> : value}</>}
        />
      </TableCell>
    </TableRow>
  );
}

export default TableNumberRow;
