import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import MuiTableCell from '@material-ui/core/TableCell';

const TableCell = styled(MuiTableCell)({
  borderBottom: 'none',
});

function TableConfirmButtonRow({ disabled }) {
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          บันทึกการรับเงิน
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default TableConfirmButtonRow;
