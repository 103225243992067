import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

function CreateOrderSuccessAlert({ sequenceNumber }) {
  return (
    <Box mb={2}>
      <Alert severity="success">
        {`บันทึกใบสั่งซื้อสำเร็จ (เลขที่ใบสั่งซื้อ: ${sequenceNumber})`}
      </Alert>
    </Box>
  );
}

export default CreateOrderSuccessAlert;
