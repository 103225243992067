import React, { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Container from '@material-ui/core/Container';
import CreateInboundTable from './CreateInventoryItemTable';
import reducer from './reducer';
import saga from './saga';
import {
  selectDisabled,
  selectInbound,
  selectInventoryItems,
  selectLoading,
} from './selectors';
import {
  addInventoryItem,
  cancelInbound,
  deleteInventoryItem,
  enableInbound,
  resetInbound,
  submitInbound,
  updateInventoryItemProductId,
  updateInventoryItemQuantity,
  updateWarehouseId,
} from './actions';
import {
  makeSelectProductsByType,
  selectLastName,
  selectName,
  selectWarehouses,
} from '../App/selectors';
import { getProducts, getWarehouses } from '../App/actions';
import { PRODUCT_TYPE } from '../../constants';

const key = 'createInventoryItem';

const selector = createStructuredSelector({
  name: selectName,
  lastName: selectLastName,
  products: makeSelectProductsByType(PRODUCT_TYPE.CONTAINER),
  warehouses: selectWarehouses,
  inbound: selectInbound,
  inventoryItems: selectInventoryItems,
  loading: selectLoading,
  disabled: selectDisabled,
});

function CreateInbound() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const {
    name,
    lastName,
    products,
    warehouses,
    inbound,
    inventoryItems,
    loading,
    disabled,
  } = useSelector(selector);
  const { id, warehouseId, updatedAt, updatedBy } = inbound;
  const { name: byName, lastName: byLastName } = updatedBy;

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getWarehouses());
  }, []);

  return (
    <Container>
      <ValidatorForm onSubmit={() => dispatch(submitInbound())}>
        <CreateInboundTable
          name={id ? byName : name}
          lastName={id ? byLastName : lastName}
          loading={loading}
          disabled={disabled}
          products={products}
          createdAt={updatedAt}
          warehouses={warehouses}
          warehouseId={warehouseId}
          inventoryItems={inventoryItems}
          onAdd={() => dispatch(addInventoryItem())}
          onProductChange={(idx, productId) =>
            dispatch(updateInventoryItemProductId(idx, productId))
          }
          onQuantityChange={(idx, quantity) =>
            dispatch(updateInventoryItemQuantity(idx, quantity))
          }
          onDelete={idx => dispatch(deleteInventoryItem(idx))}
          onWarehouseChange={e => dispatch(updateWarehouseId(e.target.value))}
          onReset={() => dispatch(resetInbound())}
          onEnable={() => dispatch(enableInbound())}
          onCancel={() => dispatch(cancelInbound())}
        />
      </ValidatorForm>
    </Container>
  );
}

export default CreateInbound;
