import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TextValidator } from 'react-material-ui-form-validator';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { VALIDATOR } from '../../constants';
import ProductSelector from '../ProductSelector';

function CreateOrderTableRow({
  idx,
  disabled,
  orderItem,
  orderItems,
  products,
  onDelete,
  onChange,
  onQuantityChange,
}) {
  const { productId, quantity } = orderItem;
  return (
    <TableRow>
      <TableCell align="left">{idx + 1}</TableCell>
      <TableCell padding="none">
        <ProductSelector
          disabled={disabled}
          productId={productId}
          products={products}
          selectedProducts={orderItems}
          onChange={product => onChange(idx, product.id)}
        />
      </TableCell>
      <TableCell>
        <TextValidator
          fullWidth
          margin="none"
          type="number"
          variant="outlined"
          disabled={disabled}
          value={quantity !== null ? quantity : ''}
          validators={['required', 'isNumber', 'minNumber:1']}
          errorMessages={[
            VALIDATOR.REQUIRED,
            VALIDATOR.IS_NUMBER,
            VALIDATOR.GREATER_THEN_OR_EQUAL_ONE,
          ]}
          placeholder="จำนวน"
          onChange={e => onQuantityChange(idx, e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="secondary"
                  disabled={disabled}
                  onClick={() => onDelete(idx)}
                >
                  <RemoveCircleIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export default CreateOrderTableRow;
