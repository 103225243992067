import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TextValidator } from 'react-material-ui-form-validator';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { VALIDATOR } from '../../constants';
import CustomerDeleteCustomerPriceDialog from './CustomerDeleteCustomerPriceDialog';
import ProductSelector from '../../components/ProductSelector';

function CustomerPriceTableRow({
  idx,
  disabled,
  products,
  discountItem,
  discountItems,
  onDiscountItemChange,
  onDiscountChange,
  onDelete,
}) {
  const { id, productId, discount, unitPrice } = discountItem;
  const [openDeleteItemDialog, setOpenDeleteItemDialog] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell align="left">{idx + 1}</TableCell>
        <TableCell>
          <ProductSelector
            disabled={disabled}
            productId={productId}
            products={products}
            selectedProducts={discountItems}
            onChange={product => onDiscountItemChange(idx, product)}
          />
        </TableCell>
        <TableCell align="left">{unitPrice}</TableCell>
        <TableCell>
          <TextValidator
            fullWidth
            margin="none"
            type="number"
            variant="outlined"
            disabled={disabled}
            value={discount !== null ? discount : ''}
            validators={['required', 'isFloat']}
            errorMessages={[VALIDATOR.REQUIRED, VALIDATOR.IS_FLOAT]}
            placeholder="ส่วนลดหน่วยละ (+/-)"
            onChange={e => onDiscountChange(idx, e.target.value)}
          />
        </TableCell>
        <TableCell align="left">
          {Number(unitPrice) + Number(discount)}
        </TableCell>
        <TableCell>
          <IconButton
            edge="end"
            color="secondary"
            disabled={disabled}
            onClick={() => {
              if (id) {
                setOpenDeleteItemDialog(true);
              } else {
                onDelete(idx);
              }
            }}
          >
            <RemoveCircleIcon fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      <CustomerDeleteCustomerPriceDialog
        open={openDeleteItemDialog}
        onAgreeClick={() => {
          setOpenDeleteItemDialog(false);
          onDelete(idx);
        }}
        onDisagreeClick={() => setOpenDeleteItemDialog(false)}
      />
    </>
  );
}

export default CustomerPriceTableRow;
