import React from 'react';
import Box from '@material-ui/core/Box';

/* eslint-disable react/jsx-props-no-spreading */
function PaddingBox({ children, ...otherProps }) {
  return (
    <Box
      px={{ xs: 1, sm: 2 }}
      pt={{ xs: 1, sm: 2 }}
      pb={{ xs: 1, sm: 2 }}
      {...otherProps}
    >
      {children}
    </Box>
  );
}

export default PaddingBox;
