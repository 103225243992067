import { useEffect, useRef } from 'react';

const useEffectAfterFirstRender = (callback, dependencies) => {
  const firstRender = useRef(true);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      return callback();
    }
  }, dependencies);
};

export default useEffectAfterFirstRender;
