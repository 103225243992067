import _ from 'lodash';
import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import yellow from '@material-ui/core/colors/yellow';
import red from '@material-ui/core/colors/red';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  ORDER_STATUS,
  ORDER_STATUS_DESC,
  ORDER_TYPE,
  ORDER_TYPE_DESC,
  PRODUCT_TYPE,
  STATION_DESC,
} from '../../constants';
import ReportTableExpandedRow from './ReportTableExpandedRow';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';
import StatusChip from '../../components/StatusChip';
import TableCell from '../../components/TableCell';

const useStyles = makeStyles({
  truckAssignment: {
    backgroundColor: yellow[50],
  },
  canceledOrder: {
    backgroundColor: red[100],
  },
});

function ReportTableRow({ idx, row }) {
  const classes = useStyles();
  const {
    id,
    sequenceNumber,
    type,
    status,
    name,
    station,
    subtotal,
    totalAdjustment,
    paidAmount,
    ownedAmount,
    totalWeight,
    truckAssignmentId,
    rows,
    orderItems,
    w40Count,
    w70Count,
    w90Count,
    w115Count,
    w135Count,
    w150Count,
    w480Count,
    isTruckAssignmentRow,
  } = row;
  const hasNonLpg = _.some(
    orderItems,
    ({ type }) =>
      type === PRODUCT_TYPE.CONTAINER || type === PRODUCT_TYPE.SERVICE,
  );
  const isSalesOrder = type === ORDER_TYPE.SALES_ORDER;
  const isCanceled = status === ORDER_STATUS.CANCELED;
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        hover
        className={clsx(
          !!truckAssignmentId && classes.truckAssignment,
          isCanceled && classes.canceledOrder,
        )}
      >
        <TableCell padding="none">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <CommaSeparatedNumber value={idx + 1} />
        </TableCell>
        <TableCell>
          <Link
            to={
              isTruckAssignmentRow ? `/truckAssignments/${id}` : `/order/${id}`
            }
          >
            {hasNonLpg ? `${sequenceNumber}*` : sequenceNumber}
          </Link>
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{ORDER_TYPE_DESC[type]}</TableCell>
        <TableCell>
          <StatusChip
            status={status}
            description={ORDER_STATUS_DESC[status]}
            showPrefix={false}
          />
        </TableCell>
        <TableCell>{STATION_DESC[station]}</TableCell>
        <TableCell backgroundColor="green" align="center">
          <CommaSeparatedNumber value={w150Count || ''} />
        </TableCell>
        <TableCell backgroundColor="lightBlue" align="center">
          <CommaSeparatedNumber value={w480Count || ''} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w40Count || ''} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w70Count || ''} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w90Count || ''} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w115Count || ''} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w135Count || ''} />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            value={isSalesOrder ? totalWeight : ''}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            value={!isSalesOrder ? totalWeight : ''}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber value={subtotal} fixedDecimalScale />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber value={totalAdjustment} fixedDecimalScale />
        </TableCell>
        <TableCell align="right">
          {paidAmount > 0 && (
            <CommaSeparatedNumber
              value={isSalesOrder ? paidAmount : ''}
              fixedDecimalScale
            />
          )}
        </TableCell>
        <TableCell align="right">
          {paidAmount > 0 && (
            <CommaSeparatedNumber
              value={!isSalesOrder ? paidAmount : ''}
              fixedDecimalScale
            />
          )}
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber value={ownedAmount} fixedDecimalScale />
        </TableCell>
      </TableRow>
      {open &&
        !_.isEmpty(rows) &&
        rows.map(row => <ReportTableRow key={row.id} row={row} />)}
      {open &&
        !_.isEmpty(orderItems) &&
        orderItems.map(orderItem => (
          <ReportTableExpandedRow
            key={orderItem.id}
            orderType={type}
            orderItem={orderItem}
          />
        ))}
    </>
  );
}

export default ReportTableRow;
