import TableCell from '../../components/TableCell';
import React from 'react';
import TableRow from '@mui/material/TableRow';
import { FulfillmentReportRowCountsAndTotals } from './types';
import TableBody from '@mui/material/TableBody';
import FulfillmentReportTableCellNumber from './FulfillmentReportTableCellNumber';

interface FulfillmentReportSummaryTableBodyProps {
  summary: FulfillmentReportRowCountsAndTotals;
}

function FulfillmentReportSummaryTableBody({
  summary,
}: FulfillmentReportSummaryTableBodyProps) {
  const {
    w40,
    w70,
    w90,
    w115,
    w135,
    w150,
    w480,
    w40Fueled,
    w70Fueled,
    w90Fueled,
    w115Fueled,
    w135Fueled,
    w150Fueled,
    w480Fueled,
    w40Swapped,
    w70Swapped,
    w90Swapped,
    w115Swapped,
    w135Swapped,
    w150Swapped,
    w480Swapped,
    totalWeight,
    totalWeightFueled,
    totalWeightSwapped,
  } = summary;
  return (
    <TableBody>
      <TableRow>
        <TableCell variant="head" align="center" backgroundColor="green">
          <FulfillmentReportTableCellNumber value={w150} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <FulfillmentReportTableCellNumber value={w480} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <FulfillmentReportTableCellNumber value={w40} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <FulfillmentReportTableCellNumber value={w70} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <FulfillmentReportTableCellNumber value={w90} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <FulfillmentReportTableCellNumber value={w115} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <FulfillmentReportTableCellNumber value={w135} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <FulfillmentReportTableCellNumber value={w150Fueled} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <FulfillmentReportTableCellNumber value={w480Fueled} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <FulfillmentReportTableCellNumber value={w40Fueled} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <FulfillmentReportTableCellNumber value={w70Fueled} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <FulfillmentReportTableCellNumber value={w90Fueled} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <FulfillmentReportTableCellNumber value={w115Fueled} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <FulfillmentReportTableCellNumber value={w135Fueled} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <FulfillmentReportTableCellNumber value={w150Swapped} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <FulfillmentReportTableCellNumber value={w480Swapped} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <FulfillmentReportTableCellNumber value={w40Swapped} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <FulfillmentReportTableCellNumber value={w70Swapped} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <FulfillmentReportTableCellNumber value={w90Swapped} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <FulfillmentReportTableCellNumber value={w115Swapped} />
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <FulfillmentReportTableCellNumber value={w135Swapped} />
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <FulfillmentReportTableCellNumber
            fixedDecimalScale
            value={totalWeight}
          />
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <FulfillmentReportTableCellNumber
            fixedDecimalScale
            value={totalWeightFueled}
          />
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <FulfillmentReportTableCellNumber
            fixedDecimalScale
            value={totalWeightSwapped}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default FulfillmentReportSummaryTableBody;
