import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  SelectValidator,
  ValidatorForm,
} from 'react-material-ui-form-validator';
import { useSnackbar } from 'notistack';
import { API, graphqlOperation } from 'aws-amplify';
import MaterialLink from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import Container from '@material-ui/core/Container';
import { getBrand, listBrands } from '../graphql/queries';
import ProductTile from '../components/ProductTile';
import { SNACKBAR_VARIANT_TYPE } from '../../../constants';
import { Spinner, Paper } from '../../../shared';

export default function ProductList() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [brands, setBrands] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const renderBrands = useCallback(async () => {
    try {
      const result = await API.graphql(graphqlOperation(listBrands));
      setBrands(result.data.listBrands.items);
    } catch {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    (async () => {
      await renderBrands();
    })();
  }, [renderBrands]);

  const handleChange = async e => {
    const brandId = e.target.value;
    setSelectedBrandId(brandId);

    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(getBrand, { id: brandId }),
      );

      setProducts(_.orderBy(result.data.getBrand.products.items, ['weight']));
    } catch {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const renderProducts = () => {
    if (products.length === 0) {
      return (
        <Box display="flex" justifyContent="center" mt={3}>
          <Typography variant="body2">ไม่พบสินค้าสำหรับยี่ห้อนี้</Typography>
        </Box>
      );
    }

    return products.map(product => (
      <ProductTile key={product.id} product={product} />
    ));
  };

  const renderBrandMenuItems = () =>
    brands.map(({ id, name }) => (
      <MenuItem key={id} value={id}>
        {name}
      </MenuItem>
    ));

  return (
    <Container>
      <Paper elevation={0}>
        <Box display="flex" justifyContent="space-between">
          {/* Title */}
          <Typography variant="h6">สินค้าและบริการ</Typography>

          {/* Add Product */}
          <Tooltip title="เพิ่มสินค้า">
            <MaterialLink
              component={Link}
              color="textPrimary"
              underline="none"
              to="/createProduct"
            >
              <Fab size="small" color="primary" onClick={() => {}}>
                <AddIcon fontSize="small" />
              </Fab>
            </MaterialLink>
          </Tooltip>
        </Box>

        {brands ? (
          <Box display="flex" flexDirection="column">
            <Box mt={3}>
              <ValidatorForm onSubmit={() => {}}>
                <SelectValidator
                  label="ยี่ห้อ / ผู้ให้บริการ"
                  variant="outlined"
                  margin="none"
                  fullWidth
                  value={selectedBrandId}
                  onChange={handleChange}
                >
                  <MenuItem value="" disabled>
                    กรุณาเลือกยี่ห้อ / ผู้ให้บริการ
                  </MenuItem>
                  {renderBrandMenuItems()}
                </SelectValidator>
              </ValidatorForm>
            </Box>
            {selectedBrandId && (
              <Box>
                {loading ? (
                  <Box mt={3}>
                    <Spinner />
                  </Box>
                ) : (
                  renderProducts()
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Spinner />
        )}
      </Paper>
    </Container>
  );
}
