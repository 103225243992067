import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelOrderDialog from './CancelOrderDialog';

function OrderHeaderUpdateButtons({
  disabled,
  updatingOrderHeader,
  onCancelOrderClick,
  onUpdateOrderHeaderClick,
  onCancelUpdatingOrderHeaderClick,
}) {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return updatingOrderHeader ? (
    <>
      <Box display="flex" flexWrap="wrap">
        <Box mt={1} mr={1}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={disabled}
            startIcon={<SaveIcon />}
          >
            บันทึก
          </Button>
        </Box>
        <Box mt={1} mr={1}>
          <Button
            type="reset"
            color="secondary"
            disabled={disabled}
            variant="contained"
            onClick={onCancelUpdatingOrderHeaderClick}
          >
            ยกเลิกการแก้ไข
          </Button>
        </Box>
        <Box mt={1} mr={1}>
          <Button
            type="reset"
            color="secondary"
            disabled={disabled}
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={() => setOpenCancelDialog(true)}
          >
            ยกเลิกรายการสั่งซื้อ
          </Button>
        </Box>
      </Box>
      <CancelOrderDialog
        open={openCancelDialog}
        onAgreeClick={() => {
          setOpenCancelDialog(false);
          onCancelOrderClick();
        }}
        onDisagreeClick={() => setOpenCancelDialog(false)}
      />
    </>
  ) : (
    <Box display="flex">
      <Box mt={1} mr={1}>
        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          startIcon={<EditIcon />}
          onClick={e => {
            e.preventDefault();
            onUpdateOrderHeaderClick();
          }}
        >
          แก้ไข
        </Button>
      </Box>
    </Box>
  );
}

export default OrderHeaderUpdateButtons;
