import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TableRow from '@material-ui/core/TableRow';

function CustomerPriceTableEmptyRow({ disabled, onAddDiscountItem }) {
  return (
    <TableRow>
      <TableCell colSpan={6} align="center">
        <Button
          color="primary"
          disabled={disabled}
          startIcon={<AddIcon />}
          onClick={onAddDiscountItem}
        >
          ไม่พบรายการปรับราคา คลิกเพื่อเพิ่มรายการปรับราคา
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default CustomerPriceTableEmptyRow;
