import TableContainer from '@mui/material/TableContainer';
import { FulfillmentReportRowCountsAndTotals } from './types';
import Table from '@mui/material/Table';
import FulfillmentReportSummaryTableHeader from './FulfillmentReportSummaryTableHeader';
import FulfillmentReportSummaryTableBody from './FulfillmentReportSummaryTableBody';

interface FulfillmentReportSummaryTableProps {
  summary: FulfillmentReportRowCountsAndTotals;
}

function FulfillmentReportSummaryTable({
  summary,
}: FulfillmentReportSummaryTableProps) {
  return (
    <TableContainer sx={{ overflow: 'auto', mb: '-1px' }}>
      <Table>
        <FulfillmentReportSummaryTableHeader />
        <FulfillmentReportSummaryTableBody summary={summary} />
      </Table>
    </TableContainer>
  );
}

export default FulfillmentReportSummaryTable;
