import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function CancelOrderItemDialog({ open, onAgreeClick, onDisagreeClick }) {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onDisagreeClick}>
      <DialogTitle>ต้องการยกเลิกรายการสินค้านี้ ?</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" color="textSecondary">
          กรุณาคลิก &#8220;ตกลง&#8221; หากคุณต้องการยกเลิกรายการสินค้านี้
          รายการนี้จะไม่สามารถนำกลับมาได้อีก
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onAgreeClick} color="primary">
          ตกลง
        </Button>
        <Button variant="contained" onClick={onDisagreeClick} color="secondary">
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelOrderItemDialog;
