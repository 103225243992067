import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import reducer from './reducer';
import saga from './saga';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFulfillmentReports, setDate } from './actions';
import FulfillmentReportSummary from './FulfillmentReportSummary';
import { Key as key } from './constants';
import { createStructuredSelector } from 'reselect';
import {
  selectDate,
  selectLoading,
  selectRows,
  selectSummary,
} from './selectors';
import Container from '@mui/material/Container';
import FulfillmentReportTable from './FulfillmentReportTable';

const stateSelector = createStructuredSelector({
  date: selectDate,
  rows: selectRows,
  summary: selectSummary,
  loading: selectLoading,
});

function FulfillmentReport() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const { date, rows, summary, loading } = useSelector(stateSelector);

  useEffect(() => {
    dispatch(getFulfillmentReports());
  }, []);

  return (
    <Container maxWidth={false}>
      <FulfillmentReportSummary
        date={date}
        loading={loading}
        summary={summary}
        handleDateChange={date => dispatch(setDate(date))}
        handleRefresh={() => dispatch(getFulfillmentReports())}
      />
      <FulfillmentReportTable rows={rows} summary={summary} loading={loading} />
    </Container>
  );
}

export default FulfillmentReport;
