import isEmpty from 'lodash/isEmpty';
import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MaterialTable from '@material-ui/core/Table';
import { styled } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TruckAssignmentOrderTableFooter from './TruckAssignmentOrderTableFooter';
import TruckAssignmentOrderTableRow from './TruckAssignmentOrderTableRow';
import TruckAssignmentOrderTableHeader from './TruckAssignmentOrderTableHeader';
import withSpinner from '../WithSpinner';
import Container from '../Container';

const Table = styled(MaterialTable)({
  width: 'auto',
  tableLayout: 'fixed',
  borderCollapse: 'separate',
});

/* eslint-disable react/jsx-props-no-spreading */
function TruckAssignmentOrderTable({
  rows,
  totals,
  groups,
  groupedProducts,
  onChange = () => {},
  disabled = false,
  showSequenceNumber = false,
  ...otherProps
}) {
  if (isEmpty(rows)) {
    return (
      <TableContainer
        marginTop
        title="รายชื่อลูกค้า"
        component={Container}
        {...otherProps}
      >
        <Box p={3} textAlign="center">
          <Typography variant="body1">ไม่พบลูกค้า</Typography>
        </Box>
      </TableContainer>
    );
  }
  return (
    <TableContainer
      marginTop
      title="รายชื่อลูกค้า"
      component={Container}
      {...otherProps}
    >
      <Box overflow="scroll" maxHeight={725}>
        <Table stickyHeader>
          <TruckAssignmentOrderTableHeader
            groups={groups}
            groupedProducts={groupedProducts}
            showSequenceNumber={showSequenceNumber}
          />
          <TableBody>
            {rows.map((row, idx) => (
              <TruckAssignmentOrderTableRow
                idx={idx}
                row={row}
                key={row.customerId}
                onChange={onChange}
                disabled={disabled}
                lastRow={idx === rows.length - 1}
                products={groupedProducts}
                showSequenceNumber={showSequenceNumber}
              />
            ))}
          </TableBody>
          <TruckAssignmentOrderTableFooter
            totals={totals}
            products={groupedProducts}
            showSequenceNumber={showSequenceNumber}
          />
        </Table>
      </Box>
    </TableContainer>
  );
}

export default withSpinner(TruckAssignmentOrderTable);
