import {
  CLEAR_DEBTOR,
  REFRESH_DEBTORS,
  SEARCH_DEBTORS,
  SEARCH_DEBTORS_ERROR,
  SEARCH_DEBTORS_SUCCESS,
  SEARCH_NEXT_DEBTORS,
  SEARCH_PREVIOUS_DEBTORS,
  SET_DEBTOR,
  SET_NAME,
  SET_SEQUENCE_NUMBER,
  SET_SORT_FIELD,
} from './constants';

export const setDebtor = debtor => ({
  type: SET_DEBTOR,
  payload: { debtor },
});

export const setName = name => ({
  type: SET_NAME,
  payload: {
    name,
  },
});

export const setSequenceNumber = sequenceNumber => ({
  type: SET_SEQUENCE_NUMBER,
  payload: {
    sequenceNumber,
  },
});

export const clearDebtor = () => ({
  type: CLEAR_DEBTOR,
});

export const setSortField = field => ({
  type: SET_SORT_FIELD,
  payload: { field },
});

export const searchDebtors = () => ({
  type: SEARCH_DEBTORS,
});

export const searchDebtorsSuccess = (debtors, nextToken) => ({
  type: SEARCH_DEBTORS_SUCCESS,
  payload: {
    debtors,
    nextToken,
  },
});

export const searchDebtorsError = () => ({
  type: SEARCH_DEBTORS_ERROR,
});

export const searchNextDebtors = () => ({
  type: SEARCH_NEXT_DEBTORS,
});

export const searchPreviousDebtors = () => ({
  type: SEARCH_PREVIOUS_DEBTORS,
});

export const refreshDebtors = () => ({
  type: REFRESH_DEBTORS,
});
