import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { S3Image } from 'aws-amplify-react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { PRODUCT_TYPE_DESC } from '../../constants';

function CustomerProductSelectorItem({ name, type, abbr, logo }) {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        {logo ? (
          <Avatar component={S3Image} imgKey={logo} />
        ) : (
          <Avatar>{abbr}</Avatar>
        )}
      </Box>
      <Box>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {`ประเภท: ${PRODUCT_TYPE_DESC[type]}`}
        </Typography>
      </Box>
    </Box>
  );
}

export default CustomerProductSelectorItem;
