import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import {
  CUSTOMER_TYPE,
  CUSTOMER_TYPE_DESC,
  DELIVERY_ROUTE,
  DELIVERY_ROUTE_DESC,
  VALIDATOR,
} from '../../constants';
import { PhoneNumberTextMask } from '../../shared';
import CustomerNameTextValidator from './CustomerNameTextValidator';

function CustomerForm({
  disabled,
  customer,
  savedName,
  onChange = () => {},
  onPhoneNumberChange = () => {},
  checkDuplicateName = true,
}) {
  const {
    name,
    type,
    sequenceNumber,
    phoneNumber,
    email,
    address,
    subdistrict,
    district,
    province,
    postalCode,
    deliveryRouteId,
  } = customer;
  return (
    <Grid container spacing={3} alignItems="center">
      {sequenceNumber && (
        <Grid item xs={12} sm={6} md={4}>
          <TextValidator
            label="รหัสลูกค้า"
            variant="outlined"
            margin="none"
            fullWidth
            disabled
            value={sequenceNumber || ''}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        {checkDuplicateName ? (
          <CustomerNameTextValidator
            name={name}
            savedName={savedName}
            disabled={disabled}
            onChange={onChange('name')}
          />
        ) : (
          <TextValidator
            label="ชื่อลูกค้า"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            value={name || ''}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            onChange={onChange('name')}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectValidator
          label="ประเภทลูกค้า"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={type || ''}
          validators={['required']}
          errorMessages={[VALIDATOR.REQUIRED]}
          onChange={onChange('type')}
        >
          <MenuItem value="" disabled>
            กรุณาเลือก
          </MenuItem>
          <MenuItem value={CUSTOMER_TYPE.GUEST}>
            {CUSTOMER_TYPE_DESC.GUEST}
          </MenuItem>
          <MenuItem value={CUSTOMER_TYPE.MEMBER}>
            {CUSTOMER_TYPE_DESC.MEMBER}
          </MenuItem>
          <MenuItem value={CUSTOMER_TYPE.OTHER_COMPANY_CUSTOMER}>
            {CUSTOMER_TYPE_DESC.OTHER_COMPANY_CUSTOMER}
          </MenuItem>
        </SelectValidator>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <SelectValidator
          label="สายรถเร่"
          variant="outlined"
          margin="none"
          fullWidth
          value={deliveryRouteId || ''}
          disabled={disabled}
          onChange={onChange('deliveryRouteId')}
        >
          <MenuItem value="">ไม่เลือก</MenuItem>
          {Object.keys(DELIVERY_ROUTE).map(deliveryRoute => (
            <MenuItem key={deliveryRoute} value={deliveryRoute}>
              {DELIVERY_ROUTE_DESC[deliveryRoute]}
            </MenuItem>
          ))}
        </SelectValidator>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="เบอร์โทร"
          variant="outlined"
          margin="none"
          fullWidth
          InputProps={{
            inputComponent: PhoneNumberTextMask,
          }}
          disabled={disabled}
          value={phoneNumber || ''}
          onChange={e =>
            onPhoneNumberChange(e.target.value.replace(/[^\d]/g, ''))
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextValidator
          label="อีเมล"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={email || ''}
          validators={['isEmail']}
          errorMessages={[VALIDATOR.EMAIL]}
          onChange={onChange('email')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="ที่อยู่"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={address || ''}
          onChange={onChange('address')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="ตำบล"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={subdistrict || ''}
          onChange={onChange('subdistrict')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="อำเภอ"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={district || ''}
          onChange={onChange('district')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          label="จังหวัด"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={province || ''}
          onChange={onChange('province')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <TextValidator
          label="รหัสไปรษณีย์"
          variant="outlined"
          margin="none"
          fullWidth
          disabled={disabled}
          value={postalCode || ''}
          validators={['isNumber', 'minNumber:1']}
          errorMessages={[VALIDATOR.IS_NUMBER, VALIDATOR.IS_NUMBER]}
          onChange={onChange('postalCode')}
        />
      </Grid>
    </Grid>
  );
}

export default CustomerForm;
