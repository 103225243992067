import produce from 'immer';
import {
  GET_DEBTOR,
  GET_DEBTOR_ERROR,
  GET_DEBTOR_SUCCESS,
  REFRESH_DEBTOR,
} from './constants';

export const initialState = {
  debtor: {
    id: null,
    name: null,
    type: null,
    sequenceNumber: null,
    phoneNumber: null,
    email: null,
    address: null,
    subdistrict: null,
    district: null,
    province: null,
    postalCode: null,
    debt: 0,
  },
  overdueOrders: [],
  loading: false,
  disabled: true,
};

/* eslint-disable default-case, no-shadow, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_DEBTOR: {
        draft.debtor.id = action.payload.customerId;
        draft.loading = true;
        break;
      }
      case GET_DEBTOR_SUCCESS: {
        draft.debtor = action.payload.debtor;
        draft.overdueOrders = action.payload.overdueOrders;
        draft.loading = false;
        break;
      }
      case REFRESH_DEBTOR: {
        draft.loading = true;
        break;
      }
      case GET_DEBTOR_ERROR: {
        draft.loading = false;
        break;
      }
    }
  });

export default reducer;
