import { createSelector } from 'reselect';
import { CUSTOMER_STORE_KEY } from './constants';

export const selectCustomer = state => state.customer;

export const selectCreateCustomer = state => state.createCustomer;

export const createStateSelector = key =>
  key === CUSTOMER_STORE_KEY ? selectCustomer : selectCreateCustomer;

export const makeSelectCustomer = key =>
  createSelector(createStateSelector(key), ({ customer }) => customer);

export const makeSelectSavedCustomer = key =>
  createSelector(
    createStateSelector(key),
    ({ savedCustomer }) => savedCustomer,
  );

export const makeSelectDiscountItems = key =>
  createSelector(
    createStateSelector(key),
    ({ discountItems }) => discountItems,
  );

export const makeSelectSavedDiscountItems = key =>
  createSelector(
    createStateSelector(key),
    ({ savedDiscountItems }) => savedDiscountItems,
  );

export const makeSelectShowDiscountItemDialog = key =>
  createSelector(
    createStateSelector(key),
    ({ showDiscountItemDialog }) => showDiscountItemDialog,
  );

export const makeSelectError = key =>
  createSelector(createStateSelector(key), ({ error }) => error);

export const makeSelectLoading = key =>
  createSelector(createStateSelector(key), ({ loading }) => loading);

export const makeSelectDisabled = key =>
  createSelector(createStateSelector(key), ({ disabled }) => disabled);

export const makeSelectShowSuccessMessage = key =>
  createSelector(
    createStateSelector(key),
    ({ showSuccessMessage }) => showSuccessMessage,
  );
