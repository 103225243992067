export const CUSTOMER_STORE_KEY = 'customer';
export const CREATE_CUSTOMER_STORE_KEY = 'createCustomer';
export const SET_CUSTOMER = 'Customer/SET_CUSTOMER';
export const SET_CUSTOMER_NAME = 'Customer/SET_CUSTOMER_NAME';
export const TOGGLE_DISCOUNT_ITEM = 'Customer/TOGGLE_DISCOUNT_ITEM';
export const UPDATE_DISCOUNT_ITEM_DISCOUNT =
  'Customer/UPDATE_DISCOUNT_ITEM_DISCOUNT';
export const UPDATE_DISCOUNT_ITEM_PRODUCT =
  'Customer/UPDATE_DISCOUNT_ITEM_PRODUCT';
export const CREATE_CUSTOMER = 'Customer/CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'Customer/CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'Customer/CREATE_CUSTOMER_ERROR';
export const UPDATE_CUSTOMER = 'Customer/UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'Customer/UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'Customer/UPDATE_CUSTOMER_ERROR';
export const HIDE_SUCCESS_MESSAGE = 'Customer/HIDE_SUCCESS_MESSAGE';
export const UPDATE_CUSTOMER_ENABLED = 'Customer/UPDATE_CUSTOMER_ENABLED';
export const INIT_CUSTOMER = 'Customer/INIT_CUSTOMER';
export const INIT_CUSTOMER_SUCCESS = 'Customer/INIT_CUSTOMER_SUCCESS';
export const INIT_EMPTY_CUSTOMER_SUCCESS =
  'Customer/INIT_EMPTY_CUSTOMER_SUCCESS';
export const INIT_CUSTOMER_ERROR = 'Customer/INIT_CUSTOMER_ERROR';
export const DELETE_DISCOUNT_ITEM = 'Customer/DELETE_DISCOUNT_ITEM';
export const DELETE_DISCOUNT_ITEM_SUCCESS =
  'Customer/DELETE_DISCOUNT_ITEM_SUCCESS';
export const DELETE_DISCOUNT_ITEM_ERROR = 'Customer/DELETE_DISCOUNT_ITEM_ERROR';
export const CANCEL_UPDATE = 'Customer/CANCEL_UPDATE';
export const RESET_CUSTOMER = 'Customer/RESET_CUSTOMER';
export const ADD_DISCOUNT_ITEM = 'Customer/ADD_DISCOUNT_ITEM';
