import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary';
import MaterialAccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import withStyles from '@material-ui/core/styles/withStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@material-ui/core/IconButton';
import ReportAllReportsCsvLink from './ReportAllReportsCsvLink';
import ReportAllSummariesAccordionChip from './ReportAllSummariesAccordionChip';
import DatePickerButton from '../../components/DatePickerButton';

const AccordionSummary = withStyles({
  root: {
    width: '100%',
  },
  content: {
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})(MaterialAccordionSummary);

const AccordionActions = withStyles({
  root: {
    justifyContent: 'flex-start',
  },
})(MaterialAccordionActions);

function ReportAllSummariesAccordion({
  date,
  summary,
  reports,
  children,
  onDateChange,
  onRefresh,
}) {
  const { sales, cash, totalAdjustment, totalWeightSold, ownedAmount } =
    summary;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          {date.format('รายงานขายประจำวันddddที่ D MMMM YYYY')}
        </Typography>
        <Box display="flex" flexWrap="wrap">
          <ReportAllSummariesAccordionChip
            title="ยอดขาย"
            value={sales}
            unit="บาท"
          />
          <ReportAllSummariesAccordionChip
            title="เงินสด"
            value={cash}
            unit="บาท"
          />
          <ReportAllSummariesAccordionChip
            title="ส่วนลด / ค่าใช้จ่าย"
            value={totalAdjustment}
            unit="บาท"
          />
          <ReportAllSummariesAccordionChip
            title="น้ำหนักขาย"
            value={totalWeightSold}
            unit="กก."
          />
          <ReportAllSummariesAccordionChip
            title="ค้างชำระ"
            color="secondary"
            value={ownedAmount}
            unit="บาท"
          />
        </Box>
      </AccordionSummary>
      {children}
      <AccordionActions>
        <DatePickerButton
          value={date}
          onChange={date => {
            if (date) onDateChange(date);
          }}
        />
        <ReportAllReportsCsvLink date={date} reports={reports} />
        <IconButton size="small" onClick={onRefresh}>
          <RefreshIcon />
        </IconButton>
      </AccordionActions>
    </Accordion>
  );
}

export default ReportAllSummariesAccordion;
