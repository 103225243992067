import {
  GET_REPORTS,
  GET_REPORTS_ERROR,
  GET_REPORTS_SUCCESS,
  SET_DATE,
  SET_ORDER_TYPES,
  SET_STATION,
  TOGGLE_SHOW_TRUCK_ASSIGNMENT,
} from './constants';

export function getReports() {
  return {
    type: GET_REPORTS,
  };
}

export function getReportsSuccess(reports, rows, truckAssignments) {
  return {
    type: GET_REPORTS_SUCCESS,
    payload: { reports, rows, truckAssignments },
  };
}

export function getReportsError() {
  return {
    type: GET_REPORTS_ERROR,
  };
}

export function setDate(date) {
  return {
    type: SET_DATE,
    payload: { date },
  };
}

export function setStation(station) {
  return {
    type: SET_STATION,
    payload: { station },
  };
}

export function toggleShowTruckAssignment() {
  return {
    type: TOGGLE_SHOW_TRUCK_ASSIGNMENT,
  };
}

export function setOrderTypes(orderTypes) {
  return {
    type: SET_ORDER_TYPES,
    payload: { orderTypes },
  };
}
