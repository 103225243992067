import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function TruckAssignmentConfirmDialog({ open, onAgreeClick, onDisagreeClick }) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onDisagreeClick}>
      <DialogTitle>บันทึกการสั่งซื้อรถเร่ ?</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" color="textSecondary">
          กรุณาคลิก &#8220;บันทึก&#8221; เพื่อยืนยัน
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAgreeClick} variant="contained" color="primary">
          บันทึก
        </Button>
        <Button onClick={onDisagreeClick} variant="contained" color="secondary">
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TruckAssignmentConfirmDialog;
