import * as ACTION_TYPES from './actionTypes';
import { PRODUCT_TYPE } from '../../constants';

export const setBrandName = brandName => ({
  type: ACTION_TYPES.SET_BRAND_NAME,
  payload: brandName,
});

export const setProduct = (product, actionType = ACTION_TYPES.SET_PRODUCT) => {
  const {
    id,
    name,
    type,
    weight,
    brandId,
    brand,
    isTruckAssignmentProduct,
    _version,
  } = product;
  const payload = {
    id: id || null,
    name: name || '',
    type: type || PRODUCT_TYPE.LPG,
    weight: weight || '',
    brandId: brandId || '',
    isTruckAssignmentProduct: isTruckAssignmentProduct || false,
    brand,
    _version,
  };

  return {
    type: actionType,
    payload,
  };
};

export const setPrices = prices => ({
  type: ACTION_TYPES.SET_PRICES,
  payload: prices,
});

export const setBrands = brands => ({
  type: ACTION_TYPES.SET_BRANDS,
  payload: brands,
});

export const setSavedPrices = savedPrices => ({
  type: ACTION_TYPES.SET_SAVED_PRICES,
  payload: savedPrices,
});

export const setLoading = loading => ({
  type: ACTION_TYPES.SET_LOADING,
  payload: loading,
});

export const setDisabled = disabled => ({
  type: ACTION_TYPES.SET_DISABLED,
  payload: disabled,
});
