import { createSelector } from 'reselect';
import _ from 'lodash';
import { isNotEmpty } from '../../utils/validators';
import { selectApp } from '../App/selectors';
import { PRODUCT_TYPE, PRODUCT_TYPE_DESC } from '../../constants';

export const selectTruckAssignmentState = state => state.truckAssignment;

export const selectTruckAssignment = createSelector(
  selectTruckAssignmentState,
  ({ truckAssignment }) => truckAssignment,
);

export const selectPayment = createSelector(
  selectTruckAssignmentState,
  truckAssignment => truckAssignment.payment,
);

export const selectRows = createSelector(
  selectTruckAssignmentState,
  ({ rows }) => rows,
);

export const selectOrderItems = createSelector(selectRows, rows =>
  _.flatMap(rows, row => _.values(row.orderItems)),
);

export const selectProducts = createSelector(
  selectTruckAssignmentState,
  truckAssignment => truckAssignment.products,
);

export const selectLoading = createSelector(
  selectTruckAssignmentState,
  truckAssignment => truckAssignment.loading,
);

export const selectError = createSelector(
  selectTruckAssignmentState,
  truckAssignment => truckAssignment.error,
);

export const selectDisabled = createSelector(
  selectTruckAssignmentState,
  truckAssignment => truckAssignment.disabled,
);

export const selectPrintable = createSelector(
  [selectTruckAssignment, selectRows],
  (truckAssignment, rows) =>
    isNotEmpty(truckAssignment, 'truckAssignmentId') && isNotEmpty(rows),
);

export const selectTruckAssignmentProductGroups = createSelector(
  selectApp,
  ({ products }) => {
    const filteredProducts = _.flow([
      _.partialRight(_.orderBy, ['weight', 'brandId'], ['asc', 'desc']),
      _.partialRight(_.filter, ['isTruckAssignmentProduct', true]),
    ])(products);

    const lpgProducts = _.flow([
      _.partialRight(_.filter, ['type', PRODUCT_TYPE.LPG]),
      _.partialRight(_.groupBy, 'weight'),
    ])(filteredProducts);

    const otherProducts = _.flow([
      _.partialRight(_.filter, ({ type }) => type !== PRODUCT_TYPE.LPG),
      _.partialRight(_.groupBy, 'type'),
      _.partialRight(_.mapKeys, (value, key) => PRODUCT_TYPE_DESC[key]),
    ])(filteredProducts);
    return _.merge(lpgProducts, otherProducts);
  },
);

export const selectGroupedProducts = createSelector(
  selectTruckAssignmentProductGroups,
  groups => _.flatMap(groups),
);
