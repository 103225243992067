import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import AdjustmentHistoryTableHeader from './AdjustmentHistoryTableHeader';
import AdjustmentHistoryTableRow from './AdjustmentHistoryTableRow';
import Container from '../../components/Container';

/* eslint-disable react/jsx-props-no-spreading */
function AdjustmentHistoryTable({ adjustments, ...otherProps }) {
  return (
    <TableContainer
      title="ประวัตการเพิ่มส่วนลด / ค่าใช้จ่าย"
      component={Container}
      {...otherProps}
    >
      <Table>
        <AdjustmentHistoryTableHeader />
        <TableBody>
          {adjustments.map((adjustment, idx) => (
            <AdjustmentHistoryTableRow
              idx={idx}
              key={adjustment.id}
              adjustment={adjustment}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AdjustmentHistoryTable;
