import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import _ from 'lodash';
import {
  CANCEL_ORDER,
  CANCEL_ORDER_ITEM,
  CREATE_ADJUSTMENT,
  CREATE_PAYMENT,
  DELETE_ADJUSTMENT,
  GET_ORDER,
  UPDATE_ORDER_HEADER,
  UPDATE_ORDER_ITEMS,
} from './constants';
import {
  selectAdjustmentId,
  selectAdjustments,
  selectOrderId,
  selectOrderItems,
  selectPayment,
  selectUpdatedOrderHeader,
  selectUpdatedOrderItems,
} from './selectors';
import {
  cancelOrderError,
  cancelOrderItemError,
  cancelOrderItemSuccess,
  cancelOrderSuccess,
  createAdjustmentError,
  createAdjustmentSuccess,
  createPaymentError,
  createPaymentSuccess,
  deleteAdjustmentError,
  deleteAdjustmentSuccess,
  getOrderError,
  getOrderSuccess,
  updateOrderHeaderError,
  updateOrderHeaderSuccess,
  updateOrderItemsError,
  updateOrderItemsSuccess,
} from './actions';
import { ORDER_STATUS } from '../../constants';
import { showErrorMessage, showSuccessMessage } from '../App/actions';
import {
  createAdjustment as createAdjustmentGraphql,
  deleteAdjustment as deleteAdjustmentGraphql,
} from '../../graphql/mutations';
import { getProducts } from '../App/saga';
import { FulfillmentStatus } from '../../models';

export const getOrderGraphql = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      customerId
      sequenceNumber
      status
      type
      station
      comment
      licensePlateNumber
      truckAssignmentId
      createdDate
      createdBy
      _version
      _deleted
      _lastChangedAt
      customer {
        name
      }
      orderItems {
        items {
          id
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      adjustments {
        items {
          id
          amount
          comment
          createdBy {
            firstName
            lastName
          }
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      payments {
        items {
          id
          type
          amount
          orderId
          createdAt
          owner
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      images {
        bucket
        region
        level
        key
      }
    }
  }
`;

export const updateOrderGraphql = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      customerId
      sequenceNumber
      status
      fulfillmentStatus
      type
      station
      comment
      licensePlateNumber
      truckAssignmentId
      createdDate
      createdBy
      _version
      _deleted
      _lastChangedAt
      customer {
        name
      }
    }
  }
`;

export const createPaymentGraphql = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput) {
    createPayment(input: $input) {
      id
      customerId
      sequenceNumber
      status
      type
      station
      comment
      licensePlateNumber
      truckAssignmentId
      createdDate
      createdBy
      _version
      _deleted
      _lastChangedAt
      customer {
        name
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
    }
  }
`;

export const updateOrderItemGraphql = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      _version
      _lastChangedAt
    }
  }
`;

export const deleteOrderItemGraphql = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
    }
  }
`;

function* getImages(images) {
  if (_.isEmpty(images)) {
    return [];
  }
  const urls = yield all(
    images.map(image => call([Storage, 'get'], image.key)),
  );
  return images.map((image, idx) => ({ ...image, url: urls[idx] }));
}

function getOrderHeader(order) {
  const {
    id,
    type,
    station,
    comment,
    status,
    customerId,
    sequenceNumber,
    licensePlateNumber,
    customer,
    createdAt,
    truckAssignmentId,
    _version,
    _lastChangedAt,
  } = order;
  return {
    id,
    type,
    station,
    status,
    customerId,
    truckAssignmentId,
    comment,
    createdAt,
    sequenceNumber,
    licensePlateNumber,
    name: customer.name,
    _version,
    _lastChangedAt,
  };
}

function* getOrder() {
  try {
    yield call(getProducts);
    const orderId = yield select(selectOrderId);
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(getOrderGraphql, { id: orderId }),
    );
    const {
      images: resultImages,
      payments: resultPayments,
      orderItems: resultOrderItems,
      adjustments: resultAdjustments,
    } = result.data.getOrder;
    const orderHeader = getOrderHeader(result.data.getOrder);
    const orderItems = _.reject(resultOrderItems.items, ['_deleted', true]);
    const adjustments = _.reject(resultAdjustments.items, ['_deleted', true]);
    const payments = _.reject(resultPayments.items, ['_deleted', true]);
    const images = yield call(getImages, resultImages);
    yield put(
      getOrderSuccess(orderHeader, orderItems, adjustments, payments, images),
    );
  } catch {
    yield all([put(showErrorMessage()), put(getOrderError())]);
  }
}

function* createPayment() {
  try {
    const orderId = yield select(selectOrderId);
    const { newAmount, paymentType } = yield select(selectPayment);
    const input = {
      orderId,
      amount: newAmount,
      type: paymentType,
    };
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(createPaymentGraphql, { input }),
    );
    const { status, payments: resultPayments } = result.data.createPayment;
    const payments = _.reject(resultPayments.items, ['_deleted', true]);
    yield put(createPaymentSuccess(status, payments));
    yield put(showSuccessMessage());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield all([put(showErrorMessage()), put(createPaymentError())]);
  }
}

function* updateOrderHeader() {
  try {
    const updatedOrderHeader = yield select(selectUpdatedOrderHeader);
    const {
      id,
      sequenceNumber,
      customerId,
      type,
      station,
      comment,
      licensePlateNumber,
      _version,
    } = updatedOrderHeader;
    const input = {
      id,
      sequenceNumber,
      type,
      customerId,
      station,
      comment,
      licensePlateNumber,
      _version,
    };
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(updateOrderGraphql, { input }),
    );
    const orderHeader = getOrderHeader(result.data.updateOrder);
    yield all([
      put(showSuccessMessage()),
      put(updateOrderHeaderSuccess(orderHeader)),
    ]);
  } catch {
    yield all([put(showErrorMessage()), put(updateOrderHeaderError())]);
  }
}

function* cancelOrder() {
  try {
    const { id, _version } = yield select(selectUpdatedOrderHeader);
    const input = {
      id,
      _version,
      status: ORDER_STATUS.CANCELED,
      fulfillmentStatus: FulfillmentStatus.CANCELED,
    };
    // TODO: Make an api to cancel the order
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(updateOrderGraphql, { input }),
    );
    const orderHeader = getOrderHeader(result.data.updateOrder);
    yield all([
      put(showSuccessMessage()),
      put(cancelOrderSuccess(orderHeader)),
    ]);
  } catch {
    yield all([put(showErrorMessage()), put(cancelOrderError())]);
  }
}

/* eslint-disable guard-for-in, no-restricted-syntax */
function* updateOrderItems() {
  try {
    const oldOrderItems = _.keyBy(yield select(selectOrderItems), 'id');
    const oldUpdatedOrderItems = _.keyBy(
      yield select(selectUpdatedOrderItems),
      'id',
    );
    const calls = [];
    for (const updateOrderItemId in oldUpdatedOrderItems) {
      const orderItem = oldOrderItems[updateOrderItemId];
      const updatedOrderItem = oldUpdatedOrderItems[updateOrderItemId];
      const { quantity, adjustment } = orderItem;
      const {
        quantity: updatedQuantity,
        adjustment: updatedAdjustment,
        _version,
      } = updatedOrderItem;

      if (quantity !== updatedQuantity || adjustment !== updatedAdjustment) {
        const input = {
          id: updateOrderItemId,
          quantity: updatedQuantity,
          adjustment: updatedAdjustment,
          _version,
        };
        calls.push(
          // eslint-disable-next-line redux-saga/yield-effects
          call(
            [API, 'graphql'],
            graphqlOperation(updateOrderItemGraphql, { input }),
          ),
        );
      }
    }
    const results = yield all(calls);
    const newUpdatedOrderItems = _.flow([
      _.partialRight(_.map, 'data.updateOrderItem'),
      _.partialRight(_.keyBy, 'id'),
    ])(results);
    const newOrderItems = _.mergeWith(
      oldOrderItems,
      newUpdatedOrderItems,
      (orderItem, updatedOrderItem) => updatedOrderItem,
    );
    yield all([
      put(showSuccessMessage()),
      put(updateOrderItemsSuccess(newOrderItems)),
    ]);
  } catch {
    yield all([put(showErrorMessage()), put(updateOrderItemsError())]);
  }
}

function* cancelOrderItem({ payload }) {
  try {
    const { idx } = payload;
    const orderItems = yield select(selectOrderItems);
    const { id, _version } = orderItems[idx];
    const input = {
      id,
      _version,
    };
    yield call(
      [API, 'graphql'],
      graphqlOperation(deleteOrderItemGraphql, { input }),
    );
    yield all([put(showSuccessMessage()), put(cancelOrderItemSuccess(idx))]);
  } catch {
    yield all([put(showErrorMessage()), put(cancelOrderItemError())]);
  }
}

function* createAdjustment({ payload }) {
  try {
    const orderId = yield select(selectOrderId);
    const { amount, comment } = payload;
    const input = {
      orderId,
      amount,
      comment,
    };
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(createAdjustmentGraphql, { input }),
    );
    const { id, createdBy, _version, _lastChangedAt } =
      result.data.createAdjustment;
    const { firstName, lastName } = createdBy;
    const adjustment = {
      id,
      amount,
      comment,
      createdBy: {
        firstName,
        lastName,
      },
      _version,
      _lastChangedAt,
    };
    yield all([
      put(showSuccessMessage()),
      put(createAdjustmentSuccess(adjustment)),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield all([put(showErrorMessage()), put(createAdjustmentError())]);
  }
}

function* deleteAdjustment() {
  try {
    const id = yield select(selectAdjustmentId);
    const adjustments = yield select(selectAdjustments);
    const { _version } = _.find(adjustments, ['id', id]);
    const input = { id, _version };
    yield call(
      [API, 'graphql'],
      graphqlOperation(deleteAdjustmentGraphql, { input }),
    );
    yield put(deleteAdjustmentSuccess(id));
    yield put(showSuccessMessage());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield all([put(showErrorMessage()), put(deleteAdjustmentError())]);
  }
}

export default function* saga() {
  yield takeLatest(GET_ORDER, getOrder);
  yield takeLatest(CANCEL_ORDER, cancelOrder);
  yield takeLatest(CANCEL_ORDER_ITEM, cancelOrderItem);
  yield takeLatest(CREATE_PAYMENT, createPayment);
  yield takeLatest(UPDATE_ORDER_HEADER, updateOrderHeader);
  yield takeLatest(UPDATE_ORDER_ITEMS, updateOrderItems);
  yield takeLatest(CREATE_ADJUSTMENT, createAdjustment);
  yield takeLatest(DELETE_ADJUSTMENT, deleteAdjustment);
}
