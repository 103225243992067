import {
  ADD_DISCOUNT_ITEM,
  CANCEL_UPDATE,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_SUCCESS,
  DELETE_DISCOUNT_ITEM,
  DELETE_DISCOUNT_ITEM_ERROR,
  DELETE_DISCOUNT_ITEM_SUCCESS,
  HIDE_SUCCESS_MESSAGE,
  INIT_CUSTOMER,
  INIT_CUSTOMER_ERROR,
  INIT_CUSTOMER_SUCCESS,
  INIT_EMPTY_CUSTOMER_SUCCESS,
  RESET_CUSTOMER,
  SET_CUSTOMER,
  SET_CUSTOMER_NAME,
  TOGGLE_DISCOUNT_ITEM,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_ENABLED,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_DISCOUNT_ITEM_DISCOUNT,
  UPDATE_DISCOUNT_ITEM_PRODUCT,
} from './constants';

export function setCustomer(key, field, value) {
  return {
    type: `${key}/${SET_CUSTOMER}`,
    payload: {
      field,
      value,
    },
  };
}

export function setCustomerName(key, name) {
  return {
    type: `${key}/${SET_CUSTOMER_NAME}`,
    payload: { name },
  };
}

export function toggleDiscountItem(key, product) {
  return {
    type: `${key}/${TOGGLE_DISCOUNT_ITEM}`,
    payload: { product },
  };
}

export function updateDiscountItemDiscount(key, idx, discount) {
  return {
    type: `${key}/${UPDATE_DISCOUNT_ITEM_DISCOUNT}`,
    payload: { idx, discount },
  };
}

export function updateDiscountItemProduct(key, idx, product) {
  return {
    type: `${key}/${UPDATE_DISCOUNT_ITEM_PRODUCT}`,
    payload: { idx, product },
  };
}

export function createCustomer(key) {
  return {
    type: `${key}/${CREATE_CUSTOMER}`,
  };
}

export function createCustomerSuccess(key, customer, discountItems) {
  return {
    type: `${key}/${CREATE_CUSTOMER_SUCCESS}`,
    payload: { customer, discountItems },
  };
}

export function createCustomerError(key) {
  return {
    type: `${key}/${CREATE_CUSTOMER_ERROR}`,
  };
}

export function updateCustomer(key) {
  return {
    type: `${key}/${UPDATE_CUSTOMER}`,
  };
}

export function updateCustomerSuccess(key, customer, discountItems) {
  return {
    type: `${key}/${UPDATE_CUSTOMER_SUCCESS}`,
    payload: { customer, discountItems },
  };
}

export function updateCustomerError(key) {
  return {
    type: `${key}/${UPDATE_CUSTOMER_ERROR}`,
  };
}

export function hideSuccessMessage(key) {
  return {
    type: `${key}/${HIDE_SUCCESS_MESSAGE}`,
  };
}

export function updateCustomerEnabled(key) {
  return {
    type: `${key}/${UPDATE_CUSTOMER_ENABLED}`,
  };
}

export function initCustomer(key, customerId) {
  return {
    type: `${key}/${INIT_CUSTOMER}`,
    payload: { customerId },
  };
}

export function initCustomerSuccess(key, customer, discountItems) {
  return {
    type: `${key}/${INIT_CUSTOMER_SUCCESS}`,
    payload: { customer, discountItems },
  };
}

export function initEmptyCustomerSuccess(key) {
  return {
    type: `${key}/${INIT_EMPTY_CUSTOMER_SUCCESS}`,
  };
}

export function initCustomerError(key) {
  return {
    type: `${key}/${INIT_CUSTOMER_ERROR}`,
  };
}

export function deleteDiscountItem(key, idx) {
  return {
    type: `${key}/${DELETE_DISCOUNT_ITEM}`,
    payload: { idx },
  };
}

export function deleteDiscountItemSuccess(key, idx) {
  return {
    type: `${key}/${DELETE_DISCOUNT_ITEM_SUCCESS}`,
    payload: { idx },
  };
}

export function deleteDiscountItemError(key) {
  return {
    type: `${key}/${DELETE_DISCOUNT_ITEM_ERROR}`,
  };
}

export function cancelUpdate(key) {
  return {
    type: `${key}/${CANCEL_UPDATE}`,
  };
}

export function resetCustomer(key) {
  return {
    type: `${key}/${RESET_CUSTOMER}`,
  };
}

export function addDiscountItem(key) {
  return {
    type: `${key}/${ADD_DISCOUNT_ITEM}`,
  };
}
