import React, { forwardRef } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import OrderReceiptHeader from './OrderReceiptHeader';
import OrderReceiptTable from './OrderReceiptTable';
import Total from '../../components/Total';

function OrderReceipt(
  {
    orderHeader,
    orderItems,
    total,
    subtotal,
    paidAmount,
    newAmount,
    paymentType,
    adjustments,
  },
  ref,
) {
  return (
    <Box display="none" displayPrint="flex" flexDirection="column" ref={ref}>
      <Typography variant="subtitle1">
        <b>ใบเสร็จรับเงิน</b>
      </Typography>
      <Box mt={1}>
        <OrderReceiptHeader orderHeader={orderHeader} />
      </Box>
      <OrderReceiptTable orderItems={orderItems} />
      <Box display="flex" justifyContent="flex-end">
        <Box width="50%">
          <Total
            total={total}
            subtotal={subtotal}
            paidAmount={paidAmount}
            newAmount={newAmount}
            paymentType={paymentType}
            adjustments={adjustments}
            dense
            showComment
            showRemoveButton={false}
            showSubmitButton={false}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default forwardRef(OrderReceipt);
