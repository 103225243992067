import produce from 'immer';
import {
  GET_CURRENT_AUTHENTICATED_USER,
  GET_CURRENT_AUTHENTICATED_USER_ERROR,
  GET_CURRENT_AUTHENTICATED_USER_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_WAREHOUSES_ERROR,
  GET_WAREHOUSES_SUCCESS,
  HIDE_ERROR_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  HIDE_WARNING_MESSAGE,
  SET_HIDE_DRAWER,
  SET_SHOW_DRAWER,
  SET_UP_MFA,
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_WARNING_MESSAGE,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  VERIFY_MFA_CODE,
} from './constants';

export const initialState = {
  user: {},
  totpCode: '',
  products: {},
  warehouses: [],
  loading: false,
  initializing: true,
  showDrawer: false,
  successMessage: '',
  showSuccessMessage: false,
  errorMessage: '',
  showErrorMessage: false,
  warningMessage: '',
  showWarningMessage: false,
  signInResult: {},
};

/* eslint-disable default-case, no-param-reassign */
export default function appReducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_SHOW_DRAWER:
        draft.showDrawer = true;
        break;
      case SET_HIDE_DRAWER:
        draft.showDrawer = false;
        break;
      case SIGN_IN:
      case SET_UP_MFA:
      case VERIFY_MFA_CODE:
      case GET_CURRENT_AUTHENTICATED_USER:
        draft.loading = true;
        break;
      case SIGN_IN_SUCCESS:
        draft.signInResult = action.payload.signInResult;
        draft.totpCode = action.payload.totpCode;
        draft.loading = false;
        draft.initializing = false;
        break;
      case GET_CURRENT_AUTHENTICATED_USER_SUCCESS:
        draft.user = action.payload.user;
        draft.signInResult = action.payload.signInResult;
        draft.totpCode = '';
        draft.loading = false;
        draft.initializing = false;
        break;
      case GET_CURRENT_AUTHENTICATED_USER_ERROR:
        draft.loading = false;
        draft.initializing = false;
        break;
      case SIGN_OUT_SUCCESS:
        draft.user = {};
        draft.signInResult = {};
        break;
      case GET_PRODUCTS_SUCCESS:
        draft.products = action.payload.products;
        break;
      case GET_PRODUCTS_ERROR:
        draft.products = [];
        break;
      case GET_WAREHOUSES_SUCCESS:
        draft.warehouses = action.payload.warehouses;
        break;
      case GET_WAREHOUSES_ERROR:
        draft.warehouses = [];
        break;
      case SHOW_SUCCESS_MESSAGE:
        draft.loading = false;
        draft.showSuccessMessage = true;
        draft.successMessage = action.payload.successMessage;
        break;
      case HIDE_SUCCESS_MESSAGE:
        draft.showSuccessMessage = false;
        draft.successMessage = '';
        break;
      case SHOW_ERROR_MESSAGE:
        draft.loading = false;
        draft.showErrorMessage = true;
        draft.errorMessage = action.payload.errorMessage;
        break;
      case HIDE_ERROR_MESSAGE:
        draft.showErrorMessage = false;
        draft.errorMessage = '';
        break;
      case SHOW_WARNING_MESSAGE:
        draft.loading = false;
        draft.showWarningMessage = true;
        draft.warningMessage = action.payload.warningMessage;
        break;
      case HIDE_WARNING_MESSAGE:
        draft.showWarningMessage = false;
        draft.warningMessage = '';
        break;
    }
  });
}
