import React from 'react';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import MaterialTable from '@material-ui/core/Table';
import { styled } from '@material-ui/core/styles';
import DebtorTableHeader from './DebtorTableHeader';
import DebtorTableBody from './DebtorTableBody';
import SearchResultPaginator from '../../components/SearchResultPaginator';
import withSpinner from '../../components/WithSpinner';

const Table = styled(MaterialTable)(({ theme }) => ({
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderColor: theme.palette.divider,
}));

function DebtorTable({
  debtors,
  next,
  previous,
  refresh,
  showNext,
  showPrevious,
  sortField,
  sortDirection,
  onSort,
}) {
  return (
    <TableContainer component={Paper} variant="outlined">
      <SearchResultPaginator
        showNext={showNext}
        showPrevious={showPrevious}
        next={next}
        previous={previous}
        refresh={refresh}
      />
      <Table>
        <DebtorTableHeader
          sortField={sortField}
          sortDirection={sortDirection}
          onSortLabelClick={field => onSort(field)}
        />
        <DebtorTableBody debtors={debtors} />
      </Table>
      <SearchResultPaginator
        showNext={showNext}
        showPrevious={showPrevious}
        next={next}
        previous={previous}
        refresh={refresh}
      />
    </TableContainer>
  );
}

export default withSpinner(DebtorTable);
