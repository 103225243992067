import React, { useMemo } from 'react';
import _ from 'lodash';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';
import TableCell from '../TruckAssignmentOrderTableCell';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  background: theme.palette.background.default,
}));

const FixedTableCell = styled(TableCellHead)(({ theme }) => ({
  left: 0,
  position: 'sticky',
  zIndex: theme.zIndex.mobileStepper,
  background: theme.palette.background.default,
}));

const SecondRowTableCell = styled(TableCellHead)({
  top: 36.5,
  position: 'sticky',
});

function TruckAssignmentOrderTableHeader({
  groups,
  groupedProducts,
  showSequenceNumber = false,
}) {
  const keys = useMemo(() => _.keys(groups), [groups]);
  return (
    <TableHead>
      <TableRow>
        <TableCellHead bt={1} variant="head" rowSpan={2} align="center">
          #
        </TableCellHead>
        {showSequenceNumber && (
          <TableCellHead bt={1} bb={1} variant="head" rowSpan={2}>
            เลขที่ใบสั่งซื้อ
          </TableCellHead>
        )}
        <FixedTableCell bt={1} variant="head" rowSpan={2}>
          ชื่อลูกค้า
        </FixedTableCell>
        {keys.map(key => (
          <TableCellHead
            key={key}
            size="small"
            align="center"
            variant="head"
            bt={1}
            bb={0}
            colSpan={groups[key].length}
          >
            {key}
          </TableCellHead>
        ))}
      </TableRow>
      <TableRow>
        {groupedProducts.map(({ id, name, weight, brand }) => (
          <SecondRowTableCell
            key={id}
            size="small"
            align="center"
            variant="head"
            bt={1}
          >
            {weight ? brand.abbr : name}
          </SecondRowTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TruckAssignmentOrderTableHeader;
