import produce from 'immer';
import * as ACTION_TYPES from './constants';

const initialState = {
  rows: [],
  error: false,
  loading: false,
  disabled: false,
  saved: false,
  truckAssignment: {
    truckAssignmentId: '',
    station: '',
    sequenceNumber: '',
    deliveryRouteId: '',
    licensePlate: '',
    driver: '',
    companion: '',
    departure: null,
    comment: '',
  },
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTION_TYPES.GET_DELIVERY_ROUTE:
      case ACTION_TYPES.SUBMIT_TRUCK_ASSIGNMENT:
        draft.loading = true;
        draft.disabled = true;
        break;
      case ACTION_TYPES.GET_TRUCK_ASSIGNMENT:
        draft.loading = true;
        draft.disabled = true;
        break;
      case ACTION_TYPES.GET_DELIVERY_ROUTE_SUCCESS:
        draft.rows = action.payload.customers.map(customer => ({
          customerId: customer.id,
          name: customer.name,
          orderItems: {},
          orderId: null,
        }));
        draft.truckAssignment.licensePlate = action.payload.licensePlate;
        draft.loading = false;
        draft.disabled = !!draft.truckAssignment.truckAssignmentId;
        break;
      case ACTION_TYPES.GET_TRUCK_ASSIGNMENT_SUCCESS:
        draft.truckAssignment = action.payload.truckAssignment;
        draft.rows = action.payload.rows;
        draft.loading = false;
        draft.disabled = true;
        draft.saved = true;
        break;
      case ACTION_TYPES.SUBMIT_TRUCK_ASSIGNMENT_SUCCESS:
        draft.truckAssignment = action.payload.truckAssignment;
        draft.rows = action.payload.rows;
        draft.loading = false;
        draft.disabled = true;
        draft.saved = true;
        break;
      case ACTION_TYPES.GET_DELIVERY_ROUTE_ERROR:
      case ACTION_TYPES.GET_TRUCK_ASSIGNMENT_ERROR:
      case ACTION_TYPES.SUBMIT_TRUCK_ASSIGNMENT_ERROR:
        draft.loading = false;
        draft.disabled = false;
        draft.saved = false;
        break;
      case ACTION_TYPES.SET_TRUCK_ASSIGNMENT:
        draft.truckAssignment[action.payload.param] = action.payload.value;
        break;
      case ACTION_TYPES.SET_ORDER:
        draft.rows[action.payload.index].orderItems[action.payload.productId] =
          {
            productId: action.payload.productId,
            quantity: action.payload.quantity,
            weight: action.payload.weight,
            type: action.payload.type,
          };
        break;
      case ACTION_TYPES.RESET_TRUCK_ASSIGNMENT:
        draft.truckAssignment.truckAssignmentId = '';
        draft.truckAssignment.station = '';
        draft.truckAssignment.sequenceNumber = '';
        draft.truckAssignment.deliveryRouteId = '';
        draft.truckAssignment.licensePlate = '';
        draft.truckAssignment.driver = '';
        draft.truckAssignment.companion = '';
        draft.truckAssignment.departure = null;
        draft.truckAssignment.comment = '';
        draft.rows = [];
        draft.loading = false;
        draft.disabled = false;
        draft.saved = false;
        break;
      case ACTION_TYPES.UPDATE_TRUCK_ASSIGNMENT:
        draft.disabled = false;
        draft.saved = false;
        break;
    }
  });

export default reducer;
