import React from 'react';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import ReportTableHeader from './ReportTableHeader';
import ReportTableBody from './ReportTableBody';
import withSpinner from '../../components/WithSpinner';
import ReportTableFooter from './ReportTableFooter';

function ReportTable({ rows, summary, component, showSummary }) {
  return (
    <TableContainer component={component}>
      <Box overflow="scroll" maxHeight={800}>
        <Table stickyHeader>
          <ReportTableHeader />
          <ReportTableBody rows={rows} />
          {showSummary && <ReportTableFooter summary={summary} />}
        </Table>
      </Box>
    </TableContainer>
  );
}

export default withSpinner(ReportTable);
