import React from 'react';
import { useHistory } from 'react-router-dom';
import PaddingBox from '../../components/PaddingBox';
import DoneButton from '../../components/DoneButton';

function OrderBackButton() {
  const history = useHistory();
  return (
    <PaddingBox>
      <DoneButton onClick={() => history.goBack()} />
    </PaddingBox>
  );
}

export default OrderBackButton;
