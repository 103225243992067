import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DoneButton from '../../components/DoneButton';

export default function CreateOrderConfirmButtons({
  disabled,
  orderCreated,
  disabledSubmit,
  onReset,
}) {
  return orderCreated ? (
    <DoneButton onClick={onReset} />
  ) : (
    <Box display="flex">
      <Box mr={1}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={disabledSubmit}
        >
          บันทึก
        </Button>
      </Box>
      <Button
        type="reset"
        color="secondary"
        variant="contained"
        onClick={onReset}
        disabled={disabled}
      >
        ยกเลิก
      </Button>
    </Box>
  );
}
