import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import {
  CLEAR_SEARCH_PARAMS,
  EXPORT_ORDERS,
  EXPORT_ORDERS_ERROR,
  EXPORT_ORDERS_SUCCESS,
  REFRESH_SEARCH_ORDERS,
  SEARCH_NEXT_ORDERS,
  SEARCH_ORDERS,
  SEARCH_ORDERS_ERROR,
  SEARCH_ORDERS_SUCCESS,
  SEARCH_PREVIOUS_ORDERS,
  SET_SEARCH_PARAM,
} from './constants';

export const initialState = {
  params: {
    types: [],
    statuses: [],
    stations: [],
    endDate: null,
    startDate: null,
    customerId: null,
    sequenceNumber: null,
  },
  orders: null,
  tokens: [],
  exportNextToken: null,
  error: false,
  loading: false,
  showNext: false,
  showPrevious: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_SEARCH_PARAM:
        draft.params[action.payload.param] = action.payload.value;
        break;
      case CLEAR_SEARCH_PARAMS:
        draft.params.types = [];
        draft.params.statuses = [];
        draft.params.stations = [];
        draft.params.endDate = null;
        draft.params.startDate = null;
        draft.params.customerId = null;
        draft.params.sequenceNumber = null;
        break;
      case SEARCH_ORDERS:
        draft.tokens = [];
        draft.exportNextToken = null;
        draft.error = false;
        draft.loading = true;
        break;
      case EXPORT_ORDERS:
      case SEARCH_NEXT_ORDERS:
        draft.error = false;
        draft.loading = true;
        break;
      case REFRESH_SEARCH_ORDERS:
        if (!isEmpty(draft.tokens)) {
          draft.tokens = draft.tokens.slice(0, draft.tokens.length - 1);
        }
        draft.error = false;
        draft.loading = true;
        break;
      case SEARCH_PREVIOUS_ORDERS:
        draft.tokens = draft.tokens.slice(0, draft.tokens.length - 2);
        draft.error = false;
        draft.loading = true;
        break;
      case SEARCH_ORDERS_SUCCESS:
        draft.orders = action.payload.orders;
        draft.tokens.push(action.payload.nextToken);
        draft.showNext =
          !isEmpty(draft.tokens) &&
          !isEmpty(draft.tokens[draft.tokens.length - 1]);
        draft.showPrevious =
          draft.tokens.length > 1 && !isEmpty(draft.tokens[0]);
        draft.error = false;
        draft.loading = false;
        break;
      case EXPORT_ORDERS_SUCCESS:
        draft.exportNextToken = action.payload.nextToken;
        draft.error = false;
        draft.loading = false;
        break;
      case SEARCH_ORDERS_ERROR:
      case EXPORT_ORDERS_ERROR:
        draft.error = true;
        draft.loading = false;
        break;
    }
  });

export default reducer;
