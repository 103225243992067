import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { VALIDATOR } from '../../../constants';

export default function ProductWholeSaleTile(props) {
  const { disabled, wholesalePrice, handleDisablePrice, handlePriceChange } =
    props;
  const { price, conditionValue } = wholesalePrice;

  const handleWholesalePriceChange = field => e =>
    handlePriceChange(field, e.target.value);

  const handleDisableWholesalePrice = () =>
    handleDisablePrice('enabled', false);

  return (
    <Box mt={3} display="flex" flexDirection="row">
      <Box mr={1} flexGrow={1}>
        <TextValidator
          label="ราคาขายส่งหน่วยละ (บาท)"
          variant="outlined"
          margin="none"
          fullWidth
          type="number"
          value={price}
          disabled={disabled}
          validators={['required', 'isPositive']}
          errorMessages={[VALIDATOR.REQUIRED, VALIDATOR.IS_POSITIVE]}
          onChange={handleWholesalePriceChange('price')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="ยกเลิกราคา">
                  <IconButton onClick={handleDisableWholesalePrice}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box flexGrow={1}>
        <TextValidator
          label="จำนวนถัง (ขึ้นไป)"
          variant="outlined"
          margin="none"
          fullWidth
          type="number"
          value={conditionValue}
          disabled={disabled}
          validators={['required', 'isNumber', 'minNumber:2']}
          errorMessages={[
            VALIDATOR.REQUIRED,
            VALIDATOR.IS_NUMBER,
            VALIDATOR.GREATER_THEN_ONE,
          ]}
          onChange={handleWholesalePriceChange('conditionValue')}
        />
      </Box>
    </Box>
  );
}
