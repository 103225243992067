import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { TableCell, TableRow } from '@material-ui/core';
import TruckAssignmentSearchResultTableRow from './TruckAssignmentSearchResultTableRow';

function TruckAssignmentSearchResultTableBody({ assignments }) {
  return (
    <TableBody>
      {assignments.length ? (
        assignments.map(assignment => (
          <TruckAssignmentSearchResultTableRow
            key={assignment.id}
            assignment={assignment}
          />
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={8}>ไม่พบข้อมูล</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default TruckAssignmentSearchResultTableBody;
