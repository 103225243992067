import React, { memo } from 'react';
import MaterialTableRow from '@material-ui/core/TableRow';
import MaterialTextField from '@material-ui/core/TextField';
import { styled } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MaterialLink from '@material-ui/core/Link';

import _ from 'lodash';
import CommaSeparatedNumber from '../CommaSeparatedNumber';
import TableCell from '../TruckAssignmentOrderTableCell';
import { isNotEmpty } from '../../utils/validators';

const FixedTableCell = styled(TableCell)({
  left: 0,
  zIndex: 1,
  position: 'sticky',
});

const TextField = styled(MaterialTextField)({
  minWidth: 45,
  textAlign: 'center',
});

function TruckAssignmentOrderTableRow({
  idx,
  row,
  products,
  onChange,
  lastRow = false,
  disabled = false,
  showSequenceNumber = false,
}) {
  const { orderId, customerId, name, sequenceNumber, orderItems } = row;
  const borderBottom = !lastRow ? 1 : 0;
  return (
    <MaterialTableRow>
      <TableCell align="center" padding="none" bb={borderBottom}>
        <CommaSeparatedNumber value={idx + 1} />
      </TableCell>
      {showSequenceNumber && (
        <TableCell bb={borderBottom}>
          <MaterialLink to={`/order/${orderId}`} component={Link}>
            {sequenceNumber}
          </MaterialLink>
        </TableCell>
      )}
      <FixedTableCell bb={borderBottom}>
        {customerId && name && (
          <MaterialLink to={`/customer/${customerId}`} component={Link}>
            {name}
          </MaterialLink>
        )}
      </FixedTableCell>
      {products.map(({ id: productId, type, weight }) => (
        <TableCell
          key={productId}
          weight={weight}
          bb={borderBottom}
          width="100"
          size="small"
          align="center"
        >
          <TextField
            type="number"
            disabled={disabled}
            inputProps={{ min: 0 }}
            defaultValue={
              orderItems && orderItems[productId]
                ? orderItems[productId].quantity
                : ''
            }
            onChange={e =>
              onChange(idx, productId, e.target.value, weight, type)
            }
          />
        </TableCell>
      ))}
    </MaterialTableRow>
  );
}

function isEqual(prev, next) {
  const prevSum = _.flow([
    _.partialRight(_.get, 'row.orderItems', {}),
    _.partialRight(_.reduce, (sum, orderItem) => sum + orderItem.quantity, 0),
  ])(prev);
  const nextSum = _.flow([
    _.partialRight(_.get, 'row.orderItems', {}),
    _.partialRight(_.reduce, (sum, orderItem) => sum + orderItem.quantity, 0),
  ])(next);
  return (
    prevSum === nextSum &&
    prev.disabled === next.disabled &&
    isNotEmpty(prev.products)
  );
}

export default memo(TruckAssignmentOrderTableRow, isEqual);
