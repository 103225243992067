import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Spinner() {
  return (
    <Box display="flex" justifyContent="center" mt={2}>
      <CircularProgress color="primary" size={30} />
    </Box>
  );
}
