import React from 'react';
import CreateOrderSuccessAlert from './CreateOrderSuccessAlert';
import Title from '../../components/Title';
import OrderForm from '../../components/OrderForm';
import Paper from '../../components/Paper';
import withSpinner from '../../components/WithSpinner';
import { isNotEmpty } from '../../utils/validators';
import CreateOrderOptions from './CreateOrderOptions';

function CreateOrderForm({
  order,
  onChange,
  onPrintClick,
  onCustomerChange,
  onCustomerNameChange,
  onAddOrderItemsClick,
  disabled = false,
  showStatus = false,
  showOrderLink = false,
  showPrintOption = false,
  showSequenceNumber = false,
  disabledCustomerSearch = false,
}) {
  return (
    <Paper>
      {isNotEmpty(order, 'sequenceNumber') && (
        <CreateOrderSuccessAlert sequenceNumber={order.sequenceNumber} />
      )}
      <Title
        title="สั่งซื้อ"
        marginBottom
        right={
          <CreateOrderOptions
            orderId={order.id}
            disabled={disabled}
            showOrderLink={showOrderLink}
            showPrintOption={showPrintOption}
            onPrintClick={onPrintClick}
            onAddOrderItemsClick={onAddOrderItemsClick}
          />
        }
      />
      <OrderForm
        order={order}
        disabled={disabled}
        showStatus={showStatus}
        showSequenceNumber={showSequenceNumber}
        disabledCustomerSearch={disabledCustomerSearch}
        onChange={onChange}
        onCustomerChange={onCustomerChange}
        onCustomerNameChange={onCustomerNameChange}
      />
    </Paper>
  );
}

export default withSpinner(CreateOrderForm);
