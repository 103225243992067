import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import { styled } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const StyledTableCell = styled(TableCell)({
  whiteSpace: 'nowrap',
});

function DebtorTableHeader({ sortField, sortDirection, onSortLabelClick }) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell>
          <TableSortLabel
            active={sortField === 'sequenceNumber'}
            direction={sortDirection}
            onClick={() => onSortLabelClick('sequenceNumber')}
          >
            <b>รหัสลูกค้า</b>
          </TableSortLabel>
        </StyledTableCell>
        <StyledTableCell>
          <TableSortLabel
            active={sortField === 'name'}
            direction={sortDirection}
            onClick={() => onSortLabelClick('name')}
          >
            <b>ชื่อลูกค้า</b>
          </TableSortLabel>
        </StyledTableCell>
        <StyledTableCell>
          <b>ประเภทลูกค้า</b>
        </StyledTableCell>
        <StyledTableCell>
          <b>เบอร์โทร</b>
        </StyledTableCell>
        <StyledTableCell>
          <TableSortLabel
            active={sortField === 'debt'}
            direction={sortDirection}
            onClick={() => onSortLabelClick('debt')}
          >
            <b>ยอดหนี้ค้างชำระ</b>
          </TableSortLabel>
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default DebtorTableHeader;
