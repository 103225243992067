import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { styled } from '@material-ui/core/styles';
import MaterialTableCell from '@material-ui/core/TableCell';
// eslint-disable-next-line import/no-cycle
import { ORDER_TABLE_COLUMNS } from './index';

const TableCell = styled(MaterialTableCell)({
  whiteSpace: 'nowrap',
});

function OrderTableHeader({ columns }) {
  return (
    <TableHead>
      <TableRow>
        {columns[ORDER_TABLE_COLUMNS.SEQUENCE_NUMBER] && (
          <TableCell>
            <b>เลขที่ใบสั่ง</b>
          </TableCell>
        )}
        {columns[ORDER_TABLE_COLUMNS.NAME] && (
          <TableCell>
            <b>ชื่อลูกค้า</b>
          </TableCell>
        )}
        {columns[ORDER_TABLE_COLUMNS.TYPE] && (
          <TableCell>
            <b>ประเภท</b>
          </TableCell>
        )}
        {columns[ORDER_TABLE_COLUMNS.STATUS] && (
          <TableCell>
            <b>สถานะ</b>
          </TableCell>
        )}
        {columns[ORDER_TABLE_COLUMNS.STATION] && (
          <TableCell>
            <b>โรงอัดแก๊ส</b>
          </TableCell>
        )}
        {columns[ORDER_TABLE_COLUMNS.SUBTOTAL] && (
          <TableCell>
            <b>ยอดขาย</b>
          </TableCell>
        )}
        {columns[ORDER_TABLE_COLUMNS.OVERDUE_AMOUNT] && (
          <TableCell>
            <b>ค้างชำระ</b>
          </TableCell>
        )}
        {columns[ORDER_TABLE_COLUMNS.CREATED_AT] && (
          <TableCell>
            <b>วันที่ขาย</b>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

export default OrderTableHeader;
