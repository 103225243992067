import _ from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { graphqlOperation } from 'aws-amplify';
import { API } from '@aws-amplify/api';
import {
  GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS,
  GET_OVERDUE_TRUCK_ASSIGNMENTS,
  GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS,
  REFRESH_OVERDUE_TRUCK_ASSIGNMENTS,
} from './constants';
import { makeSelectNextToken } from './selectors';
import {
  getOverdueTruckAssignmentsError,
  getOverdueTruckAssignmentsSuccess,
} from './actions';
import { TRUCK_ASSIGNMENT_STATUS } from '../../constants';
import { searchTruckAssignments as searchTruckAssignmentsGraphql } from './graphql';

function* getOverdueTruckAssignments() {
  try {
    const nextToken = yield select(makeSelectNextToken);
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(searchTruckAssignmentsGraphql, {
        filter: {
          or: [
            { status: { eq: TRUCK_ASSIGNMENT_STATUS.CREATED } },
            { status: { eq: TRUCK_ASSIGNMENT_STATUS.OVERDUE } },
          ],
        },
        sort: { field: 'createdAt', direction: 'desc' },
        nextToken,
      }),
    );

    const truckAssignments = result.data.searchTruckAssignments.items.map(
      assignment => {
        const {
          id,
          sequenceNumber,
          deliveryRouteId,
          status,
          driver,
          companion,
          createdAt,
          orders,
          comment,
        } = assignment;

        const subtotal = _.reduce(
          orders.items,
          (subtotal, order) => {
            const orderSubtotal = _.reduce(
              order.orderItems.items,
              (total, orderItem) => {
                const { unitPrice, adjustment, quantity } = orderItem;
                return total + quantity * (unitPrice + adjustment);
              },
              0,
            );
            return subtotal + orderSubtotal;
          },
          0,
        );

        return {
          id,
          sequenceNumber,
          deliveryRouteId,
          status,
          driver,
          companion,
          createdAt,
          subtotal,
          comment,
        };
      },
    );

    yield put(
      getOverdueTruckAssignmentsSuccess(
        truckAssignments,
        result.data.searchTruckAssignments.nextToken,
      ),
    );
  } catch {
    yield put(getOverdueTruckAssignmentsError());
  }
}

export default function* sage() {
  yield takeLatest(
    [
      GET_OVERDUE_TRUCK_ASSIGNMENTS,
      GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS,
      GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS,
      REFRESH_OVERDUE_TRUCK_ASSIGNMENTS,
    ],
    getOverdueTruckAssignments,
  );
}
