import { createSelector } from 'reselect';
import { isNotEmpty } from '../../utils/validators';

export const selectTruckAssignmentList = state => state.truckAssignmentList;

export const selectParams = createSelector(
  selectTruckAssignmentList,
  ({ params }) => params,
);

export const selectLoading = createSelector(
  selectTruckAssignmentList,
  ({ loading }) => loading,
);

export const selectAssignments = createSelector(
  selectTruckAssignmentList,
  ({ assignments }) => assignments,
);

export const selectShowNext = createSelector(
  selectTruckAssignmentList,
  ({ showNext }) => showNext,
);

export const selectShowPrevious = createSelector(
  selectTruckAssignmentList,
  ({ showPrevious }) => showPrevious,
);

export const selectNextToken = createSelector(
  selectTruckAssignmentList,
  ({ tokens }) => (isNotEmpty(tokens) ? tokens[tokens.length - 1] : null),
);

export const selectExportNextToken = createSelector(
  selectTruckAssignmentList,
  ({ exportNextToken }) => exportNextToken,
);
