import React, { ReactNode } from 'react';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';

interface PaperProps extends MuiPaperProps {
  padding?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  children: ReactNode;
}

function Paper({
  children,
  padding = true,
  marginTop = false,
  marginBottom = false,
  ...otherProps
}: PaperProps) {
  return (
    <MuiPaper
      variant="outlined"
      sx={{
        p: padding ? { xs: 2, md: 3 } : 0,
        mt: marginTop ? { xs: 2, md: 3 } : 0,
        mb: marginBottom ? { xs: 2, md: 3 } : 0,
      }}
      {...otherProps}
    >
      {children}
    </MuiPaper>
  );
}

export default Paper;
