import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import _ from 'lodash';
import CreateInventoryItemTableRow from './CreateInventoryItemTableRow';
import CreateInventoryItemTableEmptyRow from './CreateInventoryItemTableEmptyRow';

/* eslint-disable react/no-array-index-key */
function CreateInventoryItemTableBody({
  disabled,
  products,
  onDelete,
  inventoryItems,
  onProductChange,
  onQuantityChange,
}) {
  return (
    <TableBody>
      {_.isEmpty(inventoryItems) ? (
        <CreateInventoryItemTableEmptyRow />
      ) : (
        inventoryItems.map((inventoryItem, idx) => (
          <CreateInventoryItemTableRow
            idx={idx}
            key={idx}
            disabled={disabled}
            products={products}
            onDelete={onDelete}
            inventoryItem={inventoryItem}
            onProductChange={onProductChange}
            onQuantityChange={onQuantityChange}
          />
        ))
      )}
    </TableBody>
  );
}

export default CreateInventoryItemTableBody;
