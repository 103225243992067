import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';

function CreateOrderQueueCardTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" padding="none">
          <Typography variant="subtitle1">#</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle1">สินค้า</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="subtitle1">จำนวน</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default CreateOrderQueueCardTableHeader;
