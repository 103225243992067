import React, { forwardRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import OrderItemDialogHeader from './OrderItemDialogHeader';
import OrderItemDialogBody from './OrderItemDialogBody';

/* eslint-disable react/jsx-props-no-spreading */
const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

function OrderItemDialog({
  open,
  groupedByTypeProducts,
  items,
  onClick,
  onClose,
}) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <OrderItemDialogHeader onClose={onClose} />
      <OrderItemDialogBody
        groupedByTypeProducts={groupedByTypeProducts}
        items={items}
        onClick={onClick}
        onClose={onClose}
      />
    </Dialog>
  );
}

export default OrderItemDialog;
