import React from 'react';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectValidator } from 'react-material-ui-form-validator';
import { VALIDATOR } from '../../constants';
import CustomerProductSelectorItem from '../../containers/Customer/CustomerProductSelectorItem';

function ProductSelector({
  productId,
  products,
  selectedProducts,
  disabled,
  onChange,
}) {
  return (
    <SelectValidator
      fullWidth
      margin="none"
      variant="outlined"
      value={productId || ''}
      disabled={disabled}
      validators={['required']}
      errorMessages={[VALIDATOR.REQUIRED]}
      onChange={e => onChange(products[e.target.value])}
      SelectProps={{
        renderValue: productId => {
          const { name } = products[productId];
          return name;
        },
      }}
    >
      {_.map(products, ({ id, name, type, brand: { abbr, logo } }) => (
        <MenuItem
          key={id}
          dense
          divider
          value={id}
          disabled={
            _.some(selectedProducts, ['productId', id]) && productId !== id
          }
        >
          <CustomerProductSelectorItem
            name={name}
            abbr={abbr}
            type={type}
            logo={_.get(logo, 'key')}
          />
        </MenuItem>
      ))}
    </SelectValidator>
  );
}

export default ProductSelector;
