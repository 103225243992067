import React from 'react';
import Button from '@mui/material/Button';
import withSpinner from '../WithSpinner';

/* eslint-disable react/jsx-props-no-spreading */
function ButtonLoader(props) {
  const { children, loading, disabled, onClick, ...otherProps } = props;
  return (
    <Button
      type="button"
      color="primary"
      variant="contained"
      disabled={loading || disabled}
      onClick={onClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
}

export default withSpinner(ButtonLoader);
