import { Key } from './constants';
import { createSelector } from 'reselect';
import {
  ContainerState,
  FulfillmentReportRow,
  FulfillmentReportRowCountsAndTotals,
} from './types';
import { Product, WorkflowItem, WorkflowItemType } from '../../models';
import { selectProducts } from '../App/selectors';
import { isEmpty, isNotEmpty } from '../../utils/validators';

export const selectFulfillmentReport = (state): ContainerState => state[Key];

export const selectLoading = createSelector(
  selectFulfillmentReport,
  ({ loading }) => loading,
);

export const selectDate = createSelector(
  selectFulfillmentReport,
  ({ date }) => date,
);

export const selectWorkflows = createSelector(
  selectFulfillmentReport,
  ({ workflows }) => workflows,
);

function getCountsAndTotals(
  workflowItems: WorkflowItem[],
  products: { [productId: string]: Product },
): FulfillmentReportRowCountsAndTotals {
  return workflowItems.reduce(
    (counts, workflowItem) => {
      // @ts-ignore
      const { productId, type, quantity } = workflowItem;
      const { weight } = products[productId];
      if (weight) {
        const key = `w${Math.round(weight * 10)}`;
        counts[key] += quantity;
        const totalWeight = quantity * weight;
        counts.totalWeight += totalWeight;
        if (type === WorkflowItemType.SWAPPED) {
          const keySwapped = `${key}Swapped`;
          counts[keySwapped] += quantity;
          counts.totalWeightSwapped += totalWeight;
        } else {
          const keyFueled = `${key}Fueled`;
          counts[keyFueled] += quantity;
          counts.totalWeightFueled += totalWeight;
        }
      }
      return counts;
    },
    {
      w40: 0,
      w70: 0,
      w90: 0,
      w115: 0,
      w135: 0,
      w150: 0,
      w480: 0,
      w40Fueled: 0,
      w70Fueled: 0,
      w90Fueled: 0,
      w115Fueled: 0,
      w135Fueled: 0,
      w150Fueled: 0,
      w480Fueled: 0,
      w40Swapped: 0,
      w70Swapped: 0,
      w90Swapped: 0,
      w115Swapped: 0,
      w135Swapped: 0,
      w150Swapped: 0,
      w480Swapped: 0,
      totalWeight: 0,
      totalWeightFueled: 0,
      totalWeightSwapped: 0,
    },
  );
}

export const selectRows = createSelector(
  selectWorkflows,
  selectProducts,
  (workflows, products) => {
    if (isEmpty(products)) {
      return [];
    }
    return workflows.map(workflow => {
      const workflowItems = workflow.workflowItems;
      const counts = getCountsAndTotals(
        workflowItems as WorkflowItem[],
        products as { [productId: string]: Product },
      );
      const isTruckAssignment = isNotEmpty(workflow.truckAssignment);
      return {
        ...workflow,
        ...counts,
        isTruckAssignment,
      } as FulfillmentReportRow;
    });
  },
);

export const selectSummary = createSelector(selectRows, rows =>
  rows.reduce(
    (summary, row) => {
      const {
        w40,
        w70,
        w90,
        w115,
        w135,
        w150,
        w480,
        w40Fueled,
        w70Fueled,
        w90Fueled,
        w115Fueled,
        w135Fueled,
        w150Fueled,
        w480Fueled,
        w40Swapped,
        w70Swapped,
        w90Swapped,
        w115Swapped,
        w135Swapped,
        w150Swapped,
        w480Swapped,
        totalWeight,
        totalWeightFueled,
        totalWeightSwapped,
      } = row;
      summary.w40 += w40;
      summary.w70 += w70;
      summary.w90 += w90;
      summary.w115 += w115;
      summary.w135 += w135;
      summary.w150 += w150;
      summary.w480 += w480;
      summary.w40Fueled += w40Fueled;
      summary.w70Fueled += w70Fueled;
      summary.w90Fueled += w90Fueled;
      summary.w115Fueled += w115Fueled;
      summary.w135Fueled += w135Fueled;
      summary.w150Fueled += w150Fueled;
      summary.w480Fueled += w480Fueled;
      summary.w40Swapped += w40Swapped;
      summary.w70Swapped += w70Swapped;
      summary.w90Swapped += w90Swapped;
      summary.w115Swapped += w115Swapped;
      summary.w135Swapped += w135Swapped;
      summary.w150Swapped += w150Swapped;
      summary.w480Swapped += w480Swapped;
      summary.totalWeight += totalWeight;
      summary.totalWeightFueled += totalWeightFueled;
      summary.totalWeightSwapped += totalWeightSwapped;
      return summary;
    },
    {
      w40: 0,
      w70: 0,
      w90: 0,
      w115: 0,
      w135: 0,
      w150: 0,
      w480: 0,
      w40Fueled: 0,
      w70Fueled: 0,
      w90Fueled: 0,
      w115Fueled: 0,
      w135Fueled: 0,
      w150Fueled: 0,
      w480Fueled: 0,
      w40Swapped: 0,
      w70Swapped: 0,
      w90Swapped: 0,
      w115Swapped: 0,
      w135Swapped: 0,
      w150Swapped: 0,
      w480Swapped: 0,
      totalWeight: 0,
      totalWeightFueled: 0,
      totalWeightSwapped: 0,
    },
  ),
);
