import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import ProductWholeSaleTile from './ProductWholeSaleTile';
import ProductContext from '../context';
import { setDisabled, setPrices } from '../actions';
import { PRICE_TYPE } from '../../../constants';

/* eslint-disable no-continue, no-plusplus */
export default function ProductWholeSalePrice() {
  const { state, dispatch } = useContext(ProductContext);
  const { product, prices, disabled } = state;

  const handleAddWholesalePrice = () => {
    const newWholesalePrice = {
      id: null,
      type: PRICE_TYPE.WHOLESALE,
      price: '',
      productId: product.id,
      conditionDesc: '',
      conditionValue: '',
    };

    dispatch(setPrices([...prices, newWholesalePrice]));
  };

  const handlePriceChange = index => (field, value) => {
    const updatedPrices = [...prices];

    updatedPrices.splice(index, 1, {
      ...prices[index],
      [field]: value,
    });

    dispatch(setPrices(updatedPrices));
  };

  const handleDeletedPrice = index => () => {
    const updatedPrices = [...prices];

    updatedPrices.splice(index, 1, {
      ...prices[index],
      deleted: true,
    });

    dispatch(setPrices(updatedPrices));
    dispatch(setDisabled(false));
  };

  const renderWholesalePriceTiles = () => {
    const wholesalePrices = [];
    for (let i = 0; i < prices.length; i++) {
      if (
        prices[i].type !== PRICE_TYPE.WHOLESALE ||
        prices[i].deleted === true
      ) {
        continue;
      }

      wholesalePrices.push(
        <ProductWholeSaleTile
          key={i}
          disabled={disabled}
          wholesalePrice={prices[i]}
          handleDisablePrice={handleDeletedPrice(i)}
          handlePriceChange={handlePriceChange(i)}
        />,
      );
    }

    if (wholesalePrices.length) {
      return wholesalePrices;
    }
    return (
      <Box mt={3}>
        <Typography variant="body2">ไม่พบราคาขายส่งสำหรับสินค้านี้</Typography>
      </Box>
    );
  };

  return (
    <Box mt={3}>
      <Box display="flex" justifyContent="space-between">
        {/* Title */}
        <Typography variant="h6">ราคาขายส่ง</Typography>

        {/* Add Whole Sale Button */}
        <Tooltip title="เพิ่มราคาขายส่ง">
          <Box>
            <Fab
              size="small"
              color="primary"
              disabled={disabled}
              onClick={handleAddWholesalePrice}
            >
              <AddIcon fontSize="small" />
            </Fab>
          </Box>
        </Tooltip>
      </Box>
      <Box display="flex" flexDirection="column">
        {renderWholesalePriceTiles()}
      </Box>
    </Box>
  );
}
