import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import isEmpty from 'lodash/isEmpty';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DebtorTableRow from './DebtorTableRow';

function DebtorTableBody({ debtors }) {
  return (
    <TableBody>
      {!isEmpty(debtors) ? (
        debtors.map(debtor => (
          <DebtorTableRow key={debtor.id} debtor={debtor} />
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={5}>ไม่พบลูกหนี้</TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default DebtorTableBody;
