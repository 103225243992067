import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import DebtorChip from './DebtorChip';

function DebtorOrderTableHeader({ customerId, debt, children }) {
  const history = useHistory();
  return (
    <Paper variant="outlined">
      <Box p={3} display="flex" alignItems="center" flexWrap="wrap">
        <Box mr={1}>
          <Typography variant="h6">ใบสั่งซื้อค้างชำระเงิน</Typography>
        </Box>
        <Box flexGrow={1}>
          <DebtorChip debt={debt} />
        </Box>
        <Button
          color="primary"
          onClick={() => history.push(`/createDebt/${customerId}`)}
        >
          เพิ่มใบสั่งซื้อค้างชำระ
        </Button>
      </Box>
      <Divider />
      <Box overflow="auto">{children}</Box>
    </Paper>
  );
}

export default DebtorOrderTableHeader;
