import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { select } from '@redux-saga/core/effects';
import _ from 'lodash';
import {
  GET_DELIVERY_ROUTE,
  GET_TRUCK_ASSIGNMENT,
  SUBMIT_TRUCK_ASSIGNMENT,
} from './constants';
import { getDeliveryRoute as getDeliveryRouteGraphql } from '../../graphql/queries';
import { ORDER_TYPE } from '../../constants';
import {
  getDeliveryRouteError,
  getDeliveryRouteSuccess,
  getTruckAssignmentError,
  getTruckAssignmentSuccess,
  submitTruckAssignmentError,
  submitTruckAssignmentSuccess,
} from './actions';
import {
  selectDeliveryRouteId,
  selectRows,
  selectTruckAssignment,
} from './selectors';
import {
  batchUpdateOrders,
  createTruckAssignment as createTruckAssignmentGraphql,
  updateTruckAssignment as updateTruckAssignmentGraphql,
} from '../../graphql/mutations';
import { showErrorMessage, showSuccessMessage } from '../App/actions';
import { getProducts } from '../App/saga';
import { getTruckAssignment as getTruckAssignmentGraphql } from './graphql';

export function* getDeliveryRoute() {
  try {
    const id = yield select(selectDeliveryRouteId);
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(getDeliveryRouteGraphql, { id }),
    );
    const { licensePlate, customers } = result.data.getDeliveryRoute;
    yield put(getDeliveryRouteSuccess(licensePlate, customers.items));
  } catch {
    yield put(getDeliveryRouteError());
    yield put(showErrorMessage());
  }
}

function setTruckAssignment(truckAssignment) {
  const {
    id: truckAssignmentId,
    station,
    sequenceNumber,
    deliveryRouteId,
    deliveryRoute,
    licensePlate,
    driver,
    companion,
    departure,
    comment,
    _version,
    _lastChangedAt,
  } = truckAssignment;
  return {
    truckAssignmentId,
    station,
    sequenceNumber,
    deliveryRouteId,
    driver,
    companion,
    departure,
    licensePlate: licensePlate || deliveryRoute.licensePlate,
    comment,
    _version,
    _lastChangedAt,
  };
}

export function* createTruckAssignment(truckAssignment) {
  const {
    licensePlate,
    deliveryRouteId,
    driver,
    companion,
    departure,
    station,
    comment,
  } = truckAssignment;
  const input = {
    licensePlate,
    deliveryRouteId,
    driver,
    companion,
    departure,
    station,
    comment,
  };
  const result = yield call(
    [API, 'graphql'],
    graphqlOperation(createTruckAssignmentGraphql, { input }),
  );
  return setTruckAssignment(result.data.createTruckAssignment);
}

export function* updateTruckAssignment(truckAssignment) {
  const {
    truckAssignmentId,
    deliveryRouteId,
    licensePlate,
    driver,
    companion,
    departure,
    station,
    comment,
    _version,
  } = truckAssignment;
  const input = {
    id: truckAssignmentId,
    deliveryRouteId,
    licensePlate,
    driver,
    companion,
    departure,
    station,
    comment,
    _version,
  };
  const result = yield call(
    [API, 'graphql'],
    graphqlOperation(updateTruckAssignmentGraphql, { input }),
  );
  return setTruckAssignment(result.data.updateTruckAssignment);
}

export function* createOrders(rows, truckAssignmentId, station) {
  const orders = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const { orderId, customerId, orderItems } of rows) {
    const createdOrderItems = _.flow([
      _.values,
      _.partialRight(_.filter, ({ quantity }) => quantity > 0),
      _.partialRight(_.map, ({ productId, quantity }) => ({
        productId,
        quantity,
      })),
    ])(orderItems);
    if (createdOrderItems.length || orderId) {
      orders.push({
        id: orderId,
        station,
        customerId,
        truckAssignmentId,
        type: ORDER_TYPE.TRUCK_SALES_ORDER,
        orderItems: createdOrderItems,
      });
    }
  }
  const createdOrders = yield call(
    [API, 'graphql'],
    graphqlOperation(batchUpdateOrders, { orders }),
  );
  return createdOrders.data.batchUpdateOrders;
}

export function* getTruckAssignment(action) {
  try {
    yield call(getProducts);
    const { truckAssignmentId: id } = action.payload;
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(getTruckAssignmentGraphql, { id }),
    );
    const truckAssignmentResult = result.data.getTruckAssignment;
    const customers = truckAssignmentResult.deliveryRoute.customers.items;
    const orders = _.flow([
      _.partialRight(_.reject, ['_deleted', true]),
      _.partialRight(_.keyBy, 'customerId'),
    ])(truckAssignmentResult.orders.items);
    const truckAssignment = setTruckAssignment(truckAssignmentResult);
    const rows = customers.map(({ id: customerId, name }) => {
      const order = orders[customerId];
      if (!_.isEmpty(order)) {
        const orderItems = _.flow([
          _.partialRight(_.reject, ['_deleted', true]),
          _.partialRight(_.keyBy, 'productId'),
        ])(order.orderItems.items);
        return {
          customerId,
          name,
          orderItems,
          orderId: order.id,
        };
      }
      return {
        customerId,
        name,
        orderItems: {},
        orderId: null,
      };
    });
    yield put(getTruckAssignmentSuccess(truckAssignment, rows));
  } catch {
    yield put(getTruckAssignmentError());
    yield put(showErrorMessage());
  }
}

export function* submitTruckAssignment() {
  try {
    const oldTruckAssignment = yield select(selectTruckAssignment);
    const newTruckAssignment = oldTruckAssignment.truckAssignmentId
      ? yield call(updateTruckAssignment, oldTruckAssignment)
      : yield call(createTruckAssignment, oldTruckAssignment);
    const { truckAssignmentId, station } = newTruckAssignment;
    const rows = yield select(selectRows);
    const orders = yield call(createOrders, rows, truckAssignmentId, station);
    const customerOrders = _.keyBy(orders, 'customerId');
    const updatedRows = [...rows].map(row => {
      const { customerId } = row;
      return {
        ...row,
        orderId: customerOrders[customerId]
          ? customerOrders[customerId].id
          : null,
      };
    });
    yield put(submitTruckAssignmentSuccess(newTruckAssignment, updatedRows));
    yield put(showSuccessMessage('บันทึกใบสั่งซื้อรถเร่สำเร็จ'));
  } catch {
    yield put(submitTruckAssignmentError());
    yield put(showErrorMessage());
  }
}

export default function* saga() {
  yield takeLatest(GET_DELIVERY_ROUTE, getDeliveryRoute);
  yield takeLatest(GET_TRUCK_ASSIGNMENT, getTruckAssignment);
  yield takeLatest(SUBMIT_TRUCK_ASSIGNMENT, submitTruckAssignment);
}
