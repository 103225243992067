import { combineReducers } from 'redux';
import debtorReducer from './containers/Debtor/reducer';
import orderReducer from './containers/Order/reducer';
import cashierReducer from './containers/Cashier/reducer';
import orderListReducer from './containers/OrderList/reducer';
import debtorListReducer from './containers/DebtorList/reducer';
import truckAssignmentReducer from './containers/TruckAssignment/reducer';
import truckAssignmentListReducer from './containers/TruckAssignmentList/reducer';
import overdueTruckAssignmentListReducer from './containers/OverdueTruckAssignmentList/reducer';

function createReducer(injectedReducers = {}) {
  return combineReducers({
    order: orderReducer,
    debtor: debtorReducer,
    cashier: cashierReducer,
    orderList: orderListReducer,
    debtorList: debtorListReducer,
    truckAssignment: truckAssignmentReducer,
    truckAssignmentList: truckAssignmentListReducer,
    overdueTruckAssignmentList: overdueTruckAssignmentListReducer,
    ...injectedReducers,
  });
}

export default createReducer;
