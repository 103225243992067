import React, { forwardRef } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import isEmpty from 'lodash/isEmpty';
import TableContainer from '@material-ui/core/TableContainer';
import OrderItemTableHeader from './OrderItemTableHeader';
import OrderItemTableRow from './OrderItemTableRow';
import Container from '../../components/Container';
import OrderOptions from '../../components/OrderOptions';

/* eslint-disable react/jsx-props-no-spreading */
function OrderItemTable(
  {
    items,
    onChange,
    onCancel,
    onUpdateClick,
    onPrintQueueCardClick,
    updateInProgress,
    printable = false,
    showUpdateButton = false,
    ...otherProps
  },
  ref,
) {
  return (
    <TableContainer
      title="รายการสินค้า"
      component={Container}
      rightTopMenu={
        <OrderOptions
          ref={ref}
          showPrintQueueOption
          printable={printable}
          onUpdateClick={onUpdateClick}
          onPrintQueueCardClick={onPrintQueueCardClick}
          showUpdateButton={showUpdateButton}
        />
      }
      footerPadding={false}
      {...otherProps}
    >
      <Table>
        <OrderItemTableHeader updateInProgress={updateInProgress} />
        <TableBody>
          {isEmpty(items) ? (
            <TableRow>
              <TableCell colSpan="5" align="center">
                ไม่พบรายการสินค้า
              </TableCell>
            </TableRow>
          ) : (
            items.map((item, idx) => (
              <OrderItemTableRow
                idx={idx}
                key={item.id}
                name={item.name}
                quantity={item.quantity}
                adjustedPrice={item.adjustedPrice}
                unitPrice={item.unitPrice}
                adjustment={item.adjustment}
                prices={item.prices}
                total={item.total}
                comment={item.comment}
                updateInProgress={updateInProgress}
                onChange={onChange}
                onCancel={onCancel}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default forwardRef(OrderItemTable);
