import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { SNACKBAR_VARIANT_TYPE } from '../../constants';
import Spinner from '../../components/Spinner';
import createCustomerReducer from './reducer';
import createCustomerSaga from './saga';
import {
  addDiscountItem,
  cancelUpdate,
  createCustomer,
  deleteDiscountItem,
  hideSuccessMessage,
  initCustomer,
  resetCustomer,
  setCustomer,
  setCustomerName,
  updateCustomer,
  updateCustomerEnabled,
  updateDiscountItemDiscount,
  updateDiscountItemProduct,
} from './actions';
import {
  makeSelectCustomer,
  makeSelectDisabled,
  makeSelectDiscountItems,
  makeSelectError,
  makeSelectLoading,
  makeSelectSavedCustomer,
  makeSelectShowSuccessMessage,
} from './selectors';
import CustomerPriceTable from './CustomerPriceTable';
import Paper from '../../components/Paper';
import CustomerForm from '../../components/CustomerForm';
import useHasPermission from '../../utils/useHasPermission';
import { CREATE_CUSTOMER_STORE_KEY, CUSTOMER_STORE_KEY } from './constants';
import { selectProducts } from '../App/selectors';

const createStateSelector = key =>
  createStructuredSelector({
    customer: makeSelectCustomer(key),
    savedCustomer: makeSelectSavedCustomer(key),
    products: selectProducts,
    discountItems: makeSelectDiscountItems(key),
    error: makeSelectError(key),
    loading: makeSelectLoading(key),
    disabled: makeSelectDisabled(key),
    showSuccessMessage: makeSelectShowSuccessMessage(key),
  });

function Customer() {
  const { customerId } = useParams();
  const key = customerId ? CUSTOMER_STORE_KEY : CREATE_CUSTOMER_STORE_KEY;

  useInjectReducer({ key, reducer: createCustomerReducer(key) });
  useInjectSaga({ key, saga: createCustomerSaga(key) });
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const hasCreateDebtPermission = useHasPermission(['Admin', 'Accounting']);
  const dispatch = useDispatch();
  const {
    customer,
    savedCustomer,
    products,
    discountItems,
    error,
    loading,
    disabled,
    showSuccessMessage,
  } = useSelector(createStateSelector(key));

  useEffect(() => {
    dispatch(initCustomer(key, customerId));
    return () => dispatch(resetCustomer(key));
  }, [customerId]);

  // TODO: Remove showSuccessMessage
  useEffect(() => {
    if (showSuccessMessage) {
      enqueueSnackbar('บันทึกข้อมูลลูกค้าสำเร็จ', {
        variant: SNACKBAR_VARIANT_TYPE.SUCCESS,
      });
      dispatch(hideSuccessMessage(key));
    }
  }, [dispatch, enqueueSnackbar, showSuccessMessage]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
    }
  }, [enqueueSnackbar, error]);

  return (
    <Container>
      <ValidatorForm
        onSubmit={() =>
          customer.id
            ? dispatch(updateCustomer(key))
            : dispatch(createCustomer(key))
        }
        debounceTime={500}
        noValidate
      >
        <Spinner loading={loading}>
          <Paper>
            <Box display="flex" alignItems="baseline">
              <Box mr={1}>
                <Typography variant="h6">ข้อมูลลูกค้า</Typography>
              </Box>
              {customerId && hasCreateDebtPermission && (
                <Button
                  color="primary"
                  onClick={() => history.push(`/createDebt/${customerId}`)}
                >
                  เพิ่มใบสั่งซื้อค้างชำระ
                </Button>
              )}
            </Box>
            <Box mt={2}>
              <CustomerForm
                customer={customer}
                savedName={savedCustomer.name}
                disabled={disabled}
                onChange={field => e =>
                  dispatch(setCustomer(key, field, e.target.value))}
                onNameChange={e =>
                  dispatch(setCustomerName(key, e.target.value))
                }
                onPhoneNumberChange={phoneNumber =>
                  dispatch(setCustomer(key, 'phoneNumber', phoneNumber))
                }
              />
            </Box>
          </Paper>
        </Spinner>
        <Box mt={3}>
          <CustomerPriceTable
            disabled={disabled}
            loading={loading}
            products={products}
            discountItems={discountItems}
            onAddDiscountItem={() => dispatch(addDiscountItem(key))}
            onDiscountItemChange={(idx, product) =>
              dispatch(updateDiscountItemProduct(key, idx, product))
            }
            onDiscountChange={(idx, discount) =>
              dispatch(updateDiscountItemDiscount(key, idx, discount))
            }
            onEnableUpdate={() => dispatch(updateCustomerEnabled(key))}
            onDelete={idx => dispatch(deleteDiscountItem(key, idx))}
            onCancelUpdate={() => dispatch(cancelUpdate(key))}
            onReset={
              key === CREATE_CUSTOMER_STORE_KEY
                ? () => dispatch(resetCustomer(key))
                : null
            }
          />
        </Box>
      </ValidatorForm>
    </Container>
  );
}

export default Customer;
