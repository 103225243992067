import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import withStyles from '@material-ui/core/styles/withStyles';
import { ORDER_TYPE_DESC, STATION_DESC } from '../../constants';

const StyledTextField = withStyles({
  root: {
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      fontSize: '1rem',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'black',
    },
  },
})(TextField);

function CreateOrderQueueCardHeader({ order }) {
  const {
    name,
    type,
    station,
    comment,
    createdAt,
    sequenceNumber,
    licensePlateNumber,
  } = order;

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Grid item xs={12}>
        <StyledTextField
          label="เลขที่ใบสั่งซื้อ"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={sequenceNumber || '-'}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="ชื่อลูกค้า"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={name || ''}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTextField
          label="ประเภท"
          margin="none"
          size="small"
          fullWidth
          disabled
          value={type ? ORDER_TYPE_DESC[type] : ''}
        />
      </Grid>
      {licensePlateNumber && (
        <Grid item xs={12}>
          <StyledTextField
            label="ทะเบียนรถยนต์"
            margin="none"
            size="small"
            fullWidth
            disabled
            value={licensePlateNumber}
          />
        </Grid>
      )}
      {station && (
        <Grid item xs={12}>
          <StyledTextField
            label="โรงอัดแก๊ส"
            margin="none"
            size="small"
            fullWidth
            disabled
            value={STATION_DESC[station]}
          />
        </Grid>
      )}
      {createdAt && (
        <Grid item xs={12}>
          <StyledTextField
            label="วันที่ขาย"
            margin="none"
            size="small"
            disabled
            fullWidth
            value={moment(createdAt)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm')}
          />
        </Grid>
      )}
      {comment && (
        <Grid item xs={12}>
          <StyledTextField
            label="หมายเหตุ"
            margin="none"
            size="small"
            fullWidth
            disabled
            value={comment}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default CreateOrderQueueCardHeader;
