import {
  ADD_IMAGES,
  CREATE_ORDER,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_SUCCESS,
  HIDE_ORDER_ITEM_DIALOG,
  HIDE_QUEUE_CARD,
  INIT_CREATE_DEBT,
  REMOVE_IMAGE,
  REMOVE_ORDER_ITEM,
  RESET_ORDER,
  SET_CUSTOMER,
  SET_CUSTOMER_NAME,
  SET_ORDER,
  SHOW_ORDER_ITEM_DIALOG,
  SHOW_QUEUE_CARD,
  TOGGLE_ORDER_ITEM,
  UPDATE_ORDER_ITEM_PRODUCT_ID,
  UPDATE_ORDER_ITEM_QUANTITY,
} from './constants';

export function setOrder(key, field, value) {
  return {
    key,
    type: SET_ORDER,
    payload: {
      field,
      value,
    },
  };
}

export function setCustomer(key, customerId, name) {
  return {
    key,
    type: SET_CUSTOMER,
    payload: {
      customerId,
      name,
    },
  };
}

export function setCustomerName(key, name) {
  return {
    key,
    type: SET_CUSTOMER_NAME,
    payload: { name },
  };
}

export function setShowOrderItemDialog(key) {
  return {
    key,
    type: SHOW_ORDER_ITEM_DIALOG,
  };
}

export function hideOrderItemDialog(key) {
  return {
    key,
    type: HIDE_ORDER_ITEM_DIALOG,
  };
}

export function toggleOrderItem(key, product) {
  return {
    key,
    type: TOGGLE_ORDER_ITEM,
    payload: { product },
  };
}

export function removeOrderItem(key, idx) {
  return {
    key,
    type: REMOVE_ORDER_ITEM,
    payload: { idx },
  };
}

export function updateOrderItemQuantity(key, idx, quantity) {
  return {
    key,
    type: UPDATE_ORDER_ITEM_QUANTITY,
    payload: { idx, quantity },
  };
}

export function updateOrderItemProductId(key, idx, productId) {
  return {
    key,
    type: UPDATE_ORDER_ITEM_PRODUCT_ID,
    payload: { idx, productId },
  };
}

export function resetOrder(key) {
  return {
    key,
    type: RESET_ORDER,
  };
}

export function createOrder(key) {
  return {
    key,
    type: CREATE_ORDER,
  };
}

export function createOrderSuccess(key, order) {
  return {
    key,
    type: CREATE_ORDER_SUCCESS,
    payload: { order },
  };
}

export function createOrderError(key) {
  return {
    key,
    type: CREATE_ORDER_ERROR,
  };
}

export function showQueueCard(key) {
  return {
    key,
    type: SHOW_QUEUE_CARD,
  };
}

export function hideQueueCard(key) {
  return {
    key,
    type: HIDE_QUEUE_CARD,
  };
}

export function getCustomer(key, customerId) {
  return {
    key,
    type: GET_CUSTOMER,
    payload: { customerId },
  };
}

export function getCustomerSuccess(key, customerId, name) {
  return {
    key,
    type: GET_CUSTOMER_SUCCESS,
    payload: {
      customerId,
      name,
    },
  };
}

export function getCustomerError(key) {
  return {
    key,
    type: GET_CUSTOMER_ERROR,
  };
}

export function initCreateDebt(key, customerId) {
  return {
    key,
    type: INIT_CREATE_DEBT,
    payload: { customerId },
  };
}

export function addImages(key, images) {
  return {
    key,
    type: ADD_IMAGES,
    payload: { images },
  };
}

export function removeImage(key, idx) {
  return {
    key,
    type: REMOVE_IMAGE,
    payload: { idx },
  };
}
