import React from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';
import get from 'lodash/get';
import TableCell from '../TruckAssignmentOrderTableCell';

const TableCellFooter = styled(TableCell)(({ theme }) => ({
  bottom: 0,
  zIndex: 1,
  position: 'sticky',
  borderTopWidth: 1,
  backgroundColor: theme.palette.background.default,
}));

const EmptyTableCellFooter = styled(TableCellFooter)({
  bottom: 0,
  position: 'sticky',
});

const FixedTableCellFooter = styled(TableCellFooter)({
  left: 0,
  bottom: 0,
  zIndex: 2,
  position: 'sticky',
});

function TruckAssignmentOrderTableFooter({
  totals,
  products,
  showSequenceNumber,
}) {
  return (
    <TableFooter>
      <TableRow>
        <EmptyTableCellFooter />
        {showSequenceNumber && <EmptyTableCellFooter />}
        <FixedTableCellFooter align="center" variant="footer" colSpan={1}>
          รวม
        </FixedTableCellFooter>
        {products.map(({ id: productId, weight }) => (
          <TableCellFooter
            key={productId}
            weight={weight}
            width="100"
            align="center"
            variant="footer"
          >
            {get(totals, productId, 0)}
          </TableCellFooter>
        ))}
      </TableRow>
    </TableFooter>
  );
}

export default TruckAssignmentOrderTableFooter;
