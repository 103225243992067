import _ from 'lodash';
import React, { useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import CreateOrderQueueTableRow from './CreateOrderQueueTableRow';
import { PRODUCT_TYPE } from '../../constants';
import CreateOrderQueueCardTableHeader from './CreateOrderQueueCardTableHeader';

function CreateOrderQueueCardTable({ orderItems }) {
  const formattedOrderItems = useMemo(() => {
    const lpgOrderItems = _.flow([
      _.partialRight(_.filter, ['type', PRODUCT_TYPE.LPG]),
      _.partialRight(_.groupBy, 'weight'),
      _.partialRight(_.map, items => {
        const { weight } = _.head(items);
        const quantity = _.reduce(
          items,
          (totalQuantity, orderItem) =>
            totalQuantity + Number(orderItem.quantity),
          0,
        );
        return {
          id: weight,
          name: `น้ำ ${weight} กก.`,
          quantity,
        };
      }),
      _.values,
    ])(orderItems);

    const nonLpgOrderItems = _.flow([
      _.partialRight(_.filter, ({ type }) => type !== PRODUCT_TYPE.LPG),
      _.partialRight(_.groupBy, 'productId'),
      _.partialRight(_.map, items => {
        const { productId, name } = _.head(items);
        const quantity = _.reduce(
          items,
          (totalQuantity, { quantity }) => totalQuantity + Number(quantity),
          0,
        );
        return {
          id: productId,
          name,
          quantity,
        };
      }),
      // _.values,
    ])(orderItems);
    return _.union(lpgOrderItems, nonLpgOrderItems);
  }, [orderItems]);

  return (
    <TableContainer>
      <Table size="small">
        <CreateOrderQueueCardTableHeader />
        <TableBody>
          {formattedOrderItems.map(({ id, name, quantity }, idx) => (
            <CreateOrderQueueTableRow
              key={id}
              idx={idx}
              name={name}
              quantity={quantity}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CreateOrderQueueCardTable;
