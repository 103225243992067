import TableCell from '../../components/TableCell';
import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

function FulfillmentReportSummaryTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={7}
          variant="head"
          align="center"
          backgroundColor="green"
        >
          <Typography variant="body1">
            <strong>สรุปจำนวนทั้งหมด (หน่วย)</strong>
          </Typography>
        </TableCell>
        <TableCell
          colSpan={7}
          variant="head"
          align="center"
          backgroundColor="lightBlue"
        >
          <Typography variant="body1">
            <strong>สรุปจำนวนบรรจุ (หน่วย)</strong>
          </Typography>
        </TableCell>
        <TableCell
          colSpan={7}
          variant="head"
          align="center"
          backgroundColor="yellow"
        >
          <Typography variant="body1">
            <strong>สรุปจำนวนนำ้หมุน (หน่วย)</strong>
          </Typography>
        </TableCell>
        <TableCell colSpan={3} variant="head" align="center">
          <Typography variant="body1">
            <strong>สรุปน้ำหนักขาย (กก.)</strong>
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head" align="center" backgroundColor="green">
          <strong>15</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <strong>48</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <strong>4</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <strong>7</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <strong>9</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <strong>11.5</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="green">
          <strong>13.5</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <strong>15</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <strong>48</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <strong>4</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <strong>7</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <strong>9</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <strong>11.5</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="lightBlue">
          <strong>13.5</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <strong>15</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <strong>48</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <strong>4</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <strong>7</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <strong>9</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <strong>11.5</strong>
        </TableCell>
        <TableCell variant="head" align="center" backgroundColor="yellow">
          <strong>13.5</strong>
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <strong>น้ำหนักรวม</strong>
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <strong>น้ำหนักบรรจุ</strong>
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <strong>น้ำหนักนำ้หมุน</strong>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default FulfillmentReportSummaryTableHeader;
