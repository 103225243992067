import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import MaterialTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';

const TableCell = styled(MaterialTableCell)({
  whiteSpace: 'nowrap',
});

const TableCellWithMinWidth = styled(TableCell)({
  whiteSpace: 'nowrap',
  minWidth: 250,
});

function CustomerPriceTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>#</TableCell>
        <TableCellWithMinWidth>สินค้า</TableCellWithMinWidth>
        <TableCell>ราคาสินค้า</TableCell>
        <TableCellWithMinWidth>ปรับราคาหน่วยละ (+/-)</TableCellWithMinWidth>
        <TableCell>ราคาสุทธิ</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

export default CustomerPriceTableHeader;
