import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import _ from 'lodash';
import moment from 'moment';
import { getOverdueOrdersError, getOverdueOrdersSuccess } from './actions';
import {
  GET_NEXT_OVERDUE_ORDERS,
  GET_OVERDUE_ORDERS,
  GET_PREVIOUS_OVERDUE_ORDERS,
  REFRESH_OVERDUE_ORDERS,
} from './constants';
import { makeSelectNextToken } from './selectors';
import { ORDER_STATUS, ORDER_TYPE } from '../../constants';

export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        status
        station
        customerId
        sequenceNumber
        licensePlateNumber
        createdAt
        customer {
          name
        }
        orderItems {
          items {
            quantity
            unitPrice
            adjustment
          }
        }
      }
      nextToken
    }
  }
`;

function* getOverdueOrders() {
  try {
    const nextToken = yield select(makeSelectNextToken);
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(searchOrders, {
        filter: {
          and: [
            { status: { eq: ORDER_STATUS.CREATED } },
            { type: { ne: ORDER_TYPE.TRUCK_SALES_ORDER } },
            { createdDate: { match: moment().format('YYYY-MM-DD') } },
          ],
        },
        sort: { direction: 'asc', field: 'createdAt' },
        nextToken,
      }),
    );

    const overdueOrders = result.data.searchOrders.items.map(order => {
      const {
        id,
        type,
        status,
        station,
        createdAt,
        customer,
        customerId,
        sequenceNumber,
        licensePlateNumber,
        orderItems,
      } = order;

      const subtotal = _.reduce(
        orderItems.items,
        (subtotal, { quantity, unitPrice, adjustment }) =>
          subtotal + (unitPrice + (adjustment || 0)) * quantity,
        0,
      );

      return {
        id,
        type,
        status,
        station,
        createdAt,
        customerId,
        sequenceNumber,
        licensePlateNumber,
        subtotal,
        customerName: customer.name,
      };
    });
    yield put(
      getOverdueOrdersSuccess(
        overdueOrders,
        result.data.searchOrders.nextToken,
      ),
    );
  } catch {
    yield put(getOverdueOrdersError());
  }
}

export default function* sage() {
  yield takeLatest(
    [
      GET_OVERDUE_ORDERS,
      REFRESH_OVERDUE_ORDERS,
      GET_NEXT_OVERDUE_ORDERS,
      GET_PREVIOUS_OVERDUE_ORDERS,
    ],
    getOverdueOrders,
  );
}
