import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Container from '@material-ui/core/Container';
import { ItemTileList, Paper, TokenPaginator } from '../../../shared';
import CustomerSearch from '../components/CustomerSearch';
import { SNACKBAR_VARIANT_TYPE } from '../../../constants';
import { listCustomers } from '../../../graphql/queries';
import CustomerTile from '../components/CustomerTile';

export default function CustomerList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [customers, setCustomers] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCustomers = async token => {
    try {
      setLoading(true);
      const result = await API.graphql(
        graphqlOperation(listCustomers, {
          limit: 30,
          nextToken: token,
        }),
      );

      // Set Orders
      setCustomers(result.data.listCustomers.items);

      // Set Token
      setTokens(prevTokens => [
        ...prevTokens,
        result.data.listCustomers.nextToken,
      ]);
    } catch {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Get customer
    (async () => {
      await getCustomers();
    })();
  }, []);

  const getNextPage = async () => {
    await getCustomers(tokens[tokens.length - 1]);
  };

  const getPreviousPage = async () => {
    const updatedTokens = tokens.slice(0, tokens.length - 2);
    const prevToken = updatedTokens[updatedTokens.length - 1];
    setTokens(updatedTokens);
    await getCustomers(prevToken);
  };

  const handleSelectCustomer = (event, option) => {
    history.push(`/customer/${option.id}`);
  };

  return (
    <Container>
      <Paper elevation={0}>
        <Box display="flex" justifyContent="space-between">
          {/* Title */}
          <Typography variant="h6">ค้นหาลูกค้า</Typography>

          {/* Pagination Icons */}
          <TokenPaginator
            tokens={tokens}
            handlePreviousPage={getPreviousPage}
            handleNextPage={getNextPage}
          />
        </Box>

        {/* Customer Search */}
        <Box mt={3}>
          <ValidatorForm onSubmit={() => {}} noValidate>
            <CustomerSearch
              placeholder="ค้นหาลูกค้า (โดยชื่อหรือเบอร์โทร)"
              onChange={handleSelectCustomer}
            />
          </ValidatorForm>
        </Box>

        {/* Customer List */}
        <Box mt={2}>
          <ItemTileList
            items={_.orderBy(customers, ['sequenceNumber'])}
            renderItem={item => <CustomerTile key={item.id} customer={item} />}
            loading={loading}
          />
        </Box>
      </Paper>
    </Container>
  );
}
