import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { selectNextToken, selectParams } from './selectors';
import {
  EXPORT_TRUCK_ASSIGNMENTS,
  EXPORT_TRUCK_ASSIGNMENTS_HEADER,
  REFRESH_TRUCK_ASSIGNMENTS,
  SEARCH_NEXT_TRUCK_ASSIGNMENTS,
  SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS,
  SEARCH_TRUCK_ASSIGNMENTS,
} from './constants';
import {
  exportTruckAssignmentsError,
  exportTruckAssignmentsSuccess,
  searchTruckAssignmentsError,
  searchTruckAssignmentsSuccess,
} from './actions';
import { SearchTruckAssignments as searchTruckAssignmentsGraphql } from './graphql';
import Papa from 'papaparse';
import FileDownload from 'js-file-download';
import { showErrorMessage, showWarningMessage } from '../App/actions';
import { getSubtotal } from '../../utils/orderUtils';

function createVariables(params, nextToken) {
  const { startDate, endDate, statuses, deliveryRoutes } = params;
  const filters: any[] = [];
  if (!_.isEmpty(deliveryRoutes)) {
    filters.push({
      or: deliveryRoutes.map(deliveryRoute => ({
        deliveryRouteId: { eq: deliveryRoute },
      })),
    });
  }
  if (!_.isEmpty(statuses)) {
    filters.push({
      or: statuses.map(status => ({ status: { eq: status } })),
    });
  }
  filters.push({
    createdAt: {
      range: [
        startDate || moment('19000101'),
        endDate || moment().endOf('day'),
      ],
    },
  });
  return {
    filter: { and: filters },
    sort: { direction: 'asc', field: 'createdAt' },
    nextToken,
  };
}

function createTruckAssignmentRow(truckAssignment) {
  const {
    id,
    sequenceNumber,
    deliveryRouteId,
    status,
    driver,
    companion,
    createdAt,
    orders,
    comment,
  } = truckAssignment;
  const subtotal = _.flow([
    _.partialRight(_.reject, ['_deleted', true]),
    _.partialRight(
      _.reduce,
      (subtotal, order) => subtotal + getSubtotal(order.orderItems.items),
      0,
    ),
  ])(orders.items);
  return {
    id,
    sequenceNumber,
    deliveryRouteId,
    status,
    driver,
    companion,
    createdAt,
    subtotal,
    comment,
  };
}

function createTruckAssignmentRows(truckAssignments) {
  return _.flow([
    _.compact,
    _.partialRight(_.reject, ['_deleted', true]),
    _.partialRight(_.map, order => createTruckAssignmentRow(order)),
  ])(truckAssignments);
}

function* getTruckAssignments(variables) {
  const result = yield call(
    [API, 'graphql'],
    graphqlOperation(searchTruckAssignmentsGraphql, variables),
  );
  const truckAssignments = createTruckAssignmentRows(
    result.data.searchTruckAssignments.items,
  );
  const nextToken = result.data.searchTruckAssignments.nextToken;
  return [truckAssignments, nextToken];
}

function* searchTruckAssignments() {
  try {
    const params = yield select(selectParams);
    const initNextToken = yield select(selectNextToken);
    const variables = createVariables(params, initNextToken);
    const [truckAssignments, nextToken] = yield call(
      getTruckAssignments,
      variables,
    );
    yield put(searchTruckAssignmentsSuccess(truckAssignments, nextToken));
    if (!truckAssignments.length) {
      yield put(showWarningMessage('ไม่พบข้อมูล'));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield all([put(showErrorMessage()), put(searchTruckAssignmentsError())]);
  }
}

function* exportTruckAssignments(action) {
  try {
    const { nextToken } = action.payload;
    const params = yield select(selectParams);
    const result = yield call(
      // @ts-ignore
      [API, 'post'],
      'export',
      '/export/truckAssignments',
      {
        response: true,
        body: { ...params, nextToken },
      },
    );
    const csv = Papa.unparse(
      {
        fields: EXPORT_TRUCK_ASSIGNMENTS_HEADER,
        data: result.data.data.rows,
      },
      { quotes: true, encoding: 'UTF-8' },
    );
    yield call(
      FileDownload,
      csv,
      'รถเร่.csv',
      'text/csv;charset=utf-8',
      '\uFEFF',
    );
    yield put(exportTruckAssignmentsSuccess(result.data.data.nextToken));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield all([put(showErrorMessage()), put(exportTruckAssignmentsError())]);
  }
}

export default function* sage() {
  yield takeLatest(
    [
      SEARCH_TRUCK_ASSIGNMENTS,
      SEARCH_NEXT_TRUCK_ASSIGNMENTS,
      SEARCH_PREVIOUS_TRUCK_ASSIGNMENTS,
      REFRESH_TRUCK_ASSIGNMENTS,
    ],
    searchTruckAssignments,
  );
  yield takeLatest(EXPORT_TRUCK_ASSIGNMENTS, exportTruckAssignments);
}
