import React from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import { TableCell } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';

function ReportTableFooter({ summary }) {
  const {
    sales,
    ownedAmount,
    totalAdjustment,
    salesOrderCash,
    deliveryOrderCash,
    truckSalesOrderCash,
    otherCompanyCustomerOrderCash,
    salesOrderWeightSold,
    deliveryOrderWeightSold,
    truckSalesOrderWeightSold,
    otherCompanyCustomerOrderWeightSold,
    w40Count,
    w70Count,
    w90Count,
    w115Count,
    w135Count,
    w150Count,
    w480Count,
  } = summary;

  const renderText = val => (
    <Typography variant="subtitle2">
      <b>{val}</b>
    </Typography>
  );

  return (
    <TableFooter>
      <TableRow>
        <TableCell align="center" colSpan={7}>
          <Typography variant="subtitle2">
            <strong>รวม</strong>
          </Typography>
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w150Count} renderText={renderText} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w480Count} renderText={renderText} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w40Count} renderText={renderText} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w70Count} renderText={renderText} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w90Count} renderText={renderText} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w115Count} renderText={renderText} />
        </TableCell>
        <TableCell align="center">
          <CommaSeparatedNumber value={w135Count} renderText={renderText} />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            fixedDecimalScale
            renderText={renderText}
            value={salesOrderWeightSold}
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            fixedDecimalScale
            renderText={renderText}
            value={
              deliveryOrderWeightSold +
              truckSalesOrderWeightSold +
              otherCompanyCustomerOrderWeightSold
            }
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            fixedDecimalScale
            renderText={renderText}
            value={sales}
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            fixedDecimalScale
            renderText={renderText}
            value={totalAdjustment}
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            fixedDecimalScale
            renderText={renderText}
            value={salesOrderCash}
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            fixedDecimalScale
            renderText={renderText}
            value={
              deliveryOrderCash +
              truckSalesOrderCash +
              otherCompanyCustomerOrderCash
            }
          />
        </TableCell>
        <TableCell align="right">
          <CommaSeparatedNumber
            fixedDecimalScale
            renderText={renderText}
            value={ownedAmount}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default ReportTableFooter;
