import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import orderBy from 'lodash/orderBy';
import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
import Tooltip from '@material-ui/core/Tooltip';
import { PRICE_TYPE } from '../../constants';

/* eslint-disable no-shadow */
function PriceTooltip({ price, unitPrice, adjustment, prices, comment }) {
  const renderPrice = ({ id, type, price, conditionValue }) => {
    switch (type) {
      case PRICE_TYPE.WHOLESALE:
        return (
          <Typography key={id} variant="caption">
            {`ราคาขายส่ง (${conditionValue} หน่วยขึ้นไป): ${price} บาท (หน่วยละ)`}
          </Typography>
        );
      case PRICE_TYPE.SPECIAL:
        return (
          <Typography key={id} variant="caption">
            {`ราคาพิเศษ: ${price} บาท`}
          </Typography>
        );
      case PRICE_TYPE.DEFAULT:
      default:
        return (
          <Typography key={id} variant="caption">
            {`ราคาขายปลีก: ${price} บาท`}
          </Typography>
        );
    }
  };

  const priceText = `${price}${
    adjustment !== 0
      ? ` (${unitPrice} ${
          adjustment > 0
            ? `+ ${Math.abs(adjustment)}`
            : `- ${Math.abs(adjustment)}`
        })`
      : ''
  }`;

  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: `ราคาสุทธิ: <u>${priceText}</u> บาท`,
            }}
          />
          {orderBy(prices, ['price']).map(price => renderPrice(price))}
          {comment && (
            <Typography variant="caption">
              <u>{comment}</u>
            </Typography>
          )}
        </Box>
      }
    >
      <Box display="flex" alignItems="center" ml={1}>
        <MonetizationOnSharpIcon color="disabled" fontSize="small" />
      </Box>
    </Tooltip>
  );
}

export default PriceTooltip;
