import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Box from '@material-ui/core/Box';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import IconButton from '@material-ui/core/IconButton';
import CommaSeparatedNumber from '../CommaSeparatedNumber';

function AdjustmentRow({
  adjustment,
  onRemove,
  disabled = false,
  showComment = false,
  showRemoveButton = true,
}) {
  const { amount, comment } = adjustment;
  return (
    <TableRow>
      <TableCell align="left">
        <Box display="flex" alignItems="center">
          <Box mr={0.5}>{amount > 0 ? 'ค่าใช้เพิ่มเติม' : 'ส่วนลด'}</Box>
          {!showComment && (
            <Tooltip
              title={
                <Typography variant="caption">
                  {`หมายเหตุ: ${comment}`}
                </Typography>
              }
            >
              <HelpIcon color="disabled" fontSize="small" />
            </Tooltip>
          )}
        </Box>
        {showComment && (
          <Typography variant="caption">{`หมายเหตุ: ${comment}`}</Typography>
        )}
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Box mr={0.5}>
            <CommaSeparatedNumber
              value={amount}
              fixedDecimalScale
              renderText={val => val}
            />
          </Box>
          {showRemoveButton && (
            <IconButton
              size="small"
              edge="end"
              color="secondary"
              disabled={disabled}
              onClick={onRemove}
            >
              <RemoveCircleIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default AdjustmentRow;
