import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import MaterialLink from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import { PRICE_TYPE } from '../../../constants';

/* eslint-disable no-shadow */
export default function ProductTile({ product }) {
  const { id, name, weight, prices } = product;
  const getPrices = type => prices.items.filter(price => price.type === type);
  const defaultPrices = getPrices(PRICE_TYPE.DEFAULT);
  const specialPrices = getPrices(PRICE_TYPE.SPECIAL);
  const wholeSalePrices = getPrices(PRICE_TYPE.WHOLESALE);

  const renderChip = (key, label, val, unit) => (
    <Box key={key} mr={1} mt={1}>
      <Chip label={`${label}: ${val} ${unit}`} color="primary" size="small" />
    </Box>
  );

  const renderDefaultPriceChips = () =>
    defaultPrices.map(({ id, price }) =>
      renderChip(id, 'ราคาขายปลีก', price, 'บาท'),
    );

  const renderSpecialPriceChips = () =>
    specialPrices.map(({ id, price }) =>
      renderChip(id, 'ราคาพิเศษสำหรับสมาชิก', price, 'บาท'),
    );

  const renderWholesalePriceChips = () =>
    wholeSalePrices.map(({ id, price, conditionValue }) =>
      renderChip(
        id,
        `ราคาขายส่ง (เมื่อซื้อ ${conditionValue} หน่วยชึ้นไป)`,
        price,
        'บาท (หน่วยละ)',
      ),
    );

  return (
    <Box mt={2}>
      <Accordion variant="outlined" defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box width="100%" display="flex" alignItems="center">
            {/* Product Name */}
            <Typography>{name}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexWrap="wrap">
            {/* Weight */}
            {weight && renderChip(uuid(), 'น้ำหนัก', weight, 'กก.')}

            {/* Default Price */}
            {defaultPrices.length > 0 && renderDefaultPriceChips()}

            {/* Default Price */}
            {specialPrices.length > 0 && renderSpecialPriceChips()}

            {/* Whole Sale Price */}
            {wholeSalePrices.length > 0 && renderWholesalePriceChips()}
          </Box>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <MaterialLink
            component={Link}
            color="textPrimary"
            underline="none"
            to={`/product/${id}`}
          >
            <Button size="medium" color="primary">
              แก้ไข
            </Button>
          </MaterialLink>
        </AccordionActions>
      </Accordion>
    </Box>
  );
}
