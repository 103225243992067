import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const selectOverdueTruckAssignmentList = state =>
  state.overdueTruckAssignmentList;

export const makeSelectOverdueTruckAssignments = createSelector(
  selectOverdueTruckAssignmentList,
  ({ overdueTruckAssignments }) => overdueTruckAssignments,
);

export const makeSelectShowNext = createSelector(
  selectOverdueTruckAssignmentList,
  ({ showNext }) => showNext,
);

export const makeSelectShowPrevious = createSelector(
  selectOverdueTruckAssignmentList,
  ({ showPrevious }) => showPrevious,
);

export const makeSelectNextToken = createSelector(
  selectOverdueTruckAssignmentList,
  ({ tokens }) => (!isEmpty(tokens) ? tokens[tokens.length - 1] : null),
);

export const makeSelectLoading = createSelector(
  selectOverdueTruckAssignmentList,
  ({ loading }) => loading,
);

export const makeSelectError = createSelector(
  selectOverdueTruckAssignmentList,
  ({ error }) => error,
);
