import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectValidator } from 'react-material-ui-form-validator';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import { STATION, STATION_DESC } from '../../constants';

const useStyles = makeStyles({
  select: {
    padding: '16px 14px',
  },
});

function StationSelectValidator({ stations, onChange, disabled = false }) {
  const classes = useStyles();
  return (
    <SelectValidator
      fullWidth
      margin="none"
      label="โรงอัดแก๊ส"
      variant="outlined"
      disabled={disabled}
      SelectProps={{
        multiple: true,
        value: stations,
        classes: {
          select: clsx(!_.isEmpty(stations) && classes.select),
        },
        onChange,
        renderValue: stations =>
          stations.map(station => (
            <Box key={station} display="inline" mr={1}>
              <Chip
                size="small"
                color="primary"
                label={STATION_DESC[station]}
              />
            </Box>
          )),
      }}
    >
      <MenuItem value="" disabled>
        กรุณาเลือก
      </MenuItem>
      <MenuItem
        key={STATION.UNIQUE_GAS_STATION}
        value={STATION.UNIQUE_GAS_STATION}
      >
        <Checkbox
          color="primary"
          checked={stations.includes(STATION.UNIQUE_GAS_STATION)}
        />
        <ListItemText primary={STATION_DESC.UNIQUE_GAS_STATION} />
      </MenuItem>
      <MenuItem
        key={STATION.WORLD_GAS_STATION}
        value={STATION.WORLD_GAS_STATION}
      >
        <Checkbox
          color="primary"
          checked={stations.includes(STATION.WORLD_GAS_STATION)}
        />
        <ListItemText primary={STATION_DESC.WORLD_GAS_STATION} />
      </MenuItem>
      <MenuItem key={STATION.PTT_STATION} value={STATION.PTT_STATION}>
        <Checkbox
          color="primary"
          checked={stations.includes(STATION.PTT_STATION)}
        />
        <ListItemText primary={STATION_DESC.PTT_STATION} />
      </MenuItem>
    </SelectValidator>
  );
}

export default StationSelectValidator;
