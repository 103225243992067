import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';

function OrderItemDialogHeader({ onClose }) {
  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box mr={2}>
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box flex={1}>
          <Typography variant="h6">เพิ่มรายการสินค้า</Typography>
        </Box>
        <Button color="inherit" onClick={onClose}>
          <Typography variant="subtitle1">บันทึก</Typography>
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default OrderItemDialogHeader;
