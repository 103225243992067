export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
    }
  }
`;

export const getBrand = /* GraphQL */ `
  query GetBrand(
    $id: ID!
    $priceLimit: Int
    $productLimit: Int
    $filter: ModelPriceFilterInput
  ) {
    getBrand(id: $id) {
      id
      name
      products(limit: $productLimit) {
        items {
          id
          name
          weight
          type
          brandId
          prices(limit: $priceLimit, filter: $filter) {
            items {
              id
              type
              price
              productId
              conditionDesc
              conditionValue
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!, $limit: Int, $filter: ModelPriceFilterInput) {
    getProduct(id: $id) {
      id
      name
      weight
      type
      brandId
      isTruckAssignmentProduct
      prices(filter: $filter, limit: $limit) {
        items {
          id
          type
          price
          productId
          conditionDesc
          conditionValue
          createdAt
          updatedAt
          _version
          _deleted
        }
        nextToken
      }
      brand {
        id
        name
      }
      createdAt
      updatedAt
      _version
    }
  }
`;
