import produce from 'immer';
import _ from 'lodash';
import {
  CANCEL_ORDER,
  CANCEL_ORDER_ERROR,
  CANCEL_ORDER_ITEM,
  CANCEL_ORDER_ITEM_ERROR,
  CANCEL_ORDER_ITEM_SUCCESS,
  CANCEL_ORDER_SUCCESS,
  CREATE_ADJUSTMENT,
  CREATE_ADJUSTMENT_ERROR,
  CREATE_ADJUSTMENT_SUCCESS,
  CREATE_PAYMENT,
  CREATE_PAYMENT_ERROR,
  CREATE_PAYMENT_SUCCESS,
  DELETE_ADJUSTMENT,
  DELETE_ADJUSTMENT_ERROR,
  DELETE_ADJUSTMENT_SUCCESS,
  DISABLE_UPDATE_ORDER_HEADER_IN_PROGRESS,
  DISABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS,
  ENABLE_UPDATE_ORDER_HEADER_IN_PROGRESS,
  ENABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS,
  GET_ORDER,
  GET_ORDER_ERROR,
  GET_ORDER_SUCCESS,
  HIDE_ADJUSTMENT_DIALOG,
  HIDE_DELETE_ADJUSTMENT_DIALOG,
  HIDE_LIGHT_BOX,
  RESET_ORDER,
  SET_NEW_AMOUNT,
  SET_PAYMENT_TYPE,
  SET_UPDATED_ORDER,
  SET_UPDATED_ORDER_ITEM,
  SHOW_ADJUSTMENT_DIALOG,
  SHOW_DELETE_ADJUSTMENT_DIALOG,
  SHOW_LIGHT_BOX,
  UPDATE_ORDER_HEADER,
  UPDATE_ORDER_HEADER_ERROR,
  UPDATE_ORDER_HEADER_SUCCESS,
  UPDATE_ORDER_ITEMS,
  UPDATE_ORDER_ITEMS_ERROR,
  UPDATE_ORDER_ITEMS_SUCCESS,
} from './constants';
import { ORDER_STATUS, ORDER_TYPE } from '../../constants';

export const initialState = {
  orderHeader: {
    id: '',
    type: '',
    customerId: '',
    name: '',
    sequenceNumber: '',
    licensePlateNumber: '',
    station: '',
    comment: '',
    createdBy: '',
    createdAt: '',
    truckAssignmentId: '',
  },
  updatedOrderHeader: {
    id: '',
    type: '',
    customerId: '',
    sequenceNumber: '',
    name: '',
    station: '',
    comment: '',
    createdBy: '',
    createdAt: '',
    licensePlateNumber: '',
  },
  orderItems: [],
  updatedOrderItems: [],
  payment: {
    newAmount: '',
    paymentType: '',
  },
  payments: [],
  adjustments: [],
  images: [],
  initImageIdx: 0,
  loading: false,
  disabled: false,
  disabledUpdateOrderItems: false,
  updateOrderHeaderInProgress: false,
  updateOrderItemsInProgress: false,
  showLightbox: false,
  showAdjustmentDialog: false,
  adjustmentId: '',
  showDeleteAdjustmentDialog: false,
};

/* eslint-disable default-case, no-param-reassign,  consistent-return */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_ORDER:
        draft.orderHeader.id = action.payload.orderId;
        draft.loading = true;
        break;
      case CANCEL_ORDER:
      case CANCEL_ORDER_ITEM:
      case UPDATE_ORDER_HEADER:
      case UPDATE_ORDER_ITEMS:
        draft.loading = true;
        break;
      case GET_ORDER_SUCCESS: {
        const { type, status } = action.payload.orderHeader;
        draft.orderHeader = action.payload.orderHeader;
        draft.orderItems = action.payload.orderItems;
        draft.payments = action.payload.payments;
        draft.adjustments = action.payload.adjustments;
        draft.images = action.payload.images;
        draft.disabled =
          type === ORDER_TYPE.TRUCK_SALES_ORDER ||
          status === ORDER_STATUS.CANCELED ||
          status === ORDER_STATUS.COMPLETED;
        draft.disabledUpdateOrderItems =
          status === ORDER_STATUS.CANCELED || status === ORDER_STATUS.COMPLETED;
        draft.loading = false;
        break;
      }
      case UPDATE_ORDER_HEADER_SUCCESS:
        draft.orderHeader = action.payload.orderHeader;
        draft.loading = false;
        draft.updateOrderHeaderInProgress = false;
        break;
      case UPDATE_ORDER_ITEMS_SUCCESS:
        draft.orderItems = action.payload.orderItems;
        draft.updatedOrderItems = [];
        draft.loading = false;
        draft.updateOrderItemsInProgress = false;
        break;
      case CANCEL_ORDER_SUCCESS:
        draft.orderHeader = action.payload.orderHeader;
        draft.loading = false;
        draft.disabled = true;
        draft.disabledUpdateOrderItems = true;
        draft.updateOrderHeaderInProgress = false;
        break;
      case CANCEL_ORDER_ITEM_SUCCESS:
        _.pullAt(draft.orderItems, action.payload.idx);
        _.pullAt(draft.updatedOrderItems, action.payload.idx);
        draft.loading = false;
        break;
      case GET_ORDER_ERROR:
      case CANCEL_ORDER_ERROR:
      case DELETE_ADJUSTMENT_ERROR:
      case CREATE_ADJUSTMENT_ERROR:
      case CANCEL_ORDER_ITEM_ERROR:
      case UPDATE_ORDER_ITEMS_ERROR:
      case UPDATE_ORDER_HEADER_ERROR:
        draft.loading = false;
        break;
      case SET_NEW_AMOUNT:
        draft.payment.newAmount = action.payload.newAmount;
        break;
      case SET_PAYMENT_TYPE:
        draft.payment.paymentType = action.payload.paymentType;
        break;
      case CREATE_PAYMENT:
        draft.loading = true;
        break;
      case CREATE_PAYMENT_SUCCESS:
        draft.loading = false;
        draft.payment.newAmount = '';
        draft.payment.paymentType = '';
        draft.payments = action.payload.payments;
        draft.orderHeader.status = action.payload.status;
        draft.disabled = action.payload.status === ORDER_STATUS.COMPLETED;
        draft.disabledUpdateOrderItems =
          action.payload.status === ORDER_STATUS.COMPLETED;
        return;
      case CREATE_PAYMENT_ERROR:
        draft.loading = false;
        draft.disabled = false;
        draft.disabledUpdateOrderItems = false;
        break;
      case SET_UPDATED_ORDER: {
        const { param, value } = action.payload;
        draft.updatedOrderHeader[param] = value;
        break;
      }
      case SET_UPDATED_ORDER_ITEM:
        draft.updatedOrderItems[action.payload.idx][action.payload.param] =
          action.payload.value;
        break;
      case ENABLE_UPDATE_ORDER_HEADER_IN_PROGRESS:
        draft.updateOrderHeaderInProgress = true;
        draft.updatedOrderHeader = { ...draft.orderHeader };
        break;
      case DISABLE_UPDATE_ORDER_HEADER_IN_PROGRESS:
        draft.updateOrderHeaderInProgress = false;
        draft.updatedOrderHeader.id = null;
        draft.updatedOrderHeader.type = null;
        draft.updatedOrderHeader.customerId = '';
        draft.updatedOrderHeader.customerType = '';
        draft.updatedOrderHeader.name = '';
        draft.updatedOrderHeader.sequenceNumber = '';
        draft.updatedOrderHeader.licensePlateNumber = '';
        draft.updatedOrderHeader.station = '';
        draft.updatedOrderHeader.comment = '';
        draft.updatedOrderHeader.createdBy = '';
        draft.updatedOrderHeader.createdAt = '';
        draft.updatedOrderHeader.version = null;
        break;
      case ENABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS:
        draft.updateOrderItemsInProgress = true;
        draft.updatedOrderItems = draft.orderItems;
        break;
      case DISABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS:
        draft.updateOrderItemsInProgress = false;
        draft.updatedOrderItems = [];
        break;
      case SHOW_LIGHT_BOX:
        draft.showLightbox = true;
        draft.initImageIdx = action.payload.initImageIdx;
        break;
      case HIDE_LIGHT_BOX:
        draft.showLightbox = false;
        break;
      case SHOW_ADJUSTMENT_DIALOG:
        draft.showAdjustmentDialog = true;
        break;
      case HIDE_ADJUSTMENT_DIALOG:
        draft.showAdjustmentDialog = false;
        break;
      case SHOW_DELETE_ADJUSTMENT_DIALOG:
        draft.adjustmentId = action.payload.id;
        draft.showDeleteAdjustmentDialog = true;
        break;
      case HIDE_DELETE_ADJUSTMENT_DIALOG:
        draft.showDeleteAdjustmentDialog = false;
        break;
      case CREATE_ADJUSTMENT:
        draft.loading = true;
        draft.showAdjustmentDialog = false;
        break;
      case CREATE_ADJUSTMENT_SUCCESS: {
        draft.adjustments.push(action.payload.adjustment);
        draft.loading = false;
        break;
      }
      case DELETE_ADJUSTMENT:
        draft.loading = true;
        draft.showDeleteAdjustmentDialog = false;
        break;
      case DELETE_ADJUSTMENT_SUCCESS:
        _.remove(draft.adjustments, ['id', action.payload.removedAdjustmentId]);
        draft.adjustmentId = '';
        draft.loading = false;
        break;
      case RESET_ORDER:
        return initialState;
    }
  });

export default reducer;
