export const GET_OVERDUE_TRUCK_ASSIGNMENTS =
  'OverdueTruckAssignmentList/GET_OVERDUE_TRUCK_ASSIGNMENTS';
export const GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS =
  'OverdueTruckAssignmentList/GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS';
export const GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS =
  'OverdueTruckAssignmentList/GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS';
export const GET_OVERDUE_TRUCK_ASSIGNMENTS_SUCCESS =
  'OverdueTruckAssignmentList/GET_OVERDUE_TRUCK_ASSIGNMENTS_SUCCESS';
export const GET_OVERDUE_TRUCK_ASSIGNMENTS_ERROR =
  'OverdueTruckAssignmentList/GET_OVERDUE_TRUCK_ASSIGNMENTS_ERROR';
export const REFRESH_OVERDUE_TRUCK_ASSIGNMENTS =
  'OverdueTruckAssignmentList/REFRESH_OVERDUE_TRUCK_ASSIGNMENTS';
