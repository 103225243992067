export const CREATE_ORDER_STORE_KEY = 'createOrder';
export const CREATE_DEBT_STORE_KEY = 'createDebt';
export const SET_ORDER = 'CreateOrder/SET_ORDER';
export const SET_CUSTOMER = 'CreateOrder/SET_CUSTOMER';
export const SET_CUSTOMER_NAME = 'CreateOrder/SET_CUSTOMER_NAME';
export const SHOW_ORDER_ITEM_DIALOG = 'CreateOrder/SHOW_ORDER_ITEM_DIALOG';
export const HIDE_ORDER_ITEM_DIALOG = 'CreateOrder/HIDE_ORDER_ITEM_DIALOG';
export const TOGGLE_ORDER_ITEM = 'CreateOrder/TOGGLE_ORDER_ITEM';
export const REMOVE_ORDER_ITEM = 'CreateOrder/REMOVE_ORDER_ITEM';
export const UPDATE_ORDER_ITEM_QUANTITY =
  'CreateOrder/UPDATE_ORDER_ITEM_QUANTITY';
export const UPDATE_ORDER_ITEM_PRODUCT_ID =
  'CreateOrder/UPDATE_ORDER_ITEM_PRODUCT_ID';
export const RESET_ORDER = 'CreateOrder/RESET_ORDER';
export const CREATE_ORDER = 'CreateOrder/CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'CreateOrder/CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_ERROR = 'CreateOrder/CREATE_ORDER_ERROR';
export const SHOW_QUEUE_CARD = 'CreateOrder/SHOW_QUEUE_CARD';
export const HIDE_QUEUE_CARD = 'CreateOrder/HIDE_QUEUE_CARD';
export const GET_CUSTOMER = 'CreateOrder/GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'CreateOrder/GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'CreateOrder/GET_CUSTOMER_ERROR';
export const INIT_CREATE_DEBT = 'CreateOrder/INIT_CREATE_DEBT';
export const ADD_IMAGES = 'CreateOrder/ADD_IMAGES';
export const REMOVE_IMAGE = 'CreateOrder/REMOVE_IMAGE';
