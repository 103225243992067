import React from 'react';
import moment from 'moment-timezone';
import TableRow from '@material-ui/core/TableRow';
import { PAYMENT_TYPE_DESC } from '../../constants';
import { CommaSeparatedNumber } from '../../shared';
import TableCell from '../../components/TableCell';

function PaymentHistoryTableRow({ idx, payment }) {
  const { id, owner, type, amount, createdAt } = payment;
  return (
    <TableRow key={id}>
      <TableCell align="right" padding="none">
        {idx + 1}
      </TableCell>
      <TableCell align="left">{owner}</TableCell>
      <TableCell align="left">
        {moment(createdAt).format('DD/MM/YYYY HH:mm')}
      </TableCell>
      <TableCell align="left">{type ? PAYMENT_TYPE_DESC[type] : '-'}</TableCell>
      <TableCell align="right">
        <CommaSeparatedNumber value={amount} fixedDecimalScale />
      </TableCell>
    </TableRow>
  );
}

export default PaymentHistoryTableRow;
