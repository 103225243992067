import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Container from '@mui/material/Container';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import ReportTable from './ReportTable';
import ReportForm from './ReportForm';
import reducer from './reducer';
import saga from './saga';
import {
  getReports,
  setDate,
  setOrderTypes,
  setStation,
  toggleShowTruckAssignment,
} from './actions';
import {
  makeSelectDate,
  makeSelectLoading,
  makeSelectOrderTypes,
  makeSelectReports,
  makeSelectRows,
  makeSelectSelectedSummary,
  makeSelectShowTruckAssignment,
  makeSelectStation,
  makeSelectSummary,
} from './selectors';
import ReportSummaryTable from './ReportSummaryTable';
import { STATION, STATION_DESC } from '../../constants';
import ReportAllSummariesAccordion from './ReportAllSummariesAccordion';
import ReportAllOrdersCsvLink from './ReportAllOrdersCsvLink';
import useHasPermission from '../../utils/useHasPermission';

const key = 'report';

const stateSelector = createStructuredSelector({
  date: makeSelectDate(),
  filteredRows: makeSelectRows(),
  allRows: makeSelectRows(true),
  station: makeSelectStation(),
  loading: makeSelectLoading(),
  reports: makeSelectReports(),
  orderTypes: makeSelectOrderTypes(),
  summary: makeSelectSummary('ALL'),
  uniqueGasSummary: makeSelectSummary(STATION.UNIQUE_GAS_STATION),
  worldGasSummary: makeSelectSummary(STATION.WORLD_GAS_STATION),
  pttSummary: makeSelectSummary(STATION.PTT_STATION),
  selectedSummary: makeSelectSelectedSummary(),
  showTruckAssignment: makeSelectShowTruckAssignment(),
});

function Report() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const printRef = useRef();
  const {
    allRows,
    filteredRows,
    date,
    loading,
    station,
    orderTypes,
    reports,
    summary,
    uniqueGasSummary,
    worldGasSummary,
    pttSummary,
    selectedSummary,
    showTruckAssignment,
  } = useSelector(stateSelector);
  const showSummary = useHasPermission(['Admin', 'Accounting']);

  useEffect(() => {
    dispatch(getReports());
  }, []);

  return (
    <Container maxWidth="xl">
      {showSummary && (
        <>
          <ReportSummaryTable
            loading={loading}
            defaultExpanded
            summary={summary}
            component={({ children }) => (
              <ReportAllSummariesAccordion
                date={date}
                reports={reports}
                summary={summary}
                onRefresh={() => dispatch(getReports())}
                onDateChange={date => dispatch(setDate(date))}
              >
                {children}
              </ReportAllSummariesAccordion>
            )}
          />
          <ReportSummaryTable
            loading={loading}
            summary={uniqueGasSummary}
            title={`สรุปรายงาน${STATION_DESC.UNIQUE_GAS_STATION}`}
          />
          <ReportSummaryTable
            loading={loading}
            summary={worldGasSummary}
            title={`สรุปรายงาน${STATION_DESC.WORLD_GAS_STATION}`}
          />
          <ReportSummaryTable
            loading={loading}
            summary={pttSummary}
            title={`สรุปรายงาน${STATION_DESC.PTT_STATION}`}
          />
        </>
      )}

      <Box mt={showSummary ? 3 : 0}>
        <ReportTable
          rows={filteredRows}
          loading={loading}
          summary={selectedSummary}
          showSummary={showSummary}
          component={({ children }) => (
            <Paper variant="outlined">
              <Box p={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography variant="subtitle1">ใบสั่งซื้อทั้งหมด</Typography>
                  <ReportAllOrdersCsvLink date={date} rows={allRows} />
                  <IconButton
                    size="small"
                    onClick={() => dispatch(getReports())}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Box>
                <Box mt={2}>
                  <ReportForm
                    station={station}
                    printRef={printRef}
                    orderTypes={orderTypes}
                    showTruckAssignment={showTruckAssignment}
                    onStationChange={e => dispatch(setStation(e.target.value))}
                    onOrderTypesChange={e =>
                      dispatch(setOrderTypes(e.target.value))
                    }
                    onShowTruckAssignmentChange={() =>
                      dispatch(toggleShowTruckAssignment())
                    }
                  />
                </Box>
              </Box>
              <Divider />
              {children}
            </Paper>
          )}
        />
      </Box>
    </Container>
  );
}

export default Report;
