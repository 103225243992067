// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CustomerType = {
  "MEMBER": "MEMBER",
  "GUEST": "GUEST",
  "OTHER_COMPANY_CUSTOMER": "OTHER_COMPANY_CUSTOMER"
};

const ProductType = {
  "LPG": "LPG",
  "CONTAINER": "CONTAINER",
  "SERVICE": "SERVICE"
};

const PriceType = {
  "DEFAULT": "DEFAULT",
  "SPECIAL": "SPECIAL",
  "WHOLESALE": "WHOLESALE"
};

const OrderStatus = {
  "CREATED": "CREATED",
  "OVERDUE": "OVERDUE",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const PaymentStatus = {
  "PENDING": "PENDING",
  "OVERDUE": "OVERDUE",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const FulfillmentStatus = {
  "PENDING": "PENDING",
  "PARTIALLY_FULFILLED": "PARTIALLY_FULFILLED",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const OrderType = {
  "SALES_ORDER": "SALES_ORDER",
  "TRUCK_SALES_ORDER": "TRUCK_SALES_ORDER",
  "DELIVERY_ORDER": "DELIVERY_ORDER",
  "OTHER_COMPANY_CUSTOMER_ORDER": "OTHER_COMPANY_CUSTOMER_ORDER"
};

const Station = {
  "UNIQUE_GAS_STATION": "UNIQUE_GAS_STATION",
  "WORLD_GAS_STATION": "WORLD_GAS_STATION",
  "PTT_STATION": "PTT_STATION"
};

const PaymentType = {
  "CASH": "CASH",
  "CHEQUE": "CHEQUE",
  "TRANSFER": "TRANSFER"
};

const WorkflowItemType = {
  "FUELED": "FUELED",
  "SWAPPED": "SWAPPED"
};

const TruckAssignmentStatus = {
  "CREATED": "CREATED",
  "OVERDUE": "OVERDUE",
  "COMPLETED": "COMPLETED",
  "CANCELED": "CANCELED"
};

const UserGroup = {
  "ACCOUNTING": "ACCOUNTING",
  "ADMIN": "ADMIN",
  "CASHIER": "CASHIER",
  "ORDER": "ORDER",
  "WAREHOUSE": "WAREHOUSE"
};

const { Customer, Brand, Product, Price, Discount, Order, OrderItem, OrderAdjustment, Payment, Workflow, WorkflowItem, DeliveryRoute, TruckAssignment, SequenceNumber, Report, User, Inbound, InventoryItem, Warehouse, S3Object } = initSchema(schema);

export {
  Customer,
  Brand,
  Product,
  Price,
  Discount,
  Order,
  OrderItem,
  OrderAdjustment,
  Payment,
  Workflow,
  WorkflowItem,
  DeliveryRoute,
  TruckAssignment,
  SequenceNumber,
  Report,
  User,
  Inbound,
  InventoryItem,
  Warehouse,
  CustomerType,
  ProductType,
  PriceType,
  OrderStatus,
  PaymentStatus,
  FulfillmentStatus,
  OrderType,
  Station,
  PaymentType,
  WorkflowItemType,
  TruckAssignmentStatus,
  UserGroup,
  S3Object
};