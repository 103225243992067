import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from './constants';
import { getProducts } from '../App/saga';
import { selectDate } from './selectors';
import { Moment } from 'moment';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { workflowsByCreatedDate } from './graphql';
import { Workflow } from '../../models';
import {
  getFulfillmentReportsError,
  getFulfillmentReportsSuccess,
} from './actions';
import { showErrorMessage } from '../App/actions';

function createWorkflows(json: any): Workflow[] {
  const items = json.data.workflowsByCreatedDate.items;
  return items.map(item => {
    const {
      id,
      station,
      comment,
      createdDate,
      createdAt,
      workflowItems,
      order,
      truckAssignment,
      user,
    } = item;
    return {
      id,
      station,
      comment,
      createdDate,
      createdAt,
      workflowItems: workflowItems.items,
      order,
      truckAssignment,
      user,
    };
  });
}

export function* getFulfillmentReport() {
  try {
    yield call(getProducts);
    const date: Moment = yield select(selectDate);
    let nextToken: string | null = null;
    const workflows: Workflow[] = [];
    do {
      const result = yield call(
        [API, 'graphql'],
        graphqlOperation(workflowsByCreatedDate, {
          createdDate: date.format('YYYY-MM-DD'),
          nextToken,
        }),
      );
      workflows.push(...createWorkflows(result));
      nextToken = result.data.workflowsByCreatedDate.nextToken;
    } while (nextToken);
    yield put(getFulfillmentReportsSuccess(workflows));
  } catch {
    yield all([put(showErrorMessage()), put(getFulfillmentReportsError())]);
  }
}

export default function* fulfillmentReportSaga() {
  yield takeLatest(
    [ActionTypes.SET_DATE, ActionTypes.GET_FULFILLMENT_REPORTS],
    getFulfillmentReport,
  );
}
