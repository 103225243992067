import React from 'react';
import Button from '@mui/material/Button';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import { ORDER_TYPE_DESC } from '../../constants';

const useStyles = makeStyles({
  downloadLink: {
    textDecoration: 'none',
  },
});

function ReportAllReportsCsvLink({ date, reports }) {
  const classes = useStyles();
  const csvHeader = [
    '#',
    'ประเภท',
    'โรง',
    '4',
    '7',
    '9',
    '11.5',
    '13.5',
    '15',
    '48',
    'ยอดขาย (บาท)',
    'เงินสด (บาท)',
    'ส่วนลด / ค่าใช้จ่าย',
    'ค้างชำระ (บาท)',
    'สรุปน้ำหนักขาย (กก.)',
    'จำนวนใบสั่งซื้อทั้งหมด',
  ];

  const csvRows = reports.map((row, idx) => {
    const {
      type,
      station,
      w40Count,
      w70Count,
      w90Count,
      w115Count,
      w135Count,
      w150Count,
      w480Count,
      sales,
      cash,
      weight,
      orderCount,
      adjustment,
    } = row;

    return [
      idx + 1,
      ORDER_TYPE_DESC[type],
      station,
      w40Count || 0,
      w70Count || 0,
      w90Count || 0,
      w115Count || 0,
      w135Count || 0,
      w150Count || 0,
      w480Count || 0,
      sales,
      cash,
      adjustment,
      (Math.round(sales + adjustment - cash) * 100) / 100,
      weight,
      orderCount,
    ];
  });

  const csvData = [csvHeader, ...csvRows];
  return (
    <CSVLink
      data={csvData}
      filename={`สรุปรายการซื้อทั้งหมด_${date.format('YYYY-MM-DD')}.csv`}
      className={classes.downloadLink}
    >
      <Button color="primary">ดาวน์โหลด</Button>
    </CSVLink>
  );
}

export default ReportAllReportsCsvLink;
