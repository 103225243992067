import produce from 'immer';
import _ from 'lodash';
import * as ACTION_TYPES from './constants';
import { PAYMENT_TYPE } from '../../constants';

export const initialState = {
  rows: [],
  products: [],
  payments: [],
  payment: {
    subtotal: 0,
    paidAmount: 0,
    receivable: 0,
    newAmount: '',
    paymentType: PAYMENT_TYPE.CASH,
  },
  truckAssignment: {
    truckAssignmentId: '',
    station: '',
    sequenceNumber: '',
    deliveryRouteId: '',
    driver: '',
    companion: '',
    departure: null,
    licensePlate: '',
    status: '',
    comment: '',
  },
  loading: false,
  disabled: false,
};

/* eslint-disable default-case, no-shadow, no-param-reassign */
const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACTION_TYPES.GET_TRUCK_ASSIGNMENT: {
        draft.truckAssignment.truckAssignmentId =
          action.payload.truckAssignmentId;
        draft.rows = [];
        draft.error = false;
        draft.loading = true;
        draft.disabled = true;
        return;
      }
      case ACTION_TYPES.GET_TRUCK_ASSIGNMENT_SUCCESS: {
        const orderItems = _.flatMap(action.payload.orders, 'orderItems.items');
        const subtotal = _.reduce(
          orderItems,
          (total, orderItem) => {
            const { unitPrice, adjustment, quantity } = orderItem;
            total += quantity * (unitPrice + adjustment);
            return total;
          },
          0,
        );
        const paidAmount = _.reduce(
          action.payload.payments,
          (total, payment) => {
            total += payment.amount;
            return total;
          },
          0,
        );
        draft.truckAssignment = action.payload.truckAssignment;
        draft.rows = _.chain(action.payload.orders)
          .map(({ id, sequenceNumber, customer, orderItems }) => ({
            orderId: id,
            sequenceNumber,
            customerId: customer.id,
            name: customer.name,
            orderItems: _.keyBy(orderItems.items, 'productId'),
          }))
          .sortBy(({ sequenceNumber }) => {
            const split = sequenceNumber.split('_');
            return Number(split[split.length - 1]);
          })
          .value();
        draft.payments = action.payload.payments;
        draft.payment.subtotal = subtotal;
        draft.payment.paidAmount = paidAmount;
        draft.payment.receivable = subtotal - paidAmount;
        draft.disabled = draft.payment.receivable === 0;
        draft.saved = true;
        draft.loading = false;
        return;
      }
      case ACTION_TYPES.SET_PAYMENT_AMOUNT: {
        draft.payment.newAmount = action.payload.newAmount;
        draft.payment.receivable =
          Math.round(
            (draft.payment.subtotal -
              draft.payment.paidAmount -
              Number(action.payload.newAmount)) *
              100,
          ) / 100;
        return;
      }
      case ACTION_TYPES.CREATE_PAYMENT: {
        draft.loading = true;
        draft.disabled = true;
        return;
      }
      case ACTION_TYPES.CREATE_PAYMENT_SUCCESS: {
        draft.loading = false;
        draft.payments = action.payload.payments;
        draft.payment.paymentType = PAYMENT_TYPE.CASH;
        draft.truckAssignment.status = action.payload.status;
        draft.payment.paidAmount += Number(draft.payment.newAmount);
        draft.disabled = draft.payment.receivable === 0;
        draft.payment.newAmount = null;
        return;
      }
      case ACTION_TYPES.CREATE_PAYMENT_ERROR:
      case ACTION_TYPES.GET_TRUCK_ASSIGNMENT_ERROR: {
        draft.loading = false;
        draft.disabled = false;
      }
    }
  });

export default reducer;
