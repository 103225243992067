import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

function CreateOrderQueueTableRow({ idx, name, quantity }) {
  return (
    <TableRow>
      <TableCell align="center" padding="none">
        <Typography variant="subtitle1">{idx + 1}</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="subtitle1">{name}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="subtitle1">{quantity}</Typography>
      </TableCell>
    </TableRow>
  );
}

export default CreateOrderQueueTableRow;
