import React from 'react';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';
import {
  ORDER_STATUS_DESC,
  ORDER_TYPE,
  ORDER_TYPE_DESC,
  PRODUCT_TYPE,
  STATION_DESC,
} from '../../constants';
import { REPORT_ALL_ORDERS_CSV_HEADER } from './constants';

const useStyles = makeStyles({
  downloadLink: {
    textDecoration: 'none',
  },
});

function ReportAllOrdersCsvLink({ date, rows }) {
  const classes = useStyles();
  const csvRows = rows.map(row => {
    const {
      sequenceNumber,
      type,
      name,
      status,
      station,
      subtotal,
      paidAmount,
      totalAdjustment,
      ownedAmount,
      totalWeight,
      orderItems,
      w40Count,
      w70Count,
      w90Count,
      w115Count,
      w135Count,
      w150Count,
      w480Count,
      createdAt,
    } = row;

    const hasNonLpg = _.some(
      orderItems,
      ({ type }) =>
        type === PRODUCT_TYPE.CONTAINER || type === PRODUCT_TYPE.SERVICE,
    );
    const isSalesOrder = type === ORDER_TYPE.SALES_ORDER;
    return [
      hasNonLpg ? `${sequenceNumber}*` : sequenceNumber,
      name,
      ORDER_TYPE_DESC[type],
      ORDER_STATUS_DESC[status],
      STATION_DESC[station],
      w150Count || 0,
      w480Count || 0,
      w40Count || 0,
      w70Count || 0,
      w90Count || 0,
      w115Count || 0,
      w135Count || 0,
      isSalesOrder ? totalWeight : 0,
      !isSalesOrder ? totalWeight : 0,
      subtotal,
      totalAdjustment,
      isSalesOrder ? paidAmount : 0,
      !isSalesOrder ? paidAmount : 0,
      ownedAmount,
      moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    ];
  });

  const csvData = [REPORT_ALL_ORDERS_CSV_HEADER, ...csvRows];
  return (
    <CSVLink
      data={csvData}
      filename={`ใบสั่งซื้อทั้งหมด_${date.format('YYYY-MM-DD')}.csv`}
      className={classes.downloadLink}
    >
      <Button color="primary">ดาวน์โหลด</Button>
    </CSVLink>
  );
}

export default ReportAllOrdersCsvLink;
