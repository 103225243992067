import TableFooter from '@mui/material/TableFooter';
import { FulfillmentReportRowCountsAndTotals } from './types';
import TableCell from '../../components/TableCell';
import React from 'react';
import FulfillmentReportTableCellNumber from './FulfillmentReportTableCellNumber';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

interface FulfillmentReportTableFooterProps {
  summary: FulfillmentReportRowCountsAndTotals;
}

function FulfillmentReportTableFooter({
  summary,
}: FulfillmentReportTableFooterProps) {
  const {
    w40,
    w70,
    w90,
    w115,
    w135,
    w150,
    w480,
    w40Swapped,
    w70Swapped,
    w90Swapped,
    w115Swapped,
    w135Swapped,
    w150Swapped,
    w480Swapped,
    totalWeight,
    totalWeightFueled,
    totalWeightSwapped,
  } = summary;
  return (
    <TableFooter>
      <TableRow hover>
        <TableCell variant="footer" align="center" colSpan={6}>
          <Typography variant="subtitle2">
            <strong>รวม</strong>
          </Typography>
        </TableCell>
        <TableCell variant="footer" backgroundColor="green" align="center">
          <FulfillmentReportTableCellNumber bold value={w150} />
        </TableCell>
        <TableCell variant="footer" backgroundColor="lightBlue" align="center">
          <FulfillmentReportTableCellNumber bold value={w480} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold value={w40} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold value={w70} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold value={w90} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold value={w115} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold value={w135} />
        </TableCell>
        <TableCell variant="footer" backgroundColor="green" align="center">
          <FulfillmentReportTableCellNumber bold swapped value={w150Swapped} />
        </TableCell>
        <TableCell variant="footer" backgroundColor="lightBlue" align="center">
          <FulfillmentReportTableCellNumber bold swapped value={w480Swapped} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold swapped value={w40Swapped} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold swapped value={w70Swapped} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold swapped value={w90Swapped} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold swapped value={w115Swapped} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold swapped value={w135Swapped} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold value={totalWeight} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber bold value={totalWeightFueled} />
        </TableCell>
        <TableCell variant="footer" align="center">
          <FulfillmentReportTableCellNumber
            bold
            swapped
            value={totalWeightSwapped}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
}

export default FulfillmentReportTableFooter;
