import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

function OrderAdjustmentDialogTooltip() {
  return (
    <Tooltip
      title={
        <Box display="flex" flexDirection="column">
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html:
                '– กรุณาใช้เครื่องหมายลบ (<b>-</b>) ข้างหน้าหากต้องการทำรายการเป็นส่วนลด',
            }}
          />
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: '– ส่วนลดจะถูกนำไป<b>หัก</b>กับยอดทั้งหมด',
            }}
          />
          <Typography
            variant="caption"
            dangerouslySetInnerHTML={{
              __html: '– ค่าใช้จ่ายจะถูกนำไป<b>เพิ่ม</b>กับยอดทั้งหมด',
            }}
          />
        </Box>
      }
    >
      <Box display="flex" alignItems="center" ml={1}>
        <HelpIcon color="disabled" fontSize="small" />
      </Box>
    </Tooltip>
  );
}

export default OrderAdjustmentDialogTooltip;
