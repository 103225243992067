import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/logo.png';

export default function Dashboard() {
  return (
    <Container>
      <Box
        height="65vh"
        flexGrow={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box mr={1}>
          <img src={logo} width={100} height={100} alt="logo" />
        </Box>
        <Typography variant="h4">แม่จันแก๊ส</Typography>
      </Box>
    </Container>
  );
}
