import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Box from '@material-ui/core/Box';
import { VALIDATOR } from '../../constants';
import ButtonLoader from '../../components/ButtonLoader';
import logo from '../../assets/images/logo.png';
import Paper from '../../components/Paper';

function SignInForm({ loading, onSubmit }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  return (
    <Container component="main">
      <Box
        m="auto"
        width="100%"
        display="flex"
        maxWidth={400}
        minHeight="100vh"
        flexDirection="column"
        justifyContent="center"
      >
        <Paper>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box mr={2}>
              <img src={logo} width={45} height={45} alt="logo" />
            </Box>
            <Typography variant="h5">
              <b>แม่จันแก๊ส</b>
            </Typography>
          </Box>
          <Box mt={2}>
            <ValidatorForm
              noValidate
              onSubmit={() => onSubmit(username, password)}
            >
              <TextValidator
                variant="outlined"
                margin="none"
                label="ชื่อผู้ใช้"
                fullWidth
                value={username}
                disabled={loading}
                validators={['required']}
                errorMessages={[VALIDATOR.REQUIRED]}
                onChange={e => setUsername(e.target.value)}
              />
              <Box mt={2}>
                <TextValidator
                  fullWidth
                  margin="none"
                  type="password"
                  label="รหัสผ่าน"
                  value={password}
                  variant="outlined"
                  disabled={loading}
                  validators={['required']}
                  errorMessages={[VALIDATOR.REQUIRED]}
                  onChange={e => setPassword(e.target.value)}
                />
              </Box>
              <Box mt={2}>
                <ButtonLoader
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={loading}
                >
                  <Typography variant="subtitle1">เข้าสู่ระบบ</Typography>
                </ButtonLoader>
              </Box>
            </ValidatorForm>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default SignInForm;
