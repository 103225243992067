import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableNumberRow from './TableNumberRow';
import PaymentTypeRow from './PaymentTypeRow';
import AddAdjustmentRow from './AddAdjustmentRow';
import NewAmountRow from './NewAmountRow';
import AdjustmentRow from './AdjustmentRow';
import TableConfirmButtonRow from './TableConfirmButtonRow';

/* eslint-disable react/no-array-index-key */
function Total({
  total,
  subtotal,
  newAmount,
  paidAmount,
  paymentType,
  onNewAmountChange,
  onPaymentTypeChange,
  onAdjustmentClick,
  onAdjustmentRemove,
  adjustments = [],
  dense = false,
  disabled = false,
  showComment = false,
  showRemoveButton = true,
  showSubmitButton = true,
  disabledPaymentType = false,
}) {
  const ownedAmount = Math.round((total - paidAmount) * 100) / 100;
  return (
    <TableContainer>
      <Table size={dense ? 'small' : 'medium'}>
        <TableBody>
          <TableNumberRow title="ทั้งหมด" value={subtotal} />
          {adjustments.map(adjustment => (
            <AdjustmentRow
              disabled={disabled}
              key={adjustment.id}
              adjustment={adjustment}
              showComment={showComment}
              showRemoveButton={showRemoveButton}
              onRemove={() => onAdjustmentRemove(adjustment.id)}
            />
          ))}
          <TableNumberRow bold title="รวมราคาสุทธิ" value={total} />
          {paidAmount > 0 && (
            <TableNumberRow title="ยอดชำระแล้ว" value={-paidAmount} />
          )}
          <TableNumberRow bold title="ยอดค้างชำระ" value={ownedAmount} />
          {onNewAmountChange && (
            <NewAmountRow
              disabled={disabled}
              newAmount={newAmount}
              ownedAmount={ownedAmount}
              onChange={onNewAmountChange}
            />
          )}
          {onPaymentTypeChange && (
            <PaymentTypeRow
              paymentType={paymentType}
              onChange={onPaymentTypeChange}
              disabled={disabled || disabledPaymentType}
            />
          )}
          {onAdjustmentClick && (
            <AddAdjustmentRow disabled={disabled} onClick={onAdjustmentClick} />
          )}
          {showSubmitButton && <TableConfirmButtonRow disabled={disabled} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Total;
