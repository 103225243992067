import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';
import TableCell from '../../components/TableCell';

function ReportSummaryTableBody({ summary }) {
  const {
    cash,
    sales,
    ownedAmount,
    totalAdjustment,
    otherCompanyCustomerSales,
    salesOrderCash,
    deliveryOrderCash,
    truckSalesOrderCash,
    totalWeightSold,
    salesOrderWeightSold,
    deliveryOrderWeightSold,
    truckSalesOrderWeightSold,
    otherCompanyCustomerOrderWeightSold,
    w40Count,
    w70Count,
    w90Count,
    w115Count,
    w135Count,
    w150Count,
    w480Count,
  } = summary;
  return (
    <TableBody>
      <TableRow>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber value={sales} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber
            value={otherCompanyCustomerSales}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber value={cash} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber value={totalAdjustment} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber value={ownedAmount} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber value={salesOrderCash} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber value={deliveryOrderCash} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          <CommaSeparatedNumber value={truckSalesOrderCash} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          <CommaSeparatedNumber value={totalWeightSold} fixedDecimalScale />
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          <CommaSeparatedNumber
            value={salesOrderWeightSold}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          <CommaSeparatedNumber
            value={deliveryOrderWeightSold}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          <CommaSeparatedNumber
            value={truckSalesOrderWeightSold}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          <CommaSeparatedNumber
            value={otherCompanyCustomerOrderWeightSold}
            fixedDecimalScale
          />
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          <CommaSeparatedNumber value={w40Count} />
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          <CommaSeparatedNumber value={w70Count} />
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          <CommaSeparatedNumber value={w90Count} />
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          <CommaSeparatedNumber value={w115Count} />
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          <CommaSeparatedNumber value={w135Count} />
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          <CommaSeparatedNumber value={w150Count} />
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          <CommaSeparatedNumber value={w480Count} />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

export default ReportSummaryTableBody;
