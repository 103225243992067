import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Box } from '@material-ui/core';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

function TruckAssignmentOrderAlert({ truckAssignmentId }) {
  return (
    <Box mb={3}>
      <Alert severity="warning">
        รายการสั่งซื้อรถเร่ (กรุณาชำระเงินในหน้ารถเร่)&nbsp;
        <MaterialLink
          component={Link}
          to={`/truckAssignments/${truckAssignmentId}`}
        >
          คลิกที่นี่เพื่อดูรายละเอียด
        </MaterialLink>
      </Alert>
    </Box>
  );
}

export default TruckAssignmentOrderAlert;
