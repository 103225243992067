import Chip from '@mui/material/Chip';
import { ORDER_STATUS, TRUCK_ASSIGNMENT_STATUS } from '../../constants';
import { FulfillmentStatus } from '../../models';

type Status =
  | typeof ORDER_STATUS
  | typeof TRUCK_ASSIGNMENT_STATUS
  | FulfillmentStatus
  | keyof typeof FulfillmentStatus;

interface StatusChipProp {
  status: Status;
  description: string;
  showPrefix?: boolean;
}

function getBackgroundColor(status: Status): string {
  switch (status) {
    case FulfillmentStatus.COMPLETED:
    case ORDER_STATUS.COMPLETED:
    case TRUCK_ASSIGNMENT_STATUS.COMPLETED:
      return 'success.light';
    case FulfillmentStatus.CANCELED:
    case ORDER_STATUS.OVERDUE:
    case ORDER_STATUS.CANCELED:
    case TRUCK_ASSIGNMENT_STATUS.OVERDUE:
      return 'error.light';
    case FulfillmentStatus.PENDING:
    case FulfillmentStatus.PARTIALLY_FULFILLED:
    case ORDER_STATUS.CREATED:
    case TRUCK_ASSIGNMENT_STATUS.CREATED:
    default:
      return 'primary.light';
  }
}

function StatusChip({
  status,
  description,
  showPrefix = true,
}: StatusChipProp) {
  return (
    <Chip
      label={showPrefix ? `สถานะ: ${description}` : description}
      sx={{ color: 'white', backgroundColor: getBackgroundColor(status) }}
      size="small"
    />
  );
}

export default StatusChip;
