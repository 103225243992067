import MuiTableCell from '@mui/material/TableCell';
import green from '@mui/material/colors/green';
import lightBlue from '@mui/material/colors/lightBlue';
import yellow from '@mui/material/colors/yellow';
import red from '@mui/material/colors/red';
import { ReactNode } from 'react';
import { TableCellProps as MuiTableCellProps } from '@mui/material/TableCell/TableCell';

type TableCellColor = 'green' | 'lightBlue' | 'yellow' | 'red' | 'transparent';

interface TableCellProps extends MuiTableCellProps {
  color?: string;
  backgroundColor?: TableCellColor;
  children?: ReactNode;
}

function getBackgroundColor(backgroundColor: TableCellColor) {
  switch (backgroundColor) {
    case 'green':
      return green[50];
    case 'lightBlue':
      return lightBlue[50];
    case 'yellow':
      return yellow[50];
    case 'red':
      return red[100];
    default:
      return 'transparent';
  }
}

function TableCell({
  color = 'inherit',
  backgroundColor = 'transparent',
  children,
  ...otherProps
}: TableCellProps) {
  return (
    <MuiTableCell
      sx={{
        whiteSpace: 'nowrap',
        backgroundColor: getBackgroundColor(backgroundColor),
        color: color,
      }}
      {...otherProps}
    >
      {children}
    </MuiTableCell>
  );
}

export default TableCell;
