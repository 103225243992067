import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableCell from '../../components/TableCell';

function ReportSummaryTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={8} align="center" backgroundColor="green">
          <Typography variant="body1">
            <b>สรุปเงินสด (บาท)</b>
          </Typography>
        </TableCell>
        <TableCell colSpan={5} align="center" backgroundColor="lightBlue">
          <Typography variant="body1">
            <b>สรุปน้ำหนักขาย (กก.)</b>
          </Typography>
        </TableCell>
        <TableCell colSpan={7} align="center" backgroundColor="yellow">
          <Typography variant="body1">
            <b>สรุปจำนวนถัง</b>
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell align="right" backgroundColor="green">
          ยอดขาย
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          ยอดขายลูกค้าจากบริษัทอื่น
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          เงินสด
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          ส่วนลด / ค่าใช้จ่าย
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          ค้างชำระ
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          เงินสดจากลูกค้าหน้าร้าน
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          เงินสดจากออเดอร์
        </TableCell>
        <TableCell align="right" backgroundColor="green">
          เงินสดจากรถเร่
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          น้ำหนักขาย
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          ลูกค้าหน้าร้าน
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          ออเดอร์
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          รถเร่
        </TableCell>
        <TableCell align="right" backgroundColor="lightBlue">
          ลูกค้าบริษัทอื่น
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          4
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          7
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          9
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          11.5
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          13.5
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          15
        </TableCell>
        <TableCell align="center" backgroundColor="yellow">
          48
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default ReportSummaryTableHeader;
