import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const selectCashier = state => state.cashier;

export const makeSelectOverdueOrders = createSelector(
  selectCashier,
  ({ overdueOrders }) => overdueOrders,
);

export const makeSelectTokens = createSelector(
  selectCashier,
  ({ tokens }) => tokens,
);

export const makeSelectNextToken = createSelector(selectCashier, ({ tokens }) =>
  !isEmpty(tokens) ? tokens[tokens.length - 1] : null,
);

export const makeSelectShowNext = createSelector(
  selectCashier,
  ({ showNext }) => showNext,
);

export const makeSelectShowPrevious = createSelector(
  selectCashier,
  ({ showPrevious }) => showPrevious,
);

export const makeSelectError = createSelector(
  selectCashier,
  ({ error }) => error,
);

export const makeSelectLoading = createSelector(
  selectCashier,
  ({ loading }) => loading,
);
