import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { PRODUCT_TYPE_DESC } from '../../constants';

function OrderItemDialogProduct({ product, onClick, checked = false }) {
  const { name, type } = product;
  return (
    <Box
      border={1}
      position="relative"
      borderColor="divider"
      borderRadius="borderRadius"
    >
      <Button fullWidth disableRipple onClick={onClick}>
        <Box
          py={1}
          width="100%"
          display="flex"
          alignItems="start"
          flexDirection="column"
        >
          <Box position="absolute" top={0} right={0}>
            <Checkbox color="primary" checked={checked} />
          </Box>
          <Typography variant="body2">{name}</Typography>
          <Typography variant="caption" color="textSecondary">
            {`ประเภท: ${PRODUCT_TYPE_DESC[type]}`}
          </Typography>
        </Box>
      </Button>
    </Box>
  );
}

export default OrderItemDialogProduct;
