import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import MaterialLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { REGEXES } from '../../../shared/regexes';
import { CUSTOMER_TYPE } from '../../../constants';

function getFullAddress(address, subdistrict, district, province, postalCode) {
  let fullAddress = '';
  if (address) {
    fullAddress += `${address} `;
  }
  if (subdistrict) {
    fullAddress += `ตำบล ${subdistrict} `;
  }
  if (district) {
    fullAddress += `อำเภอ ${district} `;
  }
  if (province) {
    fullAddress += `จังหวัด ${province} `;
  }
  if (postalCode) {
    fullAddress += postalCode;
  }
  return fullAddress;
}

function CustomerTile({ customer }) {
  const {
    id,
    name,
    address,
    subdistrict,
    district,
    province,
    postalCode,
    phoneNumber,
    type,
    sequenceNumber,
  } = customer;

  return (
    <Box mt={2}>
      <Accordion variant="outlined" defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box width="100%" display="flex" alignItems="center">
            {/* Order Sequence Number */}
            <Box flexGrow={1}>
              <Typography>รหัสลูกค้า: {sequenceNumber}</Typography>
            </Box>

            {/* Customer Type */}
            <Box my={0.5}>
              <Chip
                label={`ประเภทลูกค้า: ${
                  type === CUSTOMER_TYPE.MEMBER
                    ? 'ลูกค้าสมาชิก'
                    : 'ลูกค้าทั่วไป'
                }`}
                color="primary"
                size="small"
              />
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexWrap="wrap">
            {/* Name */}
            <Box mr={1} mt={0.5}>
              <Chip
                label={`ชื่อลูกค้า: ${name}`}
                color="primary"
                size="small"
              />
            </Box>

            {/* Address */}
            {address && (
              <Box mr={1} mt={0.5}>
                <Chip
                  label={`ที่อยู่: ${getFullAddress(
                    address,
                    subdistrict,
                    district,
                    province,
                    postalCode,
                  )}`}
                  color="primary"
                  size="small"
                />
              </Box>
            )}

            {phoneNumber && (
              <Box mr={1} mt={0.5}>
                <Chip
                  label={`เบอร์โทร ${phoneNumber.replace(
                    REGEXES.PHONE_NUMBER,
                    '($1) $2-$3',
                  )}`}
                  color="primary"
                  size="small"
                />
              </Box>
            )}
          </Box>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <MaterialLink
            component={Link}
            color="textPrimary"
            underline="none"
            to={`/customer/${id}`}
          >
            <Button size="medium" color="primary">
              แก้ไขข้อมูล
            </Button>
          </MaterialLink>
        </AccordionActions>
      </Accordion>
    </Box>
  );
}

export default CustomerTile;
