import React from 'react';
import Alert from '@material-ui/lab/Alert';

export default function CreateTruckAssignmentMessage({ sequenceNumber }) {
  return (
    <Alert severity="success">
      {`บันทึกใบสั่งซื้อรถเร่สำเร็จ (เลขที่ใบสั่งซื้อ: ${sequenceNumber}) `}
    </Alert>
  );
}
