/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onBatchUpdateOrders = /* GraphQL */ `
  subscription OnBatchUpdateOrders {
    onBatchUpdateOrders {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      debt
      beginningBalance
      deliveryRouteId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      discounts {
        items {
          id
          discount
          productId
          customerId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      debt
      beginningBalance
      deliveryRouteId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      discounts {
        items {
          id
          discount
          productId
          customerId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      debt
      beginningBalance
      deliveryRouteId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      discounts {
        items {
          id
          discount
          productId
          customerId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePrice = /* GraphQL */ `
  subscription OnCreatePrice {
    onCreatePrice {
      id
      type
      price
      productId
      conditionDesc
      conditionValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePrice = /* GraphQL */ `
  subscription OnUpdatePrice {
    onUpdatePrice {
      id
      type
      price
      productId
      conditionDesc
      conditionValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePrice = /* GraphQL */ `
  subscription OnDeletePrice {
    onDeletePrice {
      id
      type
      price
      productId
      conditionDesc
      conditionValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateDiscount = /* GraphQL */ `
  subscription OnCreateDiscount {
    onCreateDiscount {
      id
      discount
      productId
      customerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateDiscount = /* GraphQL */ `
  subscription OnUpdateDiscount {
    onUpdateDiscount {
      id
      discount
      productId
      customerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteDiscount = /* GraphQL */ `
  subscription OnDeleteDiscount {
    onDeleteDiscount {
      id
      discount
      productId
      customerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateOrderItem = /* GraphQL */ `
  subscription OnCreateOrderItem {
    onCreateOrderItem {
      id
      orderId
      truckAssignmentId
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOrderItem = /* GraphQL */ `
  subscription OnUpdateOrderItem {
    onUpdateOrderItem {
      id
      orderId
      truckAssignmentId
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOrderItem = /* GraphQL */ `
  subscription OnDeleteOrderItem {
    onDeleteOrderItem {
      id
      orderId
      truckAssignmentId
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment($owner: String) {
    onUpdatePayment(owner: $owner) {
      id
      orderId
      truckAssignmentId
      amount
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment($owner: String) {
    onDeletePayment(owner: $owner) {
      id
      orderId
      truckAssignmentId
      amount
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
