import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import PrintIcon from '@material-ui/icons/Print';
import Box from '@material-ui/core/Box';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';

function CreateOrderOptions({
  orderId,
  onPrintClick,
  onAddOrderItemsClick,
  disabled = false,
  showOrderLink = false,
  showPrintOption = false,
}) {
  const history = useHistory();
  return (
    <Box>
      <Tooltip title="เพิ่มรายการสินค้า">
        <IconButton disabled={disabled} onClick={onAddOrderItemsClick}>
          <AddShoppingCartIcon />
        </IconButton>
      </Tooltip>
      {showPrintOption && (
        <Tooltip title="พิมพ์บัตรคิว">
          <IconButton onClick={onPrintClick}>
            <PrintIcon />
          </IconButton>
        </Tooltip>
      )}
      {showOrderLink && (
        <Tooltip title="ดูรายละเอียด">
          <IconButton onClick={() => history.push(`/order/${orderId}`)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export default CreateOrderOptions;
