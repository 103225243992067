import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

function DeleteAdjustmentDialog({ open, onConfirm, onClose }) {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onClose}>
      <DialogTitle>ต้องการยกเลิกการส่วนลด / ค่าใช้จ่ายนี้ ?</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          กรุณาคลิก &#8220;ตกลง&#8221; หากคุณต้องการยกเลิกการส่วนลด /
          ค่าใช้จ่ายนี้
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onConfirm} color="primary">
          ตกลง
        </Button>
        <Button variant="contained" onClick={onClose} color="secondary">
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteAdjustmentDialog;
