import React, { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function OrderLightbox({ initIdx, images, onClose }) {
  const [imageIdx, setImageIdx] = useState(0);
  useEffect(() => setImageIdx(initIdx), [initIdx]);
  return (
    <Lightbox
      mainSrc={images[imageIdx].url}
      nextSrc={images[(imageIdx + 1) % images.length].url}
      prevSrc={images[(imageIdx + images.length - 1) % images.length].url}
      onCloseRequest={onClose}
      onMovePrevRequest={() =>
        setImageIdx((imageIdx + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setImageIdx((imageIdx + 1) % images.length)}
    />
  );
}

export default OrderLightbox;
