import React from 'react';
import {
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import keys from 'lodash/keys';
import {
  ORDER_STATUS_DESC,
  ORDER_TYPE,
  ORDER_TYPE_DESC,
  STATION,
  STATION_DESC,
  VALIDATOR,
} from '../../constants';
import { CustomerSearch } from '../../modules/customer';

function OrderForm({
  order,
  disabled = false,
  showStatus = false,
  showSequenceNumber = false,
  disabledCustomerSearch = false,
  onChange = () => {},
  onCustomerChange = () => {},
  onCustomerNameChange = () => {},
}) {
  const {
    name,
    type,
    status,
    station,
    comment,
    createdBy,
    sequenceNumber,
    licensePlateNumber,
    _lastChangedAt: lastChangedAt,
  } = order;

  const handleChange = field => e => onChange(field, e);

  return (
    <Box display="flex" displayPrint="none">
      <Grid container spacing={2}>
        {(showSequenceNumber || sequenceNumber) && (
          <Grid item xs={12} md={6} lg={4}>
            <TextValidator
              label="เลขที่ใบสั่งซื้อ"
              variant="outlined"
              margin="none"
              fullWidth
              disabled={disabled}
              value={sequenceNumber}
              onChange={handleChange('sequenceNumber')}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4}>
          <CustomerSearch
            label="ชื่อลูกค้า"
            required
            inputValue={name}
            onChange={onCustomerChange}
            onInputChange={onCustomerNameChange}
            disabled={disabled || disabledCustomerSearch}
          />
        </Grid>
        {showStatus && status && (
          <Grid item xs={12} md={6} lg={4}>
            <TextValidator
              label="สถานะ"
              variant="outlined"
              margin="none"
              fullWidth
              disabled
              value={ORDER_STATUS_DESC[status]}
              onChange={() => {}}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4}>
          <SelectValidator
            label="โรงอัดแก๊ส"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            value={station || ''}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            onChange={handleChange('station')}
          >
            <MenuItem value="" disabled>
              กรุณาเลือก
            </MenuItem>
            {keys(STATION).map(station => (
              <MenuItem key={station} value={station}>
                {STATION_DESC[station]}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SelectValidator
            fullWidth
            variant="outlined"
            margin="none"
            label="ประเภท"
            value={type || ''}
            disabled={disabled}
            validators={['required']}
            errorMessages={[VALIDATOR.REQUIRED]}
            onChange={handleChange('type')}
          >
            <MenuItem value="" disabled>
              กรุณาเลือก
            </MenuItem>
            <MenuItem value={ORDER_TYPE.SALES_ORDER}>
              {ORDER_TYPE_DESC.SALES_ORDER}
            </MenuItem>
            <MenuItem value={ORDER_TYPE.DELIVERY_ORDER}>
              {ORDER_TYPE_DESC.DELIVERY_ORDER}
            </MenuItem>
            <MenuItem value={ORDER_TYPE.TRUCK_SALES_ORDER} disabled>
              {ORDER_TYPE_DESC.TRUCK_SALES_ORDER}
            </MenuItem>
            <MenuItem value={ORDER_TYPE.OTHER_COMPANY_CUSTOMER_ORDER} disabled>
              {ORDER_TYPE_DESC.OTHER_COMPANY_CUSTOMER_ORDER}
            </MenuItem>
          </SelectValidator>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextValidator
            label="ทะเบียนรถยนต์"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            value={licensePlateNumber || ''}
            onChange={handleChange('licensePlateNumber')}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <TextValidator
            label="หมายเหตุ"
            variant="outlined"
            margin="none"
            fullWidth
            disabled={disabled}
            value={comment || ''}
            onChange={handleChange('comment')}
          />
        </Grid>
        {createdBy && (
          <Grid item xs={12} md={6} lg={4}>
            <TextValidator
              label="ผู้ทำรายการ"
              variant="outlined"
              margin="none"
              disabled
              fullWidth
              value={createdBy}
            />
          </Grid>
        )}
        {lastChangedAt && (
          <Grid item xs={12} md={6} lg={4}>
            <TextValidator
              label="วันที่ขาย"
              variant="outlined"
              margin="none"
              disabled
              fullWidth
              value={moment(lastChangedAt)
                .tz('Asia/Bangkok')
                .format('DD/MM/YYYY HH:mm')}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default OrderForm;
