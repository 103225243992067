import {
  ADD_INVENTORY_ITEM,
  CANCEL_INBOUND,
  DELETE_INVENTORY_ITEM,
  ENABLE_INBOUND,
  RESET_INBOUND,
  SUBMIT_INBOUND,
  SUBMIT_INBOUND_ERROR,
  SUBMIT_INBOUND_SUCCESS,
  UPDATE_INVENTORY_ITEM_PRODUCT_ID,
  UPDATE_INVENTORY_ITEM_QUANTITY,
  UPDATE_WAREHOUSE_ID,
} from './constants';

export function addInventoryItem() {
  return {
    type: ADD_INVENTORY_ITEM,
  };
}

export function submitInbound() {
  return {
    type: SUBMIT_INBOUND,
  };
}

export function submitInboundSuccess(inbound) {
  return {
    type: SUBMIT_INBOUND_SUCCESS,
    payload: { inbound },
  };
}

export function submitInboundError() {
  return {
    type: SUBMIT_INBOUND_ERROR,
  };
}

export function deleteInventoryItem(idx) {
  return {
    type: DELETE_INVENTORY_ITEM,
    payload: { idx },
  };
}

export function updateInventoryItemProductId(idx, productId) {
  return {
    type: UPDATE_INVENTORY_ITEM_PRODUCT_ID,
    payload: { idx, productId },
  };
}

export function updateInventoryItemQuantity(idx, quantity) {
  return {
    type: UPDATE_INVENTORY_ITEM_QUANTITY,
    payload: { idx, quantity },
  };
}

export function updateWarehouseId(warehouseId) {
  return {
    type: UPDATE_WAREHOUSE_ID,
    payload: { warehouseId },
  };
}

export function enableInbound() {
  return {
    type: ENABLE_INBOUND,
  };
}

export function cancelInbound() {
  return {
    type: CANCEL_INBOUND,
  };
}

export function resetInbound() {
  return {
    type: RESET_INBOUND,
  };
}
