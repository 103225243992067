import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectUserGroups } from '../containers/App/selectors';

function useHasPermission(allowedGroups) {
  const groups = useSelector(selectUserGroups);
  return _.intersection(allowedGroups, groups).length > 0;
}

export default useHasPermission;
