import {
  GET_DELIVERY_ROUTE,
  GET_DELIVERY_ROUTE_ERROR,
  GET_DELIVERY_ROUTE_SUCCESS,
  GET_TRUCK_ASSIGNMENT,
  GET_TRUCK_ASSIGNMENT_ERROR,
  GET_TRUCK_ASSIGNMENT_SUCCESS,
  RESET_TRUCK_ASSIGNMENT,
  SET_ORDER,
  SET_TRUCK_ASSIGNMENT,
  SUBMIT_TRUCK_ASSIGNMENT,
  SUBMIT_TRUCK_ASSIGNMENT_ERROR,
  SUBMIT_TRUCK_ASSIGNMENT_SUCCESS,
  UPDATE_TRUCK_ASSIGNMENT,
} from './constants';

export function getDeliveryRoute() {
  return {
    type: GET_DELIVERY_ROUTE,
  };
}

export function getDeliveryRouteSuccess(licensePlate, customers) {
  return {
    type: GET_DELIVERY_ROUTE_SUCCESS,
    payload: {
      licensePlate,
      customers,
    },
  };
}

export function getDeliveryRouteError() {
  return {
    type: GET_DELIVERY_ROUTE_ERROR,
  };
}

export function setTruckAssignment(param, value) {
  return {
    type: SET_TRUCK_ASSIGNMENT,
    payload: {
      param,
      value,
    },
  };
}

export function setOrder(index, productId, quantity, weight, type) {
  return {
    type: SET_ORDER,
    payload: {
      index,
      productId,
      weight,
      type,
      quantity: Number(quantity),
    },
  };
}

export function submitTruckAssignment() {
  return {
    type: SUBMIT_TRUCK_ASSIGNMENT,
  };
}

export function submitTruckAssignmentSuccess(truckAssignment, rows) {
  return {
    type: SUBMIT_TRUCK_ASSIGNMENT_SUCCESS,
    payload: {
      truckAssignment,
      rows,
    },
  };
}

export function submitTruckAssignmentError() {
  return {
    type: SUBMIT_TRUCK_ASSIGNMENT_ERROR,
  };
}

export function resetTruckAssignment() {
  return {
    type: RESET_TRUCK_ASSIGNMENT,
  };
}

export function updateTruckAssignment() {
  return {
    type: UPDATE_TRUCK_ASSIGNMENT,
  };
}

export function getTruckAssignment(truckAssignmentId) {
  return {
    type: GET_TRUCK_ASSIGNMENT,
    payload: { truckAssignmentId },
  };
}

export function getTruckAssignmentSuccess(truckAssignment, rows) {
  return {
    type: GET_TRUCK_ASSIGNMENT_SUCCESS,
    payload: { truckAssignment, rows },
  };
}

export function getTruckAssignmentError() {
  return {
    type: GET_TRUCK_ASSIGNMENT_ERROR,
  };
}
