import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '../../components/TableCell';

function AdjustmentHistoryTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="center" padding="none">
          #
        </TableCell>
        <TableCell align="left">ผู้ดำเนินการ</TableCell>
        <TableCell align="left">วันที่</TableCell>
        <TableCell align="left">หมายเหตุ</TableCell>
        <TableCell align="left">ประเภท</TableCell>
        <TableCell align="right">จำนวนเงิน</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default AdjustmentHistoryTableHeader;
