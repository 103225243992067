import React from 'react';
import { SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import {
  DELIVERY_ROUTE,
  DELIVERY_ROUTE_DESC,
  VALIDATOR,
} from '../../constants';

export default function TruckAssignmentSelector({
  deliveryRouteId,
  onChange,
  disabled = false,
}) {
  return (
    <SelectValidator
      label="สายรถเร่"
      variant="outlined"
      margin="none"
      fullWidth
      disabled={disabled}
      value={deliveryRouteId || ''}
      validators={['required']}
      errorMessages={[VALIDATOR.REQUIRED]}
      onChange={onChange}
    >
      <MenuItem value="" disabled>
        กรุณาเลือก
      </MenuItem>
      {Object.keys(DELIVERY_ROUTE).map(deliveryRoute => (
        <MenuItem key={deliveryRoute} value={deliveryRoute}>
          {DELIVERY_ROUTE_DESC[deliveryRoute]}
        </MenuItem>
      ))}
    </SelectValidator>
  );
}
