import _ from 'lodash';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserGroups } from './selectors';

/* eslint-disable react/jsx-props-no-spreading */
function ProtectedRoute({ component: Component, allowed, ...rest }) {
  const groups = useSelector(selectUserGroups);
  const hasPermission = _.intersection(allowed, groups).length > 0;
  return (
    <Route
      {...rest}
      render={props =>
        hasPermission ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default ProtectedRoute;
