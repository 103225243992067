import { action } from 'typesafe-actions';
import { ActionTypes } from './constants';
import { Moment } from 'moment-timezone';
import { Workflow } from '../../models';

export const setDate = (date: Moment) => action(ActionTypes.SET_DATE, { date });

export const getFulfillmentReports = () =>
  action(ActionTypes.GET_FULFILLMENT_REPORTS);

export const getFulfillmentReportsSuccess = (workflows: Workflow[]) =>
  action(ActionTypes.GET_FULFILLMENT_REPORTS_SUCCESS, { workflows: workflows });

export const getFulfillmentReportsError = () =>
  action(ActionTypes.GET_FULFILLMENT_REPORTS_ERROR);
