import TableCell from '../../components/TableCell';
import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function FulfillmentReportTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell variant="head" rowSpan={2}>
          <strong>#</strong>
        </TableCell>
        <TableCell variant="head" rowSpan={2}>
          <strong>เลขที่ใบสั่งซื้อ</strong>
        </TableCell>
        <TableCell variant="head" rowSpan={2}>
          <strong>ชื่อลูกค้า / สายรถเร่</strong>
        </TableCell>
        <TableCell variant="head" rowSpan={2}>
          <strong>ประเภท</strong>
        </TableCell>
        <TableCell variant="head" rowSpan={2}>
          <strong>สถานะบรรจุแก๊ส</strong>
        </TableCell>
        <TableCell variant="head" rowSpan={2}>
          <strong>โรงบรรจุแก๊ส</strong>
        </TableCell>
        <TableCell variant="head" align="center" colSpan={7}>
          <strong>จำนวนทั้งหมด (หน่วย)</strong>
        </TableCell>
        <TableCell
          variant="head"
          align="center"
          colSpan={7}
          color="warning.main"
        >
          <strong>จำนวนนำ้หมุน (หน่วย)</strong>
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <strong>น้ำหนักรวม (กก.)</strong>
        </TableCell>
        <TableCell variant="head" align="center" rowSpan={2}>
          <strong>น้ำหนักบรรจุ (กก.)</strong>
        </TableCell>
        <TableCell
          variant="head"
          align="center"
          rowSpan={2}
          color="warning.main"
        >
          <strong>น้ำหนักนำ้หมุน (กก.)</strong>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell variant="head" backgroundColor="green" align="center">
          <strong>15</strong>
        </TableCell>
        <TableCell variant="head" backgroundColor="lightBlue" align="center">
          <strong>48</strong>
        </TableCell>
        <TableCell variant="head" align="center">
          <strong>4</strong>
        </TableCell>
        <TableCell variant="head" align="center">
          <strong>7</strong>
        </TableCell>
        <TableCell variant="head" align="center">
          <strong>9</strong>
        </TableCell>
        <TableCell variant="head" align="center">
          <strong>11.5</strong>
        </TableCell>
        <TableCell variant="head" align="center">
          <strong>13.5</strong>
        </TableCell>
        <TableCell
          variant="head"
          align="center"
          backgroundColor="green"
          color="warning.main"
        >
          <strong>15</strong>
        </TableCell>
        <TableCell
          variant="head"
          align="center"
          backgroundColor="lightBlue"
          color="warning.main"
        >
          <strong>48</strong>
        </TableCell>
        <TableCell variant="head" align="center" color="warning.main">
          <strong>4</strong>
        </TableCell>
        <TableCell variant="head" align="center" color="warning.main">
          <strong>7</strong>
        </TableCell>
        <TableCell variant="head" align="center" color="warning.main">
          <strong>9</strong>
        </TableCell>
        <TableCell variant="head" align="center" color="warning.main">
          <strong>11.5</strong>
        </TableCell>
        <TableCell variant="head" align="center" color="warning.main">
          <strong>13.5</strong>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default FulfillmentReportTableHeader;
