import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import PaddingBox from '../../components/PaddingBox';

function OrderItemUpdateButtons({ disabled, onCancel }) {
  return (
    <PaddingBox display="flex">
      <Box mr={1}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={disabled}
          startIcon={<SaveIcon />}
        >
          บันทึก
        </Button>
      </Box>
      <Button
        type="reset"
        color="secondary"
        disabled={disabled}
        variant="contained"
        startIcon={<DeleteIcon />}
        onClick={onCancel}
      >
        ยกเลิก
      </Button>
    </PaddingBox>
  );
}

export default OrderItemUpdateButtons;
