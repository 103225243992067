// eslint-disable-next-line import/prefer-default-export
export const SearchTruckAssignments = /* GraphQL */ `
  query SearchTruckAssignments(
    $filter: SearchableTruckAssignmentFilterInput
    $sort: SearchableTruckAssignmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTruckAssignments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        driver
        companion
        status
        station
        comment
        departure
        licensePlate
        sequenceNumber
        deliveryRouteId
        createdAt
        updatedAt
        _deleted
        _lastChangedAt
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
        }
        orders {
          items {
            orderItems {
              items {
                productId
                quantity
                unitPrice
                adjustment
                _deleted
              }
            }
            _deleted
          }
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
