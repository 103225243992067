export const GET_ORDER = 'Order/GET_ORDER';
export const GET_ORDER_SUCCESS = 'Order/GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'Order/GET_ORDER_ERROR';
export const SET_NEW_AMOUNT = 'Order/SET_NEW_AMOUNT';
export const SET_PAYMENT_TYPE = 'Order/SET_PAYMENT_TYPE';
export const CREATE_PAYMENT = 'Order/CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESS = 'Order/CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_ERROR = 'Order/CREATE_PAYMENT_ERROR';
export const SET_UPDATED_ORDER = 'Order/SET_UPDATED_ORDER';
export const ENABLE_UPDATE_ORDER_HEADER_IN_PROGRESS =
  'Order/ENABLE_UPDATE_ORDER_HEADER_IN_PROGRESS';
export const DISABLE_UPDATE_ORDER_HEADER_IN_PROGRESS =
  'Order/DISABLE_UPDATE_ORDER_HEADER_IN_PROGRESS';
export const UPDATE_ORDER_HEADER = 'Order/UPDATE_ORDER_HEADER';
export const UPDATE_ORDER_HEADER_SUCCESS = 'Order/UPDATE_ORDER_HEADER_SUCCESS';
export const UPDATE_ORDER_HEADER_ERROR = 'Order/UPDATE_ORDER_HEADER_ERROR';
export const CANCEL_ORDER = 'Order/CANCEL_ORDER';
export const CANCEL_ORDER_SUCCESS = 'Order/CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_ERROR = 'Order/CANCEL_ORDER_ERROR';
export const ENABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS =
  'Order/ENABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS';
export const DISABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS =
  'Order/DISABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS';
export const UPDATE_ORDER_ITEMS = 'Order/UPDATE_ORDER_ITEMS';
export const UPDATE_ORDER_ITEMS_SUCCESS = 'Order/UPDATE_ORDER_ITEMS_SUCCESS';
export const UPDATE_ORDER_ITEMS_ERROR = 'Order/UPDATE_ORDER_ITEMS_ERROR';
export const SET_UPDATED_ORDER_ITEM = 'Order/SET_UPDATED_ORDER_ITEM';
export const CANCEL_ORDER_ITEM = 'Order/CANCEL_ORDER_ITEM';
export const CANCEL_ORDER_ITEM_SUCCESS = 'Order/CANCEL_ORDER_ITEM_SUCCESS';
export const CANCEL_ORDER_ITEM_ERROR = 'Order/CANCEL_ORDER_ITEM_ERROR';
export const SHOW_LIGHT_BOX = 'Order/SHOW_LIGHT_BOX';
export const HIDE_LIGHT_BOX = 'Order/HIDE_LIGHT_BOX';
export const SHOW_ADJUSTMENT_DIALOG = 'Order/SHOW_ADJUSTMENT_DIALOG';
export const HIDE_ADJUSTMENT_DIALOG = 'Order/HIDE_ADJUSTMENT_DIALOG';
export const SHOW_DELETE_ADJUSTMENT_DIALOG =
  'Order/SHOW_DELETE_ADJUSTMENT_DIALOG';
export const HIDE_DELETE_ADJUSTMENT_DIALOG =
  'Order/HIDE_DELETE_ADJUSTMENT_DIALOG';
export const CREATE_ADJUSTMENT = 'Order/CREATE_ADJUSTMENT';
export const CREATE_ADJUSTMENT_SUCCESS = 'Order/CREATE_ADJUSTMENT_SUCCESS';
export const CREATE_ADJUSTMENT_ERROR = 'Order/CREATE_ADJUSTMENT_ERROR';
export const DELETE_ADJUSTMENT = 'Order/DELETE_ADJUSTMENT';
export const DELETE_ADJUSTMENT_SUCCESS = 'Order/DELETE_ADJUSTMENT_SUCCESS';
export const DELETE_ADJUSTMENT_ERROR = 'Order/DELETE_ADJUSTMENT_ERROR';
export const RESET_ORDER = 'Order/RESET_ORDER';
