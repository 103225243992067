import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_ERROR,
  CREATE_PAYMENT_SUCCESS,
  GET_TRUCK_ASSIGNMENT,
  GET_TRUCK_ASSIGNMENT_ERROR,
  GET_TRUCK_ASSIGNMENT_SUCCESS,
  SET_PAYMENT_AMOUNT,
} from './constants';

export function getTruckAssignment(truckAssignmentId) {
  return {
    type: GET_TRUCK_ASSIGNMENT,
    payload: {
      truckAssignmentId,
    },
  };
}

export function getTruckAssignmentSuccess(truckAssignment, orders, payments) {
  return {
    type: GET_TRUCK_ASSIGNMENT_SUCCESS,
    payload: {
      truckAssignment,
      orders,
      payments,
    },
  };
}

export function getTruckAssignmentError() {
  return {
    type: GET_TRUCK_ASSIGNMENT_ERROR,
  };
}

export function setPaymentAmount(newAmount) {
  return {
    type: SET_PAYMENT_AMOUNT,
    payload: { newAmount },
  };
}

export function createPayment() {
  return {
    type: CREATE_PAYMENT,
  };
}

export function createPaymentSuccess(status, payments) {
  return {
    type: CREATE_PAYMENT_SUCCESS,
    payload: {
      status,
      payments,
    },
  };
}

export function createPaymentError() {
  return {
    type: CREATE_PAYMENT_ERROR,
  };
}
