import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import isEmpty from 'lodash/isEmpty';
import CreateInventoryItemTableHeader from './CreateInventoryItemTableHeader';
import CreateInventoryItemTableContainer from './CreateInventoryItemTableContainer';
import withSpinner from '../../components/WithSpinner';
import CreateInventoryItemTableBody from './CreateInventoryItemTableBody';

/* eslint-disable react/jsx-props-no-spreading */
function CreateInventoryItemTable({
  disabled,
  products,
  onDelete,
  inventoryItems,
  onProductChange,
  onQuantityChange,
  ...otherProps
}) {
  return (
    <TableContainer
      component={CreateInventoryItemTableContainer}
      disabled={disabled}
      disabledSubmit={disabled || isEmpty(inventoryItems)}
      {...otherProps}
    >
      <Table>
        <CreateInventoryItemTableHeader />
        <CreateInventoryItemTableBody
          disabled={disabled}
          products={products}
          onDelete={onDelete}
          inventoryItems={inventoryItems}
          onProductChange={onProductChange}
          onQuantityChange={onQuantityChange}
        />
      </Table>
    </TableContainer>
  );
}

export default withSpinner(CreateInventoryItemTable);
