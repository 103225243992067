import { FulfillmentReportRow } from './types';
import TableCell from '../../components/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';
import { Link } from 'react-router-dom';
import {
  FULFILLMENT_STATUS_DESC,
  ORDER_TYPE_DESC,
  STATION_DESC,
} from '../../constants';
import StatusChip from '../../components/StatusChip';
import { FulfillmentStatus, OrderType } from '../../models';
import FulfillmentReportTableCellNumber from './FulfillmentReportTableCellNumber';
import yellow from '@material-ui/core/colors/yellow';

interface FulfillmentReportTableRowProps {
  idx: number;
  row: FulfillmentReportRow;
}

function getBackgroundColor(isTruckAssignment: boolean, isCanceled: boolean) {
  if (isTruckAssignment) {
    return yellow[50];
  }
  if (isCanceled) {
    return 'error.light';
  }
  return 'inherit';
}

function showNumber(value: number) {
  return value > 0;
}

function FulfillmentReportTableRow({
  idx,
  row,
}: FulfillmentReportTableRowProps) {
  const {
    station,
    w40,
    w70,
    w90,
    w115,
    w135,
    w150,
    w480,
    w40Swapped,
    w70Swapped,
    w90Swapped,
    w115Swapped,
    w135Swapped,
    w150Swapped,
    w480Swapped,
    totalWeight,
    totalWeightFueled,
    totalWeightSwapped,
    order,
    truckAssignment,
    isTruckAssignment,
  } = row;
  const fulfillmentStatus = isTruckAssignment
    ? truckAssignment?.fulfillmentStatus
    : order?.fulfillmentStatus;
  const type = isTruckAssignment ? OrderType.TRUCK_SALES_ORDER : order?.type;
  const isCanceled = order?.fulfillmentStatus === FulfillmentStatus.CANCELED;
  return (
    <TableRow
      hover
      sx={{
        backgroundColor: getBackgroundColor(isTruckAssignment, isCanceled),
      }}
    >
      <TableCell variant="body">
        <CommaSeparatedNumber value={idx + 1} />
      </TableCell>
      <TableCell variant="body">
        <Link
          to={
            isTruckAssignment
              ? `/truckAssignments/${truckAssignment?.id}`
              : `/order/${order?.id}`
          }
        >
          {isTruckAssignment
            ? truckAssignment?.sequenceNumber
            : order?.sequenceNumber}
        </Link>
      </TableCell>
      <TableCell variant="body">
        {isTruckAssignment
          ? truckAssignment?.deliveryRoute?.name
          : order?.customer?.name}
      </TableCell>
      <TableCell variant="body">{type && ORDER_TYPE_DESC[type]}</TableCell>
      <TableCell variant="body">
        {fulfillmentStatus && (
          <StatusChip
            status={fulfillmentStatus}
            description={FULFILLMENT_STATUS_DESC[fulfillmentStatus]}
            showPrefix={false}
          />
        )}
      </TableCell>
      <TableCell variant="body">{STATION_DESC[station]}</TableCell>
      <TableCell variant="body" backgroundColor="green" align="center">
        {showNumber(w150) && <FulfillmentReportTableCellNumber value={w150} />}
      </TableCell>
      <TableCell variant="body" backgroundColor="lightBlue" align="center">
        {showNumber(w480) && <FulfillmentReportTableCellNumber value={w480} />}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w40) && <FulfillmentReportTableCellNumber value={w40} />}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w70) && <FulfillmentReportTableCellNumber value={w70} />}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w90) && <FulfillmentReportTableCellNumber value={w90} />}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w115) && <FulfillmentReportTableCellNumber value={w115} />}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w135) && <FulfillmentReportTableCellNumber value={w135} />}
      </TableCell>
      <TableCell variant="body" backgroundColor="green" align="center">
        {showNumber(w150Swapped) && (
          <FulfillmentReportTableCellNumber swapped value={w150Swapped} />
        )}
      </TableCell>
      <TableCell variant="body" backgroundColor="lightBlue" align="center">
        {showNumber(w480Swapped) && (
          <FulfillmentReportTableCellNumber swapped value={w480Swapped} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w40Swapped) && (
          <FulfillmentReportTableCellNumber swapped value={w40Swapped} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w70Swapped) && (
          <FulfillmentReportTableCellNumber swapped value={w70Swapped} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w90Swapped) && (
          <FulfillmentReportTableCellNumber swapped value={w90Swapped} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w115Swapped) && (
          <FulfillmentReportTableCellNumber swapped value={w115Swapped} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(w135Swapped) && (
          <FulfillmentReportTableCellNumber swapped value={w135Swapped} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(totalWeight) && (
          <FulfillmentReportTableCellNumber value={totalWeight} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(totalWeightFueled) && (
          <FulfillmentReportTableCellNumber value={totalWeightFueled} />
        )}
      </TableCell>
      <TableCell variant="body" align="center">
        {showNumber(totalWeightSwapped) && (
          <FulfillmentReportTableCellNumber
            swapped
            value={totalWeightSwapped}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

export default FulfillmentReportTableRow;
