import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import {
  GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS,
  GET_OVERDUE_TRUCK_ASSIGNMENTS,
  GET_OVERDUE_TRUCK_ASSIGNMENTS_ERROR,
  GET_OVERDUE_TRUCK_ASSIGNMENTS_SUCCESS,
  GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS,
  REFRESH_OVERDUE_TRUCK_ASSIGNMENTS,
} from './constants';

export const initState = {
  overdueTruckAssignments: [],
  tokens: [],
  error: false,
  loading: false,
  showNext: false,
  showPrevious: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_NEXT_OVERDUE_TRUCK_ASSIGNMENTS:
      case GET_OVERDUE_TRUCK_ASSIGNMENTS:
        draft.error = false;
        draft.loading = true;
        break;
      case GET_PREVIOUS_OVERDUE_TRUCK_ASSIGNMENTS:
        draft.tokens = draft.tokens.slice(0, draft.tokens.length - 2);
        draft.error = false;
        draft.loading = true;
        break;
      case REFRESH_OVERDUE_TRUCK_ASSIGNMENTS:
        if (!isEmpty(draft.tokens)) {
          draft.tokens = draft.tokens.slice(0, draft.tokens.length - 1);
        }
        draft.error = false;
        draft.loading = true;
        break;
      case GET_OVERDUE_TRUCK_ASSIGNMENTS_SUCCESS:
        draft.overdueTruckAssignments = action.payload.overdueTruckAssignments;
        draft.tokens.push(action.payload.nextToken);
        draft.showNext =
          !isEmpty(draft.tokens) &&
          !isEmpty(draft.tokens[draft.tokens.length - 1]);
        draft.showPrevious =
          draft.tokens.length > 1 && !isEmpty(draft.tokens[0]);
        draft.error = false;
        draft.loading = false;
        break;
      case GET_OVERDUE_TRUCK_ASSIGNMENTS_ERROR:
        draft.overdueTruckAssignments = [];
        draft.error = true;
        draft.loading = false;
        break;
    }
  });

export default reducer;
