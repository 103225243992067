import React from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment-timezone';
import _ from 'lodash';
import {
  ORDER_TYPE_DESC,
  PRODUCT_TYPE,
  STATION_DESC,
  TH_VOWEL_MARK,
} from '../../constants';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
function CreateOrderPrintQueueCard({ order, printRef, orderItems }) {
  // const webPrint = useReactToPrint({
  //   content: () => printRef.current,
  // });

  const epsonPrint = () => {
    const lpgOrderItems = _.chain(orderItems)
      .filter(['type', PRODUCT_TYPE.LPG])
      .groupBy('weight')
      .map(items => {
        const { weight } = _.head(items);
        const quantity = _.reduce(
          items,
          (sum, orderItem) => sum + Number(orderItem.quantity),
          0,
        );
        return {
          id: weight,
          name: `น้ำ ${weight} กก.`,
          quantity,
        };
      })
      .value();

    const nonLpgOrderItems = _.filter(
      orderItems,
      orderItem => orderItem.type !== PRODUCT_TYPE.LPG,
    );

    const formattedOrderItems = _.union(lpgOrderItems, nonLpgOrderItems);

    const { name, type, station, comment, licensePlateNumber, createdAt } =
      order;

    const lines = [];
    lines.push(
      '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">',
    );
    if (order.sequenceNumber) {
      lines.push('<text align="center">เลขที่ใบสั่งซื้อ&#10;</text>');
      lines.push(
        `<text dh="true" dw="true" align="center">${order.sequenceNumber}&#10;</text>`,
      );
    } else {
      lines.push('<text align="center">ไม่มีเลขที่ใบสั่งซื้อ&#10;</text>');
    }
    lines.push('<text dh="false" dw="false">&#10;</text>');
    lines.push(`<text align="left">ชื่อลูกค้า: ${name}&#10;</text>`);
    if (type) {
      lines.push(`<text>ประเภท: ${ORDER_TYPE_DESC[type]}&#10;</text>`);
    }
    if (licensePlateNumber) {
      lines.push(`<text>ทะเบียนรถยนต์: ${licensePlateNumber}&#10;</text>`);
    }
    if (station) {
      lines.push(`<text>โรงอัดแก๊ส: ${STATION_DESC[station]}&#10;</text>`);
    }
    if (createdAt) {
      lines.push(
        `<text>วันที่ขาย: ${moment(createdAt)
          .tz('Asia/Bangkok')
          .format('DD/MM/YYYY HH:mm')}&#10;</text>`,
      );
    }
    if (comment) {
      lines.push(`<text>หมายเหต ${comment}&#10;</text>`);
    }

    const thVowelRegex = new RegExp(`([${TH_VOWEL_MARK}])`, 'g');
    lines.push('<text>------------------------------------------&#10;</text>');
    const leftHeader = '#  สินค้า';
    const rightHeader = 'จำนวน';
    const leftHeaderLength = leftHeader.replace(thVowelRegex, '').length;
    const rightHeaderLength = rightHeader.replace(thVowelRegex, '').length;
    lines.push(
      `<text>${leftHeader}${_.repeat(
        ' ',
        42 - leftHeaderLength - rightHeaderLength,
      )}${rightHeader}&#10;</text>`,
    );
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < formattedOrderItems.length; i++) {
      const { name, quantity } = formattedOrderItems[i];
      const quantityStr = `${quantity}`;
      const left = `${i + 1}${_.repeat(' ', i + 1 > 10 ? 1 : 2)}${name}`;
      const right = `${_.repeat(' ', 4 - quantityStr.length)}${quantityStr}`;
      const leftLength = left.replace(thVowelRegex, '').length;
      const numberOfSpaces = 42 - leftLength - right.length;
      lines.push(
        `<text>${left}${_.repeat(' ', numberOfSpaces)}${right}&#10;</text>`,
      );
    }
    lines.push('<text>------------------------------------------&#10;</text>');
    lines.push('<cut/>');
    lines.push('</epos-print>');

    const data = lines.join('');
    window.location.href =
      'tmprintassistant://tmprintassistant.epson.com/print?' +
      'ver=1&' +
      'timeout=60000&' +
      'error-dialog=yes&' +
      'data-type=eposprintxml&' +
      `data=${encodeURIComponent(data)}`;
  };

  const handlePrint = () => {
    // setTimeout(() => webPrint(), 10);
    epsonPrint();
  };

  return (
    <Button variant="contained" color="primary" onClick={handlePrint}>
      พิมพ์
    </Button>
  );
}

export default CreateOrderPrintQueueCard;
