import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '../../components/TableCell';

function PaymentHistoryTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="right" padding="none">
          #
        </TableCell>
        <TableCell align="left">ผู้รับเงิน</TableCell>
        <TableCell align="left">วันที่รับเงิน</TableCell>
        <TableCell align="left">การชำระเงิน</TableCell>
        <TableCell align="right">จำนวนเงิน</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default PaymentHistoryTableHeader;
