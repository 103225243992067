import { ContainerActions, ContainerState } from './types';
import produce from 'immer';
import { ActionTypes } from './constants';
import moment from 'moment-timezone';

const initState: ContainerState = {
  date: moment().startOf('day'),
  workflows: [],
  loading: false,
};

function fulfillmentReportReduce(
  state: ContainerState = initState,
  action: ContainerActions,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return produce(state, draft => {
    switch (action.type) {
      case ActionTypes.SET_DATE:
        draft.date = action.payload.date;
        draft.loading = true;
        break;
      case ActionTypes.GET_FULFILLMENT_REPORTS:
        draft.loading = true;
        break;
      case ActionTypes.GET_FULFILLMENT_REPORTS_SUCCESS:
        draft.workflows = action.payload.workflows;
        draft.loading = false;
        break;
      case ActionTypes.GET_FULFILLMENT_REPORTS_ERROR:
        draft.workflows = [];
        draft.loading = false;
        break;
    }
  });
}

export default fulfillmentReportReduce;
