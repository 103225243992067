export const getDebtor = /* GraphQL */ `
  query GetCustomer($id: ID!, $nextToken: String) {
    getCustomer(id: $id) {
      id
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      type
      debt
      orders(
        filter: {
          or: [{ status: { eq: CREATED } }, { status: { eq: OVERDUE } }]
        }
        nextToken: $nextToken
      ) {
        items {
          id
          status
          sequenceNumber
          createdAt
          station
          orderItems {
            items {
              unitPrice
              quantity
              adjustment
            }
          }
          payments {
            items {
              amount
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const searchOverdueOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        status
        sequenceNumber
        createdAt
        station
        orderItems {
          items {
            unitPrice
            quantity
            adjustment
          }
        }
        payments {
          items {
            amount
          }
        }
      }
      nextToken
      total
    }
  }
`;
