import React, { useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import TableRow from '@material-ui/core/TableRow';
import isEmpty from 'lodash/isEmpty';
import { TextValidator } from 'react-material-ui-form-validator';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';
import PriceTooltip from '../../components/PriceTooltip';
import { VALIDATOR } from '../../constants';
import CancelOrderItemDialog from './CancelOrderItemDialog';
import TableCell from '../../components/TableCell';

function OrderItemTableRow({
  idx,
  name,
  quantity,
  adjustedPrice,
  unitPrice,
  adjustment,
  prices,
  total,
  comment,
  updateInProgress,
  onChange,
  onCancel,
}) {
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  return (
    <>
      <TableRow>
        {updateInProgress && (
          <TableCell width={50}>
            <IconButton onClick={() => setOpenCancelDialog(true)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </TableCell>
        )}
        <TableCell align="center" padding="none">
          {idx + 1}
        </TableCell>
        <TableCell align="left">{name}</TableCell>
        {updateInProgress ? (
          <TableCell align="right">
            <TextValidator
              margin="none"
              type="number"
              variant="outlined"
              value={quantity}
              inputProps={{ style: { textAlign: 'right', minWidth: 100 } }}
              validators={['required', 'isNumber', 'minNumber:1']}
              onChange={e => onChange(idx, 'quantity', e.target.value)}
              errorMessages={[
                VALIDATOR.REQUIRED,
                VALIDATOR.IS_NUMBER,
                VALIDATOR.GREATER_THEN_ONE,
              ]}
            />
          </TableCell>
        ) : (
          <Hidden only="xs">
            <TableCell align="right">{quantity}</TableCell>
          </Hidden>
        )}
        {updateInProgress && <TableCell align="right">{unitPrice}</TableCell>}
        {updateInProgress ? (
          <TableCell align="right">
            <TextValidator
              margin="none"
              type="number"
              variant="outlined"
              value={adjustment}
              inputProps={{ style: { textAlign: 'right', minWidth: 100 } }}
              validators={['required', 'isNumber']}
              onChange={e => onChange(idx, 'adjustment', e.target.value)}
              errorMessages={[
                VALIDATOR.REQUIRED,
                VALIDATOR.IS_NUMBER,
                VALIDATOR.GREATER_THEN_OR_EQUAL_ONE,
              ]}
            />
          </TableCell>
        ) : (
          <Hidden only="xs">
            <TableCell align="right">
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <CommaSeparatedNumber fixedDecimalScale value={adjustedPrice} />
                {!isEmpty(prices) && !updateInProgress && (
                  <PriceTooltip
                    price={adjustedPrice}
                    unitPrice={unitPrice}
                    adjustment={adjustment}
                    comment={comment}
                    prices={prices}
                  />
                )}
              </Box>
            </TableCell>
          </Hidden>
        )}
        {updateInProgress && (
          <TableCell align="right">
            <CommaSeparatedNumber fixedDecimalScale value={adjustedPrice} />
          </TableCell>
        )}
        <TableCell align="right">
          <CommaSeparatedNumber fixedDecimalScale value={total} />
        </TableCell>
      </TableRow>
      <CancelOrderItemDialog
        open={openCancelDialog}
        onAgreeClick={() => {
          setOpenCancelDialog(false);
          onCancel(idx);
        }}
        onDisagreeClick={() => setOpenCancelDialog(false)}
      />
    </>
  );
}

export default OrderItemTableRow;
