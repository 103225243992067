import { call, put, select, takeLatest } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment-timezone';
import { CREATE_PAYMENT, GET_TRUCK_ASSIGNMENT } from './constants';
import { selectPayment, selectTruckAssignment } from './selectors';
import {
  createPaymentError,
  createPaymentSuccess,
  getTruckAssignmentError,
  getTruckAssignmentSuccess,
} from './actions';
import { getProducts } from '../App/saga';
import { showErrorMessage, showSuccessMessage } from '../App/actions';
import {
  createTruckAssignmentPayment as createTruckAssignmentPaymentGraphql,
  getTruckAssignment as getTruckAssignmentGraphql,
} from './graphql';

function* getTruckAssignment() {
  try {
    yield call(getProducts);
    const { truckAssignmentId } = yield select(selectTruckAssignment);
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(getTruckAssignmentGraphql, { id: truckAssignmentId }),
    );
    const {
      id,
      deliveryRouteId,
      driver,
      companion,
      deliveryRoute,
      licensePlate,
      departure,
      station,
      orders,
      payments,
      status,
      sequenceNumber,
      comment,
    } = result.data.getTruckAssignment;
    const { licensePlate: defaultLicensePlate } = deliveryRoute;
    const truckAssignment = {
      truckAssignmentId: id,
      deliveryRouteId,
      driver,
      companion,
      licensePlate: licensePlate || defaultLicensePlate,
      status,
      station,
      sequenceNumber,
      departure: moment(departure),
      comment,
    };
    yield put(
      getTruckAssignmentSuccess(truckAssignment, orders.items, payments.items),
    );
  } catch {
    yield put(getTruckAssignmentError());
    yield put(showErrorMessage());
  }
}

function* createPayment() {
  try {
    const { truckAssignmentId } = yield select(selectTruckAssignment);
    const { newAmount, paymentType } = yield select(selectPayment);
    const input = {
      truckAssignmentId,
      amount: newAmount,
      type: paymentType,
    };
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(createTruckAssignmentPaymentGraphql, { input }),
    );
    const { status, payments } = result.data.createTruckAssignmentPayment;
    yield put(createPaymentSuccess(status, payments));
    yield put(showSuccessMessage('บันทึกการรับเงินสำเร็จ'));
  } catch (e) {
    yield put(createPaymentError());
    yield put(showErrorMessage());
  }
}

export default function* saga() {
  yield takeLatest(CREATE_PAYMENT, createPayment);
  yield takeLatest(GET_TRUCK_ASSIGNMENT, getTruckAssignment);
}
