import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { SelectValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from 'lodash';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { ORDER_TYPE, ORDER_TYPE_DESC } from '../../constants';

const useStyles = makeStyles({
  select: {
    padding: '16px 14px',
  },
});

function OrderTypeSelectValidator({ orderTypes, onChange, disabled = false }) {
  const classes = useStyles();
  return (
    <SelectValidator
      fullWidth
      margin="none"
      variant="outlined"
      label="ประเภทใบสั่งซื้อ"
      disabled={disabled}
      SelectProps={{
        multiple: true,
        value: orderTypes,
        onChange,
        classes: {
          select: clsx(!_.isEmpty(orderTypes) && classes.select),
        },
        renderValue: orderTypes =>
          orderTypes.map(orderType => (
            <Box key={orderType} display="inline" mr={1}>
              <Chip
                size="small"
                color="primary"
                label={ORDER_TYPE_DESC[orderType]}
              />
            </Box>
          )),
      }}
    >
      <MenuItem value="" disabled>
        กรุณาเลือก
      </MenuItem>
      <MenuItem key={ORDER_TYPE.SALES_ORDER} value={ORDER_TYPE.SALES_ORDER}>
        <Checkbox
          color="primary"
          checked={orderTypes.includes(ORDER_TYPE.SALES_ORDER)}
        />
        <ListItemText primary={ORDER_TYPE_DESC.SALES_ORDER} />
      </MenuItem>
      <MenuItem
        key={ORDER_TYPE.DELIVERY_ORDER}
        value={ORDER_TYPE.DELIVERY_ORDER}
      >
        <Checkbox
          color="primary"
          checked={orderTypes.includes(ORDER_TYPE.DELIVERY_ORDER)}
        />
        <ListItemText primary={ORDER_TYPE_DESC.DELIVERY_ORDER} />
      </MenuItem>
      <MenuItem
        key={ORDER_TYPE.TRUCK_SALES_ORDER}
        value={ORDER_TYPE.TRUCK_SALES_ORDER}
      >
        <Checkbox
          color="primary"
          checked={orderTypes.includes(ORDER_TYPE.TRUCK_SALES_ORDER)}
        />
        <ListItemText primary={ORDER_TYPE_DESC.TRUCK_SALES_ORDER} />
      </MenuItem>
      <MenuItem
        key={ORDER_TYPE.OTHER_COMPANY_CUSTOMER_ORDER}
        value={ORDER_TYPE.OTHER_COMPANY_CUSTOMER_ORDER}
      >
        <Checkbox
          color="primary"
          checked={orderTypes.includes(ORDER_TYPE.OTHER_COMPANY_CUSTOMER_ORDER)}
        />
        <ListItemText primary={ORDER_TYPE_DESC.OTHER_COMPANY_CUSTOMER_ORDER} />
      </MenuItem>
    </SelectValidator>
  );
}

export default OrderTypeSelectValidator;
