import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '../../components/TableCell';

function OrderItemTableHeader({ updateInProgress }) {
  return (
    <TableHead>
      <TableRow>
        {updateInProgress && <TableCell />}
        <TableCell align="center" padding="none">
          #
        </TableCell>
        <TableCell align="left">สินค้า</TableCell>
        {updateInProgress ? (
          <TableCell align="right">จำนวน</TableCell>
        ) : (
          <Hidden only="xs">
            <TableCell align="right">จำนวน</TableCell>
          </Hidden>
        )}
        {updateInProgress && <TableCell align="right">ราคาหน่วยละ</TableCell>}
        {updateInProgress && (
          <TableCell align="right">ปรับราคาหน่วยละ</TableCell>
        )}
        {updateInProgress ? (
          <TableCell align="right">ราคาสุทธิหน่วยละ</TableCell>
        ) : (
          <Hidden only="xs">
            <TableCell align="right">ราคาสุทธิหน่วยละ</TableCell>
          </Hidden>
        )}
        <TableCell align="right">จำนวนเงิน</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default OrderItemTableHeader;
