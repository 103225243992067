import produce from 'immer';
import _ from 'lodash';
import {
  ADD_IMAGES,
  CREATE_ORDER,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_SUCCESS,
  HIDE_ORDER_ITEM_DIALOG,
  HIDE_QUEUE_CARD,
  INIT_CREATE_DEBT,
  REMOVE_IMAGE,
  REMOVE_ORDER_ITEM,
  RESET_ORDER,
  SET_CUSTOMER,
  SET_CUSTOMER_NAME,
  SET_ORDER,
  SHOW_ORDER_ITEM_DIALOG,
  SHOW_QUEUE_CARD,
  TOGGLE_ORDER_ITEM,
  UPDATE_ORDER_ITEM_PRODUCT_ID,
  UPDATE_ORDER_ITEM_QUANTITY,
} from './constants';
import { ORDER_STATUS } from '../../constants';

const initialState = {
  order: {
    id: null,
    type: null,
    status: null,
    customerId: null,
    name: '',
    sequenceNumber: '',
    licensePlateNumber: '',
    station: '',
    comment: '',
    createdBy: '',
    createdAt: '',
  },
  orderItems: [],
  images: [],
  error: false,
  loading: false,
  disabled: false,
  showStatus: false,
  showQueueCard: false,
  isCreatedDebt: false,
  showSequenceNumber: false,
  showOrderItemDialog: false,
  disabledSelectCustomer: false,
};

/* eslint-disable default-case, no-param-reassign */
function createOrderReducerCreator(key) {
  return function createOrderReducer(state = initialState, action) {
    if (action.key !== key) return state;
    return produce(state, draft => {
      switch (action.type) {
        case GET_CUSTOMER:
          draft.loading = true;
          break;
        case GET_CUSTOMER_SUCCESS:
          draft.loading = false;
          draft.disabledSelectCustomer = true;
          draft.order.name = action.payload.name;
          draft.order.customerId = action.payload.customerId;
          break;
        case GET_CUSTOMER_ERROR:
          draft.order.customerId = null;
          draft.order.name = '';
          draft.disabledSelectCustomer = false;
          break;
        case INIT_CREATE_DEBT:
          draft.showStatus = true;
          draft.isCreatedDebt = true;
          draft.showSequenceNumber = true;
          draft.order.status = ORDER_STATUS.OVERDUE;
          break;
        case SET_ORDER:
          draft.order[action.payload.field] = action.payload.value;
          break;
        case SET_CUSTOMER:
          draft.order.customerId = action.payload.customerId;
          draft.order.name = action.payload.name;
          break;
        case SET_CUSTOMER_NAME:
          draft.order.name = action.payload.name;
          break;
        case SHOW_ORDER_ITEM_DIALOG:
          draft.showOrderItemDialog = true;
          break;
        case HIDE_ORDER_ITEM_DIALOG:
          draft.showOrderItemDialog = false;
          break;
        case TOGGLE_ORDER_ITEM: {
          const { id, name, weight, type } = action.payload.product;
          const idx = _.findIndex(draft.orderItems, ['productId', id]);
          if (idx > -1) {
            _.pullAt(draft.orderItems, [idx]);
          } else {
            draft.orderItems.push({
              productId: id,
              name,
              type,
              weight,
              quantity: null,
            });
          }
          break;
        }
        case REMOVE_ORDER_ITEM:
          _.pullAt(draft.orderItems, [action.payload.idx]);
          break;
        case UPDATE_ORDER_ITEM_QUANTITY:
          draft.orderItems[action.payload.idx].quantity =
            action.payload.quantity;
          break;
        case UPDATE_ORDER_ITEM_PRODUCT_ID:
          draft.orderItems[action.payload.idx].productId =
            action.payload.productId;
          break;
        case RESET_ORDER:
          draft.order.id = null;
          draft.order.type = null;
          draft.order.status = null;
          draft.order.sequenceNumber = '';
          draft.order.licensePlateNumber = '';
          draft.order.station = '';
          draft.order.comment = '';
          draft.order.createdBy = '';
          draft.order.createdAt = '';
          draft.orderItems = [];
          draft.images = [];
          draft.loading = false;
          draft.disabled = false;
          if (!draft.isCreatedDebt) {
            draft.order.name = '';
            draft.order.customerId = null;
          }
          break;
        case CREATE_ORDER:
          draft.disabled = true;
          draft.loading = true;
          break;
        case CREATE_ORDER_SUCCESS:
          draft.order.id = action.payload.order.id;
          draft.order.type = action.payload.order.type;
          draft.order.sequenceNumber = action.payload.order.sequenceNumber;
          draft.order.createdBy = action.payload.order.createdBy;
          draft.order.createdAt = action.payload.order.createdAt;
          draft.loading = false;
          draft.showQueueCard = true;
          break;
        case CREATE_ORDER_ERROR:
          draft.disabled = false;
          draft.loading = false;
          break;
        case SHOW_QUEUE_CARD:
          draft.showQueueCard = true;
          break;
        case HIDE_QUEUE_CARD:
          draft.showQueueCard = false;
          break;
        case ADD_IMAGES:
          draft.images.push(...action.payload.images);
          break;
        case REMOVE_IMAGE:
          _.pullAt(draft.images, [action.payload.idx]);
          break;
      }
    });
  };
}

export default createOrderReducerCreator;
