import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import { GET_DEBTOR, REFRESH_DEBTOR } from './constants';
import { getDebtorError, getDebtorSuccess } from './actions';
import { makeSelectCustomerId } from './selectors';
import { ORDER_STATUS } from '../../constants';
import { getDebtor as getDebtorGraphql, searchOverdueOrders } from './graphql';
import { showErrorMessage } from '../App/actions';

function* getDebtor() {
  try {
    const customerId = yield select(makeSelectCustomerId);
    const results = yield all([
      call(
        [API, 'graphql'],
        graphqlOperation(getDebtorGraphql, { id: customerId }),
      ),
      call(
        [API, 'graphql'],
        graphqlOperation(searchOverdueOrders, {
          filter: {
            and: [
              { customerId: { eq: customerId } },
              {
                or: [
                  { status: { eq: ORDER_STATUS.CREATED } },
                  { status: { eq: ORDER_STATUS.OVERDUE } },
                ],
              },
            ],
          },
          sort: { direction: 'asc', field: 'createdAt' },
        }),
      ),
    ]);

    const {
      id,
      name,
      type,
      sequenceNumber,
      phoneNumber,
      email,
      address,
      subdistrict,
      district,
      province,
      postalCode,
      debt,
    } = results[0].data.getCustomer;

    const debtor = {
      id,
      name,
      type,
      sequenceNumber,
      phoneNumber,
      email,
      address,
      subdistrict,
      district,
      province,
      postalCode,
      debt,
    };

    const overdueOrders = results[1].data.searchOrders.items.map(order => {
      const {
        id,
        status,
        station,
        createdAt,
        sequenceNumber,
        orderItems,
        payments,
      } = order;

      const subtotal = _.reduce(
        orderItems.items,
        (subtotal, { quantity, unitPrice, adjustment }) =>
          subtotal + (unitPrice + (adjustment || 0)) * quantity,
        0,
      );

      const paidAmount = _.reduce(
        payments.items,
        (paidAmount, { amount }) => paidAmount + amount,
        0,
      );

      return {
        id,
        type,
        status,
        station,
        createdAt,
        sequenceNumber,
        subtotal,
        paidAmount,
      };
    });
    yield put(getDebtorSuccess(debtor, overdueOrders));
  } catch {
    yield all([put(showErrorMessage()), put(getDebtorError())]);
  }
}

export default function* saga() {
  yield takeLatest([GET_DEBTOR, REFRESH_DEBTOR], getDebtor);
}
