import React from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Box from '@material-ui/core/Box';
import ReportAccordion from './ReportAccordion';
import ReportSummaryTableHeader from './ReportSummaryTableHeader';
import ReportSummaryTableBody from './ReportSummaryTableBody';
import Spinner from '../../components/Spinner';

function ReportSummaryTable({
  title,
  summary,
  loading,
  component,
  defaultExpanded,
}) {
  return (
    <TableContainer
      title={title}
      loading={loading}
      defaultExpanded={defaultExpanded}
      component={component || ReportAccordion}
    >
      <Spinner loading={loading}>
        <Box overflow="auto">
          <Table>
            <ReportSummaryTableHeader />
            <ReportSummaryTableBody summary={summary} />
          </Table>
        </Box>
      </Spinner>
    </TableContainer>
  );
}

export default ReportSummaryTable;
