import React, { useEffect, useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { VALIDATOR } from '../../constants';
import OrderAdjustmentDialogTooltip from './OrderAdjustmentDialogTooltip';

function OrderAdjustmentDialog({
  open,
  total,
  paidAmount,
  disabled,
  onSubmit,
  onClose,
}) {
  const [amount, setAmount] = useState('');
  const [comment, setComment] = useState('');
  const minAmount = -Math.round((total - paidAmount) * 100) / 100;

  useEffect(() => {
    if (!open) {
      setAmount('');
      setComment('');
    }
  }, [open]);

  const helpText = useMemo(() => {
    if (amount > 0) {
      return `ค่าใช้จ่าย ${amount} จะถูกนำไปเพิ่มกับยอดทั้งหมด`;
    }
    if (amount < 0) {
      return `ส่วนลด ${-amount} จะถูกนำไปหักกับยอดทั้งหมด`;
    }
    return '';
  }, [amount]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <ValidatorForm onSubmit={() => onSubmit(Number(amount), comment)}>
        <DialogTitle>เพิ่มส่วนลด / ค่าใช้จ่าย</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextValidator
                fullWidth
                type="number"
                name="amount"
                variant="outlined"
                label="ส่วนลด / ค่าใช้จ่าย"
                margin="none"
                value={amount}
                disabled={disabled}
                validators={['required', 'isFloat', `minFloat:${minAmount}`]}
                errorMessages={[
                  VALIDATOR.REQUIRED,
                  VALIDATOR.IS_FLOAT,
                  VALIDATOR.INVALID,
                ]}
                onChange={e => setAmount(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <OrderAdjustmentDialogTooltip />
                    </InputAdornment>
                  ),
                }}
                helperText={helpText}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                fullWidth
                name="comment"
                variant="outlined"
                label="หมายเหตุ"
                margin="none"
                value={comment}
                disabled={disabled}
                validators={['required']}
                errorMessages={[VALIDATOR.REQUIRED]}
                onChange={e => setComment(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            ตกลง
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            ยกเลิก
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default OrderAdjustmentDialog;
