import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import { PRODUCT_TYPE, PRODUCT_TYPE_DESC } from '../../constants';
import OrderItemDialogTabPanel from './OrderItemDialogTabPanel';

function OrderItemDialogBody({ items, groupedByTypeProducts, onClick }) {
  const [tabIndex, setTabIndex] = useState(0);
  const types = [
    PRODUCT_TYPE.LPG,
    PRODUCT_TYPE.CONTAINER,
    PRODUCT_TYPE.SERVICE,
  ];
  return (
    <Container>
      <Box py={3}>
        <Paper>
          <AppBar position="static" color="default">
            <Tabs
              value={tabIndex}
              textColor="primary"
              indicatorColor="primary"
              onChange={(e, idx) => setTabIndex(idx)}
            >
              {types.map(type => (
                <Tab key={type} label={PRODUCT_TYPE_DESC[type]} />
              ))}
            </Tabs>
          </AppBar>
          <Box p={{ xs: 2, md: 3 }}>
            {types.map((type, idx) => (
              <OrderItemDialogTabPanel
                key={type}
                index={idx}
                items={items}
                tabIndex={tabIndex}
                type={type}
                groupedByTypeProducts={groupedByTypeProducts[type]}
                onClick={onClick}
              />
            ))}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default OrderItemDialogBody;
