import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import { TextValidator } from 'react-material-ui-form-validator';
import TableRow from '@material-ui/core/TableRow';
import { VALIDATOR } from '../../constants';

/* eslint-disable no-restricted-globals */
function NewAmountRow({ disabled, ownedAmount, newAmount, onChange }) {
  return (
    <TableRow>
      <TableCell colSpan={2} align="right">
        <Box displayPrint="none">
          <TextValidator
            variant="outlined"
            label="รับมา"
            margin="none"
            type="number"
            fullWidth
            disabled={disabled}
            value={newAmount}
            inputProps={{
              style: { textAlign: 'right' },
            }}
            validators={[
              'required',
              'isFloat',
              'isPositive',
              `maxFloat:${ownedAmount}`,
            ]}
            errorMessages={[
              VALIDATOR.REQUIRED,
              VALIDATOR.IS_FLOAT,
              VALIDATOR.IS_POSITIVE,
              VALIDATOR.INVALID,
            ]}
            onChange={e => onChange(e.target.value)}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default NewAmountRow;
