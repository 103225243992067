import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function TokenPaginator({ tokens, handlePreviousPage, handleNextPage }) {
  return (
    <Box display="flex">
      {tokens.length > 1 && tokens[0] && (
        <Box mr={1}>
          <Tooltip title="หน้าก่อนหน้า">
            <IconButton onClick={() => handlePreviousPage()}>
              <NavigateBeforeIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {(tokens.length === 0 || tokens[tokens.length - 1]) && (
        <Tooltip title="หน้าถัดไป">
          <IconButton onClick={() => handleNextPage()}>
            <NavigateNextIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

TokenPaginator.propTypes = {
  tokens: PropTypes.arrayOf(PropTypes.string).isRequired,
  handlePreviousPage: PropTypes.func.isRequired,
  handleNextPage: PropTypes.func.isRequired,
};

export default TokenPaginator;
