import React, { useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CreateOrderQueueCardContent from './CreateOrderQueueCardContent';
import CreateOrderPrintQueueCard from './CreateOrderPrint';

export default function CreateOrderQueueCard({
  order,
  orderItems,
  showQueueCard,
  onClose,
}) {
  const printRef = useRef();
  return (
    <Dialog
      open={showQueueCard}
      fullWidth
      maxWidth="xs"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
    >
      <DialogTitle>
        <b>
          {order.sequenceNumber
            ? `เลขที่ใบสั่งซื้อ ${order.sequenceNumber}`
            : 'ไม่มีเลขที่ใบสั่งซื้อ'}
        </b>
      </DialogTitle>
      <DialogContent dividers>
        <CreateOrderQueueCardContent
          ref={printRef}
          order={order}
          orderItems={orderItems}
        />
      </DialogContent>
      <DialogActions>
        <CreateOrderPrintQueueCard
          order={order}
          printRef={printRef}
          orderItems={orderItems}
        />
        <Button variant="contained" color="secondary" onClick={onClose}>
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
}
