import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Container from '@material-ui/core/Container';
import Paper from '../../components/Paper';
import TruckAssignmentListSearchForm from './TruckAssignmentListSearchForm';
import {
  clearSearchParams,
  exportTruckAssignments,
  refreshTruckAssignments,
  searchNextTruckAssignments,
  searchPreviousTruckAssignments,
  searchTruckAssignments,
  setSearchParam,
} from './actions';
import {
  selectAssignments,
  selectExportNextToken,
  selectLoading,
  selectParams,
  selectShowNext,
  selectShowPrevious,
} from './selectors';
import TruckAssignmentSearchResultTable from '../../components/TruckAssignmentSearchResultTable';
import ExportDialog from '../../components/ExportDialog';
import Title from '../../components/Title';

const stateSelector = createStructuredSelector({
  params: selectParams,
  assignments: selectAssignments,
  showNext: selectShowNext,
  showPrevious: selectShowPrevious,
  loading: selectLoading,
  exportNextToken: selectExportNextToken,
});

const TruckAssignmentList = () => {
  const dispatch = useDispatch();
  const {
    params,
    assignments,
    showNext,
    showPrevious,
    loading,
    exportNextToken,
  } = useSelector(stateSelector);
  const [showExportDialog, setShowExportDialog] = useState(false);

  useEffect(() => {
    if (exportNextToken) setShowExportDialog(true);
  }, [exportNextToken]);

  return (
    <Container maxWidth={false}>
      <Paper marginBottom>
        <Title marginBottom title="ค้นหารถเร่" />
        <TruckAssignmentListSearchForm
          params={params}
          loading={loading}
          handleChange={(param, value) =>
            dispatch(setSearchParam(param, value))
          }
          handleReset={() => dispatch(clearSearchParams())}
          handleSubmit={() => dispatch(searchTruckAssignments())}
          handleDownloadClick={() => dispatch(exportTruckAssignments())}
        />
      </Paper>
      {assignments != null && (
        <TruckAssignmentSearchResultTable
          loading={loading}
          showNext={showNext}
          showPrevious={showPrevious}
          assignments={assignments}
          refresh={() => dispatch(refreshTruckAssignments())}
          next={() => dispatch(searchNextTruckAssignments())}
          previous={() => dispatch(searchPreviousTruckAssignments())}
        />
      )}
      <ExportDialog
        show={showExportDialog}
        handleSubmit={() => {
          dispatch(exportTruckAssignments(exportNextToken));
          setShowExportDialog(false);
        }}
        handleClose={() => setShowExportDialog(false)}
      />
    </Container>
  );
};

export default TruckAssignmentList;
