import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import OrderReceiptTableRow from './OrderReceiptTableRow';

function OrderReceiptTable({ orderItems }) {
  return (
    <Box>
      <Typography variant="subtitle1">
        <b>รายการสินค้า</b>
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="none">
              #
            </TableCell>
            <TableCell align="left">สินค้า</TableCell>
            <TableCell align="right">จำนวน</TableCell>
            <TableCell align="right">ราคาสุทธิหน่วยละ</TableCell>
            <TableCell align="right">จำนวนเงิน</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orderItems.map((orderItem, idx) => (
            <OrderReceiptTableRow
              key={orderItem.id}
              idx={idx}
              orderItem={orderItem}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

export default OrderReceiptTable;
