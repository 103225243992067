import { FulfillmentStatus, OrderType } from './models';

export const COMPANY_BRAND_ID = 'MAECHANGAS';

export const DEFAULT_TIMEZONE = 'Asia/Bangkok';

export const AUTH_CHALLENGE_NAME = {
  MFA_SETUP: 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
};

export const USER_GROUP = {
  ADMIN: 'Admin',
  ACCOUNTING: 'Accounting',
  CASHIER: 'Cashier',
  DEBT: 'Debt',
  ORDER: 'Order',
  WAREHOUSE: 'WAREHOUSE',
};

export const SNACKBAR_VARIANT_TYPE = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export const VALIDATOR = {
  REQUIRED: 'ต้องระบุข้อมูลในฟิลล์นี้',
  INVALID: 'ค่าไม่ถูกต้อง',
  EMAIL: 'อีเมลไม่ถูกต้อง',
  IS_NUMBER: 'ค่าไม่ถูกต้อง',
  IS_FLOAT: 'ค่าไม่ถูกต้อง',
  IS_POSITIVE: 'ค่าต้องเป็นจำนวนบวก',
  GREATER_THEN_OR_EQUAL_ONE: 'ค่าต้องมากกว่า 0',
  GREATER_THEN_ONE: 'ค่าต้องมากกว่า 1',
};

export const CUSTOMER_TYPE = {
  MEMBER: 'MEMBER',
  GUEST: 'GUEST',
  OTHER_COMPANY_CUSTOMER: 'OTHER_COMPANY_CUSTOMER',
};

export const CUSTOMER_TYPE_DESC = {
  MEMBER: 'ลูกค้าสมาชิก',
  GUEST: 'ลูกค้าทั่วไป',
  OTHER_COMPANY_CUSTOMER: 'ลูกค้าพิเศษจากบริษัทอื่น',
};

export const PRODUCT_TYPE = {
  LPG: 'LPG',
  CONTAINER: 'CONTAINER',
  SERVICE: 'SERVICE',
};

export const PRODUCT_TYPE_DESC = {
  LPG: 'น้ำแก๊ส',
  SERVICE: 'บริการ',
  CONTAINER: 'ถังแก๊ส',
};

export const PRICE_TYPE = {
  DEFAULT: 'DEFAULT',
  SPECIAL: 'SPECIAL',
  WHOLESALE: 'WHOLESALE',
};

export const ORDER_TYPE = {
  SALES_ORDER: 'SALES_ORDER',
  DELIVERY_ORDER: 'DELIVERY_ORDER',
  TRUCK_SALES_ORDER: 'TRUCK_SALES_ORDER',
  OTHER_COMPANY_CUSTOMER_ORDER: 'OTHER_COMPANY_CUSTOMER_ORDER',
};

export const ORDER_TYPE_DESC = {
  [OrderType.SALES_ORDER]: 'ลูกค้าหน้าร้าน',
  [OrderType.TRUCK_SALES_ORDER]: 'รถเร่',
  [OrderType.DELIVERY_ORDER]: 'ออร์เดอร์จากลูกค้า',
  [OrderType.OTHER_COMPANY_CUSTOMER_ORDER]: 'ออร์เดอร์จากลูกค้าบริษัทอื่น',
};

export const ORDER_STATUS = {
  CREATED: 'CREATED',
  PENDING_PAYMENT: 'PENDING_PAYMENT',
  PENDING_FUELING: 'PENDING_FUELING',
  OVERDUE: 'OVERDUE',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const ORDER_STATUS_DESC = {
  CREATED: 'รอชำระเงิน/รออัด',
  PENDING_PAYMENT: 'รอชำระเงิน',
  PENDING_FUELING: 'รออัด',
  OVERDUE: 'ค้างชำระเงิน',
  COMPLETED: 'เสร็จสมบรูณ์',
  CANCELED: 'ยกเลิก',
};

export const SEQUENCE_NUMBER_TYPE = {
  ORDER_SEQUENCE_NUMBER_ID: 'ORDER',
  CUSTOMER_SEQUENCE_NUMBER_ID: 'CUSTOMER',
};

export const STATION = {
  UNIQUE_GAS_STATION: 'UNIQUE_GAS_STATION',
  WORLD_GAS_STATION: 'WORLD_GAS_STATION',
  PTT_STATION: 'PTT_STATION',
};

export const STATION_DESC = {
  UNIQUE_GAS_STATION: 'โรงยูนิค',
  WORLD_GAS_STATION: 'โรงเวิลด์',
  PTT_STATION: 'โรงปตท.',
};

export const PAYMENT_TYPE = {
  CASH: 'CASH',
  CHEQUE: 'CHEQUE',
  TRANSFER: 'TRANSFER',
};

export const PAYMENT_TYPE_DESC = {
  CASH: 'เงินสด',
  CHEQUE: 'เช็ค',
  TRANSFER: 'โอนเงิน',
};

export const DELIVERY_ROUTE = {
  BANDU_THAKHAOPLUEAK_ROUTE: 'BANDU_THAKHAOPLUEAK_ROUTE',
  DOILUANG_CHIANGSAEN_ROUTE: 'DOILUANG_CHIANGSAEN_ROUTE',
  MAEAI_ROUTE: 'MAEAI_ROUTE',
  MAESAI_ROUTE: 'MAESAI_ROUTE',
  SAIMUN_ROUTE: 'SAIMUN_ROUTE',
  SANLUANG_HUAIKHRAI_ROUTE: 'SANLUANG_HUAIKHRAI_ROUTE',
  WIANGCHAI_THOENG_ROUTE: 'WIANGCHAI_THOENG_ROUTE',
  PT_ROUTE: 'PT_ROUTE',
  PT_GAS_SHOP: 'PT_GAS_SHOP',
  KOUNGKAN_2: 'KOUNGKAN_2',
  WIANGCHIANGRUNG_DOILUANG: 'WIANGCHIANGRUNG_DOILUANG',
  KONGKARN_DOI: 'KONGKARN_DOI',
};

export const DELIVERY_ROUTE_DESC = {
  BANDU_THAKHAOPLUEAK_ROUTE: 'บ้านดู่/ท่าข้าวเปลือก',
  DOILUANG_CHIANGSAEN_ROUTE: 'ดอยหลวง/เชียงแสน',
  MAEAI_ROUTE: 'แม่อาย',
  MAESAI_ROUTE: 'แม่สาย',
  SAIMUN_ROUTE: 'ทรายมลู',
  SANLUANG_HUAIKHRAI_ROUTE: 'สันหลวง/ห้วยไคร้',
  WIANGCHAI_THOENG_ROUTE: 'เวียงชัย/เทิง',
  PT_ROUTE: 'พีที คีออส',
  PT_GAS_SHOP: 'พีที แก๊สชอป',
  KOUNGKAN_2: 'โครงการ 2',
  WIANGCHIANGRUNG_DOILUANG: 'เวียงเชียงรุ้ง/ดอยหลวง',
  KONGKARN_DOI: 'โครงการดอย',
};

export const TRUCK_ASSIGNMENT_STATUS = {
  CREATED: 'CREATED',
  OVERDUE: 'OVERDUE',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
};

export const TRUCK_ASSIGNMENT_STATUS_DESC = {
  CREATED: 'รอชำระเงิน',
  OVERDUE: 'ค้างชำระเงิน',
  COMPLETED: 'เสร็จสมบรูณ์',
  CANCELED: 'ยกเลิก',
};

export const TH_VOWEL_MARK = 'ุูึัี๊้็่๋ิฺื์ํ';

export const FULFILLMENT_STATUS_DESC = {
  [FulfillmentStatus.PENDING]: 'รอบรรจุ',
  [FulfillmentStatus.PARTIALLY_FULFILLED]: 'รอบรรจุบางส่วน',
  [FulfillmentStatus.COMPLETED]: 'เสร็จสมบรูณ์',
  [FulfillmentStatus.CANCELED]: 'ยกเลิก',
};
