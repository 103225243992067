import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';

function CreateOrderTableAddOrderItemsRow({ disabled, onClick }) {
  return (
    <TableRow>
      <TableCell colSpan={3} padding="none" align="center">
        <Box
          p={3}
          width="100%"
          display="flex"
          bgcolor="grey.100"
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          onClick={disabled ? null : onClick}
        >
          <AddIcon color="disabled" fontSize="large" />
          <Typography variant="body1" color="textSecondary">
            เพิ่มรายการสินค้า
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default CreateOrderTableAddOrderItemsRow;
