import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment-timezone';
import withStyles from '@material-ui/core/styles/withStyles';
import { ORDER_TYPE_DESC, STATION_DESC } from '../../constants';

const StyledTextValidator = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'black',
    },
  },
})(TextValidator);

function OrderReceiptHeader({ orderHeader }) {
  const {
    name,
    type,
    sequenceNumber,
    station,
    comment,
    licensePlateNumber,
    createdAt,
  } = orderHeader;

  return (
    <ValidatorForm onSubmit={() => {}}>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {sequenceNumber && (
          <Grid item xs={6}>
            <StyledTextValidator
              label="เลขที่ใบสั่งซื้อ"
              margin="none"
              size="small"
              fullWidth
              disabled
              value={sequenceNumber}
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <StyledTextValidator
            label="ชื่อลูกค้า"
            margin="none"
            size="small"
            fullWidth
            disabled
            value={name || ''}
          />
        </Grid>

        <Grid item xs={4}>
          <StyledTextValidator
            label="ประเภท"
            margin="none"
            size="small"
            fullWidth
            disabled
            value={type ? ORDER_TYPE_DESC[type] : ''}
          />
        </Grid>

        {licensePlateNumber && (
          <Grid item xs={4}>
            <StyledTextValidator
              label="ทะเบียนรถยนต์"
              margin="none"
              size="small"
              fullWidth
              disabled
              value={licensePlateNumber}
            />
          </Grid>
        )}

        {station && (
          <Grid item xs={4}>
            <StyledTextValidator
              label="โรงอัดแก๊ส"
              margin="none"
              size="small"
              fullWidth
              disabled
              value={STATION_DESC[station]}
            />
          </Grid>
        )}

        {createdAt && (
          <Grid item xs={4}>
            <StyledTextValidator
              label="วันที่ขาย"
              margin="none"
              size="small"
              disabled
              fullWidth
              value={moment(createdAt)
                .tz('Asia/Bangkok')
                .format('DD/MM/YYYY HH:mm')}
            />
          </Grid>
        )}

        {comment && (
          <Grid item xs={4}>
            <StyledTextValidator
              label="หมายเหตุ"
              margin="none"
              size="small"
              fullWidth
              disabled
              value={comment}
            />
          </Grid>
        )}
      </Grid>
    </ValidatorForm>
  );
}

export default OrderReceiptHeader;
