import { all, fork } from 'redux-saga/effects';
import orderSaga from './containers/Order/saga';
import debtorSaga from './containers/Debtor/saga';
import cashierSaga from './containers/Cashier/saga';
import orderListSaga from './containers/OrderList/saga';
import debtorListSaga from './containers/DebtorList/saga';
import truckAssignmentSaga from './containers/TruckAssignment/saga';
import truckAssignmentListSaga from './containers/TruckAssignmentList/saga';
import overdueTruckAssignmentListSaga from './containers/OverdueTruckAssignmentList/saga';

function* createSaga() {
  yield all([
    fork(orderSaga),
    fork(debtorSaga),
    fork(cashierSaga),
    fork(orderListSaga),
    fork(debtorListSaga),
    fork(truckAssignmentSaga),
    fork(truckAssignmentListSaga),
    fork(overdueTruckAssignmentListSaga),
  ]);
}

export default createSaga;
