import React, { useState } from 'react';
import MaterialAppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

function AppBar({ onShowDrawerClick, onSignOut }) {
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  return (
    <MaterialAppBar position="static">
      <Toolbar>
        <Box mr={2}>
          <IconButton edge="start" color="inherit" onClick={onShowDrawerClick}>
            <MenuIcon />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Typography variant="h6">แม่จันแก๊ส</Typography>
        </Box>
        <IconButton
          color="inherit"
          onClick={e => setAccountAnchorEl(e.currentTarget)}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={accountAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!accountAnchorEl}
          onClose={() => setAccountAnchorEl(null)}
        >
          <MenuItem onClick={onSignOut}>ออกจากระบบ</MenuItem>
        </Menu>
      </Toolbar>
    </MaterialAppBar>
  );
}

export default AppBar;
