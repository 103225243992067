import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createInjectorsEnhancer } from 'redux-injectors';
import { composeWithDevTools } from 'redux-devtools-extension';
import createReducer from './reducers';
import createSaga from './saga';

function configureStore() {
  const composeEnhancers = composeWithDevTools;

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];

  const runSaga = sagaMiddleware.run;

  const enhancers = [
    applyMiddleware(...middlewares),
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = createStore(createReducer(), composeEnhancers(...enhancers));

  sagaMiddleware.run(createSaga);

  return store;
}

export default configureStore;
