import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

function Spinner() {
  return (
    <Box
      width="100%"
      display="flex"
      minHeight="100vh"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
    >
      <Box mr={2}>
        <CircularProgress color="primary" size={35} />
      </Box>
      {/* <Typography variant="body1"> */}
      {/*  กำลังโหลด... */}
      {/* </Typography> */}
    </Box>
  );
}

export default Spinner;
