import React from 'react';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import ImageList from './ImageList';
import withSpinner from '../WithSpinner';
import Paper from '../Paper';
import Uploader from './Uploader';
import Title from '../Title';

/* eslint-disable react/no-array-index-key */
function Album({ images, disabled, onAdd, onRemove, marginTop = false }) {
  return (
    <Paper marginTop={marginTop}>
      <Title title="รูปถ่าย" marginBottom />
      {!isEmpty(images) && (
        <Box mt={1}>
          <ImageList images={images} disabled={disabled} onRemove={onRemove} />
        </Box>
      )}
      <Box mt={1}>
        <Uploader disabled={disabled} onAdd={onAdd} />
      </Box>
    </Paper>
  );
}

export default withSpinner(Album);
