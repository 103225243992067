import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import { S3Image } from 'aws-amplify-react';
import Divider from '@material-ui/core/Divider';
import OrderItemDialogProduct from './OrderItemDialogProduct';

function OrderItemDialogProductGrid({ products, items, onClick }) {
  const brand = _.get(products, '0.brand');
  const { name, abbr, logo } = brand;
  const imgKey = _.get(logo, 'key');
  return (
    <Box>
      <Box my={2} display="flex" alignItems="center">
        <Box mr={1}>
          {imgKey ? (
            <Avatar component={S3Image} imgKey={imgKey} />
          ) : (
            <Avatar>{abbr}</Avatar>
          )}
        </Box>
        <Box mr={1}>
          <Typography variant="subtitle1">{name}</Typography>
        </Box>
        <Box flexGrow={1}>
          <Divider />
        </Box>
      </Box>
      <Grid container spacing={2}>
        {products.map(product => (
          <Grid key={product.id} item xs={12} sm={4} md={3}>
            <OrderItemDialogProduct
              product={product}
              onClick={() => onClick(product)}
              checked={_.some(items, ['productId', product.id])}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default OrderItemDialogProductGrid;
