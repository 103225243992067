/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput) {
    createPayment(input: $input) {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createOrUpdateWorkflowItems = /* GraphQL */ `
  mutation CreateOrUpdateWorkflowItems(
    $input: CreateOrUpdateWorkflowItemsInput
  ) {
    createOrUpdateWorkflowItems(input: $input) {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createOrUpdateTruckAssignmentWorkflowItems = /* GraphQL */ `
  mutation CreateOrUpdateTruckAssignmentWorkflowItems(
    $input: CreateOrUpdateTruckAssignmentWorkflowItemsInput
  ) {
    createOrUpdateTruckAssignmentWorkflowItems(input: $input) {
      id
      driver
      companion
      station
      deliveryRouteId
      licensePlate
      sequenceNumber
      comment
      departure
      status
      paymentStatus
      fulfillmentStatus
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryRoute {
        id
        name
        licensePlate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customers {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const batchUpdateOrders = /* GraphQL */ `
  mutation BatchUpdateOrders($orders: [BatchUpdateOrderInput]) {
    batchUpdateOrders(orders: $orders) {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createInbound = /* GraphQL */ `
  mutation CreateInbound($input: CreateInboundInput) {
    createInbound(input: $input) {
      id
      warehouseId
      createdByUserId
      updatedByUserId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      inventoryItems {
        items {
          id
          inboundId
          productId
          quantity
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      warehouse {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateInbound = /* GraphQL */ `
  mutation UpdateInbound($input: CreateInboundInput) {
    updateInbound(input: $input) {
      id
      warehouseId
      createdByUserId
      updatedByUserId
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      createdBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      updatedBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      inventoryItems {
        items {
          id
          inboundId
          productId
          quantity
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      warehouse {
        id
        name
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const createTruckAssignment = /* GraphQL */ `
  mutation CreateTruckAssignment($input: CreateTruckAssignmentInput) {
    createTruckAssignment(input: $input) {
      id
      driver
      companion
      station
      deliveryRouteId
      licensePlate
      sequenceNumber
      comment
      departure
      status
      paymentStatus
      fulfillmentStatus
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryRoute {
        id
        name
        licensePlate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customers {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createTruckAssignmentPayment = /* GraphQL */ `
  mutation CreateTruckAssignmentPayment(
    $input: CreateTruckAssignmentPaymentInput
  ) {
    createTruckAssignmentPayment(input: $input) {
      id
      driver
      companion
      station
      deliveryRouteId
      licensePlate
      sequenceNumber
      comment
      departure
      status
      paymentStatus
      fulfillmentStatus
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryRoute {
        id
        name
        licensePlate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customers {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      debt
      beginningBalance
      deliveryRouteId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      discounts {
        items {
          id
          discount
          productId
          customerId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      debt
      beginningBalance
      deliveryRouteId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      discounts {
        items {
          id
          discount
          productId
          customerId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      type
      sequenceNumber
      name
      phoneNumber
      email
      address
      subdistrict
      district
      province
      postalCode
      debt
      beginningBalance
      deliveryRouteId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      discounts {
        items {
          id
          discount
          productId
          customerId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      name
      abbr
      logo {
        bucket
        region
        level
        key
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      products {
        items {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      name
      abbr
      logo {
        bucket
        region
        level
        key
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      products {
        items {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      name
      abbr
      logo {
        bucket
        region
        level
        key
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      products {
        items {
          id
          name
          brandId
          weight
          type
          isTruckAssignmentProduct
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      name
      brandId
      weight
      type
      isTruckAssignmentProduct
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        name
        abbr
        logo {
          bucket
          region
          level
          key
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      prices {
        items {
          id
          type
          price
          productId
          conditionDesc
          conditionValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      name
      brandId
      weight
      type
      isTruckAssignmentProduct
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        name
        abbr
        logo {
          bucket
          region
          level
          key
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      prices {
        items {
          id
          type
          price
          productId
          conditionDesc
          conditionValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      name
      brandId
      weight
      type
      isTruckAssignmentProduct
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      brand {
        id
        name
        abbr
        logo {
          bucket
          region
          level
          key
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        products {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      prices {
        items {
          id
          type
          price
          productId
          conditionDesc
          conditionValue
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createPrice = /* GraphQL */ `
  mutation CreatePrice(
    $input: CreatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    createPrice(input: $input, condition: $condition) {
      id
      type
      price
      productId
      conditionDesc
      conditionValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updatePrice = /* GraphQL */ `
  mutation UpdatePrice(
    $input: UpdatePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    updatePrice(input: $input, condition: $condition) {
      id
      type
      price
      productId
      conditionDesc
      conditionValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deletePrice = /* GraphQL */ `
  mutation DeletePrice(
    $input: DeletePriceInput!
    $condition: ModelPriceConditionInput
  ) {
    deletePrice(input: $input, condition: $condition) {
      id
      type
      price
      productId
      conditionDesc
      conditionValue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount(
    $input: CreateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    createDiscount(input: $input, condition: $condition) {
      id
      discount
      productId
      customerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount(
    $input: UpdateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    updateDiscount(input: $input, condition: $condition) {
      id
      discount
      productId
      customerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount(
    $input: DeleteDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    deleteDiscount(input: $input, condition: $condition) {
      id
      discount
      productId
      customerId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      customerId
      sequenceNumber
      status
      paymentStatus
      fulfillmentStatus
      type
      station
      comment
      createdBy
      licensePlateNumber
      truckAssignmentId
      images {
        bucket
        region
        level
        key
        __typename
      }
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        type
        sequenceNumber
        name
        phoneNumber
        email
        address
        subdistrict
        district
        province
        postalCode
        debt
        beginningBalance
        deliveryRouteId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        discounts {
          nextToken
          startedAt
          __typename
        }
        orders {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      adjustments {
        items {
          id
          orderId
          amount
          comment
          createdByUserId
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      workflows {
        items {
          id
          orderId
          truckAssignmentId
          station
          comment
          userId
          createdDate
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      __typename
    }
  }
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      orderId
      truckAssignmentId
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      orderId
      truckAssignmentId
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      orderId
      truckAssignmentId
      productId
      quantity
      unitPrice
      adjustment
      comment
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      product {
        id
        name
        brandId
        weight
        type
        isTruckAssignmentProduct
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        brand {
          id
          name
          abbr
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        prices {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createAdjustment = /* GraphQL */ `
  mutation CreateAdjustment(
    $input: CreateOrderAdjustmentInput!
    $condition: ModelOrderAdjustmentConditionInput
  ) {
    createAdjustment(input: $input, condition: $condition) {
      id
      orderId
      amount
      comment
      createdByUserId
      createdAt
      _version
      _deleted
      _lastChangedAt
      createdBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteAdjustment = /* GraphQL */ `
  mutation DeleteAdjustment(
    $input: DeleteOrderAdjustmentInput!
    $condition: ModelOrderAdjustmentConditionInput
  ) {
    deleteAdjustment(input: $input, condition: $condition) {
      id
      orderId
      amount
      comment
      createdByUserId
      createdAt
      _version
      _deleted
      _lastChangedAt
      createdBy {
        id
        firstName
        lastName
        userGroup
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      orderId
      truckAssignmentId
      amount
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      orderId
      truckAssignmentId
      amount
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateTruckAssignment = /* GraphQL */ `
  mutation UpdateTruckAssignment(
    $input: UpdateTruckAssignmentInput!
    $condition: ModelTruckAssignmentConditionInput
  ) {
    updateTruckAssignment(input: $input, condition: $condition) {
      id
      driver
      companion
      station
      deliveryRouteId
      licensePlate
      sequenceNumber
      comment
      departure
      status
      paymentStatus
      fulfillmentStatus
      createdDate
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      orders {
        items {
          id
          customerId
          sequenceNumber
          status
          paymentStatus
          fulfillmentStatus
          type
          station
          comment
          createdBy
          licensePlateNumber
          truckAssignmentId
          createdDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderItems {
        items {
          id
          orderId
          truckAssignmentId
          productId
          quantity
          unitPrice
          adjustment
          comment
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      payments {
        items {
          id
          orderId
          truckAssignmentId
          amount
          type
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deliveryRoute {
        id
        name
        licensePlate
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        customers {
          nextToken
          startedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createSequenceNumber = /* GraphQL */ `
  mutation CreateSequenceNumber(
    $input: CreateSequenceNumberInput!
    $condition: ModelSequenceNumberConditionInput
  ) {
    createSequenceNumber(input: $input, condition: $condition) {
      id
      prefix
      sequenceNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSequenceNumber = /* GraphQL */ `
  mutation UpdateSequenceNumber(
    $input: UpdateSequenceNumberInput!
    $condition: ModelSequenceNumberConditionInput
  ) {
    updateSequenceNumber(input: $input, condition: $condition) {
      id
      prefix
      sequenceNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSequenceNumber = /* GraphQL */ `
  mutation DeleteSequenceNumber(
    $input: DeleteSequenceNumberInput!
    $condition: ModelSequenceNumberConditionInput
  ) {
    deleteSequenceNumber(input: $input, condition: $condition) {
      id
      prefix
      sequenceNumber
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
