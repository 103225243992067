import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextValidator } from 'react-material-ui-form-validator';
import { VALIDATOR } from '../../constants';

/* eslint-disable react/jsx-props-no-spreading */
function AsyncAutoComplete(props) {
  const {
    label,
    options,
    inputValue,
    placeholder,
    onChange,
    onInputChange,
    open = false,
    loading = false,
    freeSolo = false,
    required = false,
    ...others
  } = props;

  return (
    <Autocomplete
      open={open}
      options={options}
      loading={loading}
      freeSolo={freeSolo}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      clearText=""
      noOptionsText="ไม่พบข้อมูล"
      loadingText="กำลังโหลด..."
      renderInput={params => (
        <TextValidator
          {...params}
          fullWidth
          variant="outlined"
          label={label}
          value={inputValue}
          placeholder={placeholder}
          validators={required ? ['required'] : []}
          errorMessages={required ? [VALIDATOR.REQUIRED] : []}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...others}
    />
  );
}

/* eslint-disable react/require-default-props, react/forbid-prop-types */
AsyncAutoComplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  open: PropTypes.bool,
  required: PropTypes.bool,
  loading: PropTypes.bool,
  freeSolo: PropTypes.bool,
};

export default AsyncAutoComplete;
