import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

interface ExportDialogProps {
  show: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

function ExportDialog({ show, handleSubmit, handleClose }: ExportDialogProps) {
  return (
    <Dialog
      open={show}
      fullWidth
      maxWidth="xs"
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
    >
      <DialogTitle>
        <b>กรุณาดาวน์โหลดข้อมูลชุดต่อไป</b>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          ข้อมูลมีมากกว่าจำนวนจำกัด กรุณาดาวน์โหลดข้อมูลชุดต่อไป
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          ดาวน์โหลด
        </Button>
        <Button onClick={handleClose}>ปิด</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExportDialog;
