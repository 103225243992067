import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import CustomerConfirmButtons from './CustomerConfirmButtons';

function CustomerPriceTableContainer({
  disabled,
  onReset,
  onAddDiscountItem,
  onEnableUpdate,
  onCancelUpdate,
  children,
}) {
  return (
    <Paper variant="outlined">
      <Box p={{ xs: 2, md: 3 }}>
        <Typography variant="h6">ปรับราคา</Typography>
      </Box>
      <Divider />
      <Box overflow="auto">{children}</Box>
      <Box p={{ xs: 2, md: 3 }}>
        <CustomerConfirmButtons
          disabled={disabled}
          onReset={onReset}
          onAddDiscountItem={onAddDiscountItem}
          onEnableUpdate={onEnableUpdate}
          onCancelUpdate={onCancelUpdate}
        />
      </Box>
    </Paper>
  );
}

export default CustomerPriceTableContainer;
