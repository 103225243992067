import React, { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import createOrderReducerCreator from './reducer';
import createOrderSagaCreator from './saga';
import {
  makeSelectDisabled,
  makeSelectDisabledSelectCustomer,
  makeSelectImages,
  makeSelectLoading,
  makeSelectOrder,
  makeSelectOrderItems,
  makeSelectShowOrderItemDialog,
  makeSelectShowQueueCard,
  makeSelectShowSequenceNumber,
  makeSelectShowStatus,
} from './selectors';
import {
  addImages,
  createOrder,
  getCustomer,
  hideOrderItemDialog,
  hideQueueCard,
  initCreateDebt,
  removeImage,
  removeOrderItem,
  resetOrder,
  setCustomer,
  setCustomerName,
  setOrder,
  setShowOrderItemDialog,
  showQueueCard,
  toggleOrderItem,
  updateOrderItemProductId,
  updateOrderItemQuantity,
} from './actions';
import CreateOrderTable from '../../components/CreateOrderTable';
import OrderItemDialog from '../../components/OrderItemDialog';
import CreateOrderQueueCard from './CreateOrderQueueCard';
import { CREATE_DEBT_STORE_KEY, CREATE_ORDER_STORE_KEY } from './constants';
import { selectGroupedByTypeProducts, selectProducts } from '../App/selectors';
import { getProducts } from '../App/actions';
import ImageUploader from '../../components/ImageUploader';
import CreateOrderForm from './CreateOrderForm';
import { isNotEmpty } from '../../utils/validators';
import useHasPermission from '../../utils/useHasPermission';

const createStateSelector = key =>
  createStructuredSelector({
    products: selectProducts,
    groupedByTypeProducts: selectGroupedByTypeProducts,
    order: makeSelectOrder(key),
    orderItems: makeSelectOrderItems(key),
    images: makeSelectImages(key),
    loading: makeSelectLoading(key),
    disabled: makeSelectDisabled(key),
    showStatus: makeSelectShowStatus(key),
    showQueueCard: makeSelectShowQueueCard(key),
    showSequenceNumber: makeSelectShowSequenceNumber(key),
    showOrderItemDialog: makeSelectShowOrderItemDialog(key),
    disabledSelectCustomer: makeSelectDisabledSelectCustomer(key),
  });

function CreateOrder() {
  const isCreateDebt = useRouteMatch('/createDebt/:customerId?');
  const key = isCreateDebt ? CREATE_DEBT_STORE_KEY : CREATE_ORDER_STORE_KEY;
  const { customerId } = useParams();
  useInjectReducer({ key, reducer: createOrderReducerCreator(key) });
  useInjectSaga({ key, saga: createOrderSagaCreator(key) });
  const dispatch = useDispatch();
  const {
    order,
    orderItems,
    images,
    products,
    groupedByTypeProducts,
    loading,
    disabled,
    showStatus,
    showSequenceNumber,
    showOrderItemDialog,
    disabledSelectCustomer,
    showQueueCard: openQueueCard,
  } = useSelector(createStateSelector(key));
  const showPrintOption = isNotEmpty(order, 'id');
  const showOrderLink = useHasPermission(['Admin']) && isNotEmpty(order, 'id');

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomer(key, customerId));
    }
  }, [customerId]);

  useEffect(() => {
    if (isCreateDebt) {
      dispatch(initCreateDebt(key));
    }
  }, [customerId, isCreateDebt]);

  const handleCustomerChange = (e, customer) => {
    if (isNotEmpty(customer)) {
      const { id, name } = customer;
      dispatch(setCustomer(key, id, name));
    } else {
      dispatch(setCustomer(key, null, ''));
    }
  };

  return (
    <Container>
      <ValidatorForm onSubmit={() => dispatch(createOrder(key))} noValidate>
        <CreateOrderForm
          order={order}
          loading={loading}
          disabled={disabled}
          onChange={(field, e) =>
            dispatch(setOrder(key, field, e.target.value))
          }
          showStatus={showStatus}
          showOrderLink={showOrderLink}
          showPrintOption={showPrintOption}
          showSequenceNumber={showSequenceNumber}
          disabledSelectCustomer={disabledSelectCustomer}
          onCustomerChange={handleCustomerChange}
          onPrintClick={() => dispatch(showQueueCard(key))}
          onCustomerNameChange={name => dispatch(setCustomerName(key, name))}
          onAddOrderItemsClick={() => dispatch(setShowOrderItemDialog(key))}
        />
        <ImageUploader
          marginTop
          images={images}
          loading={loading}
          disabled={disabled}
          onAdd={images => dispatch(addImages(key, images))}
          onRemove={idx => dispatch(removeImage(key, idx))}
        />
        <CreateOrderTable
          order={order}
          loading={loading}
          disabled={disabled}
          products={products}
          orderItems={orderItems}
          showOrderLink={showOrderLink}
          showPrintOption={showPrintOption}
          onChange={(idx, productId) =>
            dispatch(updateOrderItemProductId(key, idx, productId))
          }
          onChangeQuantity={(idx, quantity) =>
            dispatch(updateOrderItemQuantity(key, idx, quantity))
          }
          onPrintClick={() => dispatch(showQueueCard(key))}
          onReset={() => dispatch(resetOrder(key))}
          onDelete={idx => dispatch(removeOrderItem(key, idx))}
          onAddOrderItemsClick={() => dispatch(setShowOrderItemDialog(key))}
        />
      </ValidatorForm>
      <OrderItemDialog
        groupedByTypeProducts={groupedByTypeProducts}
        items={orderItems}
        open={showOrderItemDialog}
        onClick={product => dispatch(toggleOrderItem(key, product))}
        onClose={() => dispatch(hideOrderItemDialog(key))}
      />
      {isNotEmpty(order, 'id') && (
        <CreateOrderQueueCard
          order={order}
          orderItems={orderItems}
          showQueueCard={openQueueCard}
          onClose={() => dispatch(hideQueueCard(key))}
        />
      )}
    </Container>
  );
}

export default CreateOrder;
