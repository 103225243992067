import React from 'react';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import withSpinner from '../WithSpinner';
import PaddingBox from '../PaddingBox';
import Paper from '../Paper';
import Title from '../Title';

function Container({
  alert,
  title,
  chip,
  rightTopMenu,
  children,
  footer,
  marginTop = true,
  dividers = true,
  bodyPadding = false,
  scrollableBody = true,
  footerPadding = true,
}) {
  return (
    <Paper marginTop={marginTop} padding={false}>
      <PaddingBox pb={dividers ? { xs: 1, sm: 2 } : 0}>
        {alert && <Box mb={1}>{alert}</Box>}
        <Title title={title} chip={chip} right={rightTopMenu} />
      </PaddingBox>
      {dividers && <Divider />}
      {bodyPadding ? (
        <PaddingBox my="-1px" overflow={scrollableBody ? 'auto' : 'visible'}>
          {children}
        </PaddingBox>
      ) : (
        <Box my="-1px" overflow="auto">
          {children}
        </Box>
      )}
      {footer && dividers && <Divider />}
      {footer && (footerPadding ? <PaddingBox>{footer}</PaddingBox> : footer)}
    </Paper>
  );
}

export default withSpinner(Container);
