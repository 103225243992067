import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import reducer from './reducer';
import saga from './saga';
import AppBar from './AppBar';
import Drawer from '../Drawer';
import Dashboard from '../../components/Dashboard';
import SignInForm from './SignInForm';
import { CustomerList } from '../../modules/customer';
import { Product, ProductList } from '../../modules/product';
import ProtectedRoute from './ProtectedRoute';
import Order from '../Order';
import TruckAssignmentList from '../TruckAssignmentList';
import TruckAssignment from '../TruckAssignment';
import CreateOrUpdateTruckAssignment from '../CreateOrUpdateTruckAssignment';
import OverdueTruckAssignmentList from '../OverdueTruckAssignmentList';
import OrderList from '../OrderList';
import DebtorList from '../DebtorList';
import Cashier from '../Cashier';
import Debtor from '../Debtor';
import CreateOrder from '../CreateOrder';
import Customer from '../Customer';
import Report from '../Report';
import FulfillmentReport from '../FulfillmentReport';
import {
  selectErrorMessage,
  selectInitializing,
  selectLoading,
  selectShowDrawer,
  selectShowErrorMessage,
  selectShowSuccessMessage,
  selectShowWarningMessage,
  selectSignInResult,
  selectSuccessMessage,
  selectTotpCode,
  selectWarningMessage,
} from './selectors';
import {
  getCurrentAuthenticatedUser,
  hideErrorMessage,
  hideSuccessMessage,
  hideWarningMessage,
  setHideDrawer,
  setShowDrawer,
  setUpMfa,
  signIn,
  signOut,
  verifyMfaCode,
} from './actions';
import SignInMfaVerificationForm from './SignInMfaVerificationForm';
import {
  AUTH_CHALLENGE_NAME,
  SNACKBAR_VARIANT_TYPE,
  USER_GROUP,
} from '../../constants';
import withTheme from './WithTheme';
import SignInMfaSetupForm from './SignInMfaSetupForm';
import CreateInbound from '../CreateInbound';
import Spinner from './Spinner';
import { isNotEmpty } from '../../utils/validators';
// import { withAuthenticator } from 'aws-amplify-react';

const key = 'app';

const stateSelector = createStructuredSelector({
  totpCode: selectTotpCode,
  loading: selectLoading,
  initializing: selectInitializing,
  showDrawer: selectShowDrawer,
  successMessage: selectSuccessMessage,
  showSuccessMessage: selectShowSuccessMessage,
  errorMessage: selectErrorMessage,
  showErrorMessage: selectShowErrorMessage,
  warningMessage: selectWarningMessage,
  showWarningMessage: selectShowWarningMessage,
  signInResult: selectSignInResult,
});

/* eslint-disable no-nested-ternary */
function App() {
  const { enqueueSnackbar } = useSnackbar();
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const {
    totpCode,
    loading,
    initializing,
    showDrawer,
    successMessage,
    showSuccessMessage,
    errorMessage,
    showErrorMessage,
    warningMessage,
    showWarningMessage,
    signInResult,
  } = useSelector(stateSelector);

  useEffect(() => {
    dispatch(getCurrentAuthenticatedUser());
  }, []);

  useEffect(() => {
    if (showSuccessMessage) {
      enqueueSnackbar(successMessage || 'บันทึกข้อมูลสำเร็จ', {
        variant: SNACKBAR_VARIANT_TYPE.SUCCESS,
      });
      dispatch(hideSuccessMessage());
    }
  }, [dispatch, enqueueSnackbar, successMessage, showSuccessMessage]);

  useEffect(() => {
    if (showErrorMessage) {
      enqueueSnackbar(errorMessage || 'ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
      dispatch(hideErrorMessage());
    }
  }, [dispatch, enqueueSnackbar, errorMessage, showErrorMessage]);

  useEffect(() => {
    if (showWarningMessage) {
      enqueueSnackbar(warningMessage, {
        variant: SNACKBAR_VARIANT_TYPE.WARNING,
      });
      dispatch(hideWarningMessage());
    }
  }, [dispatch, enqueueSnackbar, warningMessage, showWarningMessage]);

  if (initializing) {
    return <Spinner />;
  }

  if (isEmpty(signInResult)) {
    return (
      <SignInForm
        loading={loading}
        onSubmit={(username, password) => dispatch(signIn(username, password))}
      />
    );
  }

  if (
    isNotEmpty(signInResult) &&
    signInResult.challengeName === AUTH_CHALLENGE_NAME.MFA_SETUP &&
    totpCode
  ) {
    return (
      <SignInMfaSetupForm
        loading={loading}
        totpCode={totpCode}
        onSubmit={mfaCode => dispatch(setUpMfa(mfaCode))}
      />
    );
  }

  if (
    isNotEmpty(signInResult) &&
    signInResult.challengeName === AUTH_CHALLENGE_NAME.SOFTWARE_TOKEN_MFA
  ) {
    return (
      <SignInMfaVerificationForm
        loading={loading}
        onSubmit={mfaCode => dispatch(verifyMfaCode(mfaCode))}
      />
    );
  }

  return (
    <Router>
      <AppBar
        onShowDrawerClick={() => dispatch(setShowDrawer())}
        onSignOut={() => dispatch(signOut())}
      />
      <Drawer
        showDrawer={showDrawer}
        onClose={() => dispatch(setHideDrawer())}
      />
      <Box py={3}>
        <Switch>
          {/* Dashboard */}
          <Route exact path="/" component={Dashboard} />

          {/* Customer */}
          <ProtectedRoute
            exact
            path="/customer"
            allowed={[USER_GROUP.ADMIN, USER_GROUP.ACCOUNTING, USER_GROUP.DEBT]}
            component={CustomerList}
          />
          <ProtectedRoute
            path="/customer/:customerId?"
            allowed={[USER_GROUP.ADMIN, USER_GROUP.ACCOUNTING, USER_GROUP.DEBT]}
            component={Customer}
          />
          <ProtectedRoute
            path="/createCustomer"
            allowed={[USER_GROUP.ADMIN, USER_GROUP.ORDER]}
            component={Customer}
          />

          {/* Cashier */}
          <ProtectedRoute
            exact
            path="/cashier"
            allowed={[USER_GROUP.ADMIN, USER_GROUP.CASHIER]}
            component={Cashier}
          />

          {/* Order */}
          <ProtectedRoute
            exact
            path="/order"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={OrderList}
          />
          <ProtectedRoute
            path="/order/:orderId"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={Order}
          />
          <ProtectedRoute
            path="/createOrder"
            allowed={[USER_GROUP.ADMIN, USER_GROUP.ORDER]}
            component={CreateOrder}
          />

          {/* Truck Assignment */}
          <ProtectedRoute
            exact
            path="/truckAssignments"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={TruckAssignmentList}
          />
          <ProtectedRoute
            path="/overdueTruckAssignments"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={OverdueTruckAssignmentList}
          />
          <ProtectedRoute
            path="/truckAssignments/:truckAssignmentId"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={TruckAssignment}
          />
          <ProtectedRoute
            path={[
              '/createOrUpdateTruckAssignment/:truckAssignmentId',
              '/createOrUpdateTruckAssignment',
            ]}
            allowed={[USER_GROUP.ADMIN, USER_GROUP.CASHIER]}
            component={CreateOrUpdateTruckAssignment}
          />

          {/* Debtor */}
          <ProtectedRoute
            exact
            path="/debtor"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={DebtorList}
          />
          <ProtectedRoute
            path="/debtor/:customerId?"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={Debtor}
          />
          <ProtectedRoute
            path="/createDebt/:customerId?"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
              USER_GROUP.DEBT,
            ]}
            component={CreateOrder}
          />

          {/* Product */}
          <ProtectedRoute
            exact
            path="/product"
            allowed={[USER_GROUP.ADMIN]}
            component={ProductList}
          />
          <ProtectedRoute
            path="/product/:productId?"
            allowed={[USER_GROUP.ADMIN]}
            component={Product}
          />
          <ProtectedRoute
            path="/createProduct"
            allowed={[USER_GROUP.ADMIN]}
            component={Product}
          />

          {/* Inventory */}
          <ProtectedRoute
            exact
            path="/createInbound"
            allowed={[USER_GROUP.ADMIN]}
            component={CreateInbound}
          />

          {/* Report */}
          <ProtectedRoute
            path="/report"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
            ]}
            component={Report}
          />
          <ProtectedRoute
            path="/fulfillmentReport"
            allowed={[
              USER_GROUP.ADMIN,
              USER_GROUP.ACCOUNTING,
              USER_GROUP.CASHIER,
            ]}
            component={FulfillmentReport}
          />
        </Switch>
      </Box>
    </Router>
  );
}

export default withTheme(App);
