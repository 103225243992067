import React from 'react';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import CustomerSearch from '../../modules/customer/components/CustomerSearch';
import ButtonLoader from '../../components/ButtonLoader';
import StationSelectValidator from '../../components/StationSelectValidator';
import OrderTypeSelectValidator from '../../components/OrderTypeSelectValidator';
import StatusSelectValidator from '../../components/StatusSelectValidator';

function OrderListSearchForm(props) {
  const { params, onChange, onSubmit, onExport, onReset, loading } = props;

  const { types, statuses, stations, startDate, endDate, sequenceNumber } =
    params;

  const handleSelectCustomer = (e, customer) =>
    onChange('customerId', get(customer, 'id', null));

  return (
    <ValidatorForm onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <CustomerSearch
            disabled={loading}
            placeholder="ค้นหาลูกค้า (โดยชื่อหรือเบอร์โทร)"
            onChange={handleSelectCustomer}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextValidator
            label="เลขที่ใบสั่งซื้อ"
            variant="outlined"
            margin="none"
            fullWidth
            multiple
            disabled={loading}
            input={<Input />}
            value={sequenceNumber || ''}
            onChange={e => onChange('sequenceNumber', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StationSelectValidator
            stations={stations}
            onChange={e => onChange('stations', e.target.value)}
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OrderTypeSelectValidator
            orderTypes={types}
            onChange={e => onChange('types', e.target.value)}
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StatusSelectValidator
            statuses={statuses}
            onChange={e => onChange('statuses', e.target.value)}
            disabled={loading}
          />
        </Grid>

        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid item xs={12} sm={6} md={4}>
            <KeyboardDatePicker
              margin="none"
              variant="inline"
              label="วันที่ (เริ่มต้น)"
              format="DD/MM/YYYY"
              inputVariant="outlined"
              InputAdornmentProps={{ position: 'start' }}
              invalidDateMessage="วันที่ไม่ถูกต้อง"
              fullWidth
              disableFuture
              value={startDate}
              disabled={loading}
              maxDate={endDate || moment().add(1, 'days')}
              onChange={startDate =>
                onChange(
                  'startDate',
                  startDate ? startDate.startOf('day') : null,
                )
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <KeyboardDatePicker
              margin="none"
              variant="inline"
              label="วันที่ (สิ้นสุด)"
              format="DD/MM/YYYY"
              inputVariant="outlined"
              InputAdornmentProps={{ position: 'start' }}
              invalidDateMessage="วันที่ไม่ถูกต้อง"
              fullWidth
              disableFuture
              minDate={startDate || moment('19000101')}
              value={endDate}
              disabled={loading}
              onChange={endDate =>
                onChange('endDate', endDate ? endDate.endOf('day') : null)
              }
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
      <Box mt={2} display="flex" flexWrap="wrap">
        <Box mt={1} mr={1}>
          <ButtonLoader
            type="submit"
            color="primary"
            variant="contained"
            loading={loading}
          >
            ค้นหา
          </ButtonLoader>
        </Box>
        <Box mt={1} mr={1}>
          <Button
            color="primary"
            variant="contained"
            onClick={onExport}
            disabled={loading}
          >
            ดาวน์โหลด
          </Button>
        </Box>
        <Box mt={1}>
          <Button
            variant="contained"
            color="secondary"
            type="reset"
            onClick={onReset}
            disabled={loading}
          >
            ยกเลิก
          </Button>
        </Box>
      </Box>
    </ValidatorForm>
  );
}

export default OrderListSearchForm;
