import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import { CommaSeparatedNumber } from '../../shared';
import CustomerTypeChip from '../../components/CustomerTypeChip';

const StyledTableCell = styled(TableCell)({
  whiteSpace: 'nowrap',
});

function DebtorTableRow({ debtor }) {
  const { id, name, type, debt, phoneNumber, sequenceNumber } = debtor;
  return (
    <TableRow>
      <StyledTableCell>
        <Link to={`/debtor/${id}`}>{sequenceNumber}</Link>
      </StyledTableCell>
      <StyledTableCell>{name}</StyledTableCell>
      <StyledTableCell>
        <CustomerTypeChip type={type} />
      </StyledTableCell>
      <StyledTableCell>
        {phoneNumber
          ? phoneNumber.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3')
          : '–'}
      </StyledTableCell>
      <StyledTableCell>
        <CommaSeparatedNumber value={debt || 0} fixedDecimalScale />
      </StyledTableCell>
    </TableRow>
  );
}

export default DebtorTableRow;
