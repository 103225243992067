import React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, Switch } from '@material-ui/core';
import OrderTypeSelectValidator from '../../components/OrderTypeSelectValidator';
import ReportStationSelectValidator from './ReportStationSelectValidator';

function ReportForm({
  station,
  orderTypes,
  showTruckAssignment,
  onStationChange,
  onOrderTypesChange,
  onShowTruckAssignmentChange,
}) {
  return (
    <ValidatorForm onSubmit={() => {}}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <ReportStationSelectValidator
            station={station}
            onChange={onStationChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <OrderTypeSelectValidator
            orderTypes={orderTypes}
            onChange={onOrderTypesChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showTruckAssignment}
                onChange={onShowTruckAssignmentChange}
              />
            }
            label="รวมใบสั่งซื้อรถเร่"
          />
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default ReportForm;
