import React, { FormEventHandler, MouseEventHandler } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Button from '@mui/material/Button';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TruckAssignmentListStatusSelector from './TruckAssignmentListStatusSelector';
import TruckAssignmentListDeliveryRouteSelector from './TruckAssignmentListDeliveryRouteSelector';
import ButtonLoader from '../../components/ButtonLoader';

interface TruckAssignmentListSearchFormProps {
  params: any;
  loading: boolean;
  handleReset: MouseEventHandler<HTMLButtonElement>;
  handleChange: (param: string, value: any) => void;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleDownloadClick: MouseEventHandler<HTMLButtonElement>;
}

function TruckAssignmentListSearchForm({
  params,
  loading,
  handleReset,
  handleChange,
  handleSubmit,
  handleDownloadClick,
}: TruckAssignmentListSearchFormProps) {
  const { startDate, endDate, statuses, deliveryRoutes } = params;
  return (
    <ValidatorForm onSubmit={handleSubmit}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TruckAssignmentListDeliveryRouteSelector
            values={deliveryRoutes}
            handleChange={e => handleChange('deliveryRoutes', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TruckAssignmentListStatusSelector
            values={statuses}
            handleChange={e => handleChange('statuses', e.target.value)}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid item xs={12} sm={6} md={4}>
            <KeyboardDatePicker
              margin="none"
              variant="inline"
              label="วันที่ (เริ่มต้น)"
              format="DD/MM/YYYY"
              inputVariant="outlined"
              InputAdornmentProps={{ position: 'start' }}
              invalidDateMessage="วันที่ไม่ถูกต้อง"
              fullWidth
              disableFuture
              value={startDate}
              onChange={startDate =>
                handleChange('startDate', startDate!.startOf('day'))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <KeyboardDatePicker
              margin="none"
              variant="inline"
              label="วันที่ (สิ้นสุด)"
              format="DD/MM/YYYY"
              inputVariant="outlined"
              InputAdornmentProps={{ position: 'start' }}
              invalidDateMessage="วันที่ไม่ถูกต้อง"
              fullWidth
              disableFuture
              minDate={startDate || moment('19000101')}
              value={endDate}
              onChange={endDate =>
                handleChange('endDate', endDate!.endOf('day'))
              }
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <ButtonLoader type="submit" variant="contained" loading={loading}>
              ค้นหา
            </ButtonLoader>
            <Button
              variant="contained"
              disabled={loading}
              onClick={handleDownloadClick}
            >
              ดาวน์โหลด
            </Button>
            <Button
              type="reset"
              color="error"
              variant="contained"
              disabled={loading}
              onClick={handleReset}
            >
              ยกเลิก
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default TruckAssignmentListSearchForm;
