import TableContainer from '@mui/material/TableContainer';
import FulfillmentReportTableHeader from './FulfillmentReportTableHeader';
import Paper from '../../components/Paper';
import FulfillmentReportTableBody from './FulfillmentReportTableBody';
import {
  FulfillmentReportRow,
  FulfillmentReportRowCountsAndTotals,
} from './types';
import Table from '@mui/material/Table';
import withSpinner from '../../components/WithSpinner';
import FulfillmentReportTableFooter from './FulfillmentReportTableFooter';

interface FulfillmentReportTableProps {
  rows: FulfillmentReportRow[];
  summary: FulfillmentReportRowCountsAndTotals;
}

function FulfillmentReportTable({
  rows,
  summary,
}: FulfillmentReportTableProps) {
  return (
    <Paper marginTop padding={false}>
      <TableContainer sx={{ overflow: 'auto', mb: '-1px' }}>
        <Table>
          <FulfillmentReportTableHeader />
          <FulfillmentReportTableBody rows={rows} />
          <FulfillmentReportTableFooter summary={summary} />
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default withSpinner(FulfillmentReportTable);
