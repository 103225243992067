import React, { useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import { API, graphqlOperation } from 'aws-amplify';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import { VALIDATOR } from '../../constants';
import useDebounce from '../../utils/useDebounce';

export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: SearchableCustomerSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
    }
  }
`;

/* eslint-disable jsx-a11y/anchor-is-valid */
function CustomerNameTextValidator({ name, savedName, disabled, onChange }) {
  const ref = useRef();
  const history = useHistory();
  const debouncedName = useDebounce(name, 500);
  const [customers, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!disabled) {
      (async () => {
        const result = await API.graphql(
          graphqlOperation(searchCustomers, {
            filter: {
              or: [
                { name: { wildcard: `*${name}*` } },
                { name: { matchPhrasePrefix: name } },
              ],
            },
            limit: 5,
          }),
        );
        setCustomer(result.data.searchCustomers.items);
      })();
    }
  }, [debouncedName]);

  const validateName = async name => {
    try {
      setLoading(true);
      const result = await API.graphql(
        graphqlOperation(searchCustomers, {
          filter: { name: { eq: name } },
          limit: 5,
        }),
      );
      setLoading(false);
      return isEmpty(result.data.searchCustomers.items);
    } catch {
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    ValidatorForm.addValidationRule('isNameValid', async name => {
      if (savedName === name) {
        return true;
      }
      return validateName(name);
    });
    return () => ValidatorForm.removeValidationRule('isNameValid');
  }, [savedName]);

  return (
    <>
      <TextValidator
        label="ชื่อลูกค้า"
        variant="outlined"
        margin="none"
        fullWidth
        ref={ref}
        disabled={disabled}
        value={name || ''}
        validators={['required', 'isNameValid']}
        errorMessages={[
          VALIDATOR.REQUIRED,
          'ชื่อลูกค้านี้ถูกใช้ไปแล้ว กรุณาเปลี่ยนชื่อ',
        ]}
        onChange={onChange}
        InputProps={{
          endAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress color="inherit" size={20} />
            </InputAdornment>
          ) : null,
        }}
      />
      {!disabled && !isEmpty(customers) && (
        <Box mt={2}>
          <Alert severity="info">
            ลูกค้าที่มีชื่อคล้าย
            {customers.map(({ id, name }) => (
              <Box key={id} mt={1}>
                <Link onClick={() => history.push(`/customer/${id}`)}>
                  {name}
                </Link>
              </Box>
            ))}
          </Alert>
        </Box>
      )}
    </>
  );
}

export default CustomerNameTextValidator;
