import { createSelector } from 'reselect';
import _ from 'lodash';

export const selectOrder = state => state.order;

export const selectOrderHeader = createSelector(
  selectOrder,
  ({ orderHeader }) => orderHeader,
);

export const selectUpdatedOrderHeader = createSelector(
  selectOrder,
  ({ updatedOrderHeader }) => updatedOrderHeader,
);

export const selectOrderId = createSelector(selectOrderHeader, ({ id }) => id);

export const selectOrderItems = createSelector(
  selectOrder,
  ({ orderItems }) => orderItems,
);

export const selectPayment = createSelector(
  selectOrder,
  ({ payment }) => payment,
);

export const selectPayments = createSelector(
  selectOrder,
  ({ payments }) => payments,
);

export const selectImages = createSelector(selectOrder, ({ images }) => images);

export const selectLoading = createSelector(
  selectOrder,
  ({ loading }) => loading,
);

export const selectDisabled = createSelector(
  selectOrder,
  ({ disabled }) => disabled,
);

export const selectDisabledUpdateOrderItems = createSelector(
  selectOrder,
  ({ disabledUpdateOrderItems }) => disabledUpdateOrderItems,
);

export const selectUpdateOrderHeaderInProgress = createSelector(
  selectOrder,
  ({ updateOrderHeaderInProgress }) => updateOrderHeaderInProgress,
);

export const selectUpdateOrderItemsInProgress = createSelector(
  selectOrder,
  ({ updateOrderItemsInProgress }) => updateOrderItemsInProgress,
);

export const selectUpdatedOrderItems = createSelector(
  selectOrder,
  ({ updatedOrderItems }) => updatedOrderItems,
);

export const selectShowLightbox = createSelector(
  selectOrder,
  ({ showLightbox }) => showLightbox,
);

export const selectInitImageIdx = createSelector(
  selectOrder,
  ({ initImageIdx }) => initImageIdx,
);

export const selectShowAdjustmentDialog = createSelector(
  selectOrder,
  ({ showAdjustmentDialog }) => showAdjustmentDialog,
);

export const selectAdjustmentId = createSelector(
  selectOrder,
  ({ adjustmentId }) => adjustmentId,
);

export const selectShowDeleteAdjustmentDialog = createSelector(
  selectOrder,
  ({ showDeleteAdjustmentDialog }) => showDeleteAdjustmentDialog,
);

export const selectAdjustments = createSelector(
  selectOrder,
  ({ adjustments }) => adjustments,
);

export const selectSubtotal = createSelector(selectOrderItems, orderItems =>
  _.reduce(
    orderItems,
    (subtotal, { unitPrice, quantity, adjustment }) => {
      const price = unitPrice + (adjustment || 0);
      return subtotal + price * quantity;
    },
    0,
  ),
);

export const selectPaidAmount = createSelector(selectPayments, payments =>
  _.reduce(payments, (paidAmount, { amount }) => paidAmount + amount, 0),
);

export const selectTotalAdjustment = createSelector(
  selectAdjustments,
  adjustments =>
    _.reduce(
      adjustments,
      (totalAdjustment, { amount }) => amount + totalAdjustment,
      0,
    ),
);

export const selectTotal = createSelector(
  selectSubtotal,
  selectTotalAdjustment,
  (subtotal, totalAdjustment) => subtotal + totalAdjustment,
);
