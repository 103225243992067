import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import Button from '@material-ui/core/Button';

function DoneButton({ onClick }) {
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onClick}
      startIcon={<DoneIcon />}
    >
      เสร็จสิ้นและทำรายการถัดไป
    </Button>
  );
}

export default DoneButton;
