import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

export default function CustomerConfirmButtons({
  disabled,
  onReset,
  onAddDiscountItem,
  onEnableUpdate,
  onCancelUpdate,
}) {
  return disabled ? (
    <Box display="flex">
      <Box mr={1}>
        <Button variant="contained" color="primary" onClick={onEnableUpdate}>
          แก้ไข
        </Button>
      </Box>
      {onReset && (
        <Button variant="contained" color="primary" onClick={onReset}>
          รายการใหม่
        </Button>
      )}
    </Box>
  ) : (
    <Box display="flex">
      <Box mr={1}>
        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          startIcon={<AddIcon />}
          onClick={onAddDiscountItem}
        >
          เพิ่ม
        </Button>
      </Box>
      <Box mr={1}>
        <Button variant="contained" color="primary" type="submit">
          บันทึก
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={onCancelUpdate}
        >
          ยกเลิก
        </Button>
      </Box>
    </Box>
  );
}
