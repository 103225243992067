import {
  GET_NEXT_OVERDUE_ORDERS,
  GET_OVERDUE_ORDERS,
  GET_OVERDUE_ORDERS_ERROR,
  GET_OVERDUE_ORDERS_SUCCESS,
  GET_PREVIOUS_OVERDUE_ORDERS,
  REFRESH_OVERDUE_ORDERS,
} from './constants';

export const getOverdueOrders = () => ({
  type: GET_OVERDUE_ORDERS,
});

export const getOverdueOrdersSuccess = (overdueOrders, nextToken) => ({
  type: GET_OVERDUE_ORDERS_SUCCESS,
  payload: {
    overdueOrders,
    nextToken,
  },
});

export const getOverdueOrdersError = () => ({
  type: GET_OVERDUE_ORDERS_ERROR,
});

export const getNextOverdueOrders = () => ({
  type: GET_NEXT_OVERDUE_ORDERS,
});

export const getPreviousOverdueOrders = () => ({
  type: GET_PREVIOUS_OVERDUE_ORDERS,
});

export const refreshOverdueOrders = () => ({
  type: REFRESH_OVERDUE_ORDERS,
});
