import {
  GET_CURRENT_AUTHENTICATED_USER,
  GET_CURRENT_AUTHENTICATED_USER_ERROR,
  GET_CURRENT_AUTHENTICATED_USER_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_WAREHOUSES,
  GET_WAREHOUSES_SUCCESS,
  HIDE_ERROR_MESSAGE,
  HIDE_SUCCESS_MESSAGE,
  HIDE_WARNING_MESSAGE,
  SET_HIDE_DRAWER,
  SET_SHOW_DRAWER,
  SET_UP_MFA,
  SHOW_ERROR_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  SHOW_WARNING_MESSAGE,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_OUT_ERROR,
  SIGN_OUT_SUCCESS,
  VERIFY_MFA_CODE,
} from './constants';
import { action } from 'typesafe-actions';

export function setShowDrawer() {
  return {
    type: SET_SHOW_DRAWER,
  };
}

export function setHideDrawer() {
  return {
    type: SET_HIDE_DRAWER,
  };
}

export function signIn(username, password) {
  return {
    type: SIGN_IN,
    payload: {
      username,
      password,
    },
  };
}

export function signInSuccess(signInResult, totpCode) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { signInResult, totpCode },
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}

export function signOutSuccess() {
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

export function signOutError() {
  return {
    type: SIGN_OUT_ERROR,
  };
}

export function getCurrentAuthenticatedUser() {
  return {
    type: GET_CURRENT_AUTHENTICATED_USER,
  };
}

export function getCurrentAuthenticatedUserSuccess(signInResult, user) {
  return {
    type: GET_CURRENT_AUTHENTICATED_USER_SUCCESS,
    payload: { signInResult, user },
  };
}

export function getCurrentAuthenticatedUserError() {
  return {
    type: GET_CURRENT_AUTHENTICATED_USER_ERROR,
  };
}

export function verifyMfaCode(mfaCode) {
  return {
    type: VERIFY_MFA_CODE,
    payload: { mfaCode },
  };
}

export function setUpMfa(mfaCode) {
  return {
    type: SET_UP_MFA,
    payload: { mfaCode },
  };
}

export function getProducts() {
  return {
    type: GET_PRODUCTS,
  };
}

export function getProductsSuccess(products) {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: { products },
  };
}

export function getProductsError() {
  return {
    type: GET_PRODUCTS_ERROR,
  };
}

export function getWarehouses() {
  return {
    type: GET_WAREHOUSES,
  };
}

export function getWarehousesSuccess(warehouses) {
  return {
    type: GET_WAREHOUSES_SUCCESS,
    payload: { warehouses },
  };
}

export function getWarehousesError() {
  return {
    type: GET_WAREHOUSES_SUCCESS,
  };
}

export function showSuccessMessage(successMessage) {
  return {
    type: SHOW_SUCCESS_MESSAGE,
    payload: { successMessage },
  };
}

export function hideSuccessMessage() {
  return {
    type: HIDE_SUCCESS_MESSAGE,
  };
}

export const showErrorMessage = (errorMessage?: string) =>
  action(SHOW_ERROR_MESSAGE, { errorMessage });

export const hideErrorMessage = () => action(HIDE_ERROR_MESSAGE);

export const showWarningMessage = (warningMessage: string) =>
  action(SHOW_WARNING_MESSAGE, { warningMessage });

export const hideWarningMessage = () => action(HIDE_WARNING_MESSAGE);
