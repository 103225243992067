import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { SUBMIT_INBOUND } from './constants';
import {
  createInbound as createInboundGraphql,
  updateInbound as updateInboundGraphql,
} from '../../graphql/mutations';
import { selectInbound, selectInventoryItems } from './selectors';
import { submitInboundError, submitInboundSuccess } from './actions';
import { showErrorMessage, showSuccessMessage } from '../App/actions';

function* createInbound() {
  const { warehouseId } = yield select(selectInbound);
  const inventoryItems = yield select(selectInventoryItems);
  const input = { warehouseId, inventoryItems };
  const result = yield call(
    [API, 'graphql'],
    graphqlOperation(createInboundGraphql, { input }),
  );
  const { id, updatedAt, updatedBy } = result.data.createInbound;
  const { id: userId, name, lastName } = updatedBy;
  return {
    id,
    updatedAt,
    warehouseId,
    updatedBy: {
      id: userId,
      name,
      lastName,
    },
  };
}

function* updateInbound() {
  const { id, warehouseId } = yield select(selectInbound);
  const inventoryItems = yield select(selectInventoryItems);
  const input = { id, warehouseId, inventoryItems };
  const result = yield call(
    [API, 'graphql'],
    graphqlOperation(updateInboundGraphql, { input }),
  );
  const { updatedAt, updatedBy } = result.data.updateInbound;
  const { id: userId, name, lastName } = updatedBy;
  return {
    id,
    updatedAt,
    warehouseId,
    updatedBy: {
      id: userId,
      name,
      lastName,
    },
  };
}

function* submitInbound() {
  try {
    const { id } = yield select(selectInbound);
    const inbound = id ? yield call(updateInbound) : yield call(createInbound);
    yield all([put(showSuccessMessage()), put(submitInboundSuccess(inbound))]);
  } catch {
    yield all([put(showErrorMessage()), put(submitInboundError())]);
  }
}

export default function* createInventorySaga() {
  yield takeLatest(SUBMIT_INBOUND, submitInbound);
}
