import React, { useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MuiContainer from '@material-ui/core/Container';
import { createPayment, getTruckAssignment, setPaymentAmount } from './actions';
import {
  selectDisabled,
  selectError,
  selectGroupedProducts,
  selectLoading,
  selectOrderItems,
  selectPayment,
  selectPrintable,
  selectRows,
  selectTruckAssignment,
  selectTruckAssignmentProductGroups,
} from './selectors';
import TruckAssignmentForm from '../../components/TruckAssignmentForm';
import TruckAssignmentOrderTable from '../../components/TruckAssignmentOrderTable';
import { makeSelectTotals } from '../CreateOrUpdateTruckAssignment/selectors';
import OrderOptions from '../../components/OrderOptions';
import Total from '../../components/Total';
import { makeSelectOrderItems } from '../App/selectors';
import TruckAssignmentQueueCard from '../../components/TruckAssignmentQueueCard';
import StatusChip from '../../components/StatusChip';
import { isNotEmpty } from '../../utils/validators';
import useHasPermission from '../../utils/useHasPermission';
import { TRUCK_ASSIGNMENT_STATUS_DESC, USER_GROUP } from '../../constants';

const stateSelector = createStructuredSelector({
  rows: selectRows,
  orderItems: makeSelectOrderItems(selectOrderItems),
  groups: selectTruckAssignmentProductGroups,
  payment: selectPayment,
  totals: makeSelectTotals(selectRows),
  truckAssignment: selectTruckAssignment,
  groupedProducts: selectGroupedProducts,
  error: selectError,
  loading: selectLoading,
  disabled: selectDisabled,
  printable: selectPrintable,
});

export default function TruckAssignment() {
  const printRef = useRef();
  const { truckAssignmentId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    rows,
    orderItems,
    groups,
    payment,
    totals,
    truckAssignment,
    groupedProducts,
    loading,
    disabled,
    printable,
  } = useSelector(stateSelector);
  const { newAmount, subtotal, paidAmount, paymentType } = payment;
  const showUpdateButton = useHasPermission([USER_GROUP.ADMIN]);

  useEffect(() => {
    dispatch(getTruckAssignment(truckAssignmentId));
  }, [dispatch, truckAssignmentId]);

  return (
    <MuiContainer>
      <ValidatorForm onSubmit={() => dispatch(createPayment())} noValidate>
        <TruckAssignmentForm
          disabled
          loading={loading}
          truckAssignment={truckAssignment}
          chip={
            isNotEmpty(truckAssignment, 'status') && (
              <StatusChip
                status={truckAssignment.status}
                description={
                  TRUCK_ASSIGNMENT_STATUS_DESC[truckAssignment.status]
                }
              />
            )
          }
          rightTopMenu={
            <OrderOptions
              ref={printRef}
              printable={printable}
              showUpdateButton={showUpdateButton}
              onUpdateClick={() =>
                history.push(
                  `/createOrUpdateTruckAssignment/${truckAssignmentId}`,
                )
              }
            />
          }
        />
        <TruckAssignmentOrderTable
          disabled
          showSequenceNumber
          loading={loading}
          rows={rows}
          totals={totals}
          groups={groups}
          payment={payment}
          groupedProducts={groupedProducts}
          footerPadding={false}
          rightTopMenu={
            <OrderOptions
              ref={printRef}
              printable={printable}
              printTooltipTitle="พิมพ์บัตรคิว"
              showUpdateButton={showUpdateButton}
              onUpdateClick={() =>
                history.push(
                  `/createOrUpdateTruckAssignment/${truckAssignmentId}`,
                )
              }
            />
          }
          footer={
            <Box textAlign="right">
              <Box display="inline-block" width={{ xs: '100%', sm: 350 }}>
                <Total
                  total={subtotal}
                  disabledPaymentType
                  disabled={disabled}
                  subtotal={subtotal}
                  newAmount={newAmount}
                  paidAmount={paidAmount}
                  paymentType={paymentType}
                  onNewAmountChange={newAmount =>
                    dispatch(setPaymentAmount(newAmount))
                  }
                />
              </Box>
            </Box>
          }
        />
      </ValidatorForm>
      <TruckAssignmentQueueCard
        ref={printRef}
        orderItems={orderItems}
        truckAssignment={truckAssignment}
      />
    </MuiContainer>
  );
}
