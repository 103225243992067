import React from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CommaSeparatedNumber from '../../components/CommaSeparatedNumber';

function ReportAllSummariesAccordionChip({
  title,
  value,
  unit,
  color = 'primary',
}) {
  return (
    <Box mr={0.5} my={0.5}>
      <CommaSeparatedNumber
        value={value}
        fixedDecimalScale
        renderText={value => (
          <Chip
            size="medium"
            color={color}
            label={`${title}: ${value} ${unit}`}
          />
        )}
      />
    </Box>
  );
}

export default ReportAllSummariesAccordionChip;
