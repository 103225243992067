import { useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PaymentIcon from '@mui/icons-material/Payment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DrawerLink from './DrawerLink';
import useHasPermission from '../../utils/useHasPermission';
import { USER_GROUP } from '../../constants';

export default function DrawerOrderLinks() {
  const [openCollapse, setOpenCollapse] = useState(false);
  const hasCreateOrderPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.ORDER,
  ]);
  const hasCashierPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.CASHIER,
  ]);
  const hasOrderListPermission = useHasPermission([
    USER_GROUP.ADMIN,
    USER_GROUP.CASHIER,
    USER_GROUP.ACCOUNTING,
  ]);
  return (
    <>
      <ListItem button onClick={() => setOpenCollapse(!openCollapse)}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="รายการสั่งซื้อ" />
        {openCollapse ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasCreateOrderPermission && (
            <DrawerLink
              paddingLeft
              to="/createOrder"
              title="สั่งซื้อ"
              icon={<AddShoppingCartIcon />}
            />
          )}
          {hasCashierPermission && (
            <DrawerLink
              paddingLeft
              to="/cashier"
              title="ชำระเงิน"
              icon={<PaymentIcon />}
            />
          )}
          {hasOrderListPermission && (
            <DrawerLink
              paddingLeft
              to="/order"
              title="ค้นหารายการ"
              icon={<ListAltIcon />}
            />
          )}
        </List>
      </Collapse>
    </>
  );
}
