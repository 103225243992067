import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

function CreateInventoryItemTableEmptyRow() {
  return (
    <TableRow>
      <TableCell colSpan="3" align="center">
        ไม่พบรายการสินค้า
      </TableCell>
    </TableRow>
  );
}

export default CreateInventoryItemTableEmptyRow;
