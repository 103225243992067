import React, { memo } from 'react';
import Chip from '@material-ui/core/Chip';
import { useTheme } from '@material-ui/core';
import {
  TRUCK_ASSIGNMENT_STATUS,
  TRUCK_ASSIGNMENT_STATUS_DESC,
} from '../../constants';

function TruckAssignmentStatusChip({ status }) {
  const theme = useTheme();
  let backgroundColor;
  switch (status) {
    case TRUCK_ASSIGNMENT_STATUS.COMPLETED:
      backgroundColor = theme.palette.success.main;
      break;
    case TRUCK_ASSIGNMENT_STATUS.OVERDUE:
    case TRUCK_ASSIGNMENT_STATUS.CREATED:
    default:
      backgroundColor = theme.palette.primary.main;
  }
  return (
    <Chip
      label={TRUCK_ASSIGNMENT_STATUS_DESC[status]}
      style={{ backgroundColor, color: 'white' }}
      size="small"
    />
  );
}

export default memo(TruckAssignmentStatusChip);
