import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

function CreateOrderTableHeader() {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" width="5%">
          #
        </TableCell>
        <TableCell align="left" padding="none" width="45%">
          สินค้า
        </TableCell>
        <TableCell align="left" width="50%">
          จำนวน
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default CreateOrderTableHeader;
