import { createSelector } from 'reselect';
import _ from 'lodash';
import { DELIVERY_ROUTE_DESC, ORDER_TYPE } from '../../constants';
import { isNotEmpty } from '../../utils/validators';

export const selectReport = state => state.report;

export const makeSelectDate = () =>
  createSelector(selectReport, ({ date }) => date);

export const makeSelectStation = () =>
  createSelector(selectReport, ({ station }) => station);

export const makeSelectReports = () =>
  createSelector(selectReport, ({ reports }) => reports);

const createTruckAssignmentRow = (truckAssignment, orders) => {
  const { id, status, sequenceNumber, station, deliveryRouteId, createdAt } =
    truckAssignment;
  return {
    id,
    sequenceNumber,
    station,
    status,
    truckAssignmentId: id,
    type: ORDER_TYPE.TRUCK_SALES_ORDER,
    name: `รถเร่: ${DELIVERY_ROUTE_DESC[deliveryRouteId]}`,
    subtotal: _.sumBy(orders, 'subtotal'),
    paidAmount: _.sumBy(orders, 'paidAmount'),
    totalWeight: _.sumBy(orders, 'totalWeight'),
    w40Count: _.sumBy(orders, 'w40Count'),
    w70Count: _.sumBy(orders, 'w70Count'),
    w90Count: _.sumBy(orders, 'w90Count'),
    w115Count: _.sumBy(orders, 'w115Count'),
    w135Count: _.sumBy(orders, 'w135Count'),
    w150Count: _.sumBy(orders, 'w150Count'),
    w480Count: _.sumBy(orders, 'w480Count'),
    isTruckAssignmentRow: true,
    rows: orders,
    createdAt,
  };
};

export const makeSelectRows = showAllRows =>
  createSelector(
    selectReport,
    ({ rows, station, orderTypes, truckAssignments, showTruckAssignment }) => {
      const filterRows = _.filter(
        rows,
        row =>
          showAllRows ||
          (orderTypes.includes(row.type) &&
            (station === 'ALL' || row.station === station)),
      );

      if (showTruckAssignment) {
        const truckAssignmentOrderRows = _.remove(
          filterRows,
          row => !!row.truckAssignmentId,
        );
        if (!_.isEmpty(truckAssignmentOrderRows)) {
          const keyedByTruckAssignments = _.keyBy(truckAssignments, 'id');
          const truckAssignmentRows = _.flow([
            _.partialRight(_.filter, ({ truckAssignmentId }) =>
              isNotEmpty(keyedByTruckAssignments[truckAssignmentId]),
            ),
            _.partialRight(_.groupBy, 'truckAssignmentId'),
            _.entries,
            _.partialRight(_.map, ([truckAssignmentId, orders]) =>
              createTruckAssignmentRow(
                keyedByTruckAssignments[truckAssignmentId],
                orders,
              ),
            ),
          ])(truckAssignmentOrderRows);

          return _.flow([
            _.partialRight(_.concat, truckAssignmentRows),
            _.partialRight(_.sortBy, row => new Date(row.createdAt)),
          ])(filterRows);
        }
      }

      return filterRows;
    },
  );

export const makeSelectShowTruckAssignment = () =>
  createSelector(
    selectReport,
    ({ showTruckAssignment }) => showTruckAssignment,
  );

export const makeSelectOrderTypes = () =>
  createSelector(selectReport, ({ orderTypes }) => orderTypes);

export const makeSelectLoading = () =>
  createSelector(selectReport, ({ loading }) => loading);

const createSummary = filterReports => {
  const salesOrderReports = _.filter(filterReports, [
    'type',
    ORDER_TYPE.SALES_ORDER,
  ]);
  const deliveryOrderReports = _.filter(filterReports, [
    'type',
    ORDER_TYPE.DELIVERY_ORDER,
  ]);
  const truckSalesOrderReports = _.filter(filterReports, [
    'type',
    ORDER_TYPE.TRUCK_SALES_ORDER,
  ]);
  const otherCompanyCustomerOrderReports = _.filter(filterReports, [
    'type',
    ORDER_TYPE.OTHER_COMPANY_CUSTOMER_ORDER,
  ]);
  const sales = _.sumBy(filterReports, 'sales') || 0;
  const cash = _.sumBy(filterReports, 'cash') || 0;
  const totalAdjustment = _.sumBy(filterReports, 'adjustment') || 0;
  const ownedAmount = (Math.round(sales + totalAdjustment - cash) * 100) / 100;

  return {
    sales,
    cash,
    ownedAmount,
    totalAdjustment,
    otherCompanyCustomerSales:
      _.sumBy(otherCompanyCustomerOrderReports, 'sales') || 0,
    salesOrderCash: _.sumBy(salesOrderReports, 'cash') || 0,
    deliveryOrderCash: _.sumBy(deliveryOrderReports, 'cash') || 0,
    truckSalesOrderCash: _.sumBy(truckSalesOrderReports, 'cash') || 0,
    otherCompanyCustomerOrderCash:
      _.sumBy(otherCompanyCustomerOrderReports, 'cash') || 0,
    w40Count: _.sumBy(filterReports, 'w40Count') || 0,
    w70Count: _.sumBy(filterReports, 'w70Count') || 0,
    w90Count: _.sumBy(filterReports, 'w90Count') || 0,
    w115Count: _.sumBy(filterReports, 'w115Count') || 0,
    w135Count: _.sumBy(filterReports, 'w135Count') || 0,
    w150Count: _.sumBy(filterReports, 'w150Count') || 0,
    w480Count: _.sumBy(filterReports, 'w480Count') || 0,
    totalWeightSold: _.sumBy(filterReports, 'weight'),
    salesOrderWeightSold: _.sumBy(salesOrderReports, 'weight') || 0,
    deliveryOrderWeightSold: _.sumBy(deliveryOrderReports, 'weight') || 0,
    truckSalesOrderWeightSold: _.sumBy(truckSalesOrderReports, 'weight') || 0,
    otherCompanyCustomerOrderWeightSold:
      _.sumBy(otherCompanyCustomerOrderReports, 'weight') || 0,
  };
};

export const makeSelectSummary = station =>
  createSelector(selectReport, ({ reports }) => {
    const filterReports = _.filter(
      reports,
      report => station === 'ALL' || report.station === station,
    );
    return createSummary(filterReports);
  });

export const makeSelectSelectedSummary = () =>
  createSelector(selectReport, ({ station, orderTypes, reports }) => {
    const filterReports = _.filter(
      reports,
      report =>
        orderTypes.includes(report.type) &&
        (station === 'ALL' || report.station === station),
    );
    return createSummary(filterReports);
  });
