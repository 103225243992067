export const ADD_INVENTORY_ITEM = 'CreateInbound/ADD_INVENTORY_ITEM';
export const SUBMIT_INBOUND = 'CreateInbound/SUBMIT_INBOUND';
export const SUBMIT_INBOUND_SUCCESS = 'CreateInbound/SUBMIT_INBOUND_SUCCESS';
export const SUBMIT_INBOUND_ERROR = 'CreateInbound/SUBMIT_INBOUND_ERROR';
export const DELETE_INVENTORY_ITEM = 'CreateInbound/DELETE_INVENTORY_ITEM';
export const UPDATE_WAREHOUSE_ID = 'CreateInbound/UPDATE_WAREHOUSE_ID';
export const UPDATE_INVENTORY_ITEM_QUANTITY =
  'CreateInbound/UPDATE_INVENTORY_ITEM_QUANTITY';
export const UPDATE_INVENTORY_ITEM_PRODUCT_ID =
  'CreateInbound/UPDATE_INVENTORY_ITEM_PRODUCT_ID';
export const ENABLE_INBOUND = 'CreateInbound/ENABLE_INBOUND';
export const CANCEL_INBOUND = 'CreateInbound/CANCEL_INBOUND';
export const RESET_INBOUND = 'CreateInbound/RESET_INBOUND';
