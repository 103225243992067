import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import th from 'moment/locale/th';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import config from './aws-exports';
import configureStore from './configureStore';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from './constants';

Amplify.configure(config);

moment.locale('th', th);
moment.tz.setDefault(DEFAULT_TIMEZONE);

const store = configureStore();

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
