import {
  CLEAR_SEARCH_PARAMS,
  EXPORT_ORDERS,
  EXPORT_ORDERS_ERROR,
  EXPORT_ORDERS_SUCCESS,
  REFRESH_SEARCH_ORDERS,
  SEARCH_NEXT_ORDERS,
  SEARCH_ORDERS,
  SEARCH_ORDERS_ERROR,
  SEARCH_ORDERS_SUCCESS,
  SEARCH_PREVIOUS_ORDERS,
  SET_SEARCH_PARAM,
} from './constants';

export const setSearchParam = (param, value) => ({
  type: SET_SEARCH_PARAM,
  payload: {
    param,
    value,
  },
});

export const clearSearchParams = () => ({
  type: CLEAR_SEARCH_PARAMS,
});

export const searchOrders = () => ({
  type: SEARCH_ORDERS,
});

export const searchOrdersSuccess = (orders, nextToken) => ({
  type: SEARCH_ORDERS_SUCCESS,
  payload: {
    orders,
    nextToken,
  },
});

export const searchOrdersError = () => ({
  type: SEARCH_ORDERS_ERROR,
});

export const searchNextOrders = () => ({
  type: SEARCH_NEXT_ORDERS,
});

export const searchPreviousOrders = () => ({
  type: SEARCH_PREVIOUS_ORDERS,
});

export const refreshSearchOrders = () => ({
  type: REFRESH_SEARCH_ORDERS,
});

export const exportOrders = nextToken => ({
  type: EXPORT_ORDERS,
  payload: { nextToken },
});

export const exportOrdersSuccess = nextToken => ({
  type: EXPORT_ORDERS_SUCCESS,
  payload: { nextToken },
});

export const exportOrdersError = () => ({
  type: EXPORT_ORDERS_ERROR,
});
