import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Container from '@material-ui/core/Container';
import OrderTable from '../../components/OrderTable';
import OrderListSearchForm from './OrderListSearchForm';
import {
  makeSelectError,
  makeSelectExportNextToken,
  makeSelectLoading,
  makeSelectOrders,
  makeSelectParams,
  makeSelectShowNext,
  makeSelectShowPrevious,
} from './selectors';
import {
  clearSearchParams,
  exportOrders,
  refreshSearchOrders,
  searchNextOrders,
  searchOrders,
  searchPreviousOrders,
  setSearchParam,
} from './actions';
import { SNACKBAR_VARIANT_TYPE } from '../../constants';
import useEffectAfterFirstRender from '../../utils/uesEffectAfterFirstRender';
import Paper from '../../components/Paper';
import ExportDialog from '../../components/ExportDialog';

const stateSelector = createStructuredSelector({
  params: makeSelectParams(),
  orders: makeSelectOrders(),
  exportNextToken: makeSelectExportNextToken(),
  showNext: makeSelectShowNext(),
  showPrevious: makeSelectShowPrevious(),
  error: makeSelectError(),
  loading: makeSelectLoading(),
});

function OrderList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    params,
    orders,
    showNext,
    showPrevious,
    exportNextToken,
    error,
    loading,
  } = useSelector(stateSelector);
  const [showExportDialog, setShowExportDialog] = useState(false);

  useEffect(
    () => () => {
      dispatch(clearSearchParams());
    },
    [],
  );

  useEffect(() => {
    if (exportNextToken) {
      setShowExportDialog(true);
    }
  }, [exportNextToken]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('ระบบเกิดข้อผิดพลาดกรุณาลองใหม่ภายหลัง', {
        variant: SNACKBAR_VARIANT_TYPE.ERROR,
      });
    }
  }, [enqueueSnackbar, error]);

  useEffectAfterFirstRender(() => {
    if (!orders.length) {
      enqueueSnackbar('ไม่พบข้อมูล', {
        variant: SNACKBAR_VARIANT_TYPE.WARNING,
      });
    }
  }, [enqueueSnackbar, orders]);

  return (
    <Container>
      <Paper elevation={0}>
        <Typography variant="h6">ค้นหารายการสั่งซื้อ</Typography>
        <Box mt={2}>
          <OrderListSearchForm
            params={params}
            loading={loading}
            onExport={() => dispatch(exportOrders())}
            onSubmit={() => dispatch(searchOrders())}
            onReset={() => dispatch(clearSearchParams())}
            onChange={(param, value) => dispatch(setSearchParam(param, value))}
          />
        </Box>
      </Paper>
      {orders && (
        <Box mt={3}>
          <OrderTable
            orders={orders}
            loading={loading}
            showNext={showNext}
            showPrevious={showPrevious}
            next={() => dispatch(searchNextOrders())}
            previous={() => dispatch(searchPreviousOrders())}
            refresh={() => dispatch(refreshSearchOrders())}
          />
        </Box>
      )}
      <ExportDialog
        show={showExportDialog}
        handleSubmit={() => {
          dispatch(exportOrders(exportNextToken));
          setShowExportDialog(false);
        }}
        handleClose={() => setShowExportDialog(false)}
      />
    </Container>
  );
}

export default OrderList;
