import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  SelectValidator,
  TextValidator,
} from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import moment from 'moment-timezone';
import { VALIDATOR } from '../../constants';

function CreateInboundForm({
  name,
  lastName,
  disabled,
  createdAt,
  warehouses,
  warehouseId,
  onWarehouseChange,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={4}>
        <SelectValidator
          label="คลังเก็บสินค้า"
          variant="outlined"
          margin="none"
          fullWidth
          value={warehouseId}
          validators={['required']}
          errorMessages={[VALIDATOR.REQUIRED]}
          disabled={disabled || _.isEmpty(warehouses)}
          onChange={onWarehouseChange}
        >
          {warehouses.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </SelectValidator>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <TextValidator
          disabled
          fullWidth
          margin="none"
          label="ผู้ทำรายการ"
          variant="outlined"
          value={`${name} ${lastName}`}
        />
      </Grid>
      {createdAt && (
        <Grid item xs={12} md={6} lg={4}>
          <TextValidator
            label="วันที่ทำรายการ"
            variant="outlined"
            margin="none"
            disabled
            fullWidth
            value={moment(createdAt)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm')}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default CreateInboundForm;
