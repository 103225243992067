import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

function CustomerDeleteCustomerPriceDialog({
  open,
  onAgreeClick,
  onDisagreeClick,
}) {
  return (
    <Dialog open={open} maxWidth="xs" onClose={onDisagreeClick}>
      <DialogTitle>ต้องส่วนลดนี้ ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          กรุณาคลิก &#8220;ตกลง&#8221; หากคุณต้องการยกเลิกรายการสั่งซื้อนี้
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onAgreeClick} color="primary">
          ตกลง
        </Button>
        <Button variant="contained" onClick={onDisagreeClick} color="secondary">
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomerDeleteCustomerPriceDialog;
