import React from 'react';
import Box from '@material-ui/core/Box';
import Image from 'material-ui-image';
import Carousel from 'react-multi-carousel';
import { useTheme } from '@material-ui/core';
import withSpinner from '../WithSpinner';
import Paper from '../Paper';
import Spinner from '../../shared/components/Spinner';
import 'react-multi-carousel/lib/styles.css';
import Title from '../Title';

/* eslint-disable jsx-a11y/alt-text */
function Album({ images, onClick, marginTop = false }) {
  const theme = useTheme();
  const responsive = {
    lg: {
      breakpoint: {
        max: 10000,
        min: theme.breakpoints.values.md,
      },
      items: 4,
    },
    md: {
      breakpoint: {
        max: theme.breakpoints.values.md,
        min: theme.breakpoints.values.sm,
      },
      items: 3,
    },
    sm: {
      breakpoint: {
        max: theme.breakpoints.values.sm,
        min: theme.breakpoints.values.xs,
      },
      items: 2,
    },
    xs: {
      breakpoint: {
        max: theme.breakpoints.values.xs,
        min: 0,
      },
      items: 1,
    },
  };
  return (
    <Paper marginTop={marginTop}>
      <Title title="รูปถ่าย" marginBottom />
      <Carousel
        infinite={false}
        centerMode={false}
        focusOnSelect={false}
        responsive={responsive}
      >
        {images.map((image, idx) => (
          <Box
            mr={1}
            key={image.key}
            overflow="hidden"
            sx={{ cursor: 'pointer' }}
            borderRadius="borderRadius"
          >
            <Image
              cover
              key={image.key}
              src={image.url}
              loading={<Spinner />}
              onClick={() => onClick(idx)}
            />
          </Box>
        ))}
      </Carousel>
    </Paper>
  );
}

export default withSpinner(Album);
