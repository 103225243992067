export const workflowsByCreatedDate = /* GraphQL */ `
  query WorkflowsByCreatedDate(
    $createdDate: AWSDate
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workflowsByCreatedDate(
      createdDate: $createdDate
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        station
        comment
        createdDate
        createdAt
        order {
          id
          sequenceNumber
          fulfillmentStatus
          type
          station
          comment
          createdAt
          customer {
            id
            type
            sequenceNumber
            name
          }
        }
        workflowItems {
          items {
            id
            type
            workflowId
            productId
            quantity
          }
          nextToken
          startedAt
        }
        truckAssignment {
          id
          driver
          companion
          station
          deliveryRouteId
          licensePlate
          sequenceNumber
          comment
          departure
          fulfillmentStatus
          createdAt
          deliveryRoute {
            name
          }
        }
        user {
          id
          firstName
          lastName
        }
      }
      nextToken
      startedAt
    }
  }
`;
