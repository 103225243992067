import React from 'react';

import { TextValidator } from 'react-material-ui-form-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { VALIDATOR } from '../../constants';
import ProductSelector from '../../components/ProductSelector';
import TableRow from '../../components/TableRow';
import TableCell from '../../components/TableCell';

function CreateInventoryItemTableRow({
  idx,
  inventoryItem,
  inventoryItems,
  products,
  disabled,
  onDelete,
  onProductChange,
  onQuantityChange,
}) {
  const { productId, quantity } = inventoryItem;
  return (
    <TableRow>
      <TableCell align="center" verticalAlign="middle">
        {idx + 1}
      </TableCell>
      <TableCell padding="none">
        <ProductSelector
          disabled={disabled}
          products={products}
          productId={productId}
          selectedProducts={inventoryItems}
          onChange={({ id }) => onProductChange(idx, id)}
        />
      </TableCell>
      <TableCell>
        <TextValidator
          fullWidth
          margin="none"
          type="number"
          variant="outlined"
          placeholder="จำนวน"
          disabled={disabled}
          value={quantity || ''}
          validators={['required', 'isNumber', 'minNumber:1']}
          errorMessages={[
            VALIDATOR.REQUIRED,
            VALIDATOR.IS_NUMBER,
            VALIDATOR.GREATER_THEN_OR_EQUAL_ONE,
          ]}
          onChange={e => onQuantityChange(idx, Number(e.target.value))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="secondary"
                  disabled={disabled}
                  onClick={() => onDelete(idx)}
                >
                  <RemoveCircleIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export default CreateInventoryItemTableRow;
