import * as ACTION_TYPES from './actionTypes';
import { PRODUCT_TYPE } from '../../constants';

export const initialState = {
  brandName: '',
  product: {
    id: null,
    name: '',
    weight: '',
    brandId: '',
    brand: {},
    type: PRODUCT_TYPE.LPG,
    isTruckAssignmentProduct: false,
  },
  savedProduct: {
    id: null,
    name: '',
    weight: '',
    brandId: '',
    brand: {},
    type: PRODUCT_TYPE.LPG,
    isTruckAssignmentProduct: false,
  },
  brands: [],
  prices: [],
  savedPrices: [],
  loading: false,
  disabled: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_BRAND_NAME:
      return {
        ...state,
        brandName: action.payload,
      };
    case ACTION_TYPES.SET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case ACTION_TYPES.SET_SAVED_PRODUCT:
      return {
        ...state,
        savedProduct: action.payload,
      };
    case ACTION_TYPES.SET_PRICES:
      return {
        ...state,
        prices: action.payload,
      };
    case ACTION_TYPES.SET_SAVED_PRICES:
      return {
        ...state,
        savedPrices: action.payload,
      };
    case ACTION_TYPES.SET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_TYPES.SET_DISABLED:
      return {
        ...state,
        disabled: action.payload,
      };
    default:
      return state;
  }
};
