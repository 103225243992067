import React from 'react';
import { useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { CUSTOMER_TYPE, CUSTOMER_TYPE_DESC } from '../../constants';

function CustomerTypeChip({ type }) {
  const theme = useTheme();
  return (
    <Chip
      label={CUSTOMER_TYPE_DESC[type]}
      style={{
        backgroundColor:
          type === CUSTOMER_TYPE.MEMBER
            ? theme.palette.success.main
            : theme.palette.primary.main,
        color: 'white',
      }}
      size="small"
    />
  );
}

export default CustomerTypeChip;
