import React, { ChangeEventHandler } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { SelectValidator } from 'react-material-ui-form-validator';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { TRUCK_ASSIGNMENT_STATUS_DESC } from '../../constants';

interface TruckAssignmentListDeliveryRouteSelectorProps {
  values: string[];
  handleChange: ChangeEventHandler<HTMLSelectElement>;
}

function TruckAssignmentListStatusSelector({
  values,
  handleChange,
}: TruckAssignmentListDeliveryRouteSelectorProps) {
  const renderChips = (keys, descriptions) =>
    keys.map(key => (
      <Box key={key} display="inline" mr={1}>
        <Chip label={descriptions[key]} color="primary" size="small" />
      </Box>
    ));

  const renderMenuItems = (selectedItems, descriptions) =>
    Object.keys(descriptions).map(key => (
      <MenuItem key={key} value={key}>
        <Checkbox checked={selectedItems.includes(key)} />
        <ListItemText primary={descriptions[key]} />
      </MenuItem>
    ));

  return (
    <SelectValidator
      label="สถานะ"
      variant="outlined"
      margin="none"
      fullWidth
      SelectProps={{
        multiple: true,
        value: values,
        onChange: handleChange,
        renderValue: value => renderChips(value, TRUCK_ASSIGNMENT_STATUS_DESC),
      }}
    >
      <MenuItem value="" disabled>
        กรุณาเลือก
      </MenuItem>
      {renderMenuItems(values, TRUCK_ASSIGNMENT_STATUS_DESC)}
    </SelectValidator>
  );
}

export default TruckAssignmentListStatusSelector;
