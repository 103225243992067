import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import PaymentHistoryTableRow from './PaymentHistoryTableRow';
import PaymentHistoryTableHeader from './PaymentHistoryTableHeader';
import Container from '../../components/Container';

/* eslint-disable react/jsx-props-no-spreading */
function PaymentHistoryTable({ payments, ...otherProps }) {
  return (
    <TableContainer
      title="ประวัติการชำระเงิน"
      component={Container}
      {...otherProps}
    >
      <Table>
        <PaymentHistoryTableHeader />
        <TableBody>
          {payments.map((payment, idx) => (
            <PaymentHistoryTableRow
              key={payment.id}
              idx={idx}
              payment={payment}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PaymentHistoryTable;
