import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import _ from 'lodash';
import moment from 'moment-timezone';
import { GET_REPORTS, SET_DATE } from './constants';
import { makeSelectDate } from './selectors';
import {
  listProducts,
  listReports,
  truckAssignmentsByCreatedDate,
} from '../../graphql/queries';
import { getReportsError, getReportsSuccess } from './actions';
import { ordersByCreatedDateGraphql } from './queries';

function getItemCountByWeight(orderItems) {
  const counts = {};
  orderItems.forEach(({ weight, quantity }) => {
    if (weight) {
      const key = `w${Math.round(weight * 10)}Count`;
      counts[key] = (counts[key] ? counts[key] : 0) + quantity;
    }
  });
  return counts;
}

function getTotalWeight(orderItems) {
  return _.reduce(
    orderItems,
    (totalWeight, { quantity, weight }) => totalWeight + quantity * weight,
    0,
  );
}

function getSubtotal(orderItems) {
  return _.reduce(
    orderItems,
    (subtotal, { quantity, price }) => subtotal + price * quantity,
    0,
  );
}

function getTotalAdjustment(adjustments) {
  return _.reduce(adjustments, (total, { amount }) => total + amount, 0);
}

function getPaidAmount(orderCreatedDate, payments) {
  return _.reduce(
    payments,
    (paidAmount, { amount, createdAt }) => {
      const createdAtDate = moment(createdAt)
        .tz('Asia/Bangkok')
        .format('YYYY-MM-DD');
      return orderCreatedDate === createdAtDate
        ? paidAmount + amount
        : paidAmount;
    },
    0,
  );
}

function getOrderItems(products, orderItems) {
  return orderItems.items.map(orderItem => {
    const { id, productId, quantity, unitPrice, adjustment } = orderItem;
    const price = unitPrice + (adjustment || 0);
    return {
      id,
      productId,
      quantity,
      price,
      type: products[productId].type,
      name: products[productId].name,
      weight: products[productId].weight,
      subtotal: price * quantity,
    };
  });
}

function* getRows(createdDate, products) {
  let orders = [];
  let nextToken = null;
  do {
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(ordersByCreatedDateGraphql, {
        nextToken,
        createdDate,
        sortDirection: 'ASC',
      }),
    );
    orders = _.union(orders, result.data.ordersByCreatedDate.items);
    nextToken = result.data.ordersByCreatedDate.nextToken;
  } while (!_.isEmpty(nextToken));

  return orders.map(order => {
    const {
      id,
      sequenceNumber,
      type,
      status,
      station,
      truckAssignmentId,
      customer,
      payments,
      createdAt,
    } = order;
    const orderItems = getOrderItems(products, order.orderItems);
    const adjustments = order.adjustments.items;
    const counts = getItemCountByWeight(orderItems);
    const subtotal = getSubtotal(orderItems);
    const totalAdjustment = getTotalAdjustment(adjustments);
    const paidAmount = getPaidAmount(createdDate, payments.items);
    const ownedAmount =
      (Math.round(subtotal + totalAdjustment - paidAmount) * 100) / 100;
    return {
      id,
      sequenceNumber,
      type,
      status,
      station,
      truckAssignmentId,
      name: customer.name,
      subtotal,
      totalAdjustment,
      paidAmount,
      ownedAmount,
      totalWeight: getTotalWeight(orderItems),
      ...counts,
      orderItems,
      createdAt,
    };
  });
}

function* getReport() {
  try {
    const date = yield select(makeSelectDate());
    const formattedDate = date.format('YYYY-MM-DD');
    const results = yield all([
      call([API, 'graphql'], graphqlOperation(listProducts)),
      call(
        [API, 'graphql'],
        graphqlOperation(listReports, { date: formattedDate }),
      ),
      call(
        [API, 'graphql'],
        graphqlOperation(truckAssignmentsByCreatedDate, {
          createdDate: formattedDate,
          sortDirection: 'ASC',
        }),
      ),
    ]);
    const products = _.keyBy(results[0].data.listProducts.items, 'id');
    const reports = results[1].data.listReports.items;
    const truckAssignments =
      results[2].data.truckAssignmentsByCreatedDate.items;
    const rows = yield call(getRows, formattedDate, products);
    yield put(getReportsSuccess(reports, rows, truckAssignments));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    yield put(getReportsError());
  }
}

export default function* reportSaga() {
  yield takeLatest([SET_DATE, GET_REPORTS], getReport);
}
