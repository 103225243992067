import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

function CreateInventoryItemTableButtons({
  disabled,
  disabledSubmit,
  onAdd,
  onReset,
  onEnable,
  onCancel,
}) {
  return disabled ? (
    <Box display="flex">
      <Box mr={1}>
        <Button color="primary" variant="contained" onClick={onEnable}>
          แก้ไข
        </Button>
      </Box>
      <Button color="primary" variant="contained" onClick={onReset}>
        รายการใหม่
      </Button>
    </Box>
  ) : (
    <Box display="flex">
      <Box mr={1}>
        <Button
          color="primary"
          variant="contained"
          disabled={disabled}
          startIcon={<AddIcon />}
          onClick={onAdd}
        >
          เพิ่ม
        </Button>
      </Box>
      <Box mr={1}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={disabledSubmit}
        >
          บันทึก
        </Button>
      </Box>
      <Button
        color="secondary"
        variant="contained"
        disabled={disabled}
        onClick={onCancel}
      >
        ยกเลิก
      </Button>
    </Box>
  );
}

export default CreateInventoryItemTableButtons;
