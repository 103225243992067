import {
  CANCEL_ORDER,
  CANCEL_ORDER_ERROR,
  CANCEL_ORDER_ITEM,
  CANCEL_ORDER_ITEM_ERROR,
  CANCEL_ORDER_ITEM_SUCCESS,
  CANCEL_ORDER_SUCCESS,
  CREATE_ADJUSTMENT,
  CREATE_ADJUSTMENT_ERROR,
  CREATE_ADJUSTMENT_SUCCESS,
  CREATE_PAYMENT,
  CREATE_PAYMENT_ERROR,
  CREATE_PAYMENT_SUCCESS,
  DELETE_ADJUSTMENT,
  DELETE_ADJUSTMENT_ERROR,
  DELETE_ADJUSTMENT_SUCCESS,
  DISABLE_UPDATE_ORDER_HEADER_IN_PROGRESS,
  DISABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS,
  ENABLE_UPDATE_ORDER_HEADER_IN_PROGRESS,
  ENABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS,
  GET_ORDER,
  GET_ORDER_ERROR,
  GET_ORDER_SUCCESS,
  HIDE_ADJUSTMENT_DIALOG,
  HIDE_DELETE_ADJUSTMENT_DIALOG,
  HIDE_LIGHT_BOX,
  RESET_ORDER,
  SET_NEW_AMOUNT,
  SET_PAYMENT_TYPE,
  SET_UPDATED_ORDER,
  SET_UPDATED_ORDER_ITEM,
  SHOW_ADJUSTMENT_DIALOG,
  SHOW_DELETE_ADJUSTMENT_DIALOG,
  SHOW_LIGHT_BOX,
  UPDATE_ORDER_HEADER,
  UPDATE_ORDER_HEADER_ERROR,
  UPDATE_ORDER_HEADER_SUCCESS,
  UPDATE_ORDER_ITEMS,
  UPDATE_ORDER_ITEMS_ERROR,
  UPDATE_ORDER_ITEMS_SUCCESS,
} from './constants';

export function getOrder(orderId) {
  return {
    type: GET_ORDER,
    payload: { orderId },
  };
}

export function getOrderSuccess(
  orderHeader,
  orderItems,
  adjustments,
  payments,
  images,
) {
  return {
    type: GET_ORDER_SUCCESS,
    payload: {
      orderHeader,
      orderItems,
      adjustments,
      payments,
      images,
    },
  };
}

export function getOrderError() {
  return {
    type: GET_ORDER_ERROR,
  };
}

export function setNewAmount(newAmount) {
  return {
    type: SET_NEW_AMOUNT,
    payload: { newAmount },
  };
}

export function setPaymentType(paymentType) {
  return {
    type: SET_PAYMENT_TYPE,
    payload: { paymentType },
  };
}

export function createPayment() {
  return {
    type: CREATE_PAYMENT,
  };
}

export function createPaymentSuccess(status, payments) {
  return {
    type: CREATE_PAYMENT_SUCCESS,
    payload: { status, payments },
  };
}

export function createPaymentError() {
  return {
    type: CREATE_PAYMENT_ERROR,
  };
}

export function setUpdatedOrder(param, value) {
  return {
    type: SET_UPDATED_ORDER,
    payload: {
      param,
      value,
    },
  };
}

export function enableUpdateOrderHeaderInProgress() {
  return {
    type: ENABLE_UPDATE_ORDER_HEADER_IN_PROGRESS,
  };
}

export function disableUpdateOrderHeaderInProgress() {
  return {
    type: DISABLE_UPDATE_ORDER_HEADER_IN_PROGRESS,
  };
}

export function updateOrderHeader() {
  return {
    type: UPDATE_ORDER_HEADER,
  };
}

export function updateOrderHeaderSuccess(orderHeader) {
  return {
    type: UPDATE_ORDER_HEADER_SUCCESS,
    payload: { orderHeader },
  };
}

export function updateOrderHeaderError() {
  return {
    type: UPDATE_ORDER_HEADER_ERROR,
  };
}

export function cancelOrder() {
  return {
    type: CANCEL_ORDER,
  };
}

export function cancelOrderSuccess(orderHeader) {
  return {
    type: CANCEL_ORDER_SUCCESS,
    payload: { orderHeader },
  };
}

export function cancelOrderError() {
  return {
    type: CANCEL_ORDER_ERROR,
  };
}

export function enableUpdateOrderItemsInProgress() {
  return {
    type: ENABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS,
  };
}

export function disableUpdateOrderItemsInProgress() {
  return {
    type: DISABLE_UPDATE_ORDER_ITEMS_IN_PROGRESS,
  };
}

export function setUpdatedOrderItem(idx, param, value) {
  return {
    type: SET_UPDATED_ORDER_ITEM,
    payload: {
      idx,
      param,
      value,
    },
  };
}

export function updateOrderItems() {
  return {
    type: UPDATE_ORDER_ITEMS,
  };
}

export function updateOrderItemsSuccess(orderItems) {
  return {
    type: UPDATE_ORDER_ITEMS_SUCCESS,
    payload: { orderItems },
  };
}

export function updateOrderItemsError() {
  return {
    type: UPDATE_ORDER_ITEMS_ERROR,
  };
}

export function cancelOrderItem(idx) {
  return {
    type: CANCEL_ORDER_ITEM,
    payload: { idx },
  };
}

export function cancelOrderItemSuccess(idx) {
  return {
    type: CANCEL_ORDER_ITEM_SUCCESS,
    payload: { idx },
  };
}

export function cancelOrderItemError() {
  return {
    type: CANCEL_ORDER_ITEM_ERROR,
  };
}

export function showLightbox(initImageIdx) {
  return {
    type: SHOW_LIGHT_BOX,
    payload: { initImageIdx },
  };
}

export function hideLightbox() {
  return {
    type: HIDE_LIGHT_BOX,
  };
}

export function showAdjustmentDialog() {
  return {
    type: SHOW_ADJUSTMENT_DIALOG,
  };
}

export function hideAdjustmentDialog() {
  return {
    type: HIDE_ADJUSTMENT_DIALOG,
  };
}

export function showDeleteAdjustmentDialog(id) {
  return {
    type: SHOW_DELETE_ADJUSTMENT_DIALOG,
    payload: { id },
  };
}

export function hideDeleteAdjustmentDialog() {
  return {
    type: HIDE_DELETE_ADJUSTMENT_DIALOG,
  };
}

export function createAdjustment(amount, comment) {
  return {
    type: CREATE_ADJUSTMENT,
    payload: { amount, comment },
  };
}

export function createAdjustmentSuccess(adjustment) {
  return {
    type: CREATE_ADJUSTMENT_SUCCESS,
    payload: { adjustment },
  };
}

export function createAdjustmentError() {
  return {
    type: CREATE_ADJUSTMENT_ERROR,
  };
}

export function deleteAdjustment(id) {
  return {
    type: DELETE_ADJUSTMENT,
    payload: { id },
  };
}

export function deleteAdjustmentSuccess(removedAdjustmentId) {
  return {
    type: DELETE_ADJUSTMENT_SUCCESS,
    payload: { removedAdjustmentId },
  };
}

export function deleteAdjustmentError() {
  return {
    type: DELETE_ADJUSTMENT_ERROR,
  };
}

export function resetOrder() {
  return {
    type: RESET_ORDER,
  };
}
