import React, { MouseEventHandler } from 'react';
import { Moment } from 'moment';
import Paper from '../../components/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import FulfillmentReportSummaryTable from './FulfillmentReportSummaryTable';
import { FulfillmentReportRowCountsAndTotals } from './types';
import withSpinner from '../../components/WithSpinner';
import DatePickerButton from '../../components/DatePickerButton';

interface FulfillmentReportSummaryProps {
  date: Moment;
  summary: FulfillmentReportRowCountsAndTotals;
  handleDateChange: (date: Moment) => void;
  handleRefresh: MouseEventHandler<HTMLButtonElement>;
}

function FulfillmentReportSummary({
  date,
  summary,
  handleRefresh,
  handleDateChange,
}: FulfillmentReportSummaryProps) {
  return (
    <Paper padding={false}>
      <Typography variant="h6" sx={{ p: 2 }}>
        {date.format('รายงานบรรจุแก๊สประจำวันddddที่ D MMMM YYYY')}
      </Typography>
      <Divider />
      <FulfillmentReportSummaryTable summary={summary} />
      <Divider />
      <Stack direction="row" spacing={1} sx={{ p: 1 }}>
        <DatePickerButton
          value={date}
          onChange={date => {
            if (date) handleDateChange(date);
          }}
        />
        <IconButton size="small" onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
}

export default withSpinner(FulfillmentReportSummary);
