// eslint-disable-next-line import/prefer-default-export
export const getTruckAssignment = /* GraphQL */ `
  query GetTruckAssignment($id: ID!) {
    getTruckAssignment(id: $id) {
      id
      driver
      companion
      departure
      station
      licensePlate
      sequenceNumber
      deliveryRouteId
      status
      comment
      deliveryRoute {
        id
        name
        licensePlate
        customers {
          items {
            id
            name
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      orders {
        items {
          id
          customerId
          orderItems {
            items {
              productId
              quantity
              _version
              _deleted
              _lastChangedAt
            }
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
