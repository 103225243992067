import React from 'react';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectDisabledCancelButton,
  makeSelectDisabledConfirmButton,
} from '../CreateOrUpdateTruckAssignment/selectors';
import DoneButton from '../../components/DoneButton';

const stateSelector = createStructuredSelector({
  disabledCancelButton: makeSelectDisabledCancelButton,
  disabledConfirmButton: makeSelectDisabledConfirmButton,
});

// TODO: Refactor this
function TruckAssignmentConfirmButtons({ saved, onNew, onReset }) {
  const { disabledCancelButton, disabledConfirmButton } =
    useSelector(stateSelector);
  return (
    <Box display="flex" flexWrap="wrap">
      {saved ? (
        <DoneButton onClick={onNew} />
      ) : (
        <>
          <Box mr={1}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabledConfirmButton}
            >
              บันทึก
            </Button>
          </Box>
          <Button
            variant="contained"
            color="secondary"
            type="reset"
            disabled={disabledCancelButton}
            onClick={onReset}
          >
            ยกเลิก
          </Button>
        </>
      )}
    </Box>
  );
}

export default TruckAssignmentConfirmButtons;
