import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Tooltip from '@mui/material/Tooltip';
import ReactToPrint from 'react-to-print';
import EditIcon from '@mui/icons-material/Edit';
import { isNotEmpty } from '../../utils/validators';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@material-ui/icons/Print';

function OrderOptions(
  {
    onUpdateClick,
    onPrintQueueCardClick,
    editTooltipTitle = 'แก้ไขรายการสิ้นค้า',
    printTooltipTitle = 'พิมพ์บัตรเสร็จรับเงิน',
    printable = false,
    showPrintQueueOption = false,
    showPrintOption = true,
    showUpdateButton = false,
  },
  ref,
) {
  return (
    <Box gap={1} display={'flex'} alignItems={'center'}>
      {showPrintQueueOption && (
        <Tooltip title="พิมพ์บัตรคิว">
          <IconButton onClick={onPrintQueueCardClick}>
            <PrintIcon />
          </IconButton>
        </Tooltip>
      )}
      {showPrintOption && isNotEmpty(ref, 'current') && (
        <ReactToPrint
          pageStyle="@page { margin: 5mm }"
          content={() => ref.current}
          trigger={() => (
            <Tooltip title={printTooltipTitle}>
              <IconButton disabled={!printable}>
                <ReceiptIcon />
              </IconButton>
            </Tooltip>
          )}
        />
      )}
      {showUpdateButton && (
        <Tooltip title={editTooltipTitle}>
          <IconButton onClick={onUpdateClick}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

export default forwardRef(OrderOptions);
