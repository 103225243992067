// eslint-disable-next-line import/prefer-default-export
export const searchTruckAssignments = /* GraphQL */ `
  query SearchTruckAssignments(
    $filter: SearchableTruckAssignmentFilterInput
    $sort: SearchableTruckAssignmentSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchTruckAssignments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        driver
        companion
        station
        deliveryRouteId
        sequenceNumber
        departure
        status
        comment
        deliveryRoute {
          id
          name
          licensePlate
          createdAt
          updatedAt
        }
        orders {
          items {
            orderItems {
              items {
                productId
                quantity
                unitPrice
                adjustment
              }
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
