import { createSelector } from 'reselect';

export const selectCreateInventoryItem = state => state.createInventoryItem;

export const selectInventoryItems = createSelector(
  selectCreateInventoryItem,
  ({ inventoryItems }) => inventoryItems,
);

export const selectLoading = createSelector(
  selectCreateInventoryItem,
  ({ loading }) => loading,
);

export const selectDisabled = createSelector(
  selectCreateInventoryItem,
  ({ disabled }) => disabled,
);

export const selectInbound = createSelector(
  selectCreateInventoryItem,
  ({ inbound }) => inbound,
);
